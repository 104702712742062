import React, { useEffect,useState} from "react";
import Chart from "chart.js";
import "./foodhabits.css";
import axios from "axios";
import DatePicker from 'react-date-picker';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
let myChart;
let myChart2;
function FoodHabits(props) {
  const [startDate, setStartDate]=useState(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]);
  const [endDate,setEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset()*60*1000)).toISOString().split('T')[0]);
  const [patientId,] =useState(props.id);
  const [foodList,setFoodList]=useState([]);
  const [nutrientsArray,setNutrientsArray]=useState([]);
  const [updateRender,setUpdateRender]=useState(false);
  const [advice,setAdvice]=useState('');
 const [adviceDate,setAdviceDate] = useState();
  const [submitInstructionsMsg,setSubmitInstructionsMsg] = useState("");
   const [modalInstructionsShow,setModalInstructionsShow]=useState(false);
    const [errorSubmit,setErrorSubmit]=useState(false);
  const [submitMsg,setSubmitMsg] = useState("");
    const [,setAdviceArray] = useState([]);
    const [refreshAdvice,setRefreshAdvice]=useState(false);
  const [,setAverageNutrientsGrams]=useState();
  const [caloriesData,setCaloriesData]=useState();
const [,setAvgFat]=useState();
const [,setAvgCarb]=useState();
const [,setAvgProtein]=useState();
const [avgArray,setAvgArray]=useState([]);
const [table1,setTable1]=useState(true);
const [table2,setTable2]=useState(false);
const [pieShow, setPieShow]= useState(true)
const [barShow, setBarShow]= useState(true)
const [foodDateWise,setFoodDateWise]= useState([{
   date: "2020-10-2",
   food_consumed: [
                {
                    name: "poratta",
                    quantity: 10,
                    unit: "piece"
                },
                {
                    name :"Puri",
                    quantity: 10,
                    unit :"piece"
                }
            ]
        },
        {
   date: "2020-10-20",
   food_consumed: [
                {
                    name: "bbbporatta",
                    quantity: 180,
                    unit: "bbbpiece"
                },
                {
                    name :"nnPuri",
                    quantity: null,
                     unit: "nnpiece"
                }
            ]
        },
        {
   date: "2020-10-2",
   food_consumed: [
                {
                    name: "poratta",
                    quantity: 10,
                    unit: "piece"
                },
                {
                    name :"Puri",
                    quantity: 10,
                    unit: "piece"
                }
            ]
        }

]);


  useEffect(() => {

    if(pieShow===true){
    const ctx = document.getElementById("myChart").getContext('2d');
   

if (myChart) {
        myChart.destroy();
      }
  myChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["Fat", "Carbohydrates", "Proteins","Sodium","Potassium","Fiber"],
        datasets: [
          {
            label: "Food Nutrients",
            data: nutrientsArray,
            backgroundColor: [
              'rgba(255, 99, 132, 0.9)',
               'rgba(54, 162, 235, 0.9)',
                'rgba(255, 206, 86, 0.9)',
                'rgb(63, 191, 63)',
                'rgb(191, 63, 63)',
                'rgb(127, 63, 191)'
              
              
            ],
            borderColor: ['white','white','white'],
            borderWidth: 3
          }
        ]
      },
      options:{
          legend:{
          position:'right',
          labels:{
            fontColor: '#000'
          }
        }
      }
    });
  }
  },[nutrientsArray]);


useEffect(() => {
  if(barShow===true){
    const ctx2 = document.getElementById("avg").getContext('2d');
   

if (myChart2) {
        myChart2.destroy();
      }
  myChart2 = new Chart(ctx2, {
      type: "bar",
      data: {
        labels: ["Fat", "Carbohydrates", "Protein","Sodium","Potassium","Fiber"],
        datasets: [
          {
            label: "Average value (gm/day)",
            data: avgArray,
            backgroundColor: [
              'rgba(255, 99, 132, 0.9)',
               'rgba(54, 162, 235, 0.9)',
                'rgba(255, 206, 86, 0.9)',
                'rgb(63, 191, 63)',
                'rgb(191, 63, 63)',
                'rgb(127, 63, 191)'

              
              
            ],
            barThickness:50,
            borderColor: ['white','white','white'],
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }
    });
  }
  },[avgArray]);



useEffect(()=>{
const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
    
  
    axios.get(`${APIURL}/api/v1/patient/nutrient-calorie/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
        const fat = parseFloat(res.data.total_nutrients_in_gram.fat)
        const carb = parseFloat(res.data.total_nutrients_in_gram.carbohydrates)
        const protein = parseFloat(res.data.total_nutrients_in_gram.protein)
        const sodium = parseFloat(res.data.total_nutrients_in_gram.sodium)
        const potassium = parseFloat(res.data.total_nutrients_in_gram.potassium)
        const fiber = parseFloat(res.data.total_nutrients_in_gram.fiber)

        const total = fat + carb + protein + sodium + potassium + fiber

        const fatData = ((fat/total)*100).toFixed(2)
        const carbData = ((carb/total)*100).toFixed(2)
        const protData = ((protein/total)*100).toFixed(2)
        const sodData = ((sodium/total)*100).toFixed(2)
        const potData = ((potassium/total)*100).toFixed(2)
        const fibData = ((fiber/total)*100).toFixed(2)

        if(fat===0 && carb===0 && protein===0 && sodium===0 && potassium===0 && fiber===0){
          setPieShow(false)
        }

  const arr= [];
  arr.push(fatData);
   arr.push(carbData);
    arr.push(protData);
    arr.push(sodData);
    arr.push(potData);
    arr.push(fibData);  
setNutrientsArray(arr);
let avgNutrients=res.data.nutrients_in_gram_per_day;

if(avgNutrients.fat==='0.0' && avgNutrients.carbohydrates==='0.0' && avgNutrients.protein==='0.0' && avgNutrients.sodium==='0.0' && avgNutrients.potassium==='0.0' && avgNutrients.fiber==='0.0'){
  setBarShow(false)
}

setAverageNutrientsGrams(avgNutrients);
setAvgFat(avgNutrients.fat);
setAvgCarb(avgNutrients.carbohydrates);
setAvgProtein(avgNutrients.protein);
let arr2=[];
arr2.push(avgNutrients.fat);
arr2.push(avgNutrients.carbohydrates);
arr2.push(avgNutrients.protein);
arr2.push(avgNutrients.sodium);
arr2.push(avgNutrients.potassium);
arr2.push(avgNutrients.fiber);
setAvgArray(arr2);
setCaloriesData(res.data.calories);
setFoodList(res.data.food_list);
     
     

      }
      else{
        setBarShow(false)
        setPieShow(false)
      }


    })
    .catch(err=>{
      setBarShow(false)
        setPieShow(false)
})


},[updateRender]);

useEffect(()=>{
const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
    
  
    axios.get(`${APIURL}/api/v1/patient/patient_food_consumption_history/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
       
       setFoodDateWise(res.data.food_data);
      }


    })
    .catch(err=>{
 
})


},[updateRender]);

useEffect(()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');           
    axios.get(`${APIURL}/api/v1/patient/doctor-advice/?patient_id=${patientId}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     
      if(res.data.status=== "success"){

       setAdviceArray(res.data.food_advice);

      const arr= res.data.food_advice.length>0 ? res.data.food_advice[0].diet_advice : null;
      
      if(arr){
        const instrString= arr.length> 0 ? arr.reduce((accumulator, arr) => {
                return accumulator + arr + "\n" ;
             }, "")
       : null;

      
       setAdvice(instrString);
       setAdviceDate(res.data.food_advice[0].created_at);
      } 

    
      }
    })
    .catch(err=>{

})



},[refreshAdvice]);

const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> Error in Data Submission. Please try again! </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const SubmitInstructionsPopUp=(props)=> {
 
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4> Advice submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button  className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}



const callAdviceSubmit=(adviceString)=>{
  const tokenString= localStorage.getItem('usertoken');
   const dataToSend={
    diet_advice: adviceString
    
   }
   let str= tokenString.replace(/["]+/g, '');           
    axios.post(`${APIURL}/api/v1/patient/doctor-advice/?patient_id=${patientId}`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }


    })

    .then(res=>{
     
      if(res.data.status=== "success"){
     
           setSubmitInstructionsMsg("success");
                setModalInstructionsShow(true);
              
       

     
      }
      else{
        setSubmitMsg("error");
              setErrorSubmit(true);
      }
    })
    .catch(err=>{
      setSubmitMsg("error");
              setErrorSubmit(true);

})



}

const handleSubmitAdvice=(e)=>{

  if(advice!=='' && advice!==null){
  const adviceToSend= advice.trim().split("\n");

  callAdviceSubmit(adviceToSend);
}
}
const renderFood=(cellInfo)=> {
  return(<h6> {foodList[cellInfo.index][cellInfo.column.id]} </h6>)
}

const renderIncluded=(cellInfo)=> {
  return(<h6>  {foodList[cellInfo.index][cellInfo.column.id]===true ? "Yes" :"No"}</h6>)
}

const renderDate=(cellInfo)=> {
  return(<h6> {foodDateWise[cellInfo.index][cellInfo.column.id]} </h6>)
}

const renderFoodName=(cellInfo)=> {

    const display=foodDateWise[cellInfo.index][cellInfo.column.id].length>0 ? foodDateWise[cellInfo.index][cellInfo.column.id].map((item,itemIndex)=>{
    
         return (
                   <div className="row-syn-item">  
                   <div
       
        >{item.name}</div>
        </div>
  )
} )
: null;
return display;
}

const renderFoodQuantity=(cellInfo)=> {

    const display=foodDateWise[cellInfo.index][cellInfo.column.id].length>0 ? foodDateWise[cellInfo.index][cellInfo.column.id].map((item,itemIndex)=>{
    
         return (
                   <div className="row-syn-item">  
                   <div
       
        >{item.quantity}</div>
        </div>
  )
} )
: null;
return display;
}
const renderFoodUnits=(cellInfo)=> {

    const display=foodDateWise[cellInfo.index][cellInfo.column.id].length>0 ? foodDateWise[cellInfo.index][cellInfo.column.id].map((item,itemIndex)=>{
    
         return (
                   <div className="row-syn-item">  
                   <div
        
        >{item.unit}</div>
        </div>
  )
} )
: null;
return display;
}

const renderIncludedDate=(cellInfo)=> {

   const display=foodDateWise[cellInfo.index][cellInfo.column.id].length>0 ? foodDateWise[cellInfo.index][cellInfo.column.id].map((item,itemIndex)=>{
    
         return (
                   <div className="row-syn-item">  
                   <div
        
        >{item.is_taken===true ? "Yes" :"No"}</div>
        </div>
  )
} )
: null;
return display;

 
}
const columnsFood= [
{
  Header: "Food Name",
  accessor: "name",
  Cell: renderFood,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:250,
  minWidth:100,
   sortable:true,
   filterable: true

},
{
  Header: "Quantity",
  accessor: "quantity",
  Cell: renderFood,
   sortable:true,
   filterable: true

},
{
  Header: "Unit",
  accessor: "unit",
  Cell: renderFood,
   sortable:true,
   filterable: true

},
{
  Header: <><h6>Included in </h6>
          <h6> Nutrient </h6>
          <h6> Calculations </h6></>,
  accessor: "is_taken",
  Cell: renderIncluded,
   sortable:true,
   filterable: true,
  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "yes") {
                      return row[filter.id] === true;
                    }
                    if (filter.value === "no") {
                      return row[filter.id] === false;
                    }
                    
                     
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">Show All</option>
                       <option value="yes">Yes</option>
                      <option value="no">No</option>
                     
                    </select>,
                   
              
   width:110



}]

const columnsFoodDate= [
{
  Header: "Date",
  accessor: "date",
  Cell: renderDate,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:120,
  minWidth:100,
   

},
{
  Header: "Food Name",
  accessor: "food_consumed",
  Cell: renderFoodName,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
 
  minWidth:100,
  

},
{
  Header: "Quantity",
  accessor: "food_consumed",
  Cell: renderFoodQuantity,
   minWidth:50

},
{
  Header: "Unit",
  accessor: "food_consumed",
  Cell: renderFoodUnits,
    minWidth:50

},
{
  Header: <><h6>Included in </h6>
          <h6> Nutrient </h6>
          <h6> Calculations </h6></>,
  accessor: "food_consumed",
  Cell: renderIncludedDate,
   sortable:true,
 
  
   width:110



}]
const FoodTable = <ReactTable 
         columns={columnsFood}
         data={foodList}
         defaultPageSize = {10}
         
         resizable
                           
                 
         >

         </ReactTable> 


const FoodDateWise=<ReactTable 
         columns={columnsFoodDate}
         data={foodDateWise}
         defaultPageSize = {5}
         
         resizable
                           
                 
         >
      </ReactTable> 
 const handleGo=(e)=>{
      e.preventDefault();
      setUpdateRender(!updateRender);
}
const handleAdvice=e=>{
  e.preventDefault();
  setAdvice(e.target.value);
}

/* const displayAvgNutrients=averageNutrientsGrams ? <div className="avg-table-body">
    <h6 className="each-nutr-value">Fat : <span className="nutrient-bubbles">{averageNutrientsGrams.fat? averageNutrientsGrams.fat : null}</span> </h6>
    <h6 className="each-nutr-value"> Proteins : <span className="nutrient-bubbles"> {averageNutrientsGrams.protein? averageNutrientsGrams.protein : null} </span></h6>
     <h6 className="each-nutr-value"> Carbohydrates : <span className="nutrient-bubbles"> { averageNutrientsGrams.carbohydrates? averageNutrientsGrams.carbohydrates : null}</span></h6>
    </div>
    : null; */

const handleFromDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));



let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setStartDate(DateExtracted);
  }
}

const handleToDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));

let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setEndDate(DateExtracted);
  }
}


/* const handleSend=e=>{
  
const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          const dataToSend={
            food_advice: advice
          }

    axios.post(`${APIURL}/api/v1/patient/doctor-advice/?patient_id=${patientId}`, dataToSend,{ headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
  
  
     
  setAdvice(null);
      }


    })
    .catch(err=>{

})



}*/

const handleDatewise=e=>{
  setTable2(true);
  setTable1(false);

}

const handleFoodTable=e=>{
  setTable1(true);
  setTable2(false);

}

  return (
 

    <>
     <div className="food-habits-page">
     <div className="food-habits-header">
    <h2 className="title-of-tasks"> Food Nutrients Summary & Food Habits </h2>
         {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}



<div className="calender-pickers-from-to">
<div className="span-date">           
<span> From </span>

<DatePicker
                          value={new Date(startDate)}
                          onChange={date=>{handleFromDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<div className="span-date">             
<span> To </span>

<DatePicker
                          value={new Date(endDate)}
                          onChange={date=>{handleToDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<button type="submit" className="btn btn-primary btn-col" onClick={handleGo}> Check </button>
</div>
<br/>
<h6 style={{color:"grey"}}> ** Initial result shown here is based on the last 30 days' data.<br/>
Please select 'From ' and 'To' dates and click 'Check' button to see the results for other dates.</h6>
</div> 

<br/>

<div className="food-habits-sections">

  <div className="left-column-datas">
   <div className="second-tab sub-section sub-section-dr-notes  food-advice-div" id="nutr-sections">
                      <div className="sub-section-header box-theme">
                              
                      <h6 className="sub-section-title"> Food Nutrients (%)</h6>
                      </div>
                  {
                    pieShow===true ? 
                    <div className="sub-section-body">
  

                         
                             <canvas id="myChart" width="400" height="300"></canvas>
      
                       </div> : <h6 style={{color: "red"}}> No data to show! </h6>
                  }
                      

   </div>
    <br/>
  {/**********************************/}

  <div className="second-tab sub-section sub-section-dr-notes food-advice-div" id="nutr-sections">
                      <div className="sub-section-header box-theme">
                              
                      <h6 className="sub-section-title"> Average Nutrients (grams/day)</h6>
                      </div>
                  {
                    barShow===true?
                    <div className="sub-section-body">
  

                           <canvas id="avg" width="400" height="300" ></canvas>
      
                       </div> : <h6 style={{color: "red"}}> No data to show! </h6>
                  }
                      

   </div>

 {/**************************/}

{/*

                   <div className="second-tab sub-section sub-section-dr-notes  food-advice-div">
                      <div className="sub-section-header box-theme">
                              
                      <h6 className="sub-section-title"> Average Nutrients (Grams/day)</h6>
                      </div>

                      <div className="sub-section-body">
                       {displayAvgNutrients}
                      </div>

                      </div>

                    */}
                      <br/>

                    {/**********/}
                    <div className="second-tab sub-section sub-section-dr-notes food-advice-div  cal-box" id="nutr-sections">
                      <div className="sub-section-header box-theme">
                              
                      <h6 className="sub-section-title"> Total calories : <span className="nutrient-bubbles">{caloriesData} </span>  </h6>
                      </div>

                     

                      </div>
                      <br/>
                    {/************/}




   {/* <div className="sub-section inferences-subsection">
                      <div className="sub-section-header box-theme">
                               

                      <h6 className="sub-section-title"> Average Nutrients (Grams/day) </h6>
                      </div>
                      <div className="sub-section-body">  
                      {displayAvgNutrients}
                      </div>

                      </div>
                    {/*********************************/}

                      {/*

                        <div className="sub-section inferences-subsection">
                      <div className="sub-section-header box-theme">
                               

                      <h6 className="sub-section-title"> Total Calories </h6>
                      </div>
                      <div className="sub-section-body">  
                      {caloriesData}
                      </div>

                      </div>

                   {/****************************/}
  </div>
{/**********left column datas end*****/}

    <div className="food-table">
    

    {table1===true && table2===false ? 
    foodList.length>0 ?
    <>
     <div className="select-tabs">
     <button className="btn btn-primary btn-col">Food table</button> 
     <button className="btn btn-secondary" onClick={handleDatewise}> Date wise table</button></div><br/>
         
   {FoodTable}
     </> 
     : <h6 style={{color: "red"}}> No data to show! </h6>
    : null}
  

   {table2===true && table1===false ? 
   foodDateWise.length>0 ?
     <>
     <div className="select-tabs">
     <button className="btn btn-secondary" onClick={handleFoodTable}> Food table </button>
     <button className="btn btn-primary btn-col">Date wise table</button> </div><br/>
         
   {FoodDateWise}
     </> 
     : <h6 style={{color: "red"}}> No data to show! </h6>
     : null
   
   }

     </div>
     </div>

   {/*******************************/}

                    {/*************************************/}
             <br/>
             <br/>


                   <div className="second-tab sub-section sub-section-dr-notes  food-advice-div">
                      <div className="sub-section-header box-theme">
                              
                      <h6 className="sub-section-title"> Set Diet Advice</h6>
                      </div>

                      <div className="sub-section-body">
                     <h6 className="date-adv"> {adviceDate? new Date(adviceDate).toString().slice(0,21) : null}</h6> 

                       <textarea placeholder="Add Food advice" className="text-area"  cols="150" rows="7"  value={advice} onChange={handleAdvice}/>
                        </div>


           

                     
                      <button className="btn btn-primary btn-col btn-square btn-small btn-food-advice" onClick={handleSubmitAdvice}> Submit Advice </button>
                     <br/>


{submitInstructionsMsg==="success" ? 
          <SubmitInstructionsPopUp
        show={modalInstructionsShow}
        onHide={() =>  {setModalInstructionsShow(false);
                      
                      setSubmitInstructionsMsg('');
                    setRefreshAdvice(!refreshAdvice);}}
       
      />
      :   submitMsg==="error"? 
     <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setSubmitMsg('');}}
       
      />

         : ''
          }


                </div>






 
  <br/>

     </div>
     </>
  );
}

export default FoodHabits;