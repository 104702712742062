import React , {useState ,useEffect ,useContext} from 'react'
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {APIURL} from "../../Global";
import {Button ,Modal ,Card ,ListGroup ,ListGroupItem} from 'react-bootstrap';
import '../../css/bootstrap.min.css';
import '../../css/iofrm-style.css';
import '../../css/iofrm-theme4.css';
import "./popup.css";
import "./country_region_selector.css";
import "./dob.css";
import './profile.css'
import EditProfile from './EditProfile';
import {ProfileContext} from '../../contexts/ProfileContext'
import VerifyOtp from '../VerifyOtp/VerifyOtp';


function Profile() {

    //const [img, setImg] =useState();
    const [region, setRegion]=useState('');
    const [address,setAddress]=useState('');
    const [email,setEmail]=useState('');
    const [name,setName] = useState('');
    const [gender,setGender] = useState('');
    const [dob,setDob] = useState('');
    const [number, setNumber]= useState('');
    const [editShow, setEditShow]= useState(false);
    const [country, setCountry]= useState('')
    const [refresh ,setRefresh] =useState(false)
    const [img, setImg] =useState('')
    const [render, setRender]= useContext(ProfileContext)
    const [verifyShow ,setVerifyShow] =useState(false)
    const [verified ,setVerified] =useState()
    const [user,setUser]= useState('')
    const [otpShow, setOtpShow]= useState(false)
    const [otpFail, setOtpFail]= useState(false)
    const [disable, setDisable]= useState(false)

    const handleEditPopUp =(e) => {
        e.preventDefault();
        setEditShow(true)
    }


    useEffect(()=>{

        const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')                           
          
         
          axios.get(`${APIURL}/api/v1/account/profile`, { headers: {
            
            "Authorization" : 'Token '+str  }})
          .then(res=>{
              
            if(res.data.status==="success"){
            
          
            setImg(res.data.data.photo)
            setName(res.data.data.full_name)
            setAddress(res.data.data.address)
            setGender(res.data.data.gender)
            setDob(res.data.data.dob)
            setEmail(res.data.data.email)
            setNumber(res.data.data.mobile_number)
            setRegion(res.data.data.region)
            setCountry(res.data.data.country_name)
            setVerified(res.data.data.email_verified)
            setUser(res.data.data.username)
            }
      
      
          })
          .catch(err=>{
      
      })
      
      
      },[refresh]);

      const history= useHistory();

     const [keys,] = useState({'p': false,'a': false,'n': false,'m': false,'l': false,'s': false,});


useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['p']){
      history.push('/patients-directory')
    }
    if(e.altKey && keys['a']){
      history.push('/appointment')
    }
    if(e.altKey && keys['n']){
      history.push('/doctor/notifications')
    }
    if(e.altKey && keys['m']){
      history.push('/message')
    }
    if(e.altKey && keys['l']){
      history.push('/doctor/analytics')
    }
    if(e.altKey && keys['s']){
      history.push('/service-requestlist')
    }


  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);

  const verifyMail = () => {
    const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')

      axios.get(`${APIURL}/api/v1/account/email-verification/`,{headers : {
        "Authorization" : 'Token '+str  }})
        .then(res => {

          if(res.data.status==="success"){

            setVerifyShow(true)
          }
        })
  }

  const handleReset = (e) => {
    e.preventDefault();
    setDisable(true)
    const pw = sessionStorage.getItem('pw')

    const data = null

    const tokenString= localStorage.getItem('usertoken');
    let str= tokenString.replace(/["]+/g, '');        

   axios.post(`${APIURL}/api/v1/account/reset-password/?username=${user}&password=${pw}`, data , { headers: {
     
    "Authorization" : 'Token '+str,
    'Content-Type': 'application/json' }


   })

   .then(res=>{
    
     if(res.data.status=== "success"){
        setDisable(false)
        setOtpShow(true)
     }
     else{
        setDisable(false)
        setOtpFail(true)
           }
               
           })
    .catch(err =>{
      setDisable(false)
      setOtpFail(true)
 })
  }

  const modalHandle = () => {
    setOtpShow(false)
  }
  const OtpPopUp = ({onHide}) => {
    return(
    <Modal
        show
        onHide
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VerifyOtp mail= {email} handle={modalHandle} />
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={onHide}>Close</Button>          
  
        </Modal.Footer>
      </Modal>
    )
  }

  const OtpFailPopUp = ({onHide}) => {
    return(
    <Modal
        show
        onHide
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-danger'>Error in Data Submission.Please Try Again.</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
  }


  const VerifyPopUp = ({onHide}) => {
    return(
    <Modal
        show
        onHide
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Verification Link sent to Email</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
  }


      const EditPopUp = (props) => {
          return(
            <Modal
            {...props}
            aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog1"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body1">

       <EditProfile show={editShow} image={img}/>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button className='btn btn-primary btn-col' onClick={props.onHide}> X Close</Button>   

      </Modal.Footer>
    </Modal>
          )
      }
    return (
      <>
      <HospDocNavbar />
    <div style={{marginLeft: '22%', display:'flex', marginRight:'21%', width:'auto'}}>
        <Card className="mb-2" bg={'light'} border='light' style={{ width: '50rem',height:'auto' }}>
    <div >
        <div >
            <Card.Img style={{width: 200,
                height: 200,
                maxWidth: '100%',
                borderRadius: '50%'}} variant="top" src={img} alt="Profile Photo"  />
            
        </div>
        <hr/>
    </div>
  <Card.Body>
    <Card.Title style={{fontSize:'200%'}}>Profile</Card.Title>
    <ListGroup className="list-group-flush">
    <ListGroupItem>Name : {name}</ListGroupItem>
    <ListGroupItem>Email : {email} <label onClick={verifyMail} style={{color:'blue', cursor: 'pointer'}}>{ verified===false && email !=='' ? <p>(verify)</p> :'' }</label></ListGroupItem>
    <ListGroupItem>Gender : {gender}</ListGroupItem>
    <ListGroupItem>Date of Birth : {dob}</ListGroupItem>
    <ListGroupItem>Mobile Number : {number}</ListGroupItem>
    <ListGroupItem>Address : {address}</ListGroupItem>
    <ListGroupItem>Country : {country}</ListGroupItem>
    <ListGroupItem>Region : {region}</ListGroupItem>
  </ListGroup>
    <Button style={{margin:'1rem'}} onClick={handleEditPopUp}className='btn btn-primary btn-col'>Edit Profile</Button>
    <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button>
  </Card.Body>
</Card>
            
        

        {
            editShow ? 
        <EditPopUp
            show={editShow}
            onHide= { ()=> {setEditShow(false)
                           setRefresh(!refresh)
                           setRender(!render)}} />
            : null
        } 

{
            verifyShow ? 
        <VerifyPopUp
            show={verifyShow}
            onHide= { ()=> {setVerifyShow(false)
                           }} />
            : null
        } 

{
            otpShow ? 
        <OtpPopUp
            show={otpShow}
            onHide= { ()=> {setOtpShow(false)
                           }} />
            : null
        }   

{
            otpFail ? 
        <OtpFailPopUp
            show={otpFail}
            onHide= { ()=> {setOtpFail(false)
                           }} />
            : null
        } 
        
    </div>
    </>

    
    )
}

export default Profile
