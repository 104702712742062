  import React,{useState,useRef,useEffect,useContext} from 'react';
  import ReactTable from 'react-table-v6';
  import 'react-table-v6/react-table.css'
  import "./hosppatientrecord.css";
  import { Link,useHistory} from "react-router-dom";
  import DatePicker from 'react-date-picker';
  import axios from "axios";
  import {Modal} from 'react-bootstrap';
  import {Button} from 'react-bootstrap';
  import {Tooltip} from 'react-bootstrap';
  import {OverlayTrigger} from 'react-bootstrap';
  import "./reacttable.css";
  import moment from "moment";
  import FoodHabits from "../FoodHabits/FoodHabits";
  import GlucoseVitals from  "../GlucoseVitals/GlucoseVitals";
  import AddPrescription from "../HospDr_AddPrescription/AddPrescription";
  import VitalsBP from "../VitalsBP/VitalsBP";
  import VitalsPulse from "../VitalsPulse/VitalsPulse";
  import VitalsChol from "../VitalsChol/VitalsChol";
  import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
  //import Comorbidities from "../Comorbidities/Comorbidities";
  //import Chronic from "../Chronic/Chronic";
  import DrNotes from "../DrNotes/DrNotes";
  import TargetSet from "../TargetSet/TargetSet";
  //import TargetBasic from "../TargetSet/TargetBasic";

  //import HospDrMessagesBasic from "../HospDrMessages/HospDrMessagesBasic";
  //import Observations from "../Observations/Observations";
  import AddObservations from "../Observations/AddObservations";
  import {APIURL} from "../../Global";
  import {TargetContext} from "../../contexts/TargetContext";
  //import CurrentMedications from "../HospDr_AddPrescription/CurrentMedications";
  import GeneralSearchBar from '../GeneralSearch/GeneralSearchBar'
  import PastMedications from '../HospDr_AddPrescription/PastMedications';

  //import HospDrMessages from "../HospDrMessages/HospDrMessages";

  import ImageUpdate from './ImageUpdate';
  import AddRemark from '../ServiceRequests/AddRemark';  
  /*import Inference from '../Inference/Inference'; */
   

import { Suspense, lazy } from 'react';
import EditRemark from '../HospDr_AddPrescription/EditRemark';
import ViewRemark from '../HospDr_AddPrescription/ViewRemark';

const Inference = React.lazy(() => import('../Inference/Inference'));
const TargetBasic = React.lazy(()=> import('../TargetSet/TargetBasic'));
const CurrentMedications = React.lazy(()=> import('../HospDr_AddPrescription/CurrentMedications'));
const Observations = React.lazy(()=> import('../Observations/Observations'))
const Chronic = React.lazy(()=> import('../Chronic/Chronic'))
const Comorbidities = React.lazy(()=> import('../Comorbidities/Comorbidities'))
const HospDrMessages = React.lazy(()=> import('../HospDrMessages/HospDrMessages'))


 function PatientRecord(props){
    const [height,setHeight]=useState();
    const [maritalStatus, setMaritalStatus]=useState()
    const [weight,setWeight]=useState()
    const [age,setAge]=useState()
    const [summary,setSummary]=useState('');
   const [refresh,setRefresh]= useContext(TargetContext);
    const  ref1=useRef();
    const  ref2=useRef();
    const  ref3=useRef();
    const  ref4=useRef();
    const  ref5=useRef();
    const  ref6=useRef();
    const  ref7=useRef();
    const  ref8=useRef();
    const [id,] = useState(props.match.params.id);
    const [,setuserDetails] = useState();
    const [name,setName] = useState();
    const [gender,setGender] = useState();
    const [dob,setDob] = useState();
    const [imgUrl,setImgUrl] = useState();
    const [instructions,setInstructions] = useState();
     const [instructionsDate,setInstructionsDate] = useState();

      const [,setInstructionsArray] = useState([]);
    const [next_visit,setNext_visit] = useState(null);
    const [next,setNext] = useState();

    const [testsList,setTestsList]=useState([{ id:"",test : "", date: "", labtest_reason:{details:"",image: "",reason:""}}]);
    const [scanList,setScanList]=useState([{id:"",test: "", date:"", scan_details:{details:"",image: "",reason:""}}]);
    const [popupShow,setPopupShow]=useState(false)
    const [imgEditShow,setImgEditShow]= useState(false)
    const [reasonAddShow,setReasonAddShow]= useState(false)
    const [imgViewShow,setImgViewShow]= useState(false)
    const [flag,setFlag]=useState(false)
    const [reasonEditShow,setReasonEditShow]= useState(false)
    const [index, setIndex]=useState()
    const [editShow, setEditShow]= useState(false);
    const [addShow, setAddShow]= useState(false);
    const [viewShow,setViewShow]= useState(false);
    const [remarkViewShow, setRemarkViewShow]= useState(false);
    const [detailData, setDetailData]= useState('')
    const [cell, setCell]= useState('')
    const [editSuccess,setEditSuccess]= useState(false)
    const [sizeShow ,setSizeShow] =useState(false)
    const [validShow ,setValidShow] =useState(false)
    const [contentShow ,setContentShow] =useState(false)
    const [uploadShow ,setUploadShow] =useState(false)
    const [tableRefresh,setTableRefresh]= useState(false)

     const [submitMsg,setSubmitMsg] = useState("");
     const [modalShow,setModalShow]=useState(false);
    const [deleteNextMsg,setDeleteNextMsg] = useState("");
     const [deleteNextModalShow,setDeleteNextModalShow]=useState(false);
     
    
     const [submitInstructionsMsg,setSubmitInstructionsMsg] = useState("");
   const [modalInstructionsShow,setModalInstructionsShow]=useState(false);
   const [,setPatientData] = useState();
   const [,setDisplaySuggestions] = useState(false);
   const [options,setOptions] = useState([]);
   const [display,setDisplay]=useState(false);
   const [search,setSearch]=useState("");
   const [searchScan,setSearchScan]=useState("");
   const [scanSuggest ,setScanSuggest]= useState();
   const [currentIndex,setCurrentIndex]=useState('');
   const [,setCurrentColumn] = useState('');
const [testListCounter,setTestListCounter]=useState(0);
const [scanListCounter,setScanListCounter]=useState(0);
const wrapperRef=useRef(null);

  const [render,setRender] =useState(false);
   const [reRender,setReRender] =useState(false);
const [,setDateVal]= useState("");
const [deleteScanConfirmMsg,setDeleteScanConfirmMsg]=useState("");
const [deleteScanConfirmModalShow,setDeleteScanConfirmModalShow]=useState(false);
const [deleteNextVisitConfirmMsg,setDeleteNextVisitConfirmMsg]=useState("");
const [deleteNextVisitConfirmModalShow,setDeleteNextVisitConfirmModalShow]=useState(false);

const [scanIndexToDelete,setScanIndexToDelete] = useState();
const [deleteTestConfirmMsg,setDeleteTestConfirmMsg]=useState("");
const [deleteTestConfirmModalShow,setDeleteTestConfirmModalShow]=useState(false);
const [testIndexToDelete,setTestIndexToDelete] = useState();
const [showTestTable,setShowTestTable] = useState(false);
const [showScanTable,setShowScanTable] = useState(false);
const [testErr,setTestErr]=useState("false");
const [scanErr,setScanErr]=useState("false");
const [updatedTestList,setUpdatedTestList]=useState([]);
const [updatedScanList,setUpdatedScanList]=useState([]);

const [submitTrigger,setSubmitTrigger]=useState(true);
const [errorModalShow,setErrorModalShow]=useState(false);
 const [errorScanShow,setErrorScanShow]=useState(false);
 const [errorSubmit,setErrorSubmit]=useState(false);
 const [foodHabitsShow,setFoodHabitsShow]=useState(false);
 const [glucoseShow,setGlucoseShow]=useState(false);
 const [bpShow,setBpShow]=useState(false);
const [pulseShow,setPulseShow]=useState(false);
 const [cholesterolShow,setCholesterolShow]=useState(false);
 const [,setScanShow]= useState(false);
 const [,setTestShow]=useState(false);
   const [addPrescShow,setAddPrescShow]=useState(false);
   const [drNotesShow,setDrNotesShow]=useState(false);
    const [targetShow,setTargetShow]=useState(false);
     const [inferenceShow,setInferenceShow]=useState(false);
     /* const [messagesShow,setMessagesShow]=useState(false);*/
   const [observationsShow,setObservationsShow]=useState(false);
   const [refreshInstr,setRefreshInstr]=useState(false);
   const [timing] =useState(200);
   //const [rotation] =useState(50)
   const [isBooped, setIsBooped] =useState(false)

  const [errorNextModalShow,setErrorNextModalShow]=useState(false);
  const [nextErr,setNextErr]=useState("nil");
   const [messagesShow,setMessagesShow]=useState(false)
     const [summaryDone,setSummaryDone]=useState("");
     const [prescForm, setPrescForm]= useState(false)
  const freq_link=`analysis/${id}`;
  let history=useHistory();

  let formData =new FormData();
  const reader = new FileReader();

  const [keys,] = useState({'p': false,'a': false,'n': false,'m': false,'l': false,'s': false,});
  let track = null 

  const hospId = props.location.hospId 

  useEffect(()=> {
    if(hospId !== undefined){
      sessionStorage.setItem('hospId',hospId)
    }
  },[])

  
 
      

    useEffect(()=>{
    
      window.onkeydown =(e) => {
        
        keys[e.key]=true
    
        if(e.altKey && keys['p']){
          history.push('/patients-directory')
        }
        if(e.altKey && keys['a']){
          history.push('/appointment')
        }
        if(e.altKey && keys['n']){
          history.push('/doctor/notifications')
        }
        if(e.altKey && keys['m']){
          history.push('/message')
        }
        if(e.altKey && keys['l']){
          history.push('/doctor/analytics')
        }
        if(e.altKey && keys['s']){
          history.push('/service-requestlist')
        }
    
    
      }
      window.onkeyup=(ev)=> {
        
        keys[ev.key]= false
      }
    
      return()=>{
        window.onkeydown=null
        window.onkeyup=null
      }
    },[]);


  /*useEffect(()=>{
=======
  // useEffect(()=> {
  //   const tokenString= localStorage.getItem('usertoken');
   
  //  let str= tokenString.replace(/["]+/g, '');

  //  axios.get(`${APIURL}/api/v1/patient/patients-hospital-id/?patient_id=${id}`, {
  //         headers: { Authorization: "Token "+ str },
  //       })
  //       .then((res) => {
  //         if (res.data.status === "success") {
  //           setHospId(res.data.patients_hospital_id)
            
  //       }
  //       else{

  //       }
  //   }).catch((error)=>{

  //   })

  // },[])

/*useEffect(()=>{
>>>>>>> 9e0df4d720daf1bee30c153c5af620b359786276


     const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '');
     
   if(testErr=== "nil" && scanErr=== "nil"){
    
              const data ={

              patient_id: id,
              next_visit: next_visit,
              quick_summary:summary,
              tests: updatedTestList,
              scans: updatedScanList,
            
            }


              
                axios.post(`${APIURL}/api/v1/doctor/patient-record/`,data, { headers: {
                  
                  "Authorization" : 'Token '+str,
                  'Content-Type': 'application/json'  }


                })



                .then(res=>{
                
                  if(res.data.status==="success"){
                  
                  setSubmitMsg("success");
                  setModalShow(true);
                 
                 
              }
              else{
                setSubmitMsg("error");
                setErrorSubmit(true);
               
              }

                })
                .catch(err=>{
              
              setSubmitMsg("error");
              setErrorSubmit(true);
            })
                
       }

  },[testErr!=="nil",scanErr!=="nil", submitTrigger]);*/

  useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
    
    let str= tokenString.replace(/["]+/g, '');
    
  if(testErr=== "nil" && scanErr=== "nil"){
   const scans =updatedScanList
   const tests= updatedTestList
   
    for(let i=0 ; i<scans.length; i++){
      if(scans[i].scan_details.image instanceof File === true || scans[i].scan_details.image===''){
        formData.append(`scans[${i}]test`,scans[i].test)
        formData.append(`scans[${i}]date`,scans[i].date)
        formData.append(`scans[${i}]patient_scan_details`,scans[i].scan_details.details)
        formData.append(`scans[${i}]patient_scan_reason`,scans[i].scan_details.reason)
        formData.append(`scans[${i}]patient_scan_details_image`,scans[i].scan_details.image)
      }    
    }
    for(let i=0 ; i<tests.length; i++){
      if(tests[i].labtest_reason.image instanceof File === true || tests[i].labtest_reason.image===''){
      formData.append(`tests[${i}]test`,tests[i].test)
      formData.append(`tests[${i}]date`,tests[i].date)
      formData.append(`tests[${i}]patient_lab_test_details`,tests[i].labtest_reason.details)
      formData.append(`tests[${i}]patient_lab_test_details_image`,tests[i].labtest_reason.image)
      formData.append(`tests[${i}]patient_lab_test_reason`,tests[i].labtest_reason.reason)
      }
    }
    formData.append('patient_id',id)
    if(next_visit !== null){
      formData.append('next_visit',next_visit)
    }
    
    formData.append('quick_summary',summary)
             
               axios.post(`${APIURL}/api/v1/doctor/patient-record/`,formData, { headers: {
                 
                 "Authorization" : 'Token '+str,
                 'Content-Type': 'application/json'  }


               })



               .then(res=>{
               
                 if(res.data.status==="success"){
                 
                 setSubmitMsg("success");
                 setModalShow(true);
                
                
             }
             else{
               setSubmitMsg("error");
               setErrorSubmit(true);
              
             }

               })
               .catch(err=>{
             
             setSubmitMsg("error");
             setErrorSubmit(true);
           })
               
      }

  },[testErr!=="nil",scanErr!=="nil", submitTrigger]);


  useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  
            
      
      
    
      axios.get(`${APIURL}/api/v1/doctor/lab-tests/?per_page=20&q=${search}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
          
        if(res.data.status==="success"){
        
        setOptions(res.data.lab_tests);

        }


      })
      .catch(err=>{

  })


  },[search]);


  useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  
            
      
      
    
      axios.get(`${APIURL}/api/v1/doctor/scan-search/?q=${searchScan}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
          
        if(res.data.status==="success"){
        
        setScanSuggest(res.data.scans);

        }


      })
      .catch(err=>{

  })


  },[searchScan]);


  function msgData(childData) {
    track =childData
  }
  const handleTrack= ()=> {
    setMessagesShow(false)
    if(track!==null){
      track.stop();
    }
  }
  /*
  useEffect(()=>{

    function handleClickOutside(e){
    const {current: wrap} =wrapperRef;
    if(wrap && !wrap.contains(e.target)){
    
    }
  }
    document.addEventListener("mousedown",handleClickOutside);

    return () =>{
      document.removeEventListener("mousedown",handleClickOutside)
    }
  },[wrapperRef]);
  */

  const allDatas =async()=> {
    setScanList([{id:"",test: "", date:"", scan_details:{details:"",image: "",reason:""}}]);
    setScanListCounter(0);
    setTestsList([{id:"", test: "",date:"", labtest_reason:{details:"",image: "",reason:""}}]);
      setTestListCounter(0);
    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '');           
  await axios.get(`${APIURL}/api/v1/doctor/patient-record/?patient_id=${id}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
       
        if(res.data.status=== "success"){
         
        setuserDetails(res.data);
        setPatientData(res.data);
        setName(res.data.name);
        setDob(res.data.dob);
        function getAge(dateString) {
          var today = new Date();
          var birthDate = new Date(dateString);
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age--;
          }
          return age;
        }
        
        setAge(getAge(res.data.dob));
        setSummary(res.data.quick_summary);
        
  if(res.data.scans.length>0){
           setScanList(res.data.scans);
  setScanListCounter(res.data.scans.length);
  }

  if(res.data.tests.length>0){
        setTestsList(res.data.tests);
        setTestListCounter(res.data.tests.length);
     }   
        setGender(res.data.gender);
        setNext_visit(res.data.next_visit);
     
       if(res.data.next_visit!== null){
               setNext(new Date(res.data.next_visit));
       }
       else{
        setNext(null);
       }
       
        setImgUrl(res.data.photo);
        }


      })
      .catch(err=>{

  })
  }

const handleInferenceOverlay=(e)=>{
  e.preventDefault();
 setInferenceShow(true);

}
  const summaryData= ()=> {
    const tokenString= localStorage.getItem('usertoken');
     
    let str= tokenString.replace(/["]+/g, '');  

  axios.get(`${APIURL}/api/v1/patient/cholesterol-inferences/?patient_id=${id}`, { headers: {
       
   "Authorization" : 'Token '+str  }})
  .then(res=>{

   if(res.data.status=== "success"){

    const hdl = res.data.cholesterol_inference_data.hdl_cholesterol_data
    const ldl = res.data.cholesterol_inference_data.ldl_cholesterol_data
    const serum = res.data.cholesterol_inference_data.serum_cholesterol_data
    const total = res.data.cholesterol_inference_data.total_cholesterol_data

   if(hdl!==null){
     setSummary((chol)=> chol.concat(`\n${hdl.summary}`))
   }
   if(ldl!==null){
     setSummary((chol)=> chol.concat(`\n${ldl.summary}`))
   }
   if(serum!==null){
    setSummary((chol)=> chol.concat(`\n${serum.summary}`))
  }

  if(total!==null){
    setSummary((chol)=> chol.concat(`\n${total.summary}`))
  }
    
   
   

   }

    setSummaryDone("done")
  })
  .catch(err=>{

  })
 
  }


  useEffect(()=>{
   
   async function execute() {
    await allDatas();
    await summaryData();
  
    }
    
    execute();

  },[id, reRender]);


  useEffect(()=>{
    setTestsList(testsList);
  },[testsList]);

  useEffect(()=>{
     
    
      setTestsList(testsList);
      setScanList(scanList);
      setRender(false);
     
  },[render]);


  useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
    
    let str= tokenString.replace(/["]+/g, '');
   
             


         


             
                 axios.get(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`, { headers: {
      
            "Authorization" : 'Token '+str  }})
          .then(res=>{
           
            if(res.data.status=== "success"){
              const data = res.data.health_details
              setWeight(data.weight)
              setHeight(data.height)
              setMaritalStatus(data.married)
             
                
             }
             

               })
               .catch(err=>{
             
             setSubmitMsg("error");
             setErrorSubmit(true);
           })
      },[id, reRender]);




  useEffect(()=>{
    return ()=>{
      return (   <>       <ReactTable 
                       columns={columns}
                       data={testsList}
                       defaultPageSize = {4}
                       
                       resizable
                       
                       >
                       </ReactTable>  
  </>)
    }
  },[testsList]);

  useEffect(()=>{
    const tokenString= localStorage.getItem('usertoken');
   
     let str= tokenString.replace(/["]+/g, '');           
      axios.get(`${APIURL}/api/v1/patient/instructions/?patient_id=${id}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
       
        if(res.data.status=== "success"){

         setInstructionsArray(res.data.instructions);

         const arr= res.data.instructions.length>0 ? res.data.instructions[0].instruction : null;
        
        if(arr){
          const instrString= arr.length> 0 ? arr.reduce((accumulator, arr) => {
                  return accumulator + arr + "\n" ;
               }, "")
         : null;

        
         setInstructions(instrString);
         setInstructionsDate(res.data.instructions[0].created_at);
        } 
        }
      })
      .catch(err=>{

  })



  },[id, refreshInstr]);



  const handleBack=(e)=>{
    history.goBack();
  }

  const handleClick = (ref) =>{
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
           });
        



        }

  const handleInstructions=(e)=>{

    setInstructions(e.target.value);

  }

  const handleSubmit= ()=>{
     
    setSubmitTrigger(!submitTrigger);
    setTestErr("nil");
    setScanErr("nil");
     const updatedTests = testsList.filter((row,i)=>{
     return (row.test!=="" && row.date!=="")
     
    })
    const updatedScans = scanList.filter((row,i)=>{
     return (row.test!=="" && row.date!=="" && row.scan_details.details!=="")
     
    })

    const testData = testsList.filter((row,i)=> {
      return ( row.test!=="" || row.date!=="" || row.labtest_reason.details!=="" || row.labtest_reason.reason!=="" || row.labtest_reason.image !== "" )
    })

    const scanData = scanList.filter((row,i)=> {
      return ( row.test!=="" || row.date!=="" || row.scan_details.details!=="" || row.scan_details.reason!=="" || row.scan_details.image!=="")
    })


  testData.map((item)=> {
      if(item.test===""){
        setTestErr("*Test name in Test Schedules Table is mandatory!")
        setErrorModalShow(true)
      }
      if(item.date===""){
        setTestErr("*Date in Test Schedules Table is mandatory!")
        setErrorModalShow(true)
      }
      /*if(item.test==="" && item.date===""){
        setTestErr("Please fill the Test and Date Field in row " + (index+1))
        setErrorModalShow(true)
      }*/
      if(item.test!==""&& item.date!==""){
    setUpdatedTestList(updatedTests);
    setSubmitTrigger(!submitTrigger)
      }
    })

    scanData.map((item)=> {
      if(item.test===""){
        setScanErr("*Scan name in Scan Schedules table is mandatory!")
        setErrorScanShow(true)
      }
      if(item.date===""){
        setScanErr("*Date in Scan Schedules Table is mandatory!")
        setErrorScanShow(true)
      }
      if(item.scan_details.details===""){
        setScanErr("*Scan details in Scan Schedules Table is mandatory!")
        setErrorScanShow(true)
      }
      /*if(item.test==="" && item.date==="" && item.scan_details.details===""){
        setTestErr("Please fill the Test, Date and Scan details Field in row " + (index+1))
        setErrorModalShow(true)
      }*/

      if(item.test!==""&& item.date!=="" && item.scan_details.details!==""){
    setUpdatedScanList(updatedScans);
    setSubmitTrigger(!submitTrigger)
      }
    })

   /* const newlist= testsList.map(row=>{
      if(row.test!=="" && row.date!==""){

           return true;
      }
      else if(row.test==="" && row.date!== ""){
          setTestErr("*Test name in Test Schedules Table is mandatory!");
          setErrorModalShow(true);
       
           return false;
      }
      else if(row.test!=="" && row.date=== ""){
          setTestErr("*Date in Test Schedules Table is mandatory!");
          setErrorModalShow(true);
        
           return false;
      }else{
        return false;
      }
    }) 

   const newScanList= scanList.map(row=>{
      if(row.test!=="" && row.date!==""){
        
           return true;
      }
      else if(row.test==="" && row.date!== ""){
          setScanErr("*Scan name in Scan Schedules table is mandatory!");
          setErrorScanShow(true);
          
           return false;
      }
      else if(row.test!=="" && row.date=== ""){
          setScanErr("*Date in Scan Schedules Table is mandatory!");
          setErrorScanShow(true);
         
           return false;
      }else{
        return false;
      }
    }) */

    setUpdatedTestList(updatedTests);
    setUpdatedScanList(updatedScans);
    setSubmitTrigger(!submitTrigger)
    
    

  }
  const handleNextVisit = (date)=>{
    setNext(date);
    
    if(date!==null){
  const offset = date.getTimezoneOffset();

  let dateUpdated = new Date(date.getTime() - (offset*60*1000));

  let DateExtracted = dateUpdated.toISOString().split('T')[0];



    setNext_visit(DateExtracted);
    }
  }


  const handleDeleteNextVisit1=()=>{
    setDeleteNextVisitConfirmMsg('success');
    setDeleteNextVisitConfirmModalShow('true');
  }
  const handleDeleteNext = ()=>{
    setNext_visit(null);
    setNext(null);
    callDeleteNext();
  }

  const handleDeleteTest=(index)=>{
    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '');
      
    
    const dataToUpdate ={
      ts_id: testsList[index].id,
      patient_id: id
    }


    
      axios.put(`${APIURL}/api/v1/doctor/patient-record/`,dataToUpdate, { headers: {
        
        "Authorization" : 'Token '+str,
        'Content-Type': 'application/json'  }


      })



      .then(res=>{

        if(res.data.status==="success"){
     
       setReRender(!reRender);
    }
    else{
      setSubmitMsg("error");
      
     
    }

      })
      .catch(err=>{
    
    setSubmitMsg("error");

  })


   }
  const callDeleteNext=(index)=>{
    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '');
      
    
    const dataToUpdate ={
     
      patient_id: id,
      next_visit:"True"
    }


    
      axios.put(`${APIURL}/api/v1/doctor/patient-record/`,dataToUpdate, { headers: {
        
        "Authorization" : 'Token '+str,
        'Content-Type': 'application/json'  }


      })



      .then(res=>{

        if(res.data.status==="success"){
      setDeleteNextMsg("Successfully deleted next visit data");
                  setDeleteNextModalShow(true);
       
    }
    else if(res.data.status==="error"){
      setNextErr(res.data.message ? res.data.message : "Error in submission!");
      
     
    }
     else{
      setNextErr("Error in submission!");
      
     
    }

      })
      .catch(err=>{
    
      setNextErr("Error in submission!");

  })


   }

  const handleDisplayTestTable =()=>{
    setShowTestTable(!showTestTable);
     }

  const handleDisplayScanTable =()=>{
    setShowScanTable(!showScanTable);
   }
    

  const DeleteNextVisitConfirmPopup =(props)=>{
      

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Next Visit!</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

          <Button variant="danger" onClick={()=>{setDeleteNextVisitConfirmModalShow(false);
                                                                 handleDeleteNext();
                                                                   }}> Confirm</Button>

         

        </Modal.Footer>
      </Modal>
    );
  }

  const DeleteTestConfirmPopup =(props)=>{
      

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Test</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

          <Button variant="danger" onClick={()=>{setDeleteTestConfirmModalShow(false);
                                                                 handleDeleteTest(props.index);;
                                                                   setTestIndexToDelete(null);}}> Confirm</Button>

         

        </Modal.Footer>
      </Modal>
    );
  }

  const FoodHabitsPopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w"
          className="nutrients-modal"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FoodHabits {...props} />
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }
  const TargetPopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TargetSet {...props} />
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }

  /*const MessagesPopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HospDrMessages patientId={id} />
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }

  const ScanPopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="container scan-table-holder">
            <ReactTable 
           columns={columnsScans}
           data={scanList}
           defaultPageSize = {5}
           
           resizable
                             
                   
           >

           </ReactTable> 

          

           <br/>
                        
   



           {deleteScanConfirmMsg==="success" ? 

            <DeleteScanConfirmPopup
          show={deleteScanConfirmModalShow}
          onHide={() =>  setDeleteScanConfirmModalShow(false)}
          index= {scanIndexToDelete}
        />

             : 
               '' }
            <br/>
         </div>
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }

  */

  const PulsePopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsPulse {...props} />
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }
  const GlucosePopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GlucoseVitals {...props} />
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }

  const BpPopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsBP {...props} />
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }
  const CholesterolPopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsChol {...props} />
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }

const AddPrescPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <h2 className="title-of-tasks"> Current Medications </h2>

         <Suspense fallback={<div>Loading...</div>}>
            <CurrentMedications id={id} />
         </Suspense>
        
        <div className="divider"></div>
          <PastMedications id={id} />
        <AddPrescription {...props} />
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}
 

  const InferencePopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <h2 className="title-of-tasks"> Inference & Impact Analysis </h2>
          <Inference patientId={id}  summary={displaySummaryTable}/>
         
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }
  const MessagesPopup=(props)=> {
   
   
    return (
      <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Suspense fallback={<div>Loading...</div>}>
            <HospDrMessages patientId={id} track={msgData} />
        </Suspense>
            
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }

/*  const PrescFormPopUp=(props)=>{
    

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">         
          
       <DrAddPrescription />
         
        </Modal.Body>
        <Modal.Footer>
           
           <Button variant="secondary" onClick={props.onHide}>Close</Button>
  
           
  
         
         
  
        </Modal.Footer>
      </Modal>
    );
  }*/

  const SubmitPopUp=(props)=> {
   
   
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Data submitted sucessfully!</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }
  const DeletePopUp=(props)=> {
   
   
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{deleteNextMsg}</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }



  const SubmitInstructionsPopUp=(props)=> {
   
   
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Instructions submitted sucessfully!</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }

  const ErrorNextPopup =(props)=>{
      

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{color: "red"}}> {nextErr}</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>

         
         

        </Modal.Footer>
      </Modal>
    );
  }

  const ErrorPopup =(props)=>{
      

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{color: "red"}}> {testErr}</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>

         
         

        </Modal.Footer>
      </Modal>
    );
  }

  const SubmitErrorPopup =(props)=>{
      

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{color: "red"}}> Error in Data Submission. Please try again! </h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>

         
         

        </Modal.Footer>
      </Modal>
    );
  }




  const handleDrNotesOverlay=(e)=>{
   e.preventDefault();
   setDrNotesShow(true);

  }
  const handleObservationsOverlay=(e)=>{
   e.preventDefault();
   setObservationsShow(true);

  }

  /*const handleScanOverlay=(e)=>{
   e.preventDefault();
   setScanShow(true);

  }

  const handleTestOverlay=(e)=>{
   e.preventDefault();
   setTestShow(true);

  }*/
  const handleFoodHabitsOverlay=(e)=>{
   e.preventDefault();
   setFoodHabitsShow(true);

  }
  const handleTargetOverlay=(e)=>{
   e.preventDefault();
   setTargetShow(true);

  }

  /*const handleMessagesOverlay=(e)=>{
   e.preventDefault();
   setMessagesShow(true);

  }
  */
  const handleGlucoseOverlay=(e)=>{
   e.preventDefault();
   setGlucoseShow(true);

  }
  const handlePulseOverlay=(e)=>{
   e.preventDefault();
   setPulseShow(true);

  }
  const handleCholOverlay=(e)=>{
   e.preventDefault();
   setCholesterolShow(true);

  }
  const handleBpOverlay=(e)=>{
   e.preventDefault();
   setBpShow(true);

  }
  const handleAddPrescOverlay=(e)=>{
   e.preventDefault();
   setAddPrescShow(true);

  }

  // const handleAddPrescForm=(e)=>{
  //   e.preventDefault();
  //   history.push('/prescription-form')
  //   //setPrescForm(true)
 
  //  }

  
  const ErrorScanPopup =(props)=>{
      

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{color: "red"}}> {scanErr}</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>

         
         

        </Modal.Footer>
      </Modal>
    );
  }

  const EditPopup=(props)=>{

    return (
      <Modal
        {...props}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUpdate img={scanList[index].scan_details.image}  imgUpdate={handleFile}/>
          

        </Modal.Body>
        <Modal.Footer>
           
           <Button variant="secondary" onClick={props.onHide}>Close</Button>
           <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>
             
           


        </Modal.Footer>
      </Modal>
    );
  }

  const ImgEditPopup=(props)=>{

    return (
      <Modal
        {...props}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUpdate img={testsList[index].labtest_reason.image}  imgUpdate={handleFile}/>
          

        </Modal.Body>
        <Modal.Footer>
           
           <Button variant="secondary" onClick={props.onHide}>Close</Button>
           <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>
             
           


        </Modal.Footer>
      </Modal>
    );
  }

  const ViewPopup=(props)=>{

    return (
      <Modal
        {...props}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            flag ? <ImageUpdate img={testsList[index].labtest_reason.image}  imgUpdate={handleFile}/>
            :
                  <ImageUpdate img={scanList[index].scan_details.image}  imgUpdate={handleFile}/>

          }
          
          

        </Modal.Body>
        <Modal.Footer>
           
           <Button variant="secondary" onClick={props.onHide}>Close</Button>



        </Modal.Footer>
      </Modal>
    );
  }
  let imgFile = null

  function handleFile(childData){
    imgFile = childData
  }

  const reasonAddHandle= ()=> {
    setReasonAddShow(false)
    const tests =testsList
    tests[cell].labtest_reason.reason= sendData
    setTestsList(tests)
    setEditSuccess(true)
  }

  const ReasonAddPopup=(props)=>{
      

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} save={reasonAddHandle}/>

        </Modal.Body>
        <Modal.Footer>
           
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
         

        </Modal.Footer>
      </Modal>
    );
  }


  const ReasonEditPopup=(props)=>{

    return (
      <Modal
        {...props}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={detailData} update={handleSend}/>
    
        </Modal.Body>
        <Modal.Footer>
           
           <Button variant="secondary" onClick={props.onHide}>Close</Button>
           <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const callInstructionSubmit=(instrString)=>{

    const tokenString= localStorage.getItem('usertoken');
     const dataToSend={
      instruction: instrString
     }
     let str= tokenString.replace(/["]+/g, '');           
      axios.post(`${APIURL}/api/v1/patient/instructions/?patient_id=${id}`,dataToSend, { headers: {
        
        "Authorization" : 'Token '+str,
        'Content-Type': 'application/json'  }


      })

      .then(res=>{
       
        if(res.data.status=== "success"){
       
             setSubmitInstructionsMsg("success");
                  setModalInstructionsShow(true);
                     
         

       
        }
        else{
          setSubmitMsg("error");
                setErrorSubmit(true);
        }
      })
      .catch(err=>{
        setSubmitMsg("error");
                setErrorSubmit(true);

  })



  }
  const handleSubmitInstructions=(e)=>{

    if(instructions!=='' && instructions!==null){
    const instrToSend= instructions.trim().split("\n");

    callInstructionSubmit(instrToSend);
  }
  }
  const handleDeleteRowTest = (index)=>{
    setDeleteTestConfirmMsg('success');
    setDeleteTestConfirmModalShow('true');
    setTestIndexToDelete(index);
     
  }

  const deleteRow=(index)=>{
    let newlist=[];
    if(index> testListCounter-1){
    newlist = testsList.filter((test,i)=>{
      return i!== index
    })

    setTestsList(newlist);
    if(newlist.length<=0){
     
      setTestsList([{id:"",test: "", date:"", labtest_reason:{details:"",image: "",reason:""}}]);
      
    }
    


  }
  else{

    handleDeleteRowTest(index);
      
  }
    
  }
  const renderEditableTest=(cellInfo)=> {
    if(cellInfo.index > testListCounter-1 ||testListCounter===0){
   
      return (<div  ref={wrapperRef}>
        
        <input
          style={{ backgroundColor: "#fafafa" }}
          value= {testsList[cellInfo.index][cellInfo.column.id]}
          className="form-control"        
         name="test"

          onChange={(e)=>{

             e.preventDefault();

  setCurrentIndex(cellInfo.index);
                               setCurrentColumn(cellInfo.column.id);
                              setDisplaySuggestions(true);
                              setDisplay(true);
   

   

    setSearch(e.target.value);


   
    const tests = testsList;
            tests[cellInfo.index].test = e.target.value;
            
            
            setTestsList(tests);
     } }
       autoComplete="off"
                 
        />

         

           
          {currentIndex === cellInfo.index && cellInfo.column.id=== "test" && display && (<div className="suggestions-box">
               {options.map((item,i)=>{
           
      return(
        <div className="scan-item" 
            
              key={i} 
              onClick={(e)=>{ e.preventDefault();
               
                setRender(!render);
                
                                        const tests=testsList;
                                        tests[cellInfo.index].test= item;
                                         
                                   
                                        setTestsList(tests);
                                        setDisplay(false);

                                        }}
              >
        <p className="search-item">{item}</p>
        </div>
        )
    }) 
             }

          </div>)
        }
        

        </div>
          

      )
    }
    else{
      return (<div>
        
        <div
          style={{ backgroundColor: "#fafafa" }}
          
          className="form-control test-name-col"        
         name="date"
          
          
         
          value={testsList[cellInfo.index].date}
         
          onBlur={(e)=>{const tests = testsList;
            tests[cellInfo.index].test = e.target.innerHTML;
            
            setTestsList(tests);

          

         }}
          
          
       autoComplete="off"
        dangerouslySetInnerHTML={{
            __html: testsList[cellInfo.index][cellInfo.column.id]
          }}
        />

         

           
         
          </div>)

    }
    }



  const renderEditableTestDate=(cellInfo)=> {
    if(cellInfo.index > testListCounter-1){

      const today =new Date();
     
      const offset = today.getTimezoneOffset();

  let dateUpdated = new Date(today.getTime() - (offset*60*1000));



  let DateExtracted = dateUpdated.toISOString().split('T')[0];
      return (<div>
        <input type="date" data-date={testsList[cellInfo.index].date} className="scan-date-col date-custom-format" min={DateExtracted} onChange={e=>

                                {
                                  e.preventDefault();
                                  let date=e.target.value;
                                 
                                  const tests = testsList;
            tests[cellInfo.index].date = date;
          
            setTestsList(tests);
            const m = moment(date,"YYYY-MM-DD").format("YYYY-MM-DD");
          
            setDateVal(m);
                                 }
  }
  /> 

                       </div>

        )

        }




    else{

      return (<div>
        
        <div
          style={{ backgroundColor: "#fafafa" }}
          
          className="form-control test-date-col"        
         name="date"
          
          
         
          value={testsList[cellInfo.index].date}
         
          onBlur={(e)=>{const tests = testsList;
            tests[cellInfo.index].date = e.target.innerHTML;
            
            setTestsList(tests);

          

         }}
          
          
       autoComplete="off"
        dangerouslySetInnerHTML={{
            __html: testsList[cellInfo.index][cellInfo.column.id]
          }}
        />

         

           
         
          </div>)

    }

        }
   
      

        const editTestImage = (pos)=> {
          setCell(pos)
          const fileInput = document.getElementById('testImage')
          fileInput.click();
        }
        
        const uploadTestImage= (e)=> {
          const file=  e.target.files[0];
                    const test =testsList
                    if(!file){
                      setUploadShow(!uploadShow)
                      //setInvalidPic('Please Select an Image')
                      return false
                    }
                    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
                      setValidShow(!validShow)
                      //setInvalidPic('Please select valid image')
                      return false;
                    }
                    if(file.size>2e6){
                      setSizeShow(!sizeShow)
                      return false
                    }
                    reader.onload = (e) => {
                      const img1 = new Image();
                      img1.onload = () => {
                        //setToggle(true)
                        //setImg(file)
                        test[cell].labtest_reason.image= file
                        setTestsList(test)
                        setIndex(cell)
                        setTableRefresh(!tableRefresh)
                      };
                      img1.onerror = () => {
                        //setPreviewOn(false)
                        setContentShow(!contentShow)
                        
                        return false;
                      };
                      img1.src = e.target.result;
                    };
                    reader.readAsDataURL(file);       
        }
         
              const renderTestImage= (cellInfo)=> {
                if(cellInfo.index > testListCounter-1){

                  if(testsList[cellInfo.index].labtest_reason.image===null || testsList[cellInfo.index].labtest_reason.image===''){
                    return(
                      <div>
                        <input id='testImage' hidden='hidden' type="file"  onChange={uploadTestImage} />
              
                        <i onClick={()=>{editTestImage(cellInfo.index)}} class="fas fa-image select-img"></i>
                      </div>
                    )
                  }
                  else {
                    return(
                      <button onClick={()=> {setImgEditShow(true); setIndex(cellInfo.index)}} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
                    )
                  }
                }
                
                else{
                  if(testsList[cellInfo.index].labtest_reason.image===null || testsList[cellInfo.index].labtest_reason.image===''){
            
                    return null
                    
                  }
                  else{
                    return(
                      <button onClick={()=> {setViewShow(true); setIndex(cellInfo.index); setFlag(true)}} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
                    )
                  }
                  
                }
                
              } 
              
            const renderTestDetails= (cellInfo)=> { 
        
            if(cellInfo.index > testListCounter-1){
              return (<div>
                <textarea className="text-area" placeholder="Add Test Details"  cols="100" rows="2"  onChange={e=>
          
                                        {
                                          e.preventDefault();
                                          let data=e.target.value;
                                         
                                          const tests = testsList;
                                          testsList[cellInfo.index].labtest_reason.details = data;
                    
                    setTestsList(tests);
        
                                         }
          }
          /> 
          
                               </div>
          
                )
          
                }
          
          
                else{
                   return (
        
        <textarea  className="scan-text" value={testsList[cellInfo.index].labtest_reason.details}  cols="100" rows="2"/>
                
                /*<div
                  style={{ backgroundColor: "#fafafa" }}
                  
                  className="form-control scan-date-col"        
                 name="date"
                   
                 
                  value={scanList[cellInfo.index].scan_details.details}
                 
                  onBlur={(e)=>{const scans = scanList;
                    scanList[cellInfo.index].scan_details.details = e.target.innerHTML;
                    
                    setScanList(scans);
          
          
                 }}
                  
                  
               autoComplete="off"
                dangerouslySetInnerHTML={{
                    __html: scanList[cellInfo.index].scan_details.details
                  }}
                />
          
                 
          
                   
                 
                  </div>*/)
                }
        }
        
        const handleReasonAdd =(index)=> {
          setCell(index)
          setReasonAddShow(true)
        }
        
        const handleReasonEdit =(data,index) => {
          setDetailData(data)
          setCell(index)
          setReasonEditShow(true)
        }
        
        const renderTestReason= (cellInfo)=> {
          
          if(cellInfo.index > testListCounter-1){
            if(testsList[cellInfo.index][cellInfo.column.id].reason==="" || testsList[cellInfo.index][cellInfo.column.id].reason===null){
              return (
                <button onClick={()=> handleReasonAdd(cellInfo.index)}  className='btn btn-smallest add-remark-btn'><i className="fas fa-plus"></i></button>
              )
            }
            else{
              return(
                <button onClick={()=> handleReasonEdit(testsList[cellInfo.index][cellInfo.column.id].reason, cellInfo.index)} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
              )
            }
              
          }
              else{
                if(testsList[cellInfo.index][cellInfo.column.id].reason==="" || testsList[cellInfo.index][cellInfo.column.id].reason===null){
                  return null
                  
                }
                else{
                  return <button onClick={()=> handleView(testsList[cellInfo.index][cellInfo.column.id].reason)} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
                }
              }
        }
        
            
                
        
        const AddRowTest=()=>{
          const list = testsList;
          list.push({id:"", test: "",date:"", labtest_reason:{details:"",image: "",reason:""}});
          
          setTestsList(list);
          setRender(true);
        
        }
      


  const columns = [


{
  Header: <i className="fas fa-trash-alt"></i>,
  Cell: props=>{
    return (
  <i className="fas fa-trash-alt trash-btn" onClick={()=>{deleteRow(props.index)}} ></i>
    )
  },
  width:60,
  sortable:false,
  filterable:false
},

  {
    Header: "TEST NAME",
    accessor: "test",
    Cell: renderEditableTest,
    style:{
      backgroundColor:"rgba(0,0,0,0.1)"
    },
    width:310,
    minWidth:100,
     sortable:false

  },

  {
    Header: "DATE (YYYY-MM-DD)",
    accessor: "date",
    Cell: renderEditableTestDate,
     sortable:false,
     width:190


  },
  {
    Header: "Test Details",
    accessor: "labtest_reason",
    Cell: renderTestDetails,
     sortable:false,
     filterable:false,
     width:310

  },
  {
    Header: "Reason",
    accessor: "labtest_reason",
    Cell: renderTestReason,
     sortable:false,
     filterable:false,
     width:110

  },
  {
    Header: <i class="fas fa-upload"></i>,
    accessor: "labtest_reason",
    Cell: renderTestImage,
    sortable:false,
    filterable:false,
    width:100
  },

  {
    Header: "ADD",
    
    Cell: props=>{
      return (
      props.index === testsList.length- 1 ? 
      <button className="btn btn-primary btn-col btn-col btn-col btn-small" onClick={AddRowTest}>+Add</button> 
      : null 
      )
    },
     width:80,
    sortable:false,
    filterable:false
  }

  ]


  const AddRowScan=()=>{
    const list = scanList;
    list.push({id:"",test: "", date:"", scan_details:{details:"",image: "",reason:""}});
    
    setScanList(list);
    setRender(true);

  }
  const handleDeleteRowScan = (index)=>{
    setDeleteScanConfirmMsg('success');
    setDeleteScanConfirmModalShow('true');
    setScanIndexToDelete(index);
     
  }


    const DeleteScanConfirmPopup =(props)=>{
     
    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Scan</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

          <Button variant="danger" onClick={()=>{setDeleteScanConfirmModalShow(false);
                                                                 handleDeleteScan(props.index);
                                                                 setScanIndexToDelete(null);}}> Confirm</Button>

         

        </Modal.Footer>
      </Modal>
    );
  }

  const DrNotesPopup =(props)=>{
     
    
    return (
         <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <DrNotes  {...props}/> 
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>

         

         

        </Modal.Footer>
      </Modal>
    );
  }

  const ObservationsPopup =(props)=>{
     
    
    return (
         <Modal
        {...props}
       
         aria-labelledby="example-custom-modal-styling-title"
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <h3 className="title-of-tasks header">   Observations & Symptoms </h3>
         <Suspense fallback={<div>Loading...</div>}>
            <Observations patientId={id}/> 
         </Suspense>
         
          <AddObservations  patientId={id}/>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button className='btn btn-primary btn-col' onClick={props.onHide}> X Close</Button>

         

         

        </Modal.Footer>
      </Modal>
    );
  }

  const SizePopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <h4 id="confirm-reject">You have Exceeded the size limit 2MB</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const LoadPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <h4 id="confirm-reject">Please select an Image</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const ValidPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <h4 id="confirm-reject">Please select a valid Image</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const ContentPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <h4 id="confirm-reject">Invalid Image Content</h4>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const handleDeleteScan=(index)=>{
   
    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '');
      
      
    const dataToUpdate ={
      ts_id: scanList[index].id,
      patient_id: id
    }


    
      axios.put(`${APIURL}/api/v1/doctor/patient-record/`,dataToUpdate, { headers: {
        
        "Authorization" : 'Token '+str,
        'Content-Type': 'application/json'  }


      })



      .then(res=>{
     
        if(res.data.status==="success"){
        setReRender(!reRender);
      
       
    }
    else{
     
     
    }

      })
      .catch(err=>{
    
    setSubmitMsg("error");
  })


   
  }
  const deleteRowScan=(index)=>{
    let newlist=[];
    if(index>scanListCounter-1){
    newlist = scanList.filter((scan,i)=>{
      return i!== index

    })

    setScanList(newlist);
   
    if(newlist.length<=0){
    
      setScanList([{id:"",test: "", date:"", scan_details:{details:"",image: "",reason:""}}]);
      
    }
  }
  else{
    handleDeleteRowScan(index);
      }
  }


  const handleMessagesOverlay=(e)=>{
   e.preventDefault();
   setMessagesShow(true);

  }

  const renderEditableScan=(cellInfo)=> {

     if(cellInfo.index > scanListCounter-1 ||scanListCounter===0){
      return (<div >
        
        <input
          style={{ backgroundColor: "#fafafa" }}
          value= {scanList[cellInfo.index][cellInfo.column.id]}
          className="form-control"        
         name="test"

          onChange={(e)=>{

             e.preventDefault();

  setCurrentIndex(cellInfo.index);
                               setCurrentColumn(cellInfo.column.id);
                                 setDisplaySuggestions(true);
                                 setDisplay(true);
   

   
    
    setSearchScan(e.target.value);

    
   
           const scans = scanList;
            scans[cellInfo.index].test = e.target.value;
            
            
            setScanList(scans);
     } }
       autoComplete="off"
                 
        />

         

           
          {currentIndex === cellInfo.index && cellInfo.column.id=== "test" && display && (<div className="suggestions-box">
               {scanSuggest.map((item,i)=>{
           
      return(
        <div className="scan-item" 
            
              key={i} 
              onClick={(e)=>{ e.preventDefault();
                
                setRender(!render);
                
                                        const scans=scanList;
                                        scans[cellInfo.index].test= item;
                                        
                                        setScanList(scans);
                                        setDisplay(false);

                                        }}
              >
        <p className="search-item">{item}</p>
        </div>
        )
    }) 
             }

          </div>)
        }
        

        </div>
          

      )
    }
    else{
       return (<div>
        
        <div
          style={{ backgroundColor: "#fafafa" }}
          
          className="form-control scan-name-col"        
         name="scan"
           
         
          value={scanList[cellInfo.index].scan}
         
          onBlur={(e)=>{const scans = scanList;
            scans[cellInfo.index].scan = e.target.innerHTML;
            
            setScanList(scans);


         }}
          
          
       autoComplete="off"
        dangerouslySetInnerHTML={{
            __html: scanList[cellInfo.index][cellInfo.column.id]
          }}
        />

         

           
         
          </div>)
    }
    }


    const renderEditableScanDate=(cellInfo)=> {
    if(cellInfo.index > scanListCounter-1){

      const today =new Date();
      
      const offset = today.getTimezoneOffset();

  let dateUpdated = new Date(today.getTime() - (offset*60*1000));



  let DateExtracted = dateUpdated.toISOString().split('T')[0];
      return (<div>
        <input type="date" data-date={scanList[cellInfo.index].date} className="scan-date-col date-custom-format" min={DateExtracted} onChange={e=>

                                {
                                  e.preventDefault();
                                  let date=e.target.value;
                                 
                                  const scans = scanList;
            scans[cellInfo.index].date = date;
            
            setScanList(scans);
            const m = moment(date,"YYYY-MM-DD").format("YYYY-MM-DD");
          
            setDateVal(m);
                                 }
  }
  /> 

                       </div>

        )

        }


        else{
           return (<div>
        
        <div
          style={{ backgroundColor: "#fafafa" }}
          
          className="form-control scan-date-col"        
         name="date"
           
         
          value={scanList[cellInfo.index].date}
         
          onBlur={(e)=>{const scans = scanList;
            scans[cellInfo.index].date = e.target.innerHTML;
            
            setScanList(scans);


         }}
          
          
       autoComplete="off"
        dangerouslySetInnerHTML={{
            __html: scanList[cellInfo.index][cellInfo.column.id]
          }}
        />

         

           
         
          </div>)
        }
        }

    
        let sendData = null

  function handleSend(childData){
    sendData = childData
  }

  const addHandle= ()=> {
    setAddShow(false)
    const scans =scanList
    scans[cell].scan_details.reason= sendData
    setScanList(scans)
    setEditSuccess(true)
  }

  const AddPopup=(props)=>{
      

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} save={addHandle}/>

        </Modal.Body>
        <Modal.Footer>
           
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
         

        </Modal.Footer>
      </Modal>
    );
  }

  const RemarkViewPopup=(props)=>{
      

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ViewRemark data={detailData}/>

        </Modal.Body>
        <Modal.Footer>
           
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
         

        </Modal.Footer>
      </Modal>
    );
  }


  const DetailPopup=(props)=>{

    return (
      <Modal
        {...props}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={detailData} update={handleSend}/>
    
        </Modal.Body>
        <Modal.Footer>
           
           <Button variant="secondary" onClick={props.onHide}>Close</Button>
           <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const DetailsUpdateSuccessPopUp=(props)=> {
   
   
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Updated sucessfully.Please Submit to Save Changes</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
          
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
         
         
        </Modal.Footer>
      </Modal>
    );
  }



  const handleAdd =(index)=> {
    setCell(index)
    setAddShow(true)
  }

  const handleView =(data) => {
    setDetailData(data)
    setRemarkViewShow(true)
  }

  const handleEdit =(data,index) => {
    setDetailData(data)
    setCell(index)
    setEditShow(true)
  }

    const renderDetails= (cellInfo)=> { 

      if(cellInfo.index > scanListCounter-1){
        return (<div>
          <textarea  className="text-area" placeholder="Add Scan Details"  cols="100" rows="2"  onChange={e=>
    
                                  {
                                    e.preventDefault();
                                    let data=e.target.value;
                                   
                                    const scans = scanList;
                                    scanList[cellInfo.index].scan_details.details = data;
              
              setScanList(scans);

                                   }
    }
    /> 
    
                         </div>
    
          )
    
          }
    
    
          else{
             return (

  <textarea  className="scan-text" value={scanList[cellInfo.index].scan_details.details}  cols="100" rows="2"/>
          
          /*<div
            style={{ backgroundColor: "#fafafa" }}
            
            className="form-control scan-date-col"        
           name="date"
             
           
            value={scanList[cellInfo.index].scan_details.details}
           
            onBlur={(e)=>{const scans = scanList;
              scanList[cellInfo.index].scan_details.details = e.target.innerHTML;
              
              setScanList(scans);
    
    
           }}
            
            
         autoComplete="off"
          dangerouslySetInnerHTML={{
              __html: scanList[cellInfo.index].scan_details.details
            }}
          />
    
           
    
             
           
            </div>*/)
          }
  }

  const renderReason= (cellInfo)=> {
    
    if(cellInfo.index > scanListCounter-1){
      if(scanList[cellInfo.index][cellInfo.column.id].reason==="" || scanList[cellInfo.index][cellInfo.column.id].reason===null){
        return (
          <button onClick={()=> handleAdd(cellInfo.index)}  className='btn btn-smallest add-remark-btn'><i className="fas fa-plus"></i></button>
        )
      }
      else{
        return(
          <button onClick={()=> handleEdit(scanList[cellInfo.index][cellInfo.column.id].reason, cellInfo.index)} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
        )
      }
        
    }
        else{
          if(scanList[cellInfo.index][cellInfo.column.id].reason==="" || scanList[cellInfo.index][cellInfo.column.id].reason===null){
            return null
            
          }
          else{
            return <button onClick={()=> handleView(scanList[cellInfo.index][cellInfo.column.id].reason)} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
          }
        }
  }

  const editImage = (pos)=> {
    setCell(pos)
    const fileInput = document.getElementById('imageInput')
    fileInput.click();
  }

  const uploadImage= (e)=> {
    const file=  e.target.files[0];
              const scans =scanList
              if(!file){
                setUploadShow(!uploadShow)
                return false
              }
              if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
                setValidShow(!validShow)
                return false;
              }
              if(file.size>2e6){
                setSizeShow(!sizeShow)
                return false
              }
              reader.onload = (e) => {
                const img1 = new Image();
                img1.onload = () => {
                  scans[cell].scan_details.image= file
                  setScanList(scans)
                  setIndex(cell)
                  setTableRefresh(!tableRefresh)
                };
                img1.onerror = () => {
                  setContentShow(!contentShow)
                  
                  return false;
                };
                img1.src = e.target.result;
              };
              reader.readAsDataURL(file);     

  }

    const renderImage= (cellInfo)=> {
      if(cellInfo.index > scanListCounter-1){
        /*if(scanList[cellInfo.index].scan_details.image!==null || scanList[cellInfo.index].scan_details.image!==''){
          return(
            <button onClick={()=> {setPopupShow(true); setIndex(cellInfo.index)}} className='btn btn-secondary btn-square btn-smallest'>
              {scanList[cellInfo.index].scan_details.image instanceof File ? 'View/Edit' : 'View'}</button>
            )
          }*/

        if(scanList[cellInfo.index].scan_details.image===null || scanList[cellInfo.index].scan_details.image===''){
          return(
            <div>
              <input id='imageInput' hidden='hidden' type="file"  onChange={uploadImage} />
    
              <i onClick={()=>{editImage(cellInfo.index)}} class="fas fa-image select-img"></i>
            </div>
          )
        }
        else {
          return(
            <button onClick={()=> {setPopupShow(true); setIndex(cellInfo.index)}} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
          )
        }
        /*else {
          return(
            <button onClick={()=> {setPopupShow(true); setIndex(cellInfo.index)}} className='btn btn-secondary btn-square btn-smallest'>
              {scanList[cellInfo.index].scan_details.image instanceof File ? 'View/Edit' : 'View'}</button>
          )
        }*/
      }
      
      else{
        if(scanList[cellInfo.index].scan_details.image===null || scanList[cellInfo.index].scan_details.image===''){

          return null
          
        }
        else{
          return(
            <button onClick={()=> {setViewShow(true); setIndex(cellInfo.index)}} className='btn btn-success btn-square btn-smallest'>View</button>
          )
        }
        
      }
      
    }
        

    const columnsScans = [


    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: props=>{
        return (
                  <i className="fas fa-trash-alt trash-btn" onClick={()=>{deleteRowScan(props.index)}}></i>
        )
      },
      width:60,
      sortable:false,
      filterable:false
      },
      {
        Header: "SCAN NAME",
        accessor: "test",
        Cell: renderEditableScan,
        style:{
          backgroundColor:"rgba(0,0,0,0.1)"
        },
        width:310,
        minWidth:100,
         sortable:false
      
      },
      {
        Header: "DATE (YYYY-MM-DD)",
        accessor: "date",
        Cell: renderEditableScanDate,
         sortable:false,
         width:190
      
      },

      {
        Header: "Scan Details",
        accessor: "scan_details",
        Cell: renderDetails,
         sortable:false,
         filterable:false,
         width:310
      
      },
      {
        Header: "Reason",
        accessor: "scan_details",
        Cell: renderReason,
         sortable:false,
         filterable:false,
         width:110
      
      },
      {
        Header: <i class="fas fa-upload"></i>,
        accessor: "scan_details",
        Cell: renderImage,
        sortable:false,
        filterable:false,
        width:100
      },
      {
        Header: "ADD",
       
        Cell: props=>{
          return (
          props.index === scanList.length- 1 ? 
          <button className="btn btn-primary btn-col btn-col btn-small" onClick={AddRowScan}> +Add</button> 
          : null 
          )
        },
         width:80,
        sortable:false,
        filterable:false
      }
      
      ]

  const renderTooltip2 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      Can set new targets for the patient, displays active targets and past targets of the patient. 
    </Tooltip>
  );

  const renderTooltip3 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
     Includes Vitals details, Chronic medical condition, Inferences and Comorbidities
    </Tooltip>
  );

  const renderTooltip4 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      Vital data with graph and tabular details. 
    </Tooltip>
  );

  const renderTooltip6 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      Add the additional conditions occuring in a patient 
    </Tooltip>
  );
  const renderTooltip7 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      Vital Inferences derived from patient's data.
    </Tooltip>
  );
  const renderTooltip8 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      Current Medications taken by the patient displayed here. Can also set new medication. 
    </Tooltip>
  );
  const renderTooltip9 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      Observations & Symptoms noted is displayed here. Can set new Observations & Symptoms. 
    </Tooltip>
  );
  const renderTooltip10 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      Includes Nutrients data, Past Medical records of the patient.
    </Tooltip>
  );

  const renderTooltip12 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      All past medical records of the patient 
    </Tooltip>
  );
  const renderTooltip13 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      Any Instructions for the patient to follow is displayed. Can set/update instructions.
    </Tooltip>
  );
  const renderTooltip14 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
      Set Test Schedules,Scan schedules and Next Visit for the patient
    </Tooltip>
  );




const renderTooltip15 = (props) => (
    <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
     Time based and Event based Inference - Impact analysis 
         </Tooltip>
  );
  let summaryArray = summary.split(/[\r\n]+/);
  let  displaySummary= summaryArray.length>1 ? summaryArray.map((line,i)=>{
    if(line){
    return <><h6 className="text-left "> {i+1}.{line} </h6></>
  }
   }) : "-No data to show-" ;


let displaySummaryTable= <div className="sub-section inferences-subsection">
                        <div className="sub-section-header box-theme">
                                 

                        <h6 className="sub-section-title"> Inferences <span className="ref-text">(Ref: last 60 days) </span><OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip7}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
                        </div>
                        <div className="sub-section-body">  
                        {displaySummary}
                        </div>

                        </div>
   useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);


  return(<>
  <HospDocNavbar /> 
  <br/>

  <div className=" flex-row-end">
  <div className=" text-left">
          
            <button className="btn btn-info btn-col back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back </button> 
      </div> 

      <GeneralSearchBar {...props}/>
  </div>

  	<div className="patient-record-holder ">
               <div className="sticky-nav">
        <div className="left-nav">
            
           
           <ul className="stickynav-ul"> 
               
              
            
              <li onClick={()=>{handleClick(ref1)}}> Personal Data & Targets</li>
                 
             <li onClick={()=>{handleClick(ref2)}}> Diagnostics Info <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip3}><i className="fas fa-info-circle"></i></OverlayTrigger> </li>
               <li onClick={()=>{handleClick(ref8)}}> Inference & Impact Analysis <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip15}><i className="fas fa-info-circle"></i></OverlayTrigger> </li>
             
              
              <li onClick={()=>{handleClick(ref3)}}> Current Medications <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip8}><i className="fas fa-info-circle"></i></OverlayTrigger> </li> 
              <li onClick={()=>{handleClick(ref4)}}> Observations & Symptoms <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip9}><i className="fas fa-info-circle"></i></OverlayTrigger></li> 
             <li onClick={()=>{handleClick(ref5)}}>Health Info & Medical records <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip10}><i className="fas fa-info-circle"></i></OverlayTrigger></li> 
             <li onClick={()=>{handleClick(ref6)}}> Instructions <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip13}><i className="fas fa-info-circle"></i></OverlayTrigger> </li> 
              <li onClick={()=>{handleClick(ref7)}}> Treatment Planner <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip14}><i className="fas fa-info-circle"></i></OverlayTrigger> </li> 

            

              <span className="history-link" onClick={handleDrNotesOverlay} >
              <div className="sub-section-header header-box1 box-4 box-dr-notes">
                               
              <h6  className="sub-section-title dr-notes-nav"> Doctor Notes </h6>
             {/* <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="grey" xmlns="http://www.w3.org/2000/svg">
                  
            <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
          </svg> */}

              </div>
                </span> 
                <span  className="freq-link text-left"><Link to={{pathname:freq_link}} > <div className=" box-4 box-dr-notes header-box1 freq-box sub-section-header">
                             

              <h6 className="med-tests-link">Frequent Medicines/Tests Analysis</h6>
              </div>
              </Link></span>
             

              {/**********
               
             
              <button className="btn btn-primary btn-col btn-col btn-square btn-small" onClick={handleSubmit}> Save & Submit </button>
              **************/}
           </ul>


        </div>


        </div>
      

    <div className="right-section">
     
    <h4><span className="title-of-tasks" id={gender==='female' ? 'patient-female' : 'patient-male'}> {name} </span><span className="prime-header felixa-header"> (Felixa ID: {id} {sessionStorage.getItem('hospId') !==''? ' , Hospital ID:'+ sessionStorage.getItem('hospId') : null })</span></h4>
   <br/>
    
{/*********top panel**********/}
     <div className="info section-1  zero-contentbox"  > 
        
        
       <div className="box-2 visit-box  panel-box" onClick={()=>{handleClick(ref7)}}><h6 className="prime-header "> Next Visit : {next_visit ? <span> {next_visit}</span> : <span>  ---</span> }</h6></div>
       <div className="box-1 visit-box panel-box" onClick={()=>{handleClick(ref7)}}><h6 className="prime-header">  Upcoming Test : {testsList.length>0 ? <><span>{testsList[0].test ? testsList[0].test : "---"}</span><h6> {testsList[0].date}</h6></> : null}</h6></div>
        <div className="box-4 visit-box panel-box" onClick={()=>{handleClick(ref7)}}><h6 className="prime-header"> Upcoming Scan : {scanList.length>0 ? <><span>{scanList[0].test ? scanList[0].test : "---"}</span><h6> {scanList[0].date}</h6></> : null }</h6></div>
        {/* <div className="box-4 visit-box panel-box" onClick={()=>{handleClick(ref7)}}><h6 className="prime-header"> Procedure : {scanList.length>0 ? <><span>{scanList[0].test ? scanList[0].test : "---"}</span><h6> {scanList[0].date}</h6></> : null }</h6></div> */}

</div>
      
  
     {/*********top panel ends**********/}
           
          
           <div className="info section-1 each-section first-contentbox " ref={ref1}> 
          
         
             
            <div className="second-tab sub-section ">        
                      
                   <div className="sub-section-header box-theme">
                                 
                      
                        <h6 className="sub-section-title"> Personal Data </h6>
                      </div>
                        <div className="personal-details-sub-section">

                       <div className="sub-section-img ">
           <img className="profile-picture" src={imgUrl} alt='Profile Photo' width="120px" height="auto"/>                
           </div><br/><br/>
                  
                    <h6 > Gender : <span className="user-details">{gender}</span></h6>
                    <h6 > DOB: <span className="user-details"> {dob}</span> </h6>
                    <h6 > Age: <span className="user-details"> {age}</span> </h6>
                    <h6 > Weight: <span className="user-details wt"> {weight ? weight +" kg" : "-"}</span> </h6>
                    <h6 > Height: <span className="user-details ht"> {height ? height +" cm" : "-"}</span> </h6>
                    <h6 > Marital Status: <span className="user-details"> {maritalStatus? "Married" : "Not Married"}</span> </h6>
                   {/* <h6 className="user-details"> Place:  -  </h6>
                      <h6 className="user-details"> Phn:  -  </h6> */}
                   {/* <h6><i className="fa fa-phone" aria-hidden="true"></i> : +919876363647 </h6>
                    <h6>Addr: Palarivattom, <br/> Kochi, <br/>676123 </h6>  */}
                    </div>
            </div>
            

          


           <div className="second-tab sub-section imp-info-subsection">
              <div className="sub-section-header header-box1">
                     <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-bookmark-fill" fill="var(--red)" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5V2z"/>
              </svg>
                 
              <h6 className="sub-section-title"> Targets <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip2}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
              </div>
          
              <div className="target-tab-content">
             {/* <h6 style={{color:"grey",fontWeight:"bold"}}>Main Targets: </h6> */}
                <Suspense fallback={<div>Loading...</div>}>

                  <TargetBasic {...props} />

                </Suspense>
                  
                                   
                                   <br/>
                                    <span className="history-link" onClick={handleTargetOverlay} >

                                         <button className="btn btn-primary btn-col btn-col btn-smallest btn-square"> Goto Target Tables </button>
                                    </span>
                                   <br/>

              </div>
              
          </div>
      

         

  </div>
           {/*****************Diagnostic section ************/}
           <div className="each-section summary  section-diagnostic" ref={ref2}>
           <h5 className="sub-title">  Diagnostics Info <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip3}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>
               <div className="second-contentbox">
            
                  
                           
                              <div className="sub-section vitals-subsection diagnostics">
                        <div className="sub-section-header box-theme">
                                 

                        <h6 className="sub-section-title"> Vitals <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip4}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
                        </div>
                        <div className="sub-section-body">
                        <div className="vitals-col">
                        <div className="vitals-row">
                                 <div className="each-list-title each-tile box-4 colored-box colored-box-small" id="vitals-tile-small" onClick={handleGlucoseOverlay}>
                                <span className="history-link" >
                                     <h6>Blood Sugar </h6>
                                </span>
                                </div>

                                 <div className="each-list-title each-tile box-4 colored-box colored-box-small" id="vitals-tile-small" onClick={handlePulseOverlay}>
                                 <span className="history-link" >
                                <h6> Pulse </h6>
                                </span>
                                </div>



                        </div> 
                         <div className="vitals-row">
                                 <div className="each-list-title each-tile box-4 colored-box colored-box-small" id="vitals-tile-small"  onClick={handleBpOverlay}>
                                <span className="history-link" >
                                <h6>Blood Pressure </h6>
                                </span>
                                </div>

                                 <div className="each-list-title each-tile box-4 colored-box colored-box-small" id="vitals-tile-small" onClick={handleCholOverlay}>
                                <span className="history-link"  >
                                  <h6> Cholesterol </h6>
                                </span>
                                </div>



                        </div> 





                       </div>
                        </div>

                        </div>


                        
                             
                      
                              
                      {/******Primary/Chronic Medical Condition*******/}
                         <div className="sub-section primarymed-subsection diagnostics">
                        <div className="sub-section-header box-theme">
                                 
                      
                        <h6 className="sub-section-title"> Primary/Chronic Medical Condition</h6>                     
                        </div>
                        <div className="sub-section-body comorb-body-content"> 

                        <Suspense fallback={<div>Loading...</div>}>
                            <Chronic {...props} />
                        </Suspense>
                        
                        
                        </div>

                        </div>
                         {/******Chronic/Other Medical Condition******END*/}
                        {/******Comorbidities*******/}
                         <div className="sub-section comorbidities-subsection diagnostics">
                        <div className="sub-section-header box-theme">
                                 
                      
                        <h6 className="sub-section-title"> Comorbidities <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip6}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
                        </div>
                        <div className="sub-section-body comorb-body-content"> 

                        <Suspense fallback={<div>Loading...</div>}>
                            <Comorbidities {...props} />
                        </Suspense>
                        
                        
                        </div>

                        </div>
                         {/******Comorbidities******END*/}
                           
                         
                              

          </div>
               



                </div>


 {/******* Inferences/ Summary****

<div className="each-section summary  section-diagnostic" >
 <div className="second-contentbox">

 
                
                 <div className="sub-section inferences-subsection">
                        <div className="sub-section-header box-theme">
                                 

                        <h6 className="sub-section-title"> Inferences <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip7}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
                        </div>
                        <div className="sub-section-body">  
                        {displaySummary}
                        </div>

                        </div>

                
                </div>
              </div>
************/}
<div className="each-section summary  section-diagnostic" ref={ref8}>
  <h5 className="sub-title">  Inference & Impact Analysis <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip15}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>
              
            
                  

 <div className="">

 {/**** Inferences/ Summary******/}
                
                 <div className="">
                       
                        <div className="sub-section-body"> 
                         {summaryDone==="done" ? 
                         <Suspense fallback={<div>Loading...</div>}>
        <Inference patientId={id}  summary={displaySummaryTable}/>
      </Suspense>
                      
         : null}
                      <br/>
                                  <span className="history-link" onClick={handleInferenceOverlay} >

                                       <button className="btn btn-primary btn-col btn-col btn-smallest btn-square"> View Details</button>
                                  </span>
                                 <br/>
                        </div>

                        </div>

                
                </div>
              </div>




        {/**********************************/}
            <div className="each-section summary  section-diagnostic" ref={ref3}>
                    <h5 className="sub-title">  Current Medications & Set Online Prescription <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip8}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>
               <div className="second-contentbox">
            
                   {/******Prescription*******/}
                   <Suspense fallback={<div>Loading...</div>}>

                      <CurrentMedications id={id}/>

                   </Suspense>
                  

                    <span className="history-link" onClick={handleAddPrescOverlay} >

                                         <button className="btn btn-primary btn-col btn-col btn-small btn-square"> View Medications/ Set Prescription </button>
                                         
                                    </span>
                                  
                  <Link to={{pathname:'/prescription-form', id: id }}>
                    <span className="history-link" >

                                         <button style={{marginLeft:'10px'}} className="btn btn-primary btn-col btn-col btn-small btn-square"> Add Prescription </button>
                                         
                                    </span>
                       </Link>          

                        


              </div>
          </div>
          {/*************/}  


          {/*******************************OBSERVATIONS************/}     



            <div className="each-section summary  section-diagnostic" ref={ref4}>
                    <h5 className="sub-title">  Observations & Symptoms <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip9}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>
               
                         
                        
                                           {/****placeholder only****/}
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <Observations  patientId={id}/>
                                            </Suspense>
                                             

                                              <span className="history-link" onClick={handleObservationsOverlay}>
                                 <button className="btn btn-primary btn-col btn-col btn-smallest btn-square"> Set Observations & Symptoms </button>
                                </span>
                       
               
       {/******placeholder*** ENd*/}
                                             

                     

              
          </div>



          {/*****************************OBS END ********************/}       
                        
          {/*********Doctor Notes*********/}
           {/** <div className="second-tab sub-section sub-section-dr-notes ">
              <div className="sub-section-header box-theme">
                       <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
          }
    <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
  </svg>

              <h6 className="sub-section-title"> Doctor Notes</h6>
              </div>
              <div className="sub-section-body">
               <textarea className="text-area" placeholder=""  />
                </div>
          </div>
                  
   **/}
                 {/*****************/}
                
   





    {/**********************************/}
            <div className="each-section summary  section-diagnostic" ref={ref5}>
                    <h5 className="sub-title">  Health Info & Medical Records</h5>
                    <div className="second-contentbox">
              
            
                          {/******nutr*******/}
                         <div className="sub-section nutr-subsection ">
                              <div className="sub-section-header box-theme">
                                       
                            
                              <h6 className="sub-section-title"> Nutrients & Fitness Info </h6>
                              </div>
                        <div className="sub-section-body "> 
                          
                               <div className="history-boxes" >
                                        {/****box****/}
                                       <div className="each-list-title each-tile box-4 colored-box" onClick={handleFoodHabitsOverlay}>
                                
                              
                               
                                  <h5 className="each-title box-titles" >


                                    {" "}
                                     

                                    <span className="history-link"  >
                                      <h5>  Nutrients / <br/> Food Habits  </h5>
                                    
                                    
                                    </span>

                                   
                                  </h5>
                                  </div>
                                   {/****box end****/}

                                           {/****box****/}

                                                      <div className="each-list-title each-tile box-4 colored-box">
                                                {/*  <i className="fa fa-file task-icon" id="task-icon-color" aria-hidden="true"></i> */}
                                                 
                                                    <h5 className="each-title box-titles">


                                                      {" "}
                                                      <Link to="/in-progress" style={{ textDecoration: 'none' }}>
                                                        <h5>  Fitness Data  </h5>
                                                        
                                                      </Link >
                                                    </h5>
                                                    </div>

                                                     {/****box**end**/}

                                                  
                        </div>
       
                      
                        
                        </div>

                        </div>
                         {/******Nutr******END*/}


                       {/******MED HISTORY *******/}
                         <div className="sub-section medhistory-subsection">
                              <div className="sub-section-header box-theme">
                                       
                            
                              <h6 className="sub-section-title"> Medical Records <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip12}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
                              </div>
                        <div className="sub-section-body "> 
                          
                            <div className="history-boxes">

                                              {/****box****/}
                                            <div>
                                            <Link to={{
                                          pathname: `/lab-reports/${id}`,
                                          }} style={{ textDecoration: 'none' }}>
                                                                                      <div className="each-list-title each-tile box-4 colored-box" id="box-3">

                                  <h5 className="each-title box-titles">


                                    
                                      <h5>  Lab Reports & Comparison </h5>
                                      
                                      
                                    
                                  </h5>
                                  </div>
                                  </Link>
                                  
                                  </div>

                            {/*****box *****/}
                              
                                    <div>          
                            {/*  <i className="fa fa-medkit task-icon" id="task-icon-color"  aria-hidden="true"></i> */}
                            <Link to={{
                                        pathname: `/patient-prescriptions/${id}`,
                                        }} style={{ textDecoration: 'none' }}>
                                                                   <div className="each-list-title each-tile box-4 colored-box" id="box-2">

                                <h5 className="each-title box-titles">


                                  {" "}
                                  
                                    <h5>  Prescriptions  </h5>
                                    <h5> </h5>
                                    
                                  
                                </h5>
                                </div>
                                </Link>
                                
                                


                                </div>

                      {/*****BOX*****/}
          
                             <div>

                            {" "}
                            <Link to={{
                                  pathname: `/patient-discharge-summary/${id}`,
                                  }} style={{ textDecoration: 'none' }}>
                                  <div className="each-list-title each-tile box-4 colored-box">
                       {/* <i className="fa fa-medkit task-icon" id="task-icon-color"  aria-hidden="true"></i> */}
                         
                          <h5 className="each-title box-titles">

                              <h5>  Discharge <br/>Summary  </h5>
                              
                            
                          </h5>
                          </div>
                          </Link>
                          </div>
                        {/*****BOX ends****/}
                        {/*****BOX*****/}
          
                             
                            <div>

                            {" "}
                            <Link to={{
                                  pathname: `/patient-scan-report/${id}`,
                                  }} style={{ textDecoration: 'none' }}>
                                <div className="each-list-title each-tile colored-box box-4" id="box-1">
                       {/* <i className="fa fa-medkit task-icon" id="task-icon-color"  aria-hidden="true"></i> */}
                         
                          <h5 className="each-title box-titles"></h5>
                              <h5 style={{fontWeight: 'bolder'}}>  Scan Reports  </h5>
                              
                            
                          </div>
                          </Link>
                           </div>

                        {/*****BOX ends****/}
                        {/*****BOX*****/}
          
                             
                        <div>

                        {" "}
                        <Link to={{
                              pathname: `/miscellaneous/${id}`,
                              }} style={{ textDecoration: 'none' }}>
                            <div className="each-list-title each-tile box-4 colored-box">
                        {/* <i className="fa fa-medkit task-icon" id="task-icon-color"  aria-hidden="true"></i> */}

                          <h5 className="each-title box-titles"></h5>
                              <h5 style={{fontWeight: 'bolder'}}>  Miscellaneous  </h5>
    

  </div>
  </Link>
  </div>
                        

                </div>







                        </div>
                        </div>
                       {/******MED HISTORY --ends*******/}
                       </div>
          </div>
          {/*************/}   





  {/********* Set Reminders******/}
  <div className="each-section summary section-2" ref={ref6}>
                    <h5 className="sub-title">  Set Instructions <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip13}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>

                    
                    {/********instruction ****/}
                    <div className="second-contentbox">
                     <div className="second-tab sub-section sub-section-dr-notes ">
              <div className="sub-section-header box-theme">
                      
              <h6 className="sub-section-title"> Set Instruction</h6>
              </div>
              <div className="sub-section-body">
              <h6 date-instr> {instructionsDate? new Date(instructionsDate).toString().slice(0,21) : null}</h6> 

               <textarea className="text-area" placeholder=""  cols="150" rows="7"  value={instructions} onChange={handleInstructions}/>
                </div>


             

               
                <button className="btn btn-primary btn-col btn-col btn-square btn-small" onClick={handleSubmitInstructions}> Save Instructions </button>
               <br/><br/>
          </div>


        {/****** Messages *************

              <div className="second-tab sub-section ">
                 <div className="sub-section-header box-theme">
                     
                 <h6 className="sub-section-title header">   <i className="fas fa-comments messenger-icon"></i>Felixa Messenger  </h6>

                 </div>
              

       
              
             <div>
          
              <HospDrMessagesBasic patientId={id}/>

              <div className="history-link" onClick={handleMessagesOverlay} >

              
                                         <button className="btn btn-primary btn-col btn-col btn-smallest btn-square"> <i className="fas fa-expand-arrows-alt"></i> Load Messenger </button>
              </div>
              <br/>

              </div>

          </div>

          ************/}

           </div>
     

               </div>

                



   {/*****Schedule planners****/}
            <div className="each-section section-5 " ref={ref7}>
                  <h5 className="sub-title">  Treatment Planner <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip14}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>
                  <div className="schedule-planners">
                {/************/}

                <div className="second-contentbox">
                  <div className="each-list-title each-tile box-4 colored-box colored-box-small schedule-box" >
                   <h6 className="planner-titles">  Next Visit  </h6> 

                          <div>
                            <DatePicker
                            value={next}
                            onChange={date=>{handleNextVisit(date)}}
                             minDate = {new Date()}
                             clearIcon={null}

                            
                       /> 
                        <button className="delete-btn btn-smallest" 
         onClick={()=>{handleDeleteNextVisit1()}}> X </button>
                       </div>


                    </div>



              <div className="each-list-title each-tile box-4 colored-box colored-box-small"  onClick={handleDisplayTestTable}>
   <span className="history-link"><h6>Test Schedules </h6></span></div>


     <div className="each-list-title each-tile box-4 colored-box colored-box-small"  onClick={handleDisplayScanTable}>
     <span className="history-link"><h6>Scan Schedules </h6></span></div>

     {/* <div className="each-list-title each-tile colored-box colored-box-small" id='box-1'  onClick={handleDisplayScanTable}>
     <span className="history-link"><h6>Procedures </h6></span></div> */}

     

          </div><br/>


       {/***********schedule boxes-end*****/}
        

        {/***********SCAN SCHEDULE*****/}       

    {showScanTable?
                      (<>
                      <p className='scan-close'>
             <i onClick={()=>setShowScanTable(false)} className="fas fa-times-circle scan-close-icon"></i></p>
                      <ReactTable 
           columns={columnsScans}
           data={scanList}
           defaultPageSize = {5}
           
           resizable
           className="scan-react-table"
                             
                   
           >

           </ReactTable> 
           <br/>
        

           </>)

           : null }

            {/***********SCAN SCHEDULE***ends**/}   


    {/***********TEST SCHEDULE*****/}   

              {showTestTable?
                      (<> 
                      <p className='scan-close'>
             <i onClick={()=>setShowTestTable(false)} className="fas fa-times-circle scan-close-icon"></i></p>
                      <ReactTable 
                       columns={columns}
                       data={testsList}
                       defaultPageSize = {5}
                       
                       resizable
                       className="test-react-table"
                       >

                       </ReactTable><br/>  

                         {testsList.length<1 ?   <button className="btn btn-primary btn-col btn-col add-btn-react-table" onClick={AddRowTest}> + Click to Add Data </button>                  
                   : null}
                   </>)

                       : 

                       null }

    {/***********Test SCHEDULE***ends**/}   







                

    
                   

           
                        
   

           

           {deleteScanConfirmMsg==="success" ? 

            <DeleteScanConfirmPopup
          show={deleteScanConfirmModalShow}
          onHide={() =>  setDeleteScanConfirmModalShow(false)}
          index= {scanIndexToDelete}
        />

             : 
               '' }
            
            {deleteNextVisitConfirmMsg==="success" ? 

            <DeleteNextVisitConfirmPopup
          show={deleteNextVisitConfirmModalShow}
          onHide={() =>  setDeleteNextVisitConfirmModalShow(false)}
        
        />

             : 
               '' }
            
                  
                

                 
    

                   

                     
                        {deleteTestConfirmMsg==="success" ? 

            <DeleteTestConfirmPopup
          show={deleteTestConfirmModalShow}
          onHide={() =>  setDeleteTestConfirmModalShow(false)}
          index= {testIndexToDelete}
        />

             : 
               '' }
                       
                        

                     
                  
                                    
                   


                
                  </div>


        <button className="btn btn-primary btn-col btn-col btn-square btn-small" onClick={handleSubmit}> Save and Submit </button>

             </div>





  <br/>



  {(testErr!== "nil" && testErr !== "false") ?
   <ErrorPopup
          show={errorModalShow}
          onHide={() =>  setErrorModalShow(false)}
          
        />

  : null }

  {(scanErr!== "nil" && scanErr !== "false") ?
   <ErrorScanPopup
          show={errorScanShow}
          onHide={() =>  setErrorScanShow(false)}
          
        />

  : null }

  {drNotesShow ?
  <DrNotesPopup 
          show={drNotesShow}
          onHide={() =>  setDrNotesShow(false)}
          id ={id}
          
        />

  : null }

  {nextErr!== "nil" ?
   <ErrorNextPopup
          show={errorNextModalShow}
          onHide={() =>  setErrorNextModalShow(false)}
          
        />

  : null }

  {observationsShow ?
  <ObservationsPopup 
          show={observationsShow}
          onHide={() =>  setObservationsShow(false)}
          id ={id}
          
        />

  : null }

  {/*
  {scanShow? 
    <ScanPopup 
          show={scanShow}
          onHide={() =>  setScanShow(false)}
          id ={id}
          
        />

  : null }
  */}
  {foodHabitsShow? 
    <FoodHabitsPopup 
          show={foodHabitsShow}
          onHide={() =>  setFoodHabitsShow(false)}
          id ={id}
          
        />

  : null }
  {targetShow? 
    <TargetPopup 
          show={targetShow}
          onHide={() =>  {setTargetShow(false);
                         setRefresh(!refresh);}}
          id ={id}
          
        />

  : null }

  {/*
  {messagesShow? 
    <MessagesPopup 
          show={messagesShow}
          onHide={() =>  setMessagesShow(false)}
          id ={id}
          
        />

  : null }

  */}
  {glucoseShow? 
    <GlucosePopup 
          show={glucoseShow}
          onHide={() =>  setGlucoseShow(false)}
          id ={id}
          
        />

  : null }

  {bpShow? 
    <BpPopup 
          show={bpShow}
          onHide={() =>  setBpShow(false)}
          id ={id}
          
        />

  : null }

  {pulseShow? 
    <PulsePopup 
          show={pulseShow}
          onHide={() =>  setPulseShow(false)}
          id ={id}
          
        />

  : null }

  {cholesterolShow? 
    <CholesterolPopup 
          show={cholesterolShow}
          onHide={() =>  setCholesterolShow(false)}
          id ={id}
          
        />

  : null }

  {addPrescShow? 
    <AddPrescPopup 
          show={addPrescShow}
          onHide={() =>  setAddPrescShow(false)}
          id ={id}
          
        />

  : null }

  {inferenceShow? 
    <InferencePopup 
          show={inferenceShow}
          onHide={() =>  setInferenceShow(false)}
          
          
        />

  : null }

  {submitInstructionsMsg==="success" ? 
            <SubmitInstructionsPopUp
          show={modalInstructionsShow}
          onHide={() =>  {setModalInstructionsShow(false);
                        setReRender(!reRender);
                        setRefreshInstr(!refreshInstr);
                        setSubmitInstructionsMsg('')}}
         
        />
        :   submitMsg==="error"? 
       <SubmitErrorPopup
          show={errorSubmit}
          onHide={() =>  {setErrorSubmit(false);
                          setSubmitMsg('');}}
         
        />

           : ''
            }

  {deleteNextMsg!== "" ? 
            <DeletePopUp
          show={deleteNextModalShow}
          onHide={() =>  {setDeleteNextModalShow(false);
                        setReRender(!reRender);
                 
                        setDeleteNextMsg('');}}
         
        />
           : ""}
  {submitMsg==="success" ? 
            <SubmitPopUp
          show={modalShow}
          onHide={() =>  {setModalShow(false);
                        setReRender(!reRender);
                 
                        setSubmitMsg('');}}
         
        />
           :  submitMsg==="error"? 
       <SubmitErrorPopup
          show={errorSubmit}
          onHide={() =>  {setErrorSubmit(false);
                          setSubmitMsg('');}}
         
        />

           : ''
            }

  {
          sizeShow ? 
          <SizePopUp
          show={sizeShow}
          onHide={()=>{
            setSizeShow(false)
          }} /> : null
        } 

  {
          uploadShow ? 
          <LoadPopUp
          show={uploadShow}
          onHide={()=>{
            setUploadShow(false)
          }} /> : null
        } 

  {
          validShow ? 
          <ValidPopUp
          show={validShow}
          onHide={()=>{
            setValidShow(false)
          }} /> : null
        } 

  {
          contentShow ? 
          <ContentPopUp
          show={contentShow}
          onHide={()=>{
            setContentShow(false)
          }} /> : null
        } 

  {
          popupShow ? 
          <EditPopup
          show={popupShow}
          onHide={()=>{
            setPopupShow(false)
          }}
          onSave= {() => {
            if(imgFile !== null){
              setPopupShow(false)
              const scans= scanList
              scans[index].scan_details.image= imgFile
              setScanList(scans)            
              setEditSuccess(true)
            }
            
        } }
           /> : null
        } 

  {
          imgEditShow ? 
          <ImgEditPopup
          show={imgEditShow}
          onHide={()=>{
            setImgEditShow(false)
          }}
          onSave= {() => {
            if(imgFile !== null){
              setImgEditShow(false)
              const tests= testsList
              tests[index].labtest_reason.image= imgFile
              setTestsList(tests)            
              setEditSuccess(true)
            }
            
        } }
           /> : null
        }

  {
        remarkViewShow ? 
        <RemarkViewPopup
          show={remarkViewShow}
          onHide= {() => { setRemarkViewShow(false)     
          }}
        /> : null
      }

  {
          editSuccess ? 
          <DetailsUpdateSuccessPopUp
          show={editSuccess}
          onHide={()=>{
            setEditSuccess(false)          
          }}
          
           /> : null
        }

  {
        addShow ? 
        <AddPopup
          show={addShow}
          onHide= {() => { setAddShow(false)     
          }}
        /> : null
      }

      {
        editShow ? 
        <DetailPopup
          show={editShow}
          onHide= {()=> {
            setEditShow(false)
          }}
          onSave= {() => {
            if(sendData !== scanList[cell].scan_details.reason){
              setEditShow(false);
              setEditSuccess(true)
              const scans= scanList
              scans[cell].scan_details.reason= sendData
              setScanList(scans) 
              
            }
        } } /> : null
      }

  {
        viewShow ? 
        <ViewPopup
          show={viewShow}
          onHide= {() => { setViewShow(false); setFlag(false)     
          }}
        /> : null
      }

  {
        reasonAddShow ? 
        <ReasonAddPopup
          show={reasonAddShow}
          onHide= {() => { setReasonAddShow(false)     
          }}
        /> : null
      }

      {
        reasonEditShow ? 
        <ReasonEditPopup
          show={reasonEditShow}
          onHide= {()=> {
            setReasonEditShow(false)
          }}
          onSave= {() => {
            if(sendData !== testsList[cell].labtest_reason.reason){
              setReasonEditShow(false);
              setEditSuccess(true)
              const tests= testsList
              tests[cell].labtest_reason.reason= sendData
              setTestsList(tests) 
              
            }
        } } /> : null
      }


  {messagesShow===false ?
    <div className="dr-msgs-basic-sticky " onClick={handleMessagesOverlay} title="Message via Felixa Messenger">

              <i className="fas fa-comments messenger-icon"></i>
                                       
              </div>
              : null
  }

  {/*
   <div className="dr-msgs-basic-sticky">
       <HospDrMessagesBasic patientId={id}/>

  </div>
  */}
      </div>

     
      </div>

      { messagesShow? 
  <MessagesPopup 
        show={messagesShow}
        onHide={handleTrack}
        id ={id}
        
      />

  : null  }

{/*{prescForm ? 
<PrescFormPopUp
show={prescForm}
onHide={() =>  {setPrescForm(false);}}
/> 
: null}*/}


     </>
  )
  }
  export default PatientRecord;
