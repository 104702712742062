import React, {useState,useEffect}  from 'react';

import {Link} from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import '../../css/bootstrap.min.css';
import '../../css/iofrm-style.css';
import '../../css/iofrm-theme4.css';
import "./register.css"
import axios from "axios";

import "./country_region_selector.css";
import "./phoneinput.css";
import "./hospregister.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

import {APIURL} from "../../Global";

function HospRegister(){
 const[img ,setImg] =useState();
   const [hospitalDatas,setHospitalDatas] = useState({
     hosp_name: '',
     phone: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
     email:'',
     country:'',
     state:'',
     location:'',
     street: "",
     password : '',
     confirmpassword:"",
   
      specialties:[],
      specialtiesArray:[],
     facilities: [],
     facilitiesArray:[],
     accreditation:null,
     regn_number:null,
     contact_phone:{
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
     primary_email:"",
     website_url: "",
     established_year: null,
     healthcare_type: "",
     practise_type:"",
     img_url:"",
     img:"",
     gst:"",
     description:"",
     emergency:{
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },

 })
   const [error,setError] = useState({
     hosp_name: '',
     phone: '',
     email:'',
     country:'',
     state:'',
     location:'',
     street:"",
     password : '',
     confirmpassword:"",
    
      specialties:"",
      specialtiesArray:"",
     facilities: "",
     facilitiesArray: "",
     accreditation:'',
     regn_number:"",
     contact_phone:"",
     primary_email:"",
     website_url: "",
     established_year: "",
     healthcare_type: "",
     practise_type:"",
     img_url:"",
     img:"",
     emergency:""



 });
   const [specialties,setSpecialties]=useState({
   general_surgery:false,
   metabolism_diabetes:false,
   emergency:false,
   oncology:false,
   pediatrics:false,
   ivf:false,
   obstetrics_gynaec:false,
   dietetics:false,
   physiology:false,
   cardiology:false,
   gastroenterology_nutrition:false,
   ophthalmology:false,
   ent:false,
   neurology:false,
   nephrology:false,
   family_medicine:false


 });
   const [facilities,setFacilities]=useState({
    icu:false,
    nicu: false,
    labour_room: false,
    physiotherapy:false,
    laboratory:false,
    ambulance:false,
    operation_theatre:false,
    blood_bank:false,
    emergency:false,
    pharmacy:false
   })
   const [facilitiesArray,setFacilitiesArray]=useState([]);
   const [specialtiesArray,setSpecialtiesArray]= useState([]);
const [isValidEmail,setIsValidEmail]=useState(true);
const [isValidPhone,setIsValidPhone]=useState(true);
const [invalidPic ,setInvalidPic]= useState(null)
const [previewOn , setPreviewOn]= useState(false);
const [formDataStatus,setFormDataStatus]=useState(false);
const [sizeShow ,setSizeShow] =useState(false)
const [validShow ,setValidShow] =useState(false)
const [contentShow ,setContentShow] =useState(false)
const [uploadShow ,setUploadShow] =useState(false)

 const [errorSubmitMsg,setErrorSubmitMsg]=useState(null);
 const [errorSubmit,setErrorSubmit]=useState(false);


 const [successSubmitMsg,setSuccessSubmitMsg]=useState(null);
 const [successSubmit,setSuccessSubmit]=useState(false);

 let formData =new FormData();
   //

   
   useEffect(()=>{
    let updatedFacilitiesArray= [];
    for(var key in facilities){
      if(facilities[key]=== true){
      updatedFacilitiesArray.push(key)
    }
  }     
    setFacilitiesArray(updatedFacilitiesArray);



 
   },[facilities]);

useEffect(()=>{
  let updatedSpecialtiesArray= [];
    for(var key in specialties){
      if(specialties[key]=== true){
      updatedSpecialtiesArray.push(key)
    }
  }

     setSpecialtiesArray(updatedSpecialtiesArray);


   },[specialties])


    const handleChange=e=>{
      e.preventDefault();
      const val=e.target.value;
      const key=e.target.name;
    
         setHospitalDatas(currentstate=>({
            ...currentstate,
            [key]: val
          }));
  
}

const reader = new FileReader();
const uploadImage=async e=>{
  const file=  e.target.files[0];
 
  if(!file){
    setUploadShow(!uploadShow)
    //setInvalidPic('Please Select an Image')
    return false
  }
  if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
    setValidShow(!validShow)
    //setInvalidPic('Please select valid image')
    return false;
  }
  if(file.size>2e6){
    setSizeShow(!sizeShow)
    return false
  }

  reader.onload = (e) => {
    const img = new Image();
    img.onload = () => {
      setImg(file);
      setInvalidPic(null)
    };

    img.onerror = () => {
      setPreviewOn(false)
      setContentShow(!contentShow)
      //setInvalidPic('Invalid Image Content')    
      return false;
    };
    img.src = e.target.result;
  };
  
  reader.readAsDataURL(file)

  const objectURL = URL.createObjectURL(file);
  
  setHospitalDatas(currentstate=>({
    ...currentstate,
       img_url: objectURL
     }));
  setPreviewOn(true);
 
 

  setFormDataStatus(true);


  
}


const SizePopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h5 id="confirm-reject">You have exceeded image size limit 2MB</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const LoadPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select an Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ValidPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select a valid Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ContentPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Invalid Image Content</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}


const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorSubmitMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}
const SubmitSuccessPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h4>Registered successfully !! </h4>
        <h6>
         Kindly verify your account from the email we have sent you.  
          <br />
        </h6>
        <h6>
         We are in process of reviewing your registration details and shall approve your account at the earliest.  
          <br />
        </h6>
         <h6>
         Thank you for understanding!  
          <br />
        </h6>
         <h6>
         In case of any queries, kindly contact us at  <a href="mailto:felixacare@IntPurple.com" target="_blank" rel="noopener noreferrer"> felixacare@IntPurple.com </a>   
          <br />
        </h6>
        
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>

         <Link to="/login"><Button className='btn btn-primary btn-col'>Goto Login</Button></Link>


       
       

      </Modal.Footer>
    </Modal>
  );
}


const removePicPreview=(e)=>{
  e.preventDefault();
  setPreviewOn(false);

 }


const handleContinue= async (e)=>{

    e.preventDefault();
    

   const res= await validate();
 
    if(res===true && isValidEmail && isValidPhone){
 
   handleSignup();
    }

   

}
const validate=async ()=>{
      let input = hospitalDatas;
      let errors = {};
      let isValid = true;
    setError({});


  

      if (input["hosp_name"]==="" || input["hosp_name"]=== null ) {
        isValid = false;
        errors["hosp_name"] = "Please enter Hospital name";
      }

      if (typeof input["hosp_name"] !== "undefined") {
          
        var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
        if (pattern3.test(input["hosp_name"])) {
          isValid = false;
          errors["hosp_name"] = "Special Characters are not allowed";
        }
      }
     
     if ( hospitalDatas.country==="" || hospitalDatas.country===null) {
        isValid = false;
        errors["country"] = "Please enter Country";
      }
      if (typeof hospitalDatas.state==="undefined" || hospitalDatas.state==="" || hospitalDatas.state===null) {
        isValid = false;
        errors["state"] = "Please enter Region";
      }
     
      if (typeof input["location"]==="undefined" || input["location"]==="" || input["location"]===null) {
        isValid = false;
        errors["location"] = "Please enter Location";
      }
     
        if (typeof input["street"]==="undefined" || input["street"]==="" || input["street"]===null) {
        isValid = false;
        errors["street"] = "Please enter Street address";
      }
     
 
      
  
     
      
      if(isValidPhone=== false){
        isValid = false;
        errors["phone"] = "Phone number already registered!";
      }
     if (hospitalDatas.phone.dialcode===null || hospitalDatas.phone.dialcode==="" || !hospitalDatas.phone.rawphone || hospitalDatas.phone.rawphone==="" ) {
        isValid = false;
        errors["phone"] = "Please enter phone number";
      }
   if (hospitalDatas.website_url!=="" && hospitalDatas.website_url!==null) {
        
        const pattern1 = new RegExp('^(https?:\\/\\/)?'+ 
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ 
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ 
            '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ 
            '(\\#[-a-z\\d_]*)?$','i'); 
        if (!pattern1.test(hospitalDatas.website_url)) {
          isValid = false;
          errors["website_url"] = "Please enter a valid url.";
        }
      }


      if (typeof input["email"] !== "undefined") {
          
        const pattern2 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern2.test(input["email"])) {
          isValid = false;
          errors["email"] = "Please enter a valid email address.";
        }
      }



     
     if(isValidEmail=== false){
        isValid = false;
        errors["email"] = "Email already registered!";
      }
     if (typeof input["email"]==="undefined" || input["email"]===null || input["email"]==="") {
        isValid = false;

        errors["email"] = "Please enter email Address.";
      }
      

     if (typeof input["primary_email"]!== "undefined" && input["primary_email"]!=="" && input["primary_email"]!==null ) {
          
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["primary_email"] = "Please enter a valid email address.";
        }
      }


      
      
    
     
       {/*if(typeof hospitalDatas.practise_type==="undefined" ||  hospitalDatas.practise_type==="" ||  hospitalDatas.practise_type=== null){
        isValid = false;
        errors["practise_type"] = "Please select Practise type";
      } 
      if(typeof hospitalDatas.healthcare_type==="undefined" ||  hospitalDatas.healthcare_type==="" ||  hospitalDatas.healthcare_type=== null){
        isValid = false;
        errors["healthcare_type"] = "Please select Health Care type";
      }

      if (facilitiesArray.length ===0) {
        isValid = false;
        errors["facilitiesArray"] = "Please mark Facilities";
      } */}
      if (specialtiesArray.length===0) {
        isValid = false;
        errors["specialtiesArray"] = "Please mark Specialties";
      }

      if (typeof input["password"] !== "undefined") {
          
        let pattern = new RegExp(/[A-Z]/g);
        if (!pattern.test(input["password"])) {
          isValid = false;
          errors["password"] = "Must contain atleast one Capital letter";
        }
        
      }
      
       if (input["password"] && typeof input["password"] !== "undefined") {
        if(input["password"].length >0 && input["password"].length < 6){
            isValid = false;
           
            errors["password"] = "Must contain atleast 6 characters";
        }
      }
      if (input["password"]===null || typeof input["password"]==="undefined" || input["password"]==="" || input["password"]===null) {
        isValid = false;
        errors["password"] = "Please enter your password";
      }
  

  
     
  
      if (typeof input["password"] !== "undefined" && input["password"]!=="" && typeof input["confirmpassword"] !== "undefined" && input["confirmpassword"]!=="" && input["confirmpassword"]!==null) {
          
        if (input["password"] !== input["confirmpassword"]) {
          isValid = false;
          errors["confirmpassword"] = "Passwords don't match";
        }
      }

      if (input["confirmpassword"]===null  || typeof input["confirmpassword"]==="undefined" || input["confirmpassword"]==="") {
        isValid = false;
        errors["confirmpassword"] = "Please enter confirm password";
      }
  
   let text = /^[0-9]+$/;
   if(hospitalDatas && hospitalDatas.established_year && hospitalDatas.established_year!== null && hospitalDatas.established_year!==""){
  if(hospitalDatas.established_year.length===4) {
    if (hospitalDatas.established_year !== 0) {
        if ((hospitalDatas.established_year !== "") && (!text.test(hospitalDatas.established_year))) {
            isValid = false;
        errors["established_year"] = "Please Enter Numeric Values Only";
           
        }

        if (hospitalDatas.established_year.length !== 4) {
           isValid = false;
             errors["established_year"] = "Please Enter year in proper format";
           
        }
        var current_year=new Date().getFullYear();
        if((hospitalDatas.established_year < 1600) || (hospitalDatas.established_year > current_year))
            {
               isValid = false;
            errors["established_year"] ="Year should be in range 1600 to current year";
            
            }
       
    }
    else{
      isValid = false;
            errors["established_year"] ="Year should be in proper format";
    } }
    else{
      isValid = false;
            errors["established_year"] ="Year should be in proper format";
            
    }
  }



       setError(currentstate=>({
            ...currentstate,
            ...errors
          }));
  

      return isValid;
  }
             /*Handle Signup */
  const  callSignUpWithoutFormData=()=>{
      


       
                 const dataToSend={
                    name:hospitalDatas.hosp_name,
                    mobile_number: hospitalDatas.phone.mobile_number,
                    email: hospitalDatas.email,
                    country:hospitalDatas.country,
                     state:hospitalDatas.state,
                      location: hospitalDatas.location,
                    street: hospitalDatas.street,
                   contact_phone: hospitalDatas.contact_phone.mobile_number? hospitalDatas.contact_phone.mobile_number : null,
                   accreditation:hospitalDatas.accreditation,
                   reg_number: hospitalDatas.regn_number,
                   primary_email:hospitalDatas.primary_email,
                   established_year: hospitalDatas.established_year,
                   
                   website:hospitalDatas.website_url,
                   
                    health_care_type: hospitalDatas.healthcare_type,
                    practise_type:hospitalDatas.practise_type,

                    facilities:facilitiesArray,
                     specializations : specialtiesArray,
                    password : hospitalDatas.password,
                    gst : hospitalDatas.gst,
                    description : hospitalDatas.description,
                    emergency_number : hospitalDatas.emergency.mobile_number? hospitalDatas.emergency.mobile_number : null,
                 }
                 
                  
    {/*  const data2={
                    name:hospitalDatas.hosp_name,
                    mobile_number: hospitalDatas.phone,
                    email: hospitalDatas.email,
                    country:hospitalDatas.country,
                     state:hospitalDatas.state,
                      location: hospitalDatas.location,
                    street: hospitalDatas.street,
                   contact_phone: null,
                   accreditation:null,
                   reg_number: null,
                   primary_email:"",
                   estlablished_year: "",
                   
                   website:"",
                   
                    health_care_type: "",
                    practise_type:"",

                    facilities:"",
                     specializations : specialtiesArray,
                    password : hospitalDatas.password
                 }  */}

       axios.post(`${APIURL}/api/v1/hospital/register/`, dataToSend, { headers: {
     'Content-Type': 'application/json'}})
    .then(res =>{
            
            if(res.data.status === "success"){
                
                setSuccessSubmitMsg("success");
          setSuccessSubmit(true);
                  
                    //history.push('/success') 

                }
            else {
               setErrorSubmitMsg(res.data.message);
          setErrorSubmit(true);
          
            
              //history.push('/error') */}

            }
                
            })
     .catch(err =>{
      setErrorSubmitMsg("Error in Hospital Registration. Please try again!");
          setErrorSubmit(true);


  })

   
 
    }


    const  callSignUpWithFormData=()=>{
      
       
      const user={       
         name:hospitalDatas.hosp_name,
         mobile_number: hospitalDatas.phone.mobile_number,
         email: hospitalDatas.email,
         country:hospitalDatas.country,
          state:hospitalDatas.state,
           location: hospitalDatas.location,
         street: hospitalDatas.street,
        contact_phone: hospitalDatas.contact_phone.mobile_number? hospitalDatas.contact_phone.mobile_number : null,
        accreditation:hospitalDatas.accreditation,
        reg_number: hospitalDatas.regn_number,
        primary_email:hospitalDatas.primary_email,
        established_year: hospitalDatas.established_year,
        
        website:hospitalDatas.website_url,
        
         health_care_type: hospitalDatas.healthcare_type,
         practise_type:hospitalDatas.practise_type,

         facilities:facilitiesArray,
          specializations : specialtiesArray,
         password : hospitalDatas.password,
         gst : hospitalDatas.gst,
         description : hospitalDatas.description,
         emergency_number : hospitalDatas.emergency.mobile_number? hospitalDatas.emergency.mobile_number : null,
      }

      if(user.emergency_number===null){
        formData.append("emergency_number",'');
       }
       else{
        formData.append("emergency_number",user.emergency_number);
       }

      if(user.established_year===null){
        formData.append("established_year",'');
       }
       else{
        formData.append("established_year",user.established_year);
       }
    
       if(user.contact_phone===null){
        formData.append("contact_phone", '');
       }
       else{
        formData.append("contact_phone", user.contact_phone);
       }

       if(user.gst===""){
        formData.append("gst",'');
       }
       else{
        formData.append("gst",user.gst);
       }

       if(user.description===""){
        formData.append("description",'');
       }
       else{
        formData.append("description",user.description);
       }

      formData.append('photo',img)
      formData.append("name", user.name);
      formData.append("mobile_number", user.mobile_number);
          formData.append("accreditation", user.accreditation);
           formData.append("reg_number", user.reg_number);
             formData.append("email", user.email);
              formData.append("primary_email", user.primary_email);
                formData.append("country", user.country);
                formData.append("state", user.state);
                formData.append("location", user.location);
                 formData.append("street",user.street);
                 formData.append("website", user.website);
               formData.append("health_care_type", user.health_care_type);
                formData.append("practise_type",user.practise_type);
                formData.append("facilities", user.facilities);
                formData.append("specializations", user.specializations);
                formData.append("password", user.password);

axios.post(`${APIURL}/api/v1/hospital/register/`, formData, { headers: {
  "Content-Type": "multipart/form-data"}})
.then(res =>{
 
 if(res.data.status === "success"){
     
     setSuccessSubmitMsg("success");
setSuccessSubmit(true);
       
         //history.push('/success') 

     }
 else {
    setErrorSubmitMsg(res.data.message);
setErrorSubmit(true);

 
   //history.push('/error') 

 }
     
 })
.catch(err =>{
setErrorSubmitMsg("Error in Hospital Registration. Please try again!");
setErrorSubmit(true);


})



}



    const  handleSignup=()=>{


      if(formDataStatus){
         
    
                    callSignUpWithFormData();
      }
      else{
      
         callSignUpWithoutFormData();
    
      }
    }
   

   
    
  
  const selectCountry =(val)=> {
     setHospitalDatas(currentstate=>({
        ...currentstate,
           country: val
         }));
     
    
  }

  const selectRegion= (val)=> {
    setHospitalDatas(currentstate=>({
        ...currentstate,
           state: val
         }));
  }

 const handlePhoneInput= (value, data, event, formattedValue)=>{
  const rawPhone = value.slice(data.dialCode.length) ;
  
  const dialcode = value.slice(0,data.dialCode.length) ;
     setHospitalDatas(currentstate=>({
        ...currentstate,
         phone: {
            dialcode: dialcode,
              rawphone: rawPhone,
              mobile_number: value
            }

                       }));
  
  

 }
 const handleContactPhoneInput= (value, data, event, formattedValue)=>{
  const rawPhone = value.slice(data.dialCode.length) ;
  
  const dialcode = value.slice(0,data.dialCode.length) ;
     setHospitalDatas(currentstate=>({
        ...currentstate,
         contact_phone: {
            dialcode: dialcode,
              rawphone: rawPhone,
              mobile_number: value
            }

                       }));
  
  

 }

 const handleEmergencyInput= (value, data, event, formattedValue)=>{
  const rawPhone = value.slice(data.dialCode.length) ;
  
  const dialcode = value.slice(0,data.dialCode.length) ;
     setHospitalDatas(currentstate=>({
        ...currentstate,
         emergency: {
            dialcode: dialcode,
              rawphone: rawPhone,
              mobile_number: value
            }

                       }));
  
 }
  
  
     
    const handleSpecialties=e=>{



    
      //const val=e.target.value;
      const key=e.target.name;
    
         setSpecialties(currentstate=>({
            ...currentstate,
            [key]: !(specialties[key])
          }));
      

 
} 


const handleCheckExisting=e=>{
  setIsValidEmail(true);
if(e.target.value!== ""){
       setError(currentstate=>({
              ...currentstate,
            email:  ''}));
  e.preventDefault();
  let key=e.target.name;


    const data = {
            [e.target.name]: e.target.value
        }
     
          axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
     'Content-Type': 'application/json'}})
    .then(res =>{
          
            if(res.data.status === "success"){
              if(res.data.is_existing_user === true){
             
                    setError(currentstate=>({
              ...currentstate,
            [key]: `This ${key} is already registered!`}));
             setIsValidEmail(false);
                 }
                  else{
                       setIsValidEmail(true);
                  }
                   
                    
                }
            else{
        
              setIsValidEmail(true);
                  
            }
                
            })
    .catch(err =>{
 
    
  })
}

}
const handleCheckExistingPhone= (e)=>{
setIsValidPhone(true);
if(e.target.value !==""){
  
  const rawPhone =  hospitalDatas.phone.rawphone ;
  
  const dialcode =  hospitalDatas.phone.dialcode;
   setError(currentstate=>({
              ...currentstate,
            phone: ''}));

  

    const data = {
            code: dialcode,
            mobile_number: rawPhone
        }
       
          axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
     'Content-Type': 'application/json'}})
    .then(res =>{
         
            if(res.data.status === "success"){
              if(res.data.is_existing_user === true){
               
                  setError(currentstate=>({
              ...currentstate,
            phone: "Phone number already registered!"}));
                 setIsValidPhone(false);
                 }
                  else{
                         setIsValidPhone(true);
   
                  }
                 
                    
                }
            else{
            
              setIsValidPhone(true);
                  
            }
                
            })
    .catch(err =>{

    
  })

}
  

 }

const handleFacilities=e=>{



    
      //const val=e.target.value;
      const key=e.target.name;
    
         setFacilities(currentstate=>({
            ...currentstate,
            [key]: !(facilities[key])
          }));
      


} 

const handlePractiseType=e=>{

      const val=e.target.value;
    setHospitalDatas(currentstate=>({
        ...currentstate,
          practise_type: val
         }));
}
const handleHealthCareType=e=>{

      const val=e.target.value;
    setHospitalDatas(currentstate=>({
        ...currentstate,
          healthcare_type: val
         }));
}
        

        
              return (
                <div className="form-body">
                   
                    <div className="row">
                        <div className="img-holder">
                            <div className="bg bg-2"> 
            <h4 className="grey-title tagline">The Intelligent Platform for Patient Engagement</h4></div>
                            <div className="info-holder holder-sticky">
                               <div className="captions-holder">
                                     
                                           <h2 className="captions"> Build Trust and Retain Customers. </h2> <br/> 
                                       
                                        <h2 className="captions">Register as Hospital at <span className="felixacare-logo-color">FelixaCare platform</span>.
                                     </h2> 
                                    
                                  </div>
                            </div>
                        </div>
                        <div className="form-holder form-container">
                            <div className="form-content">
                                <div className="form-items">
                                    
                                    <p></p>
                                    <div className="page-links">
                                        <Link to="/login" >Login</Link><Link to="register" className="active">Register</Link>
                                       
                                    </div>

                                    

                                         <form className="reg-form hosp-form" onSubmit={handleContinue}>

                                         
                                         <h3 className="form-heading"> Hospital Registration </h3>
                                           <div className="reg-form-div">
                                           <div className="first-row">   

                                    
<div className="custom-file">
   {previewOn=== false ?  
               <div className="pic-container">
                <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={uploadImage}/>
                {invalidPic && <p className="error">{invalidPic}</p>}
                <label className="" htmlFor="validatedCustomFile"><p>Upload Profile Picture</p><div className="camera-icon">
                <i className="fas fa-2x fa-camera cam-icon"></i>
                </div>
                </label>
                {/*
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Photo</label> */}
                </div>
 : 
 <div>
   <div onClick={removePicPreview}><i style={{left:'65px'}} className="fas fa-times-circle close-img-btn"></i></div>
   <img src={hospitalDatas.img_url} alt='Hospital Picture' className="avatar"/>
   </div> }
   
 </div>
 

{/*
 <div className="custom-file">
                <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={preview_image}/>
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Photo</label>
 
   <img id="output_image" className="avatar"/>
 </div>  */}
</div>
                                         
                                       
                                        <h6 className="sub-headings">  Registration Details * </h6>  

                                        <div className="item item-left-row"> 
                                        <label className="">* Hospital Name: </label><input className="form-control" type="text" name="hosp_name" value={hospitalDatas.hosp_name} placeholder="" onChange={handleChange} maxLength='100'
                                        />
                                        {error.hosp_name ? <div className="error-validation-msg">{error.hosp_name}</div> : null}
                                        </div>
                                        

                                        <div className="item item-left-row"> 
                                        <label className="">* Email Address: </label><input className="form-control" type="email" name="email" value={hospitalDatas.email} placeholder="" onChange={handleChange} onBlur={handleCheckExisting} maxLength='50'/>
                                       {error.email ? <div className="error-validation-msg">{error.email}</div> : null}
                                       </div>
                                        <div className="form-control phone-input item item-left-row">
                                           <label className="">* Phone: </label>
                                        <PhoneInput
                                            inputProps={{
                                                 
                                                }}
                                            country={'in'}
                                            value={hospitalDatas.phone.mobile_number}
                                            onChange={handlePhoneInput}
                                            onBlur={handleCheckExistingPhone}

                                          />

                                          
                                          {error.phone ? <div className="error-validation-msg">{error.phone}</div> : null}
                                          </div>
                                       
                                    
                                          
                                          <div className="form-control phone-input item item-left-row">
                                          <label className=""> Emergency Number: </label>
                                        <PhoneInput
                                            inputProps={{
                                                  
                                                 
                                                  
                                                }}
                                            country={'in'}
                                            value={hospitalDatas.emergency.mobile_number}
                                            onChange={handleEmergencyInput}

                                          />
                                          {error.emergency ? <div className="error-validation-msg">{error.emergency}</div> : null}
                                          </div>
                                     





                                     <div className="divider"></div>
                                      <h6 className="sub-headings">  Address </h6>  
                                       <div className="country-selector item-left-row">
                                         <label className="">* Country: </label>
                                        
                                            <CountryDropdown
                                              value={hospitalDatas.country}
                                              name="country"
                                              onChange={(val) => selectCountry(val)} 
                                               />
                                          {error.country ? <div className="error-validation-msg">{error.country}</div> : null}

                                        </div>

                                     <div className="region-selector item-left-row">
                                         <label className="">* Region:  </label>
                                            <RegionDropdown
                                              country={hospitalDatas.country}
                                              value={hospitalDatas.state}
                                              name="state"
                                              onChange={(val) => selectRegion(val)} 
                                              />
                                              <small id="emailHelp" className="form-text text-muted">*Select State- (Enabled once you select Country)</small>
                                            {error.state ? <div className="error-validation-msg">{error.state}</div> : null}
                                          </div>
                                           

                                         <div className="item item-left-row"><label className="">* Location/City: </label> <input className="form-control" type="text" name="location" placeholder="" onChange={handleChange} maxLength='150' /> 
                                     {error.location ? <div className="error-validation-msg">{error.location}</div> : null}
                                     </div>


                                        <div className="item item-left-row"><label className="">* Street Address: </label> <input className="form-control" type="text" name="street" placeholder="" onChange={handleChange} maxLength='150' /> 
                                     {error.street ? <div className="error-validation-msg">{error.street}</div> : null}
                                     </div>

                                    
                                     

                                     <div className="divider"></div>

                                     <h6 className="sub-headings">  Hospital Details </h6>   
                                     
                                     
                                        
                                          <div className="item"> <input className="form-control" type="text" value={hospitalDatas.accreditation} name="accreditation" placeholder="Accreditation" onChange={handleChange} maxLength='30' /> 
                                          {error.accreditation ? <div className="error-validation-msg">{error.accreditation}</div> : null}
                                           </div>  

                                          <div className="item"> <input className="form-control" type="text" value={hospitalDatas.regn_number} name="regn_number" placeholder="Registration Number" onChange={handleChange} maxLength='30'/> 
                                          {error.regn_number ? <div className="error-validation-msg">{error.regn_number}</div> : null}

                                          </div>

                                              
                                           
                                          <div className="form-control phone-input">
                                        <PhoneInput
                                            inputProps={{
                                                  
                                                 
                                                  
                                                }}
                                            country={'in'}
                                            value={hospitalDatas.contact_phone.mobile_number}
                                            onChange={handleContactPhoneInput}

                                          />
                                          {error.contact_phone ? <div className="error-validation-msg">{error.contact_phone}</div> : null}
                                          </div>

                                          <div className="item"> <input className="form-control" type="email" value={hospitalDatas.primary_email} name="primary_email" placeholder="Primary Email" onChange={handleChange} maxLength='50' /> 
                                          {error.primary_email ? <div className="error-validation-msg">{error.primary_email}</div> : null}
                                           </div> 

                                          <div className="item"> <input className="form-control" type="text" value={hospitalDatas.website_url} name="website_url" placeholder="Website URL" onChange={handleChange} maxLength='150' /> 
                                          {error.website_url ? <div className="error-validation-msg">{error.website_url}</div> : null}
                                           </div> 

                                          <div className="item"> 
                                         
    <input className="form-control" type="text" value={hospitalDatas.established_year} name="established_year" placeholder="Established Year" onChange={handleChange} maxLength='30' /> 
                                          {error.established_year ? <div className="error-validation-msg">{error.established_year}</div> : null}
                                           </div> 

                                           <div className="item"> <input className="form-control" type="text" value={hospitalDatas.gst} name="gst" placeholder="GST" onChange={handleChange} maxLength='30' /> 
                                           </div>

                                           <div className="item"> <textarea rows="3" className="text-area-about" placeholder="Description" name="description" onChange={handleChange} maxLength='150'
                                        />  
                                           </div>

                                         
                                      



<div className="divider"></div>
          <h6 className="sub-headings"> Health Institute Type </h6>
                                         <div className="row">
                                        <div className="radio-options"> 
                                          <input 
                                             type="radio" 
                                             id="hospital" 
                                             name="healthcare_type" 
                                             value="hospital"
                                             checked={hospitalDatas.healthcare_type=== "hospital"}
                                             onChange={handleHealthCareType}
                                            
                                                 />
                                          <label htmlFor="hospital">Hospital</label>
                                          </div>

                                          <div className="radio-options"> 
                                          <input 
                                             type="radio" 
                                             id="Clinic" 
                                             name="healthcare_type" 
                                             value="Clinic"
                                             checked={hospitalDatas.healthcare_type=== "Clinic"}
                                             onChange={handleHealthCareType}
                                            
                                                 />
                                          <label htmlFor="Clinic">Clinic</label>
                                          </div>

                                          <div className="radio-options"> 
                                          <input 
                                             type="radio" 
                                             id="Medical College" 
                                             name="healthcare_type" 
                                             value="Medical College"
                                             checked={hospitalDatas.healthcare_type== "Medical College"}
                                             onChange={handleHealthCareType}
                                            
                                                 />
                                          <label htmlFor="Medical College">Medical College</label>
                                          </div>


                                         {/* <div className="radio-options"> 
                                          <input 
                                             type="radio" 
                                             id="Health Centre" 
                                             name="healthcare_type" 
                                             value="Health Centre"
                                             checked={hospitalDatas.healthcare_type== "Health Centre"}
                                             onChange={handleHealthCareType}
                                            
                                                 />
                                          <label htmlFor="Health Centre">Health Centre</label>
                                          </div>

                                        */}

                                        {error.healthcare_type ? <div className="error-validation-msg">{error.healthcare_type}</div>  : null}




                                          

                                        </div>

                                                                       




  <div className="divider"></div>
          <h6 className="sub-headings"> Medical Practise Type </h6>
                                         <div className="row">
                                        <div className="radio-options"> 
                                          <input 
                                             type="radio" 
                                             id="allopathy" 
                                             name="practise_type" 
                                             value="allopathy"
                                             checked={hospitalDatas.practise_type=== "allopathy"}
                                             onChange={handlePractiseType}
                                            
                                                 />
                                          <label htmlFor="allopathy">Allopathy/Modern Medicine</label>
                                          </div>

                                 <div className="radio-options"> 
                                          <input 
                                             type="radio" 
                                             id="ayurveda" 
                                             name="practise_type" 
                                             value="ayurveda"
                                             checked={hospitalDatas.practise_type=== "ayurveda"}
                                             onChange={handlePractiseType}
                                            
                                                 />
                                          <label htmlFor="ayurveda">Ayurveda</label>
                                          </div>

                                      <div className="radio-options"> 
                                          <input 
                                             type="radio" 
                                             id="homeopathy" 
                                             name="practise_type" 
                                             value="homeopathy"
                                             checked={hospitalDatas.practise_type=== "homeopathy"}
                                             onChange={handlePractiseType}
                                            
                                                 />
                                          <label htmlFor="homeopathy">Homeopathy</label>
                                          </div>


                                          {error.practise_type ? <div className="error-validation-msg">{error.practise_type}</div> : null}

                                        </div>

                                                                       



          <div className="divider"></div>
          <h6 className="sub-headings"> *Specialties </h6>   
                        <div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="general_surgery"  name="general_surgery" checked={specialties.general_surgery}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="general_surgery">
   General Medicine & Surgery
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="metabolism_diabetes"  name="metabolism_diabetes" checked={specialties.metabolism_diabetes}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="metabolism_diabetes">
  Metabolism & Diabetes
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="emergency"  name="emergency" checked={specialties.emergency}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="emergency">
 Emergency Medicine
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox" id="physiology"  name="physiology" checked={specialties.physiology}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="physiology">
 Physiology
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox" id="family_medicine"  name="family_medicine" checked={specialties.family_medicine}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="family_medicine">
 Family Medicine
  </label>
</div>


<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="pediatrics"  name="pediatrics" checked={specialties.pediatrics}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="pediatrics">
  Pediatrics
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="oncology"  name="oncology" checked={specialties.oncology}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="oncology">
 Oncology
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="cardiology"  name="cardiology" checked={specialties.cardiology}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="cardiology">
Cardiology
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="dietetics"  name="dietetics" checked={specialties.dietetics}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="dietetics">
  Dietetics
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="gastroenterology_nutrition"  name="gastroenterology_nutrition" checked={specialties.gastroenterology_nutrition}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="gastroenterology_nutrition">
  Gastroenterology & Nutrition
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="obstetrics_gynaec"  name="obstetrics_gynaec" checked={specialties.obstetrics_gynaec}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="obstetrics_gynaec">
  Obstetrics & Gynaecology
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="ivf"  name="ivf" checked={specialties.ivf}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="ivf">
In Vitro Fertilization (IVF)
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="ent"  name="ent" checked={specialties.ent}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="ent">
ENT 
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="ophthalmology"  name="ophthalmology" checked={specialties.ophthalmology}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="ophthalmology">
Ophthalmology
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="neurology"  name="neurology" checked={specialties.neurology}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="neurology">
Neurology
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox" id="nephrology"  name="nephrology" checked={specialties.nephrology}
                                      onChange={handleSpecialties}/>
  <label className="form-check-label" htmlFor="nephrology">
 Nephrology
  </label>
</div>
{error.specialtiesArray ?<div className="error-validation-msg">{error.specialtiesArray}</div> : null}







 

   <div className="divider"></div>
          <h6 className="sub-headings"> Facilities </h6>   
                        <div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="icu"  name="icu" checked={facilities.icu}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="icu">
   ICU
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="nicu"  name="nicu" checked={facilities.nicu}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="nicu">
   NICU
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="labour_room"  name="labour_room" checked={facilities.labour_room}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="labour_room">
   Labour Room
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="physiotherapy"  name="physiotherapy" checked={facilities.physiotherapy}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="physiotherapy">
   Physiotherapy
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="laboratory"  name="laboratory" checked={facilities.laboratory}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="laboratory">
   Laboratory
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="pharmacy"  name="pharmacy" checked={facilities.pharmacy}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="pharmacy">
   Pharmacy
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="ambulance"  name="ambulance" checked={facilities.ambulance}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="ambulance">
  Ambulance
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="operation_theatre"  name="operation_theatre" checked={facilities.operation_theatre}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="operation_theatre">
   Operation Theatre
  </label>
</div>

<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="blood_bank"  name="blood_bank" checked={facilities.blood_bank}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="blood_bank">
   Blood Bank
  </label>
</div>
<div className="form-check item text-left">
  <input className="form-check-input" type="checkbox"  id="emergency_trauma"  name="emergency_trauma" checked={facilities.emergency_trauma}
                                      onChange={handleFacilities}/>
  <label className="form-check-label" htmlFor="emergency_trauma">
  Emergency & Trauma
  </label>
</div>

{error.facilitiesArray ? <div className="error-validation-msg">{error.facilitiesArray}</div> : null}

                                        <div className="divider"></div>
                                        <div className="item item-left-row"><label className="">* Password: </label> <input className="form-control pswd" type="password" name="password" placeholder="" onChange={handleChange} maxLength='30' /> 
                                          {error.password ? <div className="error-validation-msg">{error.password}</div> : null}
                                          </div>
                                         <div className="item item-left-row"><label className="">* Confirm Password: </label> <input className="form-control pswd" type="password" name="confirmpassword" placeholder="" onChange={handleChange} maxLength='30' /> 
                                          {error.confirmpassword ? <div className="error-validation-msg">{error.confirmpassword}</div> : null}
                                          </div> 
                                         
                                               
                                      
                                        </div>
                                        <div className="form-button">
                                            <button id="submit" type="submit" className="btn btn-primary btn-col" >Register</button>
                                        </div>

                      
                            
      
                                      </form>

                              
                                </div>
                            </div>
                        </div>
                    </div>



               {errorSubmitMsg!==null ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  setErrorSubmit(false)}
       
      />: null}

       {successSubmitMsg === "success" ?
    <SubmitSuccessPopup
        show={successSubmit}
        onHide={() =>  setSuccessSubmit(false)}
       
      />: null}

{
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 

{
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 

{
        validShow ? 
        <ValidPopUp
        show={validShow}
        onHide={()=>{
          setValidShow(false)
        }} /> : null
      } 

{
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      }
                </div>
              )
       
    
}



export default HospRegister;