import React, { useState, useEffect, useContext } from 'react';
import ReactTable from 'react-table-v6';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL } from "../../../Global";
import { MedContext } from "../../../contexts/MedContext";
import DatePopUp from '../../HospDr_AddPrescription/DatePopUp';
// import ImageScale from '../ImageScale/ImageScale';
import ViewRemark from '../../HospDr_AddPrescription/ViewRemark';

function CurrentMed(props) {
    const [img, setImg] = useState([]);
    const [index, setIndex] = useState();
    const [medRender, setMedRender] = useContext(MedContext);
    const [dateShow, setDateShow] = useState(false)
    //const [prescData ,setPrescData]= useState([]);
    const [medList, setMedList] = useState([{


        drug: "",

        from: "",
        to: "",

        dosage: "",

        intake_count: [],
        intake_time: '',
        remarks: ""
    }]);

    const [render,] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [submitMsg, setSubmitMsg] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [imageShow, setImageShow] = useState(false)
    //const [clickShow ,setClickShow]= useState(false)
    const [remarkData, setRemarkData] = useState('')
    const [viewShow, setViewShow] = useState(false)
    const [errorSubmitModalShow, setErrorSubmitModalShow] = useState(false);

    const id = props.id;

    const [keys,] = useState({ 'z': false });


    useEffect(() => {
        const handleKeyDown = (e) => {
            keys[e.key] = true

            if (e.altKey && keys['z']) {
                setErrorSubmitModalShow(false)
                setErrorMsg('')
                setModalShow(false)
                setMedRender(!medRender)
                setSubmitMsg('')
            }

        }
        window.addEventListener('keydown', handleKeyDown)


        const handleKeyUp = (ev) => {

            keys[ev.key] = false
        }

        window.addEventListener('keyup', handleKeyUp)



        return () => {
            window.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('keyup', handleKeyUp)

        }
    }, [submitMsg, errorMsg]);



    useEffect(() => {

        setMedList(medList)

    }, [render, medRender, medList]);


    useEffect(() => {

        const tokenString = localStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '');




        axios.get(`${APIURL}/api/v1/hospital/add-hospital-userdetails/?patient_id=${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Token ' + str
                }
            })
            .then(res => {




                if (res.data.status === "success") {


                    // setPrescData(res.data.medicines)
                    //    let data = []

                    //     if(res.data.doctor.length ===0 && res.data.patient.length ===0 && res.data.prescription.length ===0){
                    //       data=[]
                    //     }

                    //     if(res.data.doctor.length !==0){
                    //         res.data.doctor.map((item)=> {
                    //           data = data.concat(item.medicines)
                    //         })
                    //       }

                    //       if(res.data.patient.length !==0){
                    //         res.data.patient.map((item)=> {
                    //           data = data.concat(item.medicine)
                    //         })
                    //       }

                    //     if(res.data.prescription.length !==0){
                    //       res.data.prescription.map((item)=> {
                    //         data = data.concat(item.medicines)
                    //       })
                    //     }
                    setMedList(res.data.medicines)


                } else {

                    // setErrorMsg(res.data.message? res.data.message : "Error in fetching data");
                    // setErrorSubmitModalShow(true);
                }


            })
            .catch(err => {

                setErrorMsg("Error in fetching data");
                setErrorSubmitModalShow(true);
            })



    }, [medRender, id])


    /*const AddRow=()=>{
      const list = medList;
      list.push({drug:"", strength: "",form:"",duration: "",route:"", dosage:"",frequency:"",intake_count:[]});
      
      setMedList(list);
      setRender(!render);
      
    }*/




    /*const handleNewRow=(e)=>{
      e.preventDefault();
         const newMed = medList;
        newMed.push({drug:""});
        setMedList(newMed);
    
    }*/

    let newDate = ''
    function DateHandler(childData) {
        newDate = childData

    }

    const callDelete = () => {

        if (newDate !== '') {

            const tokenString = localStorage.getItem('usertoken');

            let str = tokenString.replace(/["]+/g, '');


            const dataToUpdate = {
                med_id: index,
                stop_medicine: true,
                stop_medicine_date: newDate
            }

            axios.put(`${APIURL}/api/v1/doctor/patient-current-medication/?patient_id=${id}`, dataToUpdate,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": 'Token ' + str
                    }
                })
                .then(res => {




                    if (res.data.status === "success") {
                        setSubmitMsg(res.data.message ? res.data.message : "Successfully stopped medicine");
                        setModalShow(true);





                    } else {
                        setErrorMsg(res.data.message ? res.data.message : "Error in removing medicine");
                        setErrorSubmitModalShow(true);
                    }


                })
                .catch(err => {
                    setErrorMsg("Error in removing medicine");
                    setErrorSubmitModalShow(true);


                })
        }

    }

    /*const deleteRow=(index)=>{
      const newarr=medList;
      const newList = newarr.filter((elt,i)=>{
        return i=== index
      })
      callDelete(newList[0].id);
    
     
    }*/

    /*const handleShow=(index)=> {
      const newarr=medList;
      const newList = newarr.filter((elt,i)=>{
        return i=== index
      })
    
      let data=[]
      for(let i=0; i<prescData.length; i++) {
        if(prescData[i].prescription_id===newList[0].prescription_id){
          prescData[i].image.map(item=>{
                data= data.concat(item)
            })
          setImg(data)
          
          break;
        }
        else {
          setImg([])
        }
      }
    
      
      setImageShow(true)
      
      
    }*/

    const ViewPopup = (props) => {


        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                dialogClassName="remark-width"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <ViewRemark data={remarkData} />

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={props.onHide}>Close</Button>


                </Modal.Footer>
            </Modal>
        );
    }

    const handleView = (data, index) => {
        setRemarkData(data)
        setViewShow(true)
    }

    const renderRemarks = (cellInfo) => {
        if (medList[cellInfo.index][cellInfo.column.id] === "" || medList[cellInfo.index][cellInfo.column.id] === null) {
            return null
        }
        else {

            return (
                <button onClick={() => handleView(medList[cellInfo.index][cellInfo.column.id], cellInfo.index)} className='btn btn-primary btn-col btn-square btn-small'>View</button>
            )
        }
    }


    const renderEditable = (cellInfo) => {

        return (
            <div style={{ backgroundColor: "#fafafa" }}
                className="form-control"
            > {medList[cellInfo.index][cellInfo.column.id]}</div>)
    }

    /*const renderDr=(cellInfo)=>{
    const creator =medList[cellInfo.index]
    const type =creator.creator_type
      return(
    
        type==='doctor'?
    
        <div style={{ backgroundColor: "#fafafa" }}
            className="form-control"
          ><h6> {medList[cellInfo.index].doctor}</h6>
           <h6> {medList[cellInfo.index].hospital} </h6> </div> :
    
           type==='self'?
    
           <div style={{ backgroundColor: "#fafafa" }}
            className="form-control"
          ><h6>Added by Patient</h6>
           </div> : 
    
           type=== undefined ?
           <div style={{ backgroundColor: "#fafafa" }}
            className="form-control"
          ><h6>Added as a Prescription</h6>
          <button className="presc-btn" 
           onClick={()=>{handleShow(cellInfo.index);}}>Click to View</button>
           </div> : null
    
            )
    }*/
    const renderIntakeTime = (cellInfo) => {
        const food_time = medList[cellInfo.index][cellInfo.column.id];
        let food_label = "";
        if (food_time === "0") {
            food_label = "N/A";
        }
        else if (food_time === "1") {
            food_label = "Before food";
        }
        else if (food_time === "2") {
            food_label = "After food";
        }
        return (

            <div style={{ backgroundColor: "#fafafa" }}
                className="form-control"
            > {food_label}</div>


        )
    }



    const dateSelect = (index) => {
        const newarr = medList;
        const newList = newarr.filter((elt, i) => {
            return i === index
        })
        setIndex(newList[0].id)
        setDateShow(true)
    }


    const columns = [

        {
            Header: <span className="table-header-tooltip" data-toggle="tooltip"
                data-placement="top" title="Stop Medicine">Stop Med.</span>,
            Cell: props => {
                return (
                    <button className="stop-btn"
                        onClick={() => { dateSelect(props.index) }}> Stop </button>
                )
            },
            sortable: false,
            filterable: false,
            minWidth: 120

        },
        {
            Header: "DRUG",
            accessor: "drug",
            Cell: renderEditable,
            style: {
                backgroundColor: "rgba(0,0,0,0.1)"
            },

            minWidth: 100,
            sortable: false,
            filterable: false,
            width: 200

        },

        {
            Header: "Strength",
            accessor: "strength",
            minWidth: 80,
            width: 110,
            Cell: renderEditable,
            sortable: false,
            filterable: false

        },
        {
            Header: <span className="table-header-tooltip" data-toggle="tooltip"
                data-placement="top" title="INTAKE COUNT">COURSE</span>,
            accessor: "intake_count",
            Cell: renderEditable,
            sortable: false,
            width: 110,
            minWidth: 100,
            filterable: false

        },
        /*{
          Header: "Prescribed by",
          accessor: "doctor",
          Cell: renderDr,
          style:{
            backgroundColor:"rgba(0,0,0,0.1)"
          },
          
          minWidth:100,
           sortable:false,
           filterable:false,
           width:200
        
        },*/
        {
            Header: "REMARKS",
            accessor: "remarks",
            Cell: renderRemarks,
            sortable: false,
            width: 130,
            minWidth: 100,
            filterable: false

        },
        {
            Header: "DOSAGE",
            accessor: "dosage",
            minWidth: 80,
            width: 100,
            Cell: renderEditable,
            sortable: false,
            filterable: false

        },
        {
            Header: "FROM",
            accessor: "date",
            minWidth: 80,
            width: 120,
            Cell: renderEditable,
            sortable: false,
            filterable: false


        },
        {
            Header: "TO",
            accessor: "till_date",
            minWidth: 80,
            width: 120,
            Cell: renderEditable,
            sortable: false,
            filterable: false

        },
        {
            Header: "Frequency",
            accessor: "frequency",
            minWidth: 80,
            width: 100,
            Cell: renderEditable,
            sortable: false,
            filterable: false

        },
        {
            Header: "Form",
            accessor: "form",
            minWidth: 80,
            width: 110,
            Cell: renderEditable,
            sortable: false,
            filterable: false

        },
        {
            Header: "Route",
            accessor: "route",
            minWidth: 80,
            width: 130,
            Cell: renderEditable,
            sortable: false,
            filterable: false

        },

        {
            Header: <span className="table-header-tooltip" data-toggle="tooltip"
                data-placement="top" title="INTAKE TIME">TIME</span>,
            accessor: "intake_time",
            Cell: renderIntakeTime,
            sortable: false,
            width: 130,
            minWidth: 100,
            filterable: false

        },



    ]


    const ErrorSubmitPopup = (props) => {


        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 style={{ color: "red" }}> {errorMsg} </h5>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={props.onHide}>Ok</Button>




                </Modal.Footer>
            </Modal>
        );
    }




    const SubmitPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{submitMsg}!</h4>

                </Modal.Body>
                <Modal.Footer>


                    <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>


                </Modal.Footer>
            </Modal>
        );
    }

    const ImagePopUp = (props) => {
        return (
            <Modal
                {...props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <ImageScale img={img} presc={true} /> */}

                </Modal.Body>
                <Modal.Footer>


                    <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>


                </Modal.Footer>
            </Modal>
        );
    }

    const DateChange = (props) => {
        return (
            <Modal
                {...props}
                dialogClassName="modal-90h"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                     <DatePopUp date={DateHandler} />   

                </Modal.Body>
                <Modal.Footer>


                    <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
                    <Button variant='danger' onClick={props.confirm}>Confirm</Button>


                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className="overlay-body">


            <div className="add-presc-page"><br />


                {/*<h4> Patient : ID {id} </h4><br/> */}

                <div className="current-table-holder">
                    <ReactTable
                        columns={columns}
                        data={medList}
                        defaultPageSize={5}
                        filterable
                        resizable
                        noDataText={"No data"}
                        className="current-med-table"
                    >

                    </ReactTable>
                    <br />
                </div>




            </div>








            {errorMsg !== "" ?
                <ErrorSubmitPopup
                    show={errorSubmitModalShow}
                    onHide={() => {
                        setErrorSubmitModalShow(false);
                        setErrorMsg('')
                    }}

                /> : null}



            {submitMsg !== "" ?

                <SubmitPopUp
                    show={modalShow}
                    onHide={() => {
                        setModalShow(false);
                        setMedRender(!medRender);
                        setSubmitMsg('')
                    }}
                />
                : ""}

            {/* {imageShow ? 

<ImagePopUp
show={imageShow}
image={img}
onHide={() =>  {setImageShow(false)}}
/> 
: null} */}

            {dateShow ?

                <DateChange
                    show={dateShow}
                    onHide={() => { setDateShow(false) }}
                    confirm={() => {
                        callDelete(); if (newDate !== '') {
                            setDateShow(false)
                        }
                    }}
                />
                : null}

            {
                viewShow ?
                    <ViewPopup
                        show={viewShow}
                        onHide={() => {
                            setViewShow(false)
                        }}
                    /> : null
            }

        </div>
    )
}

export default CurrentMed;