import React, {useState, useEffect} from 'react'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../Global";
import ViewRemark from '../HospDr_AddPrescription/ViewRemark';

function PastPayments(props) {

    const [paymentData,setPaymentData]= useState([])
    const [modalShow, setModalShow]= useState(false);
    const [errorMsg,setErrorMsg]=useState("");
    const [submitMsg,setSubmitMsg] = useState("");
     const [successSubmit,setSuccessSubmit]=useState(false);
     const [errorSubmit,setErrorSubmit]=useState(false);



     useEffect(() =>{
  
          const tokenString = localStorage.getItem("usertoken");
    
          let v2 = tokenString.replace(
            /["]+/g,
            ""
          ); /* REGEX To remove double quotes from tokenstring */
    
    
            axios
            .get(`${APIURL}/api/v1/hospital/hospital-all-payment-history/?patient_id=${props.id}`, {
              headers: { Authorization: "Token "+ v2 },
            })
            .then((res) => {
              if (res.data.status === "success") {  
                setPaymentData(res.data.all_payments)
            }
            else{
    
            }
        }).catch((error)=>{
    
        })
  
      },[])

    const renderEditable = (cellInfo) => {
        if(paymentData[cellInfo.index][cellInfo.column.id]){
            return (
                <div className="fixed-co"> {paymentData[cellInfo.index][cellInfo.column.id]}</div>
            )
        }
        else {
            return <span className='text-danger'>NIL</span>
        }
    }

    const renderDate=(cellInfo)=> {

        let dateString = paymentData[cellInfo.index][cellInfo.column.id];
    
    let dateObject = new Date(dateString);
    
    const updatedDate = dateObject.toString();
        return (<div >
          <h6> {updatedDate.slice(4,16)}  </h6>
           <h6> {updatedDate.slice(16,24)}</h6>
         </div>)
    
       }


    const SuccessPopup=(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <h5 style={{color:"green"}}>{props.msg} </h5>
              
              
           
             
            </Modal.Body>
            <Modal.Footer>
               
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
               
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }
       
      
       const SubmitErrorPopup =(props)=>{
          
      
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }    

    const columnsProcedure = [
        
        {
            Header: "Invoice No",
            accessor: "invoice",
            Cell: renderEditable,
          //   style:{
          //     backgroundColor:"rgba(0,0,0,0.1)"
          //   },
            width:110,
            minWidth:100,
             sortable:false
          
          },
        {
          Header: "Paid For",
          accessor: "paid_for",
          Cell: renderEditable,
        //   style:{
        //     backgroundColor:"rgba(0,0,0,0.1)"
        //   },
          width:200,
          minWidth:100,
           sortable:false
        
        },
        {
            Header: "Date",
            accessor: "paid_date",
            Cell: renderDate,

            width:180,
            minWidth:100,
             sortable:false
          
          },
        {
          Header: "Method",
          accessor: "payment_method",
          Cell: renderEditable,

          width:150,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Status",
          accessor: "payment_status",
          Cell: renderEditable,

          width:150,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Paid Amount",
          accessor: "total_amount_paid",
          Cell: renderEditable,

          width:150,
          minWidth:100,
           sortable:false
        
        },
        {
            Header: "Added By",
            accessor: "added_by",
            Cell: renderEditable,

            width:200,
            minWidth:100,
            sortable:false
          
          },
        
    ]


    const displayTable=<ReactTable 
    columns={columnsProcedure}
    data={paymentData}
    defaultPageSize = {5}
    className="procedure-table container pr-0 pl-0"
    resizable
 
           
    >

    </ReactTable>

    return (
        <div id='procedure-table-list'>
            <h3 className='title-of-tasks'>Past Payments of <span className='past-payment-name'>{props.name}</span></h3>
            {displayTable}
        <br/>

        {submitMsg!=='' ?
    <SuccessPopup
        show={successSubmit}
        msg={submitMsg}
        onHide={() => { setSuccessSubmit(false);
                        setSubmitMsg('');}}
       
      />: ''}

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default PastPayments
