import React, {useState, useEffect} from 'react'
import HospBdoNavbar from '../HospBdoNavbar/HospBdoNavbar'
import {useHistory} from 'react-router-dom'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import './emergencycalllog.css'
import axios from "axios";
import {APIURL} from "../../Global";

function EmergencyCallLog() {

  const [logData, setLogData]= useState([])
  const [load, setLoad]= useState('')

    const history =useHistory();

    const handleBack=(e)=>{
        history.goBack();
      }

      const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'s': false,'q': false});


      useEffect(()=>{
      
        window.onkeydown =(e) => {
          
          keys[e.key]=true
      
          if(e.altKey && keys['u']){
            history.push('/directory')
          }
          if(e.altKey && keys['c']){
            history.push('/careplans-db')
          }
          if(e.altKey && keys['n']){
            history.push('/notifications')
          }
          if(e.altKey && keys['a']){
            history.push('/analytics')
          }
          if(e.altKey && keys['s']){
            history.push('/service-requests')
          }
          if(e.altKey && keys['q']){
            history.push('/qrcode')
          }     
      
        }
        window.onkeyup=(ev)=> {
          
          keys[ev.key]= false
        }
      
        return()=>{
          window.onkeydown=null
          window.onkeyup=null
        }
      },[]);

      useEffect(()=> {
        setLoad('Loading Data...')
        const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;

   axios.get(`${APIURL}/api/v1/patient/emergency_call_log/`, { headers: {
      
    "Authorization" : 'Token '+str  }})
  .then(res=>{
   

 
    if(res.data.status==="success"){

        setLoad('')
        let log = res.data.call_log
        const updatedLog = log.sort((a,b)=> {
          return new Date(b.date_time) - new Date(a.date_time)
        })
        setLogData(updatedLog)
    }

      else{
        setLoad('Error in Fetching Data')
    }
    


  })
  .catch(err=>{
    setLoad('Error in Fetching Data')
    })
},[])

      const renderTable= (cellInfo)=> {
          return(
              <div>{logData[cellInfo.index][cellInfo.column.id]}</div>
          )
      }
      const renderDate =(cellInfo)=> {
        let dateString = logData[cellInfo.index][cellInfo.column.id]
        
        let date1 = dateString.split('T').join(' ')
        const date2 = date1.split('Z').join('')

        const theDate = new Date(Date.parse(`${date2} UTC`));

        let finalDate = theDate.toString();

//const updatedDate = dateObject.toString();

        return(
          <div >
            <h6> {finalDate.slice(4,16)} </h6>
            <h6>{finalDate.slice(16,24)}</h6>
           </div>
        )
      }

      const columns = [
        {
          Header: "Date",
          accessor: "date_time",
          Cell: renderDate,
           style:{
            backgroundColor:"rgba(0,0,0,0.1)"
          },
           sortable:true,
            filterable:true,
           width:170
        
        },
        
        {
          Header: "Patient Name",
          accessor: "patient_name",
          Cell: renderTable,
           sortable:true,
            filterable:true,
           width:200
        
        },
        {
          Header: "Mobile Number",
          accessor: "patient_mob",
          Cell: renderTable,
          style:{
            backgroundColor:"rgba(0,0,0,0.1)"
          },
           sortable:true,
            filterable:true,
           width:200
        
        }]
const display = <ReactTable
              columns={columns}
              data={logData}
              defaultPageSize = {10}
              className='calllog-table'
              resizable
              ></ReactTable>
    return (
        <>
        <HospBdoNavbar /><br/>
        <div className="flex-head">
        
          <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className="title-of-tasks title-mg"> Emergency Call Log</h2>
    </div> 
    <br/>
    <div className="log-div">
     
      <div className="text-center">
        <div className="service-table-holder flex-col"> {load===''?display: load==='Error in Fetching Data'? <h6 style={{color: "red"}}>{load}</h6> : <h6 style={{color: "grey"}}>{load}</h6>}
        </div>
    
     
    </div>
    </div>
        </>
    )
}

export default EmergencyCallLog
