import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function EditSymptoms(props) {

    const {data, id} =props
    const [obsData, setObsData]= useState(data.symptoms)
    const [modalShow, setModalShow]=useState(false)
    const [errorShow, setErrorShow]= useState(false)
    
    const handleSave = ()=> {
        const tokenString= localStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               "symptoms": obsData,
               "symptoms_id": data.id
           }
     
     
       
         axios.put(`${APIURL}/api/v1/hospital/add-hospital-userdetails/?patient_id=${id}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
                setModalShow(true)
         
          
       }
       else{
        setErrorShow(true)
        
       }
     
         })
         .catch(err=>{
       
            setErrorShow(true)
     })

    }


    const SubmitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-success'>Symptom edited  Successfully !!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const ErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> Error in data submission.Please check again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

    return (
        <div className='flex-col'>        

                <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {obsData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setObsData(e.target.value)
          } }
            autoComplete="off"
                />
            <button disabled={obsData=== data.observation} onClick={handleSave} className='btn btn-primary btn-col'>Save</button>





            {
      modalShow ? 
      <SubmitPopup
        show={modalShow}
        onHide= {() => { setModalShow(false); props.handleObs();    
        }}
      /> : null
    }

{
      errorShow ? 
      <ErrorPopup
        show={errorShow}
        onHide= {() => { setErrorShow(false)     
        }}
      /> : null
    }

        </div>



    )
}

export default EditSymptoms
