import React,{useState, useEffect} from "react";
import "./createappuser.css";
import axios from 'axios';

import { Link, useHistory } from "react-router-dom";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import HospitalNavbar  from "../HospitalNavbar/HospitalNavbar";
import {APIURL} from "../../Global";
function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>User credentials created successfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        


        <Link to="/doctors-list"><Button className='btn btn-primary btn-col' > Goto Doctors List</Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

function CreateAppUser(props) {
     const [hospData,] =useState(JSON.parse(sessionStorage.getItem('hosp_data'))? JSON.parse(sessionStorage.getItem('hosp_data')) : null);
    const [modalShow, setModalShow] = useState(false);
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState(null);
   const [submitMsg, setSubmitMsg]= useState("");
    const [error,setError]=useState({
    username:"",
    password: '',
    confirm_password:"",
   
   });
   const id = props.match.params.id;
   const name = props.location.state;
  const hospital_username =  hospData.hospital_username;

  const history =useHistory();

const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['d']){
   history.push('/doctors-list')
 }
 if(e.altKey && keys['a']){
   history.push('/hospital/add')
 }
 if(e.altKey && keys['b']){
   history.push('/bdo-list')
 }
 if(e.altKey && keys['o']){
   history.push('/hospital/add-bdo')
 }
 if(e.altKey && keys['n']){
   history.push('/emergency-number')
 }
 if(e.altKey && keys['s']){
   history.push('/service-request-list')
 }
 if(e.altKey && keys['l']){
   history.push('/license-details')
 }
 if(e.altKey && keys['p']){
   history.push('/license-payment')
 }
 if(e.altKey && keys['c']){
   history.push('/hosp-calllog')
 }
 if(e.altKey && keys['h']){
   history.push('/payment-history')
 }

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);


   const handleUserNameChange =(e)=>{
   
   	setUsername(e.target.value);
   }

    const handlePasswordChange =(e)=>{

   	setPassword(e.target.value);
   }

    const handleConfirmPassword =(e)=>{

    setConfirmPassword(e.target.value);
   }
const validate=async ()=>{
   
      let errors = {};
      let isValid = true;
    setError({});

 if (!username || username==="" || username===null) {
        isValid = false;
        errors["username"] = "Please enter username prefix";
      }
  

 if (typeof password !== "undefined") {
          
        let pattern1 = new RegExp(/[A-Z]/g);
        if (!pattern1.test(password)) {
          isValid = false;
          errors["password"] = "Must contain atleast one Capital letter";
        }
       
      }
      if (!password || password==="" || password===null) {
        isValid = false;
        errors["password"] = "Please enter password";
      }
  
      if (!confirmPassword || confirmPassword==="" || confirmPassword=== null || typeof confirmPassword === "undefined") {
        isValid = false;
        errors["confirm_password"] = "Please enter confirm password";
      }
  
      if (password && typeof password !== "undefined") {
        if(password.length >0 && password.length < 6){
            isValid = false;
            errors["password"] = "Must contain atleast 6 characters";
        }
      }
  
      if (typeof password !== "undefined" && password!=="" && password!==null && typeof confirmPassword !== "undefined" && confirmPassword!=="" && confirmPassword!== null) {
       if (password !== confirmPassword) {
          isValid = false;
          errors["confirm_password"] = "Passwords don't match";
        }
      }
     
        


    
       await setError(currentstate=>({
            ...currentstate,
            ...errors
          }));
      

      return isValid;
  }



   const handleSubmit=async (e)=>{
    e.preventDefault();
    if(await validate()){
    callSubmit();

  }
}
   const callSubmit=()=>{
   	

   	const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

    
  const data = {
  	username: username+"@"+hospital_username,
  	password : password
  }

    axios.post(`${APIURL}/api/v1/hospital/doctors/${id}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization:  "Token "+v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setModalShow(true);
        

          
        } else {
        
           setSubmitMsg(res.data.message);
        }
      })
      .catch((err) => {
       
         setSubmitMsg("error");
      });
  }

   
 return( 
 <>
 <HospitalNavbar/>

 <div className="liner">

  </div>
    <div className="container">
    <h4 className="title-of-page"> User Credentials </h4>
       <form className="create-app-user-form col-lg-6" onSubmit={handleSubmit}>
       
         <h5 className="dr-details"> Name : {name} </h5>
          <h5 className="dr-details"> Doctor Id : {id} </h5> <br/>
       <div className="input-group mb-3">
          <input className="form-control" type="text" maxLength='30' placeholder="*Username" value={username} onChange={handleUserNameChange} />
          <div className="input-group-append">
    <span className="input-group-text postfix-username" id="basic-addon2">@{hospital_username}</span>
   
                                         
  </div>
   {error.username ?<div className="error-validation-msg"> {error.username}</div>: null} 
         </div>

         <div className="form-group ">
           <input className="form-control " type="password" maxLength='30' placeholder="*Password" value={password} onChange={handlePasswordChange} />
         {error.password ?<div className="error-validation-msg"> {error.password}</div>: null} 
           </div>
          <div className="form-group ">
           <input className="form-control " type="password" maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={handleConfirmPassword} />
         
        {error.confirm_password ?<div className="error-validation-msg"> {error.confirm_password}</div>: null} 
                                         
         </div>

  <div className="form-group ">
          <button type="submit" className="btn btn-primary btn-col" >Generate User Credentials </button>
  </div>







       </form>

        <SubmitPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

     {submitMsg!== "" ?
       <h5 style={{ color: "red" }}>
            
            {submitMsg}
          </h5>
      :
      null}

    </div>
 </>
);
}
export default CreateAppUser;