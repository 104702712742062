import React, {useState,useEffect} from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./procedurelist.css";
import { Link } from "react-router-dom";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
import Select from 'react-select';
import {Tooltip} from 'react-bootstrap';
import {OverlayTrigger} from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../Global";
import {useHistory} from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import ProcedureList from "./ProcedureList";
import GenerateInvoiceBdo from "./GenerateInvoiceBdo";
import GenerateInvoiceView from "./GenerateInvoiceView";
// import EditRemark from "./EditRemark";
// import AddRemark from "./AddRemark";

function ProcedureRequestsBdo() {

const [render,setRender]=useState(true);
const [reRender,setRerender]=useState(false);
  const [,setGetError]=useState('');
const [tableData,setTableData]=useState([]);
 const [currentIndex,setCurrentIndex]=useState('');
  const [,setCurrentColumn] = useState('');
  const [display,setDisplay]=useState(false);

  const [search,setSearch]=useState("");
  const [options,setOptions] = useState([]);
 const [,setOptionsDisplay] = useState([]);
   const [errorMsg,setErrorMsg]=useState("");

           const [submitMsg,setSubmitMsg] = useState("");
            const [successSubmit,setSuccessSubmit]=useState(false);
            const [errorSubmit,setErrorSubmit]=useState(false);
            const [editShow, setEditShow]= useState(false);
            const [modalShow, setModalShow]= useState(false);
            const [remarkData, setRemarkData]= useState('')
            const [remarkList, setRemarkList]= useState([])
            const [cell, setCell]= useState('')
            const [updateSuccess, setUpdateSuccess]= useState(false)
            const [backup, setBackup]= useState('')
            const [procedureData, setProcedureData]= useState([])
            const [procedureShow, setProcedureShow]= useState(false)
            const [invoiceShow, setInvoiceShow]= useState(false)
            const [invoiceData, setInvoiceData]= useState([])
            const [viewShow, setViewShow]= useState(false)

   useEffect(() => { window.scrollTo(0, 0) }, [])
let history=useHistory();

const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'q': false,'l': false,});


useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['u']){
      history.push('/directory')
    }
    if(e.altKey && keys['c']){
      history.push('/careplans-db')
    }
    if(e.altKey && keys['n']){
      history.push('/notifications')
    }
    if(e.altKey && keys['a']){
      history.push('/analytics')
    }
    if(e.altKey && keys['q']){
      history.push('/qrcode')
    }
    if(e.altKey && keys['l']){
      history.push('/call-log')
    }


  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);


useEffect(()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
  
  
    axios.get(`${APIURL}/api/v1/hospital/patient-hospital-procedures/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
        
        let dataList= res.data.data;

         setTableData(dataList);

      }
 
        else{
        setGetError(res.data.message);
      }
      


    })
    .catch(err=>{

 setGetError("Error in fetching data");
})


},[reRender]);


useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  
            
      
      
      axios.get(`${APIURL}/api/v1/hospital/procedure-assignee/?q=${search}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
       const assignee_List=[]
        if(res.data.status==="success"){
        
          const assigneeList=res.data.data
          assigneeList.map((person, index) => (assignee_List.push(person["id"])))
        
        setOptions(assigneeList);
        setOptionsDisplay(assignee_List)
        
  
        }
  
  
      })
      .catch(err=>{
  
  })
  
  
  },[search]);

useEffect(()=>{
    setTableData(tableData)
  },[render,tableData]);

 const statusOptions = [ { value: 'assigned' , label: 'Assigned'},
       { value: 'ongoing' , label: 'Ongoing'},
       { value: 'completed' , label: 'Completed'},
       { value: 'rejected' , label: 'Rejected'},
    ];


const SuccessPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"green"}}>{props.msg} </h5>
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}
 

 const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}  

var sendData = null

function handleSend(childData){
  sendData = childData
}


const EditPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <EditRemark data={remarkData} list={remarkList} update={handleSend}/> */}
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}

const UpdatePopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"green"}}>Please save to complete changes </h5>
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}
  
  const InvoicePopup=(props)=>{
      
  
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            <GenerateInvoiceBdo invoiceData={invoiceData} />  
  
        </Modal.Body>
        <Modal.Footer>
           
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
         
  
        </Modal.Footer>
      </Modal>
    );
  }

  const InvoiceViewPopup=(props)=>{
      
  
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            <GenerateInvoiceView invoiceData={invoiceData} />  
  
        </Modal.Body>
        <Modal.Footer>
           
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
         
  
        </Modal.Footer>
      </Modal>
    );
  }

const renderAssigned2=(cellInfo)=> {

 return (<div  >
 
    

    

      
      <input
        style={{ backgroundColor: "#fafafa" }}
        value= {tableData[cellInfo.index].assign_name}
        className="form-control"        
       name="assignee"
       
        onChange={(e)=>{

          e.preventDefault();




 
  const list = tableData;
          list[cellInfo.index].assign_name = e.target.value;
          
          
          setTableData(list);

                setCurrentIndex(cellInfo.index);
                             setCurrentColumn(cellInfo.column.id);
                               
                               setDisplay(true);
 

 

  setSearch(e.target.value);

  
   } }
    
               
      />

        {currentIndex === cellInfo.index && cellInfo.column.id=== "assign_name" && display && (<div className="suggestions-box-holder">
           <button className="btn-suggestion btn btn-secondary btn-small" title="close" onClick={()=>setDisplay(false)}> X </button>

            <div className="suggestions-box suggestions-assignee-box">
            

             {options.map((item,i)=>{
         
    return(
      <div className="scan-item" 
          
            key={i} 
            onClick={(e)=>{ e.preventDefault();
             
              setRender(!render);
              
                                      const List = tableData;
                                      List[cellInfo.index].assign_id= item.id;
                                       List[cellInfo.index].assign_name= item.name;
                                 
                                      setTableData(List);
                                      setDisplay(false);

                                      }}
            >
            <div className="search-item each-option-suggestion">
      <h6 className="">ID: {item.id} </h6>
      <h6 className=""> {item.name}</h6>
       <h6 className="">{item.email} </h6>
       <h6 className=""> {item.mobile_number}</h6>
       </div>

      </div>
      )
  }) 
           }

        </div>
         </div>
        )
      }
      

      </div>
        

    )

      }
    
 
const renderTable=(cellInfo)=> {
  
    return (

      <div  className="fixed-co"> {tableData[cellInfo.index][cellInfo.column.id]}</div>
      
    );
  }
  const renderId=(cellInfo)=> {
  if(tableData[cellInfo.index][cellInfo.column.id]){
    return (

      <div  className="fixed-col"> {tableData[cellInfo.index][cellInfo.column.id]}</div>
      
    );
  }
  else{
    return null
  }
  }
  const renderService=(cellInfo)=> {
  
    return (
     
      <div > <span style={{margin:"10px"}}> 
          {tableData[cellInfo.index][cellInfo.column.id]}
       </span>  
       <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          Details: {tableData[cellInfo.index].details!== null? tableData[cellInfo.index].details: 'Not Available'} <br/>
           
       
          </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger> 
       </div>
      
    );
  }
  const renderContact=(cellInfo)=> {
  
    return (
     
      <div > <span style={{margin:"10px"}}> 
          {tableData[cellInfo.index][cellInfo.column.id]}
       </span>  
       <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          
          
       {tableData[cellInfo.index].address!== null? tableData[cellInfo.index].address: 'Not Available'} <br/>
          </Tooltip> }><h6 className="overlay-address">Address</h6></OverlayTrigger> 
          
       </div>
      
    );
  }
/*const renderDetails=(cellInfo)=> {
  
    return (

     <div > <p > {tableData[cellInfo.index][cellInfo.column.id]}</p></div>
      
    );
  }*/






const renderStatus2=(cellInfo)=> {
  
    return (
<>
       <Select
       value={tableData[cellInfo.index].statusObj}
       onChange={(data)=>{
        const List = tableData;
       

             List[cellInfo.index].statusObj = data;
            List[cellInfo.index].status=data.value;
             setTableData(List);
             setRender(!render);
             
      }}
        options={statusOptions}
        className= {`status-select ${tableData[cellInfo.index].status ==="new" ? "new-status" : 
                         tableData[cellInfo.index].status ==="accepted" ? "accepted-status" : 
                         tableData[cellInfo.index].status ==="ongoing" ? "ongoing-status" :
                          tableData[cellInfo.index].status ==="pending" ? "pending-status" :
                           tableData[cellInfo.index].status ==="cancelled" ? "cancelled-status" :
                           tableData[cellInfo.index].status ==="completed" ? "completed-status" :
                           tableData[cellInfo.index].status ==="delivered" ? "delivered-status" :
                           tableData[cellInfo.index].status ==="rejected" ? "rejected-status" :
                         ""}`}  
      />
      </>
    );
  }

  const renderInvoice = (cellInfo) => {
    if(tableData[cellInfo.index].payment_status === "paid"){

      return (
        <button  className="btn btn-primary btn-col btn-square btn-small" 
           onClick={()=>{viewChanges(cellInfo.index)}}>View </button>
        )

    }
    else {

      return (
        <button className="btn btn-primary btn-col btn-square btn-small" 
           onClick={()=>{saveChanges(cellInfo.index)}}>Generate </button>
        )

    }

  }


const saveChanges=(index)=>{
  const rowToUpdate = tableData.filter((elt,i)=>{
    return i=== index
  })

  setInvoiceShow(true)
  setInvoiceData(rowToUpdate[0])

}

const viewChanges=(index)=>{
  const rowToUpdate = tableData.filter((elt,i)=>{
    return i=== index
  })

  setViewShow(true)
  setInvoiceData(rowToUpdate[0])

}
const validate=(rowToValidate)=>{
  let isValid = true;

  if(!rowToValidate.status && !rowToValidate.assign_id ){
    isValid=false;

 setErrorMsg("Please enter status or assignee")

  setErrorSubmit(true);
  }     

      return isValid;

}


const callSave=(rowToUpdate)=>{

  if(validate(rowToUpdate)){

    const id = rowToUpdate.all_procedures.map(item => {
        return(
            item.procedure_details.procedure_id
        )
    })


  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
        const procedure_id=id;
        const assign_id=rowToUpdate.assign_id;
        const status= rowToUpdate.status;

   const dataToUpdate={
    procedure_id,
    assign_id,
    status,

   }
   
    axios.put(`${APIURL}/api/v1/hospital/patient-procedure-fulfilment/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
    
      setSubmitMsg(res.data.message ? res.data.message : "Updated successfully!");
          setBackup('')
          setSuccessSubmit(true);
          setTableData([]);
   
   }
       else{
       

         setErrorMsg(res.data.message ? res.data.message : "Error in submission");
              setErrorSubmit(true);
              
      }
    


    })
    .catch(err=>{
  
   setErrorMsg( "Error in submission");
              setErrorSubmit(true);

})
}
}

/*const callSaveRemarks=(rowToUpdate)=>{

  if(validate(rowToUpdate)){

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
      const order_id=rowToUpdate.ref;
      const remarks= sendData
      const status= rowToUpdate.status;
      const assignee = rowToUpdate.assigned_to;
      const priority= rowToUpdate.priority;
   const dataToUpdate={
    order_id,
    remarks,
    status,
    assignee,
    priority
   }
   
    axios.post(`${APIURL}/api/v1/hospital/service-request-order/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
    
      setSubmitMsg(res.data.message ? res.data.message : "Updated successfully!");

          setSuccessSubmit(true);
          setTableData([]);
   
   }
       else{
       

         setErrorMsg(res.data.message ? res.data.message : "Error in submission");
              setErrorSubmit(true);
              
      }
    


    })
    .catch(err=>{
  
   setErrorMsg( "Error in submission");
              setErrorSubmit(true);

})
}
}*/

   const ProcedurePopup=(props)=>{
    

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
           <ProcedureList data={procedureData} toggle='bdo'/> 
  
        </Modal.Body>
        <Modal.Footer>
           
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
         
  
        </Modal.Footer>
      </Modal>
    );
  }

   const viewProcedures = (data)=> {
       setProcedureData(data)
       setProcedureShow(true)
   }


   const renderProcedures = (cellInfo)=> {

    if(tableData[cellInfo.index].all_procedures){
      if(tableData[cellInfo.index].all_procedures.length > 0 ){
        return <button onClick={()=> viewProcedures(tableData[cellInfo.index].all_procedures)} className='btn btn-primary btn-col btn-square btn-small'>View</button>
    }
    }
       
   }


const columns = [

{
  Header: "Id",
  accessor: "patient_id",
  Cell: renderId,
  
   sortable:true,
    filterable:true,
    filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase()) : true
  },     
   width:50

},
{
  Header: <><h6>Patient Name</h6>
           </>,
  accessor: "patient_name",
  Cell: renderTable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
    sortable:true,
    filterable:true,
    filterMethod:(filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  },         
   width:150

},
{
    Header: "Procedures",
    accessor: "all_procedures",
    Cell: renderProcedures,
    style:{
      backgroundColor:"rgba(0,0,0,0.1)"
    },
  
    width:120,
    filterable:true,
    sortable:true
},

// {
//   Header: "Contact",
//   accessor: "contact_number",
//   Cell: renderContact,
//    sortable:false,
//     filterable:true,
//    width:150

// },

// {
//   Header: <><h6>*Status</h6>
//             </>,
//   accessor: "statusObj",
//   Cell: renderStatus2,
//    sortable:true,
//     filterable:true,
//    filterMethod: (filter, row) => {

//                     if (filter.value === "all") {
//                       return true;
//                     }
//                     if (filter.value.toLowerCase() === "assigned") {
//                         return row[filter.id].value === "assigned";
//                       }
//                     if (filter.value.toLowerCase() === "ongoing") {
//                       return row[filter.id].value ==="ongoing";
//                     }
//                     if (filter.value.toLowerCase() === "completed") {
//                       return row[filter.id].value === "completed";
//                     } 
//                      if (filter.value.toLowerCase() === "rejected") {
//                       return row[filter.id].value === "rejected";
//                     } 
                   
                     
//                   },
//                   Filter: ({ filter, onChange }) =>
//                     <select
//                       onChange={event => onChange(event.target.value)}
//                       style={{ width: "100%" }}
//                       value={filter ? filter.value : "all"}
//                     >
//                       <option value="all">All</option>
//                       <option value="assigned">Assigned</option>
//                       <option value="ongoing">Ongoing</option>
//                       <option value="completed">Completed</option>
//                       <option value="rejected">Rejected</option>
                      
                                          
//                     </select>,
                     
//    width:160
// },
// {
//   Header: <><h6>Assignee</h6>
//          </>,
//   accessor: "assign_name",
//   Cell: renderAssigned2,
//    style:{
//     backgroundColor:"rgba(0,0,0,0.1)"
//   },

//    sortable:true,
//    filterable:true,
   
//    filterMethod:(filter, row, column) => {
//     const id = filter.pivotId || filter.id
//     return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
//   },     
//    width:160

// },

{
    Header: "Amount",
    accessor: "net_total_procedure_amount",
    Cell: renderTable,

    width:120,
    filterable:true,
    sortable:true
},

{
    Header: "Status",
    accessor: "payment_status",
    Cell: renderTable,
    style:{
      backgroundColor:"rgba(0,0,0,0.1)"
    },
  
    width:120,
    filterable:true,
    sortable:true
},

{
  Header: "Invoice",
  Cell: renderInvoice,
  filterable:true,
  sortable:true,
  minWidth:50,
  width:100
}






]

  const displayTable=<ReactTable 
         columns={columns}
         data={tableData}
         defaultPageSize = {20}
         className="service-req-table procedure-table"
         resizable
      
                
         >

         </ReactTable> 

  const handleBack=(e)=>{
  history.goBack();
}
  return (<>
  <HospBdoNavbar />
  <br/>
  <div className="flex-head">       
          <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <h2 className="title-of-tasks title-mg"> Procedure Fulfillment</h2>
    </div> 
    <br/>
    <div className="">

      <div className="text-center">
        <div className="service-table-holder flex-col"> {displayTable}
        </div>
    
     
    </div>
    </div>
    <br/>
    <br/>
    
    <br/>
    <br/>
    <div className="info-notes-sr flex-row">
      <div className="info-div italics-text">
      <h6 className="" > - Status: New, ongoing, accepted , pending and cancelled requests are listed here </h6>
      <h6 className="" > - Completed, Cancelled and Rejected status requests are displayed in Service History table in <Link to="/careplans-db">Care plans Dashboard</Link> </h6>
     <h6 className="" > - Click on column header/title to sort the respective column in table </h6>
     <h6 className="" > - Filter the result by searching in the input field at each column header </h6>
     <h6 className="" > - Multisorting and multifiltering can be done at multiple columns   </h6>
     </div>
     <div className="info-div info-div-2">

     <h6 className="bold-text">Status: </h6>
      <span>   <span className="new-status-2">Nw  </span> : New </span>
        <span>  <span className="accepted-status-2">Ac  </span> : Accepted </span>
    <span> <span className="ongoing-status-2">On  </span> : Ongoing</span>
           <span>  <span className="pending-status-2">Pen  </span> : Pending </span>
       <span>  <span className="cancelled-status-2">Can  </span> : Cancelled </span>
       <span>  <span className="completed-status-2">Com  </span> : Completed </span>
       <span>  <span className="rejected-status-2">Rej  </span> : Rejected </span>
     </div>

    <div className="info-div info-div-2">

     <h6 className="bold-text" > Priority : </h6>
    <span> <h6><span className="critical-priority-2"> Cr  </span> : Critical </h6></span>
     <span> <h6>  <span className="very-high-priority-2">V.h  </span> : Very High </h6> </span>
       <span> <h6> <span className="high-priority-2"> Hi  </span> : High </h6></span>
        <span> <h6> <span className="medium-priority-2"> Md </span> : Medium</h6> </span>
         <span> <h6> <span className="low-priority-2"> Lw  </span> : Low </h6></span>

     </div>
</div>

    {errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setErrorMsg('');
                        setTableData([]);
                       setRerender(!reRender)}}
       
      />: ''
    }

{
      procedureShow ? 
      <ProcedurePopup
        show={procedureShow}
        onHide= {() => { setProcedureShow(false)     
        }}
      /> : null
    }


 {
      invoiceShow ? 
      <InvoicePopup
        show={invoiceShow}
        onHide= {() => { setInvoiceShow(false);
                         setRerender(!reRender)     
        }}
      /> : null
    } 

{
      viewShow ? 
      <InvoiceViewPopup
        show={viewShow}
        onHide= {() => { setViewShow(false);    
        }}
      /> : null
    } 

    {
      editShow ? 
      <EditPopup
        show={editShow}
        onHide= {()=> {
          setEditShow(false)
        }}
        onSave= {() => {
          if(sendData !== tableData[cell].remarks){
            const data =tableData
            data[cell].remarks=sendData
            setBackup(sendData)
            setTableData(data)
            setEditShow(false);
            setRender(!render);
            setUpdateSuccess(true)
            //saveChanges(cell)
          }
      } } /> : null
    }



    {submitMsg!=='' ?
    <SuccessPopup
        show={successSubmit}
        msg={submitMsg}
        onHide={() => { setSuccessSubmit(false);
                        setSubmitMsg('');
                        setTableData([]);
                       setRerender(!reRender)}}
       
      />: ''}

{
      updateSuccess ? 
      <UpdatePopup
        show={updateSuccess}
        onHide= {() => { setUpdateSuccess(false);     
        }}
      /> : null
    }

    </>
  );
}

export default ProcedureRequestsBdo;
