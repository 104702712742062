import React,{useState, useEffect, useRef} from 'react'
import HospBdoNavbar from '../HospBdoNavbar/HospBdoNavbar'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import ReactTable from 'react-table-v6';
import './payments.css'
import PaymentConfig from './PaymentConfig';
import PaymentInvoice from './PaymentInvoice';
import PastPayments from './PastPayments';
import moment from "moment";

function Payments() {

    const [searchTerm, setSearchTerm]= useState('')
    const [patient,setPatient]= useState([])
    const [modalShow, setModalShow]= useState(false)
    const [config, setConfig]= useState([])
    const [invoiceShow, setInvoiceShow]= useState(false)
    const [invoiceData, setInvoiceData]= useState([])
    const [errorShow, setErrorShow]= useState(false)
    // const [totalPages, setTotalPages]= useState(0)
    // const [perPage, setPerPage]= useState(2)
    // const [currentPage, setCurrentPage]= useState(1)
    // const [totalRecords, setTotalRecords]= useState(0)
    const [pastShow, setPastShow]= useState(false)
    const [validateErrorShow, setValidateErrorShow]= useState(false)
    const [display, setDisplay]= useState(false)
    const [allData, setAllData]= useState([])
    const [patientDetails, setPatientDetails]= useState({
      id:"",
      name:"",
      mobile_number:"",
      dob:"",
      address:"",
      next_visit:"",
      cpr:"",
      file_number:""
    })

    const handleDisplay = () => {
      setDisplay(false)
    }

    useEffect(()=> {
        window.scrollTo(0,0)

        document.addEventListener('click', handleDisplay)

        return () => {
          document.removeEventListener('click',handleDisplay)
        }
    },[])

    // const handleBack=(e)=>{
    //     history.goBack();
    //   }

  const initialRender = useRef(true)

    useEffect(() =>{

      if( initialRender.current ){
        initialRender.current = false
      }
      else if(searchTerm === ""){
        setPatient([])
      }
      else {

          const tokenString = localStorage.getItem("usertoken");
  
          let v2 = tokenString.replace(
            /["]+/g,
            ""
          ); /* REGEX To remove double quotes from tokenstring */
    
    
            axios
            .get(`${APIURL}/api/v1/hospital/hospital-all-payment/search/?q=${searchTerm}&per_page=10`, {
              headers: { Authorization: "Token "+ v2 },
            })
            .then((res) => {
              if (res.data.status === "success") {  
                setPatient(res.data.results.data)
                setDisplay(true)
                // setTotalPages(res.data.total_pages)
                // setCurrentPage(res.data.page)
                // setTotalRecords(res.data.total_records)
            }
            else{
    
            }
        }).catch((error)=>{
    
        })

    }

    },[searchTerm])

      const handleSearch =() => {

        const tokenString = localStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


        axios
        .get(`${APIURL}/api/v1/hospital/hospital-all-payment/search/?q=${searchTerm}&per_page=10`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {  
            setPatient(res.data.results.data)
            setDisplay(true)
        }
        else{

        }
    }).catch((error)=>{

    })

      }


  const handleGenerate = (e) =>{

    e.preventDefault();

    const tokenString= localStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  

     let checkProcedure = config.length > 0 ? config.some(item => {
       return item.name === "Procedure"
     })
     : false

     let checkRegistration = config.length > 0 ? config.some(item => {
      return item.name === "Registration"
    })
    : false
    
    let checkConsultation = config.length > 0 ? config.some(item => {
      return item.name === "Consultation"
    })
    : false


     let data = config.filter(item => {
       return item.name === 'Procedure'
     })

     let idArray = data.filter(item => {
       return item.id !== ""
     }).map(item =>{
       return item.id
     })


     const docId = config.find(item =>{
       return item.name === "Consultation"
     })

   const dataToSend= {
    "procedure_id_list": idArray,
    "doctor_id": docId ? docId.doctorId : ""
   }
  
      axios.post(`${APIURL}/api/v1/hospital/hospital-all-payment-invoice/?patient_id=${patientDetails.id}${checkProcedure?"&procedure_payment=true":""}${checkConsultation?"&consultation=true":""}${checkRegistration?"&registration=true":""}`, dataToSend, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
    
  
     
        if(res.data.status==="success"){
          setInvoiceData(res.data)
          setInvoiceShow(true)

      
        }else{
          setErrorShow(true)
          // setErrorMsg(true)
        }
  
  
      })
      .catch(err=>{

        setErrorShow(true)
        // setErrorMsg(true)
   
  })

  }

      const configDataHandle =(childData) =>{

        setAllData(childData)

        const filteredConfig = childData.filter(item => {
          return item.status
        })

        setConfig(filteredConfig)

      }

  const handleModal = () => {
    setModalShow(false)
    setSearchTerm("")
  }

      const ConfigPopUp = (props) => {
        return(
          <Modal
          {...props}
          aria-labelledby="example-custom-modal-styling-title"
     backdrop="static"
      keyboard={false}
      dialogClassName="config-modal-width"
    >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
      
       
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>

     <PaymentConfig  dataHandle ={configDataHandle} modalClose={handleModal} config={allData}/>
     
    </Modal.Body>
    <Modal.Footer>
     
       <Button className='btn btn-primary btn-col' onClick={props.onHide}> X Close</Button>   

    </Modal.Footer>
  </Modal>
        )
    }

    const InvoicePopup=(props)=>{

      const consultData = config.find(item => { return item.name === 'Consultation' })
      
  
      return (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
            dialogClassName="modal-80w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
              <PaymentInvoice invoiceData={invoiceData} consultData={consultData} allData={config} name={patientDetails.name} id={patientDetails.id} />  
    
          </Modal.Body>
          <Modal.Footer>
             
            <Button variant="secondary" onClick={props.onHide}>Close</Button>
           
    
          </Modal.Footer>
        </Modal>
      );
    }

    const PastPaymentPopup=(props)=>{
    

      return (
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
            dialogClassName="modal-80w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
             <PastPayments id={patientDetails.id} name={patientDetails.name}/> 
    
          </Modal.Body>
          <Modal.Footer>
             
            <Button variant="secondary" onClick={props.onHide}>Close</Button>
           
    
          </Modal.Footer>
        </Modal>
      );
    }

    const ErrorPopup =(props)=>{
    
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 style={{color: "red"}}>Error in Data Submission. Please try again!</h5>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>
    
           
           
    
          </Modal.Footer>
        </Modal>
      );
    }

    const ValidateErrorPopup =(props)=>{
          
      
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 style={{color: "red"}}>Please Select a Patient !!</h5>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>
    
           
           
    
          </Modal.Footer>
        </Modal>
      );
    }

    // const patientDisplay =  patient.map((value,index) => {
    //     return (
    //       <div tabindex="-1" onClick={() => { setPatientId(value.id); setPatientName(value.name); }} className='hover-detail'>
    //         <div tabindex="-1" key ={value.id} className='patient-detail-grid-view'>               
    //                   <h5 className="each-list-link">{(currentPage-1)*10 + index+1}.{value.name}</h5>  
    //                   <div className="details">
    //                     <p> Felixa Id: {value.id} </p>                                              
    //                     <p> Age : {value.age} </p>
    //                     <p> Mobile : {value.mobile_number} </p>
    //                     <span> File Number: {value.file_no} </span>                        
    //                     <span> Last Visit: {value.next_visit} </span>
    //                     <span> CPR: {value.cpr} </span>
    //                   </div>                                                                 
    //               </div>
    //               </div>
    //     )
    // }) 

    // const handleDate = (date,index)=>{

    //   if(date!==null){
    //     const offset = date.getTimezoneOffset();
        
    //     let dateUpdated = new Date(date.getTime() - (offset*60*1000));
        
    //     let DateExtracted = dateUpdated.toISOString();

    //     const list = config
    //     list[index].date = DateExtracted
    //     setConfig(list)
    //     setRender(!render)
    //   }

    // }

    // const handlePageChange = (pageNumber) => {
    //   setCurrentPage(pageNumber)
    // }

    const handleSearchChange =(e)=> {
      setSearchTerm(e.target.value)
    }

    const renderPaymentType = (cellInfo) => {
      if(config[cellInfo.index].status){
        return <p>{config[cellInfo.index].name}</p>
      }
    }

    const renderAmount = (cellInfo) => {

      if(config[cellInfo.index].status){

        if(config[cellInfo.index].amount){

          return (
            <>
  
  <div
                style={{ backgroundColor: "#fafafa" }}
                
                className="form-control test-name-col doc-detail-payment"        
               name="date"
                
                
               
                value={config[cellInfo.index].amount}
               
                onBlur={(e)=>{const procedure = config;
                  procedure[cellInfo.index].amount = e.target.innerHTML;
                  
                  setConfig(procedure);
        
                
        
               }}
                
                
             autoComplete="off"
              dangerouslySetInnerHTML={{
                  __html: config[cellInfo.index].amount
                }}
              />
    
            </>
          )
        }
        else{
          return <h6 className='text-danger'>NIL</h6>
        }


      }
    }

    const renderDate = (cellInfo) => {

      if(config[cellInfo.index].status){

        if(config[cellInfo.index].date){

          return (
            <>

<div
              style={{ backgroundColor: "#fafafa" }}
              
              className="form-control test-name-col doc-detail-payment"        
             name="date"
              
              
             
              value={moment.utc(config[cellInfo.index].date).local().format('DD-MM-YYYY')}
             
              onBlur={(e)=>{const procedure = config;
                procedure[cellInfo.index].date = e.target.innerHTML;
                
                setConfig(procedure);
      
              
      
             }}
              
              
           autoComplete="off"
            dangerouslySetInnerHTML={{
                __html: moment.utc(config[cellInfo.index].date).local().format('DD-MM-YYYY')
              }}
            />
  
          </>
        )
      }

            //  <DatePicker
            //               format='dd-MM-yyyy'
            //               value={new Date(config[cellInfo.index].date)}
            //               onChange={date=>{handleDate(date,cellInfo.index)}}
            //               clearIcon={null}
            //               //  minDate = {new Date()}
                          
            //          /> 
            else{
              return <h6 className='text-danger'>NIL</h6>
            }

      }   

    }

    const renderProcedureName= (cellInfo) => {

      if(config[cellInfo.index].procedureName){

        return (
          <>

<div
              style={{ backgroundColor: "#fafafa" }}
              
              className="form-control test-name-col doc-detail-payment"        
             name="date"
              
              
             
              value={config[cellInfo.index].procedureName}
             
              onBlur={(e)=>{const procedure = config;
                procedure[cellInfo.index].procedureName = e.target.innerHTML;
                
                setConfig(procedure);
      
              
      
             }}
              
              
           autoComplete="off"
            dangerouslySetInnerHTML={{
                __html: config[cellInfo.index].procedureName
              }}
            />
  
          </>
        )
      }
      else{
        return <h6 className='text-danger'>NIL</h6>
      }
  }

  const renderProcedureType = (cellInfo) => {

    if(config[cellInfo.index].type){

      return (
        <>

<div
            style={{ backgroundColor: "#fafafa" }}
            
            className="form-control test-name-col doc-detail-payment"        
           name="date"
            
            
           
            value={config[cellInfo.index].type}
           
            onBlur={(e)=>{const procedure = config;
              procedure[cellInfo.index].type = e.target.innerHTML;
              
              setConfig(procedure);
    
            
    
           }}
            
            
         autoComplete="off"
          dangerouslySetInnerHTML={{
              __html: config[cellInfo.index].type
            }}
          />

        </>
      )
    }
    else{
      return <h6 className='text-danger'>NIL</h6>
    }
}

const renderDoctorName= (cellInfo) => {

  if(config[cellInfo.index].doctorName){

    return (
      <>

<div
          style={{ backgroundColor: "#fafafa" }}
          
          className="form-control test-name-col doc-detail-payment"        
         name="date"
          
          
         
          value={config[cellInfo.index].doctorName}
         
          onBlur={(e)=>{const list = config;
            list[cellInfo.index].doctorName = e.target.innerHTML;
            
            setConfig(list);
  
          
  
         }}
          
          
       autoComplete="off"
        dangerouslySetInnerHTML={{
            __html: config[cellInfo.index].doctorName
          }}
        />

      </>
    )
  }
  else{
    return <h6 className='text-danger'>NIL</h6>
  }
}

const renderDoctorDept= (cellInfo) => {

  if(config[cellInfo.index].dept){

    return (
      <>

<div
          style={{ backgroundColor: "#fafafa" }}
          
          className="form-control test-name-col doc-detail-payment"        
         name="date"
          
          
         
          value={config[cellInfo.index].dept}
         
          onBlur={(e)=>{const list = config;
            list[cellInfo.index].dept = e.target.innerHTML;
            
            setConfig(list);
  
          
  
         }}
          
          
       autoComplete="off"
        dangerouslySetInnerHTML={{
            __html: config[cellInfo.index].dept
          }}
        />

      </>
    )
  }
  else{
    return <h6 className='text-danger'>NIL</h6>
  }
}

const renderDoctorSpec= (cellInfo) => {

  if(config[cellInfo.index].spec){

    return (
      <>

<div
          style={{ backgroundColor: "#fafafa" }}
          
          className="form-control test-name-col doc-detail-payment"        
         name="date"
          
          
         
          value={config[cellInfo.index].spec}
         
          onBlur={(e)=>{const list = config;
            list[cellInfo.index].spec = e.target.innerHTML;
            
            setConfig(list);
  
          
  
         }}
          
          
       autoComplete="off"
        dangerouslySetInnerHTML={{
            __html: config[cellInfo.index].spec
          }}
        />

      </>
    )
  }
  else{
    return <h6 className='text-danger'>NIL</h6>
  }
}

const deleteRow =(index) => {
  const list = config
  const data = allData

  const filtered = list.filter((item,pos) => {
    return pos !== index
  })

  const filteredData = data.filter((item,pos)=> {
    return item.status
  })

  const filteredIndex = filteredData.filter((item,pos)=> {
    return pos !== index
  })


  // const filteredFalseData = data.filter((item,pos)=> {
  //   return item.status === false
  // })

  // const final = filteredIndex.concat(filteredFalseData)

  // final.sort(function(a,b){
  //   return data.indexOf(a) - data.indexOf(b)
  // })


  setConfig(filtered)
  setAllData(filteredIndex)
}

    const columnsPayment = [
        
      {
        Header: <i className="fas fa-trash-alt"></i>,
        Cell: props=>{
          return (
        <i className="fas fa-trash-alt trash-btn" onClick={()=>{deleteRow(props.index)}} ></i>
          )
        },
        width:60,
        sortable:false,
        filterable:false
        },
        {
          Header: "Payment",

          // accessor: "payment_type",
          Cell: renderPaymentType,
          style:{
            backgroundColor:"rgba(0,0,0,0.1)"
          },
          width:150,
          minWidth:100,
           sortable:false
        
        },
        
        {
          Header: "Amount",
          Cell: renderAmount,
           sortable:false,
           width:100
        
        
        },
        {
          Header: "Date",
          Cell: renderDate,

          width:140,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Procedure Name",
          Cell: renderProcedureName,
          style:{
            backgroundColor:"rgba(0,0,0,0.1)"
          },
          width:180,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Type",
          Cell: renderProcedureType,
          style:{
            backgroundColor:"rgba(0,0,0,0.1)"
          },
          width:140,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Doctor Name",
          Cell: renderDoctorName,
           sortable:false,
           filterable:false,
           width:140
        
        },
        {
          Header: "Dept",
          Cell: renderDoctorDept,
          width:140,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Speciality",
          Cell: renderDoctorSpec,
          width:140,
          minWidth:100,
           sortable:false
        
        },
        
        ]

        const handleClick = (item) => {
          initialRender.current = true
          setSearchTerm(item.name)         
          setPatientDetails(item)
          setDisplay(false)
          setAllData([])
        }

    return (<>
        <HospBdoNavbar />
          {/* <div className="flex-head">       
            <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
            <h2 className=" title-of-tasks title-mg"> Payments </h2><br/>
        </div> 

        <br/> */}
<div className='pad-payment'>
<div className='payment-page'>

      <div className='payment-display'>
      {/* {
  patientName ?
  <h5 className='selected-display-payment'>Selected Patient : {patientName}</h5> : null
} */}
        <div className='payment-search-grid'>
            <div className='flex-col justify-content-start'>
                <h5 className='title-of-tasks'>Search Patient</h5>
                <div style={{width:"100%"}} className='flex-row'>

                <input
                style={{width:'70%'}}
                className="form-control"
                type="text"
                maxLength="100"
                placeholder="Enter Name/File/CPR"
                value={searchTerm}
                onChange={handleSearchChange}
              />

              <button disabled={searchTerm===""} onClick={handleSearch}  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>

                </div>
                {
                        display ?
                        <div className='suggest-display adjust-dimensions'>
                            {
                              patient.length > 0 ?  patient.map((item)=> {
                                    return (
                                        <p onClick={() => handleClick(item)} className='suggest-item text-secondary'>{item.name}</p>
                                    )
                                }) : <h6 className='text-muted'>No Patients found !!</h6>
                            }
                        </div>

                        : null
                    }

            </div>

            <div className="detail-input-grid">

              <input placeholder="Name" type='text' value={patientDetails.name} className="form-control" />
              <input placeholder="Mobile Number" type='text' value={patientDetails.mobile_number} className="form-control" />
              <input placeholder="Address" type='text' value={patientDetails.address} className="form-control" />
              <input placeholder="File Number" type='text' value={patientDetails.file_number} className="form-control" />
              <input placeholder="CPR" type='text' value={patientDetails.cpr} className="form-control" />
              <input placeholder="Next Visit" type='text' value={patientDetails.next_visit ? moment(patientDetails.next_visit).format("DD-MM-YYYY") : ""} className="form-control" />
            </div>
      
            {/* <div>
                {patient.length > 0 ?
                    patientDisplay 
                    : <h6 style={{color:"red", alignSelf:"end"}}>No Patients Found</h6> }
<br/>
<br/>
   {totalPages > 0 && totalRecords > 0 && searchTerm !== "" ?
    <div className="pagn">

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={totalRecords}
          pageRangeDisplayed={totalPages}
          onChange={handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null} 
            </div> */}

            <div>
                <button onClick={()=> { patientDetails.id !=="" ? setPastShow(true) : setValidateErrorShow(true) }} className='btn btn-primary btn-col btn-small'>Past Payments</button>
                <button onClick={()=> { patientDetails.id !=="" ? setModalShow(true) : setValidateErrorShow(true) }} className='btn btn-primary btn-col btn-small ml-2'>+ Payment</button>
            </div>
           

        </div>
      </div>

                    <div className='flex-row'>

            <ReactTable 
         columns={columnsPayment}
         data={config}
         defaultPageSize = {5}
         
         resizable
         className="scan-react-table"
                           
                 
         >

         </ReactTable> 

            </div>

<br/>
<button disabled={config.length < 1} onClick={handleGenerate} className='btn btn-primary btn-col'>Generate Bill</button>
<br/>
<br/>
<br/>
</div>
</div>

        {
            modalShow ? 
        <ConfigPopUp
            show={modalShow}
            onHide= { ()=> {setModalShow(false)
                           }} />
            : null
        }

{
      invoiceShow ? 
      <InvoicePopup
        show={invoiceShow}
        onHide= {() => { setInvoiceShow(false);
                         //setRerender(!reRender)     
        }}

        
      /> : null
    }

{
      pastShow ? 
      <PastPaymentPopup
        show={pastShow}
        onHide= {() => { setPastShow(false);
                         //setRerender(!reRender)     
        }}

        
      /> : null
    }


{errorShow ?
    <ErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        //setTableData([]);
                       //setRerender(!reRender)
                      }}
       
      />: ''
    }

{validateErrorShow ?
    <ValidateErrorPopup
        show={validateErrorShow}
        onHide={() =>  setValidateErrorShow(false)}
       
      />: ''
    }

        </>
    )
}

export default Payments
