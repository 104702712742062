import React,{useState, useEffect} from "react";

import "./hospital.css";
import { useHistory } from "react-router-dom";

import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import pic from "../../images/illustration_dr_cropped.JPG";
import {Button ,Modal} from 'react-bootstrap';

function Hospital() {
   
   const [hospData,] =useState(JSON.parse(sessionStorage.getItem('hosp_data'))? JSON.parse(sessionStorage.getItem('hosp_data')) : null);
   const [modalShow, setModalShow]= useState(false)
    const Hospital_name= hospData? hospData.name : null;
    //const Hospital_specializations = hospData? hospData.specializations : null;
   
   const location = hospData ? hospData.location : null;
 
   const history =useHistory();

   const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});


useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['d']){
      history.push('/doctors-list')
    }
    if(e.altKey && keys['a']){
      history.push('/hospital/add')
    }
    if(e.altKey && keys['b']){
      history.push('/bdo-list')
    }
    if(e.altKey && keys['o']){
      history.push('/hospital/add-bdo')
    }
    if(e.altKey && keys['n']){
      history.push('/emergency-number')
    }
    if(e.altKey && keys['s']){
      history.push('/service-request-list')
    }
    if(e.altKey && keys['l']){
      history.push('/license-details')
    }
    if(e.altKey && keys['p']){
      history.push('/license-payment')
    }
    if(e.altKey && keys['c']){
      history.push('/hosp-calllog')
    }
    if(e.altKey && keys['h']){
      history.push('/payment-history')
    }

  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);

const ShortcutPopup = ({onHide}) => {
  return(
  <Modal
      show
      onHide
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4>Shortcuts</h4>
          <br/>
          <span className='shortcut-class'>alt+d </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Doctors List</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+a </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Add Doctor Details </span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+b </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>BDO List</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+o </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Add BDO Details</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+n </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Emergency Number</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+l </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>License Details</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+p </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Purchase License</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+c </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Emergency Call Log</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+h </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Payment History</span>

        </div>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={onHide}>Ok</Button>          

      </Modal.Footer>
    </Modal>
  )
}
  
  return (<>
    <HospitalNavbar />
    <div className="hospital-dashboard-container"> 
    
    

{/*     <HospitalJumbotron /> 


     <div className="jumbotron jumbotron-fluid jumbo-div">
  <div className=" jumbo-content">
    <div className="captions">

         <h1 className="title-of-hosp"> {Hospital_name} </h1>
        
        {/*<h3 className="additional-text">{Hospital_specializations} </h3> 
        <h3 className="additional-text">{location} </h3>
    </div>
  

  
  </div>
</div>
*/}

{/*<div className="seperator"> </div> */}

<div className="section-drs">
<div className="horizontal">
{/*<div className="illustration">
<img src={d} width="150px" height="auto" /> 
</div>
*/}

</div>


 <div className="">


<div className="drs-management-section">
  <h1 className="additional-text "> {Hospital_name} </h1>
   <h3 className="subtitle-of-page">{location} </h3>
<div className="task-div">
        
        <div className="parent-of-lists">

      
          <div onClick={()=> history.push('/doctors-list')} className="each-list-title col-lg-2">
           <i className="fa fa-address-card task-icon" id="task-icon-color" aria-hidden="true"></i>
            <h5 className="each-title">
              {" "}

                Doctors List 

            </h5>

           
          </div>
          




          <div onClick={()=> history.push('/hospital/add')} className="each-list-title col-lg-2">
          <i className="fa fa-user-md task-icon" id="task-icon-color" aria-hidden="true" ></i>
            <h5 className="each-title">
              {" "}

                Add Doctor Details

            </h5>
            
          </div>

            <div onClick={()=> history.push('/bdo-list')} className="each-list-title col-lg-2">
           <i className="fa fa-address-card task-icon" id="task-icon-color" aria-hidden="true"></i>
            <h5 className="each-title">
              {" "}

                BDO List 

            </h5>

           
          </div>
          




          <div onClick={()=> history.push('/hospital/add-bdo')} className="each-list-title col-lg-2">
          <i className="fa fa-user-md task-icon" id="task-icon-color" aria-hidden="true" ></i>
            <h5 className="each-title">
              {" "}

                Add BDO Details

            </h5>
            
          </div>

          <div onClick={()=> history.push('/sda-list')} className="each-list-title col-lg-2">
           <i className="fa fa-address-card task-icon" id="task-icon-color" aria-hidden="true"></i>
            <h5 className="each-title">
              {" "}

                SDA List 

            </h5>

           
          </div>
          




          <div onClick={()=> history.push('/hospital/add-sda')} className="each-list-title col-lg-2">
          <i className="fa fa-user-md task-icon" id="task-icon-color" aria-hidden="true" ></i>
            <h5 className="each-title">
              {" "}

                Add SDA Details

            </h5>
            
          </div>

           <div onClick={()=> history.push('/emergency-number')} className="each-list-title col-lg-2">

           <i className="fas fa-phone task-icon" id="task-icon-color" aria-hidden="true"></i>
          
            <h5 className="each-title">
              {" "}

                 Emergency Number 

            </h5>

           
          </div>

          {/* <div onClick={()=> history.push('/service-request-list')} className="each-list-title col-lg-2 box-theme">
          <i className="fas fa-ambulance" id="task-icon-color" aria-hidden="true"></i>
          
            <h5 className="each-title">

            
              {" "}

               <h4> Service Fulfillment </h4>
                </h5>
                 </div>


                 <div onClick={()=> history.push('/rental-request-list')} className="each-list-title col-lg-2 box-theme">

       
           <i className="fas fa-wheelchair" id="task-icon-color" aria-hidden="true"></i>
   
            <h5 className="each-title">

            
              {" "}

               <h4> Equipment fulfillment </h4>

            </h5>

           
          </div> */}
  <div onClick={()=> history.push('/license-details')} className="each-list-title col-lg-2 box-theme">
          <i className="fas fa-certificate" id="task-icon-color" aria-hidden="true"></i>

            <h5 className="each-title">

            
              {" "}

               <h4> License Details </h4>
                </h5>
                 </div>


    <div onClick={()=> history.push('/license-payment')} className="each-list-title col-lg-2 box-theme">
          <i className="far fa-credit-card" id="task-icon-color" aria-hidden="true"></i>

            <h5 className="each-title">

            
              {" "}

               <h4> Purchase License </h4>
                </h5>
                 </div>


          <div onClick={()=> history.push('/hosp-calllog')} className="each-list-title col-lg-2">

          <i className="fas fa-list-ul" id="task-icon-color" aria-hidden="true"></i>
          
            <h5 className="each-title">
              {" "}

                 Emergency Call Log


            </h5>

           
          </div>

          <div onClick={()=> history.push('/payment-history')} className="each-list-title col-lg-2">


          <i className="fas fa-clipboard-list" id="task-icon-color" aria-hidden="true"></i>

          
            <h5 className="each-title">
              {" "}

                 Payment History


            </h5>

           
          </div>
          

          {/*
           <div className="each-list-title col-lg-3">
           <i className="fas fa-users task-icon task-icon-small" id="task-icon-color"></i>
         
            <h5 className="each-title">
              {" "}
             <Link to="/in-progress">
                Customer Engagement
               </Link>
            </h5>
           

           
          </div>
           */}
    

          {/*
          <div className="each-list-title temp-disabled">
          <i className="fa fa-user-plus task-icon task-icon-small" aria-hidden="true"></i>
            <h5 className="each-title">
              {" "}
           
                 Create App User
                
            </h5>
          
          </div>

           <div className="each-list-title temp-disabled">
           <i className="fa fa-id-badge task-icon" aria-hidden="true"></i>
            <h5 className="each-title">
              {" "}
              
                Manage Authorization
              
            </h5>
           
          </div>
              */}

        </div>
      </div>
      <p className='short-align' title="shortcuts">
<i onClick={()=> setModalShow(true)}className="fas fa-map-signs"></i>
</p>
    </div>
    </div>

    </div>


</div>

<div className="illustration">
<img src={pic} width="400px" height="auto" alt="illustration"/> 
</div>

{
            modalShow ? 
        <ShortcutPopup
            show={modalShow}
            onHide= { ()=> {setModalShow(false)
                           }} />
            : null
        }
</>
  );
}
export default Hospital;
