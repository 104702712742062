import React,{useState} from "react";
import {useHistory} from "react-router-dom";
import {Modal} from 'react-bootstrap';
import "./loginbdo.css";
import graphic from "../../images/graphic3.svg";
import pic from "../../images/graphic5.svg";
import HospBdoNavbarBanner from "../HospBdoNavbar/HospBdoNavbarBanner";
import CallLogout from "../Navbar/Logout"

function HospitalBdoBanner() {

    const [notification ,] =useState();
     
      const [bannerSubmit,setBannerSubmit]=useState(true);
   
    const [bdo_Data,]=useState(JSON.parse(sessionStorage.getItem('bdodata'))? JSON.parse(sessionStorage.getItem('bdodata')) : null);
   //const bdo_name= bdo_Data ? bdo_Data.name : null;
   /* const doctor_specializations = hospBdoData ? hospBdoData.doctor_specializations : null; */
   const department  = bdo_Data ? bdo_Data.department : null;
   // const Id = bdo_Data ? bdo_Data.user_id : null;
   //const QR_Id =bdo_Data ?bdo_Data.hospital_id :null;
   //const QR_usertype= bdo_Data ? bdo_Data.user_type :'hospital';
   const hospitalName= bdo_Data ? bdo_Data.hospital_username: null;
   /*const specializations = hospBdoData ? hospBdoData.specializations : null;*/
   //const location = bdo_Data ? bdo_Data.location: null;

   const history =useHistory();
 

 /*************

useEffect(()=>{
  const tokenString= localStorage.getItem('usertoken');
 
 let str= tokenString.replace(/["]+/g, '');

  axios.get(`${APIURL}/api/v1/account/web-notification/?key=new`, 
  { headers: {
    'Content-Type': 'application/json',
    "Authorization" : 'Token '+str  }})
     .then(res=>{
  

 

    if(res.data.status==="success"){
     const data=res.data.new_notifications

      setNotification(data)
      

    }else{
    
    }


  })
  .catch(err=>{

})
},[])


*****/

function BannerPopup(props){
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"red"}}> Your License seems to be invalid/expired.</h5><br/>
        <h5 style={{color:"red"}}> Kindly contact your Hospital admin to renew your License. </h5>
        <img src={graphic} alt='License Expired' height="200px" width="auto" />
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
          <CallLogout />
     

   
         

       
       

      </Modal.Footer>
    </Modal>
  );
}

  return (
    <> <HospBdoNavbarBanner />
       <div className="hospital-dashboard-container hosp-bdo-page"> <br/>
       <h2 className="title-bdo">    {hospitalName ? hospitalName : null}</h2>
       <h4 className="additional-text text-bdo">  {department!=="" && department!==null ? department +"Department" : null}</h4>
      
    
        

       {/*<HospitalJumbotron /> */}

         <div className="jumbotron jumbotron-fluid  jumbo-div-bdo" >
            
                             
            <div className=" jumbo-text-content bdo-tasks">
                  
            <div className="container flex-center bdo-tabs-holder ">
             
          
            <div onClick={()=> history.push('/')} className="each-list-title col-lg-3 box-theme" >
           <i className="fas fa-search task-icon" id="task-icon-color" aria-hidden="true"></i>
           
            <h5 className="each-title">


              {" "}

                <h4> User Directory </h4>
                  
            </h5>
            </div>


            {/**************

           <div onClick={()=> history.push('/care-programs')} className="each-list-title col-lg-3 box-theme">
           <i className="fa fa-hospital task-icon" id="task-icon-color" aria-hidden="true"></i>
           
            <h5 className="each-title">

            
              {" "}

               <h4> Care Programs </h4>

            </h5>

           
          </div>
         *******/}
          <div onClick={()=> history.push('/')} className="each-list-title col-lg-3 box-theme">
           <i className="fa fa-hospital task-icon" id="task-icon-color" aria-hidden="true"></i>
           
            <h5 className="each-title">

            
              {" "}

               <h4> Care Plans Dashboard </h4>

            </h5>

           
          </div>

         

          <div onClick={()=> history.push('/')} className="each-list-title col-lg-3 box-theme">
          {/*} <i className="fa fa-bell" id="notification-icon"  aria-hidden="true"></i>*/}
          { notification >=1 ?
            <a style={{pointerEvents:'none'}} className="notification">
            <svg width="2.3em" height="2.3em" viewBox="0 0 36 36"><path className="clr-i-solid-doc--badged clr-i-solid-path-1--badged" d="M18 34.28A2.67 2.67 0 0 0 20.58 32h-5.26A2.67 2.67 0 0 0 18 34.28z" fill="red"></path><path className="clr-i-solid-doc--badged clr-i-solid-path-2--badged" d="M32.85 28.13l-.34-.3A14.37 14.37 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94a10.92 10.92 0 0 0-.16-1.79A7.5 7.5 0 0 1 22.5 6v-.63a10.57 10.57 0 0 0-3.32-1V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.3 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93l-.34.3v2.82h29.8z" fill="currentColor"></path></svg>
            <span style={{position:'absolute',top:'-6px',right:'-2px'}} className="badge">{notification}</span>
            </a>
          : <svg width="2.3em" height="2.3em" viewBox="0 0 36 36"><path className="clr-i-solid-doc clr-i-solid-path-1" d="M32.85 28.13l-.34-.3A14.37 14.37 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94A10.81 10.81 0 0 0 19.21 4.4V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.33 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93l-.34.3v2.82h29.8z" fill="currentColor"></path><path className="clr-i-solid-doc clr-i-solid-path-2" d="M15.32 32a2.65 2.65 0 0 0 5.25 0z" fill="currentColor"></path></svg> }
            <h5 style={{marginTop:'35px'}} className="each-title">

            
              {" "}

               <h4> Notifications </h4>

            </h5>

           
          </div>

       
          

          <div onClick={()=> history.push('/')} className="each-list-title col-lg-3 box-theme">
          <i className="fas fa-chart-bar" id="task-icon-color" aria-hidden="true"></i>
          
            <h5 className="each-title">

            
              {" "}

               <h4> Analytics & Reports </h4>

            </h5>

           
          </div>

          <div onClick={()=> history.push('/')} className="each-list-title col-lg-3 box-theme">
          <i className="fas fa-ambulance" id="task-icon-color" aria-hidden="true"></i>
          
            <h5 className="each-title">

            
              {" "}

               <h4> Service Requests </h4>

            </h5>

           
          </div>
 
          <div onClick={()=> history.push('/')} className="each-list-title col-lg-3 box-theme" >
         
            <i className="fas fa-qrcode task-icon" id="task-icon-color"></i>
            <h5 className="each-title">

            
              {" "}

               <h4> QR Code </h4>

            </h5>

           
          </div>

          <div onClick={()=> history.push('/')} className="each-list-title col-lg-3 box-theme" >
         
            <i className="fas fa-list-ul" id="task-icon-color"></i>
            <h5 className="each-title">

            
              {" "}

               <h4> Emergency Call Log </h4>

            </h5>

           
          </div>




           

        
</div> 
<br/>
<div className="divider"></div>

 <div className="row-jumbo-content">
                     <div className="illustration-bdo">
                        <img src={pic} width="350px" height="auto" alt="illustration"/> 
                        </div>




            {/*}


              <div className="captions">
            */}

                {/*  <h3 className="title-bdo">  {bdo_name} </h3>
                  <h5 className="additional-text">  BDO ID: {Id} </h5>
                   <h5 className="additional-text">  {department} Department,</h5> */}

                 {/*  <h2 className="title-bdo">  Analytics & Reports </h2>
                  <h4 className="additional-text text-bdo">  {hospitalName} Hospital</h4><br/>
                  <Link to="/analytics"><button className="btn btn-info" > Goto Analytics </button></Link>
                  
                 
              </div>
            */}
  

  </div>
  </div>
</div>

{/*<h2 className="title-of-lists title-of-tasks"> My Tasks </h2>   */}

<div className="patients-section">

{/*
<div className="search-form-div">

<form >
          <div className="search-div">
            <input
              className="form-control search-input"
              type="text"
              placeholder="Search Patient Name/phone"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
          </div>
        </form>  

</div>
*/}


</div>
<br/>
<br/>

{/*

<div className="illustration">
<img src={pic} width="400px" height="auto" alt="illustration"/> 
</div>

*/}
{bannerSubmit===true ?
 <BannerPopup
        show={bannerSubmit}
        
        
       
      />

      : null}

</div>

    </>
  );
}
export default HospitalBdoBanner;
