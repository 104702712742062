import React,{useState, useEffect} from "react";

import "./staffdashboard.css";
import { Link,useHistory } from "react-router-dom";


import tests from "../../images/icons/tests.png";

import medicine from "../../images/icons/medicine.png";
import report from "../../images/icons/report.png";
import editreport from "../../images/icons/edit.png";
import search from "../../images/icons/search.png";
import checkmark from "../../images/icons/checkmark.png";
import steth from "../../images/icons/stethoscope.png";
import survey from "../../images/icons/survey.png";
import {Button ,Modal} from 'react-bootstrap';


function StaffDashboard() {

  const history =useHistory();

  const [modalShow, setModalShow]= useState(false)
  const [keys,] = useState({'h': false,'d': false,'u': false,'r': false,'l': false,'i': false,'a': false,'p': false,'v': false,'t': false,'n': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['h']){
   history.push('/hospitallist')
 }
 if(e.altKey && keys['d']){
   history.push('/doctorslist')
 }
 if(e.altKey && keys['u']){
   history.push('/search-hospitals')
 }
 if(e.altKey && keys['r']){
   history.push('/rejectedlist')
 }
 if(e.altKey && keys['l']){
   history.push('/reportlist')
 }
 if(e.altKey && keys['i']){
   history.push('/addsynonyms')
 }
 if(e.altKey && keys['a']){
   history.push('/failedlist')
 }
 if(e.altKey && keys['p']){
   history.push('/prescriptionlist')
 }
 if(e.altKey && keys['v']){
   history.push('/written-prescriptionlist')
 }
 if(e.altKey && keys['t']){
   history.push('/testlist')
 }
 if(e.altKey && keys['n']){
  history.push('/nutrition')
}

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

const ShortcutPopup = ({onHide}) => {
  return(
  <Modal
      show
      onHide
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4>Shortcuts</h4>
          <br/>
          <span className='shortcut-class'>alt+h </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Hospitals</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+d </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Doctors</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+u </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>All Users</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+r </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>rejected Users</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+l </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Lab Reports</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+i </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Header Issue</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+a </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Failed Lab Reports</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+p </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Prescriptions</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+v </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Handwritten Prescriptions</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+t </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Test Names</span>
          <br/>
          <br/>
          <span className='shortcut-class'>alt+n </span>{' '} <i className="fas fa-arrow-right arrow"></i>{' '}<span className='text-muted direct'>Nutrients Table</span>


        </div>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={onHide}>Ok</Button>          

      </Modal.Footer>
    </Modal>
  )
}

  return (
    <> <div className="jumbotron jumbotron-fluid staff-jumbo">
  <div className=" ">
      <h2 style={{color: "var(--theme-blue)",fontWeight:"bolder"}}> Welcome to Admin Dashboard</h2>
  </div>
  </div>
      <div className="">
      <h3 className="title-of-page"> Customer Management</h3>
        <div className="parent-of-lists box-container">

           <Link to="/hospitallist" >
           <div className="each-list-title">
             

           <img src={medicine} width="65px" alt="lab reports"/>

            
            <h4 className="each-title">
              {" "}
             
                Hospitals </h4>
                <h5>Pending Approval</h5>
           
           
           </div>
            </Link>

            <Link to="/doctorslist" >
            <div className="each-list-title ">
             

           <img src={steth} width="65px" alt="lab reports"/>

            
            <h4 className="each-title">
              {" "}
             
                Doctors </h4>
                <h5>Pending Approval</h5>
           
           
           </div>
            </Link>
             
        <Link to="/search-hospitals" >
          <div className="each-list-title ">

          <img src={search} width="65px" alt="add headings"/>
           

            <h4 className="each-title">
    
                {" "}
                All Users </h4>
                <h5> View Details, </h5>
                <h5> Enable/Disable Account </h5>
             
           
          </div>
         </Link>

         <Link to="/rejectedlist" >
         <div className="each-list-title ">

          <img src={survey} width="65px" alt="add headings"/>
           

            <h4 className="each-title">
    
                {" "}
                All Users </h4>
                <h5>Rejected List </h5>
             
           
          </div>
  </Link>
        
          </div>

<div className="seperator divide-line"> </div>

          <h3 className="title-of-page"> Lab Reports</h3>
        <div className="parent-of-lists box-container">

           <Link to="/reportlist" >
            <div className="each-list-title ">
             

           <img src={report} width="65px" alt="lab reports"/>

            
            <h4 className="each-title">
              {" "}
             
                Lab Reports </h4>
                <h5>Verification</h5>
           
           
           </div>
            </Link>
             
        <Link to="/addsynonyms" >
          <div className="each-list-title ">

          <img src={search} width="65px" alt="add headings"/>
           

            <h4 className="each-title">
    
                {" "}
                Headers issue </h4>
                <h5> Add Header </h5>
             
           
          </div>
         </Link>
        
         <Link to="/failedlist" >
          <div className="each-list-title ">

            <img src={tests} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Failed Lab Reports   </h4>
                <h5>
                  {" "}
                  Add Manually{" "}
                </h5>
              
           
          </div>
          </Link>

{/*
           <Link to="/in-progress" >
          <div className="each-list-title box-blue task-box">

            <img src={tests} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Missing Header </h4>
                <h5>
                  {" "}
                  Add Header</h5>
              
           
          </div>
          </Link>
 */}

</div>

<div className="seperator divide-line"> </div>

 <h3 className="title-of-page"> Prescriptions & Other Tasks</h3>

 <div className="parent-of-lists box-container">
        <Link to="/prescriptionlist" >
          <div className="each-list-title  ">
             <img src={medicine} width="65px" alt="prescription"/>
            <h4 className="each-title">
            
             
                {" "}
                Prescriptions </h4>
                <h5>Verification</h5>
              
          

          </div>
          </Link>

          
            <Link to="/written-prescriptionlist" >
          <div className="each-list-title ">

          
           <img src={editreport} width="65px" alt="handwritten prescription"/>
            <h4 className="each-title">
            
                {" "}
                Handwritten Prescriptions </h4>
                <h5>
                  Verification</h5>
              
           
          </div>
          </Link>
              

            <Link to="/testlist" >
          <div className="each-list-title ">

            <img src={tests} width="65px" alt="tests"/>

            <h4 className="each-title">
            
                {" "}
                Test Names   </h4>
                <h5>
                  {" "}
                  Add Synonyms{" "}
                </h5>
              
           
          </div>
          </Link>
         

          
         


        
         


          <Link to="/nutrition" >
          <div className="each-list-title ">

            <img src={checkmark} width="65px" alt="nutrition"/>

            <h4 className="each-title">
            
                {" "}
                Nutrients Table   </h4>
                <h5>
                  {" "}
                  Add Values
                </h5>
              
           
          </div>
          </Link>

</div>

<div className="seperator divide-line"> </div>

 <h3 className="title-of-page"> Care plans Service and Equipment Management</h3>

 <div className="parent-of-lists box-container">
        <Link to="/features-list" >
          <div className="each-list-title  ">
             <img src={steth} width="65px" alt="services"/>
            <h4 className="each-title">
            
             
                {" "}
                Service and Equipment </h4>
                <h5>Add Features</h5>
              
          

          </div>
          </Link>

  </div>
       <br/>
       <p className='short-align' title="shortcuts">
<i onClick={()=> setModalShow(true)}className="fas fa-map-signs"></i>
</p>       
        <br/>
      </div>

      {
            modalShow ? 
        <ShortcutPopup
            show={modalShow}
            onHide= { ()=> {setModalShow(false)
                           }} />
            : null
        }
    </>
  );
}
export default StaffDashboard;
