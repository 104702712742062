import React, {useState,useEffect} from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "../ServiceRequests/service.css";
import { useHistory, Link } from "react-router-dom";
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function LicenseTable(props) {

const [licenseData, setLicenseData]= useState([])
const [confirmShow, setConfirmShow]= useState(false)
const [successShow, setSuccessShow]= useState(false)
const [errorShow, setErrorShow]= useState(false)
const [errorMsg, setErrorMsg]= useState('')
const [index, setIndex]= useState()

const id =props.location.id
const flag = props.location.flag

const history =useHistory();

const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['d']){
   history.push('/doctors-list')
 }
 if(e.altKey && keys['a']){
   history.push('/hospital/add')
 }
 if(e.altKey && keys['b']){
   history.push('/bdo-list')
 }
 if(e.altKey && keys['o']){
   history.push('/hospital/add-bdo')
 }
 if(e.altKey && keys['n']){
   history.push('/emergency-number')
 }
 if(e.altKey && keys['s']){
   history.push('/service-request-list')
 }
 if(e.altKey && keys['l']){
   history.push('/license-details')
 }
 if(e.altKey && keys['p']){
   history.push('/license-payment')
 }
 if(e.altKey && keys['c']){
   history.push('/hosp-calllog')
 }
 if(e.altKey && keys['h']){
   history.push('/payment-history')
 }

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

const handleBack=(e)=>{

  if(flag===true){
    history.push('/bdo-list');
  }
  else if(flag==='sda'){
    history.push('/sda-list')
  }
  else if(flag=== false){
    history.push('/doctors-list');
  }
  
}

useEffect(()=> {
    if(id!==undefined){
        sessionStorage.setItem('id',id)
    }       
},[])

useEffect(()=>{
    const tokenString= localStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '')  ;
       
    axios.get(`${APIURL}/api/v1/hospital/hospital-licenses/`, { headers: {
          
        "Authorization" : 'Token '+str  }})
      .then(res=>{
       
  
     
     
        if(res.data.status==="success"){ 

          const data =res.data.hospital_licenses
          setLicenseData(data)
              
          }

        else{


          }
  
      })
      .catch(err=>{
  
  })
    
    
    },[]);

    const ConfirmPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Are you sure to add this user to a License ?</h4>
               
              </Modal.Body>
              <Modal.Footer>
      
                 <Button variant="secondary" onClick={props.onHide}>No</Button>
                 <Button variant="danger" onClick={props.onCall}>Yes</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

    const SubmitPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>User added to License Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>{errorMsg}</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

const renderTable= (cellInfo)=> {
    return(
        <div>{licenseData[cellInfo.index][cellInfo.column.id]}</div>
    )
}

/*const renderAmount= (cellInfo)=> {
    return(
        <div>{licenseData[cellInfo.index][cellInfo.column.id]}{'\n'}{licenseData[cellInfo.index].currency}</div>
    )
}*/

const renderDate= (cellInfo)=> {
    const data =licenseData[cellInfo.index][cellInfo.column.id]
    const date = data.slice(0,10)
    return(
        <div>{date}</div>
    )
}

const addUser= (cellInfo)=> {

    setConfirmShow(true)
    setIndex(cellInfo)
}

const addLicense= ()=> {

    const userId= sessionStorage.getItem('id')

    const tokenString = localStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
          );
          const data ={
              license_id : licenseData[index].license_id,
              user_id : userId
          }
          axios.post(`${APIURL}/api/v1/hospital/hospital-licenses/`, data, {
            headers: { Authorization: "Token " + v2 },
       })
       .then((res) =>{
           if(res.data.status==='success'){
            
                setSuccessShow(true)
           }
           else{
                setErrorShow(true)
                setErrorMsg(res.data.message)
           }
       })
       .catch((err) => {
            setErrorShow(true)
            setErrorMsg('Error in data submission.Please check again!')
       })
}

const columns = [
{
  Header: "License ID",
  accessor: "license_id",
  Cell: renderTable,
    width:110,
    style:{
        backgroundColor:"rgba(0,0,0,0.1)"
      },
},
{
    Header: "Amount Paid",
    accessor: "amount_paid",
    Cell: renderTable,

      width:130
  },
  {
    Header: "Currency",
    accessor: "currency",
    Cell: renderTable,

      width:130
  },
  {
    Header: "Valid From",
    accessor: "valid_from",
    Cell: renderDate,
      width:120,
      style:{
          backgroundColor:"rgba(0,0,0,0.1)"
        },
  },
  {
    Header: "Valid Till",
    accessor: "valid_till",
    Cell: renderDate,
      width:120,
      style:{
          backgroundColor:"rgba(0,0,0,0.1)"
        },
  },
{
    Header: "Tax",
    accessor: "tax_percentage",
    Cell: renderTable,

      width:100
  },
  {
    Header: "Tenure",
    accessor: "tenure",
    Cell: renderTable,

      width:100
  },
  {
    Header: "User Count",
    accessor: "user_count_limit",
    Cell: renderTable,
      width:110,
      style:{
          backgroundColor:"rgba(0,0,0,0.1)"
        },
  },
  {
    Header: "Active Users",
    accessor: "active_user_count",
    Cell: renderTable,
      width:130,
  }, 
  {
    Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
                 data-placement = "top" title = "Add License">Add to License</span>,
    Cell: props=>{
      return (
      <button className="btn btn-success btn-square btn-small" 
         onClick={()=>{addUser(props.index)}}>Add User</button>
      )
    },
    minWidth:140
  
  },
]

  const displayTable=<ReactTable 
         columns={columns}
         data={licenseData}
         defaultPageSize = {5}
         className="service-req-table SR-basic-table"
         resizable
                  
                
         >

         </ReactTable> 
  return (<>
  <HospitalNavbar /><br/>
    <div className="flex-between">
        
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
          <Link to={{pathname:'license-payment', flag:"others-back" }}><button type="button" style={{marginLeft:"10px"}} className="btn btn-info dr-btn"> Purchase License </button></Link>
    </div> 
  <div className='flex-col'>

            <h2 className="title-of-page">Hospital License </h2>
    <br/>
    <br/>
      <div className="text-center">
        <div className="service-table-holder flex-col">
           {displayTable}
        </div>   
    
      </div>

  </div>
<br/>
<br/>

    {
        confirmShow ? 
        <ConfirmPopUp 
            show={confirmShow}
            onCall={() => { addLicense();  setConfirmShow(false) }}
            onHide={() => setConfirmShow(false) }/> : null
    }
    
    {successShow ? 

<SubmitPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false);
                flag===true? history.push('/bdo-list') :
                 flag === false ? history.push('/doctors-list') : 
                 flag === 'sda' ? history.push('/sda-list') : history.push('/hospital') }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}

    </>
  );
}

export default LicenseTable;
