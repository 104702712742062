import React from 'react';


import {Link } from 'react-router-dom';

import '../../css/bootstrap.min.css';
import '../../css/iofrm-style.css';
import '../../css/iofrm-theme4.css';
import "./register.css"

import "./reg1.css";

import { useTranslation } from "react-i18next";

function Reg1(){

    const { t } = useTranslation();
	 return (
                <div className="form-body">
                   
                    <div className="row">
                        <div className="img-holder">
                            <div className="bg bg-2"> {/*<img src={logo} className="logo-footer felixacare-logo-content" alt="felixacare"/><br/> <br/>
        */}    <h4 className="grey-title tagline">{t('The Intelligent Platform For Patient Engagement')}</h4></div>

                            <div className="info-holder">
                                   
                             <div className="captions-holder">
                                   
                                 <h2 className="captions"> {t('Continuum Care for Patients.')} 
                                  </h2>
                                  <h2 className="captions">
                                       {t('Digitized Health Documents.')}</h2><br/>
                                  <h2 className="captions"> {t('Special Care for Chronically Diseased.')}
                                  </h2>
                             </div>
                            </div>
                        </div>
                        <div className="form-holder form-container">
                            <div className="form-content" id="form-content-holder">
                                <div className="form-items">
                                    
                                    <p></p>
                                    <div className="page-links">
                                        <Link to="/login" >{t('Login')}</Link><Link to="register" className="active">{t('Register')}</Link>
                                       
                                    </div>

                                    

                                         <form className="reg2-form">

                                         <div className="register-usertype"> 
                                              
                                            <div className="parent-users-outer">
                                              <Link to="/registerdoctor" className="link"><div className="reg-each-type">
                                                  <i className="fas fa-user-md"></i>
                                                  <h5> {t('Doctor Registration')}  </h5>
                                              </div> </Link>

                                               <Link to="/registerpatient" className="link"><div style={{width:'220px'}} className="reg-each-type">
                                                  <i className="far fa-user"></i>
                                                  <h5> {t('Patient / Caregiver Registration')}  </h5>
                                              </div> </Link>

                                              <Link to="/hospital-register" className="link"><div className="reg-each-type">
                                                  <i className="fas fa-hospital"></i>
                                                  <h5> {t('Hospital Registration')}  </h5>
                                              </div> </Link>
                                          </div>

                                              

                                        </div>


                                         
                                         </form>
                                  
                                 </div>
                            </div>
                        </div>

                        </div>
                       </div>
		
		)
}

export default Reg1;
