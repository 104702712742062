import React, { useState,useContext,useEffect} from "react";
import { Link, useHistory} from "react-router-dom";

import "./login.css";
import "../../css/bootstrap.min.css";
import axios from "axios";
import "../../css/iofrm-style.css";
import "../../css/iofrm-theme4.css";
import {LoginStatusContext} from "../../contexts/LoginStatusContext";
import {HospLoginContext} from "../../contexts/HospitalLoginContext";
import {HospDrLoginContext} from "../../contexts/HospDrLoginContext";
import {LoginContext} from "../../contexts/LoginContext";
import {BdoLoginContext} from "../../contexts/BdoLoginContext";
import { Dropdown } from 'react-bootstrap';
import {APIURL} from "../../Global";
import { useTranslation } from "react-i18next";


function Login() {
  
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [,setIsLoggedIn] = useContext(LoginStatusContext);
  const [,setHospData] =useContext(HospLoginContext);
  const [,setHospDocData] =useContext(HospDrLoginContext);
  const [,setBdoData]=useContext(BdoLoginContext);
 
  const [error, setError] = useState(false);
  const [, setLoginDatas] = useContext(LoginContext);

  const { t } = useTranslation();


useEffect(()=>{
  
  localStorage.clear();
  sessionStorage.clear();
  setLoginDatas(null);
  setIsLoggedIn(false);
},[]);
  let history = useHistory();
  const handleLogin = async (e) => {
    e.preventDefault();

    await apicall();
  };



  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "loginUsername") {
      setLoginUsername(val);
    }
    if (key === "loginPassword") {
      setLoginPassword(val);
    }
  };

  const apicall = ()=> {
    
     axios
      .post(`${APIURL}/api/v1/account/login/`, {
        username: loginUsername,
        password: loginPassword,
      },{ headers: {
     'Content-Type': 'application/json'}})
      .then((res) => {
     
        if (res.data.status === "success") {
          sessionStorage.setItem('pw',loginPassword)
       
         sessionStorage.setItem('logindatas', JSON.stringify(res.data));
          setLoginDatas(res.data);
          /* Store Token */
          localStorage.setItem("usertoken", JSON.stringify(res.data.token));
              sessionStorage.setItem("user_token", JSON.stringify(res.data.token));
          
          localStorage.setItem("usertype", res.data.user_type);
          localStorage.setItem("userid",res.data.user_id);
         
          if (res.data.user_type === "staff") {
            

            history.push("./dashboard");
          } 
          else if(res.data.user_type === "hospital" && res.data.role==="bdo"){
            localStorage.setItem("role", res.data.role);
            sessionStorage.setItem('bdodata', JSON.stringify(res.data))
               setBdoData(res.data);
               if(res.data.hospital_license === false){
               history.push({pathname: '/bdo-invalid-license'});
                }
                else{
                  history.push({pathname: '/bdo'});
                }
               

          }
          else if (res.data.user_type === "hospital"){

            if(res.data.role === 'sda'){
              history.push('./loggedin')
            }
           
         
        
            if(res.data.verification_status=== "pending"){
               sessionStorage.setItem('hosp_data', JSON.stringify(res.data));
               setHospData(res.data);

               history.push({ pathname: '/verifyhospital'});

               
            }
            if(res.data.verification_status=== "approved"){
               sessionStorage.setItem('hosp_data', JSON.stringify(res.data));
          setHospData(res.data);
               history.push({ pathname: '/hospital'});

            }
          }

          else if (res.data.user_type === "hospital_doctor"){
       sessionStorage.setItem('hosp_doc_data', JSON.stringify(res.data));
        setHospDocData(res.data);

              if(res.data.hospital_license === false){
               history.push({pathname: '/dr-invalid-license'});
                }
                else{
                  history.push({pathname: '/doctor'});
                }

                
          }
          else {
            history.push("./loggedin");
          }
         
        } else {
          setLoginUsername("");
          setLoginPassword("");
          setError(res.data.message);
        }
      })
      .catch(() => {
        
        setLoginUsername("");
        setLoginPassword("");
        setError("There was some error in login");
      });
  };

  return (
    <div className="form-body">
      {/*<div className="website-logo">
            <a href="index.html">
                <div className="logo">
                    <img className="logo-size" src="images/logo-light.svg" alt="">
                </div>
            </a>
        </div> */}
      <div className="row">
        <div className="img-holder">
           <div className="bg bg-2"> 
            <h4 className="grey-title tagline">{t('The Intelligent Platform For Patient Engagement')}</h4></div>
          <br/> <br/>            
           
          <div className="info-holder">
                 
           <div className="captions-holder">
             
             <h2 className="captions"> {t('Continuum Care for Patients.')} 
              </h2>
               <h2 className="captions">
               {t('Digitized Health Documents.')}</h2><br/>
              <h2 className="captions"> {t('Special Care for Chronically Diseased.')} 
              </h2>
           </div>
          </div>
        </div>
        <div className="form-holder">
          <div className="form-content">
            <div className="form-items">
              
              
                 

              {/* <div className="page-links">
                <Link to="/login" className="active">
                {t('Login')}
                </Link>
                 <Link to="register">{t('Register')}</Link> 
              </div> */}
              <form className="login-form" onSubmit={handleLogin}>
               <h3 className="form-heading"> {t('Login')} </h3>
                <input
                  className="form-control item"
                  type="text"
                  name="loginUsername"
                  placeholder={t("E-mail Address")}
                  value={loginUsername}
                  maxLength='50'
                  onChange={handleChange}
                  required
                />
                <input
                  className="form-control item"
                  type="password"
                  value={loginPassword}
                  name="loginPassword"
                  placeholder={t("Password")}
                  maxLength='30'
                  onChange={handleChange}
                  required
                />
                

                <div className="forgot-password">
                 <Link to="/forgot-password" className="active">{t('Forgot password?')}</Link>
                  </div>

             
                <div className="form-button">
                  <button id="submit" type="submit" className="btn btn-primary btn-col btn-login">
                  {t('Login')}
                  </button>
                  
                    {error ? ( 
                      <div>
                        <p className="login-error" style={{ color: "red" }}>
                         {t('Error')}: {error==='Invalid credentials'? t('Invalid credentials') : error==='There was some error in login'? t('There was some error in login') : error  }  <br />
                          {t('Please try again!')}
                        </p>{" "}
                      </div>
                    ) : null}

                    
                 
                </div>
              </form>

             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
