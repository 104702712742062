import React,{useState,useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function PaymentConfig(props) {

    const [configData, setConfigData]= useState([
      {
        status: false,
        name:"Registration",
        amount: "5",
        date: new Date().toISOString().split('T')[0]
      },
      {
        status: false,
        name:"Consultation",
        amount:"10",
        doctorName:"",
        doctorId:"",
        desc:"",
        spec:"",
        date: new Date().toISOString()
      },
      // {
      //   status: false,
      //   name:"Other",
      //   amount:"",
      //   date: new Date().toISOString().split('T')[0]
      // },
      // {
      //   status: false,
      //   name:"Procedure",
      //   id:"",
      //   procedureName:"",
      //   type:"",
      //   amount:"",
      //   date: new Date().toISOString().split('T')[0]
      // },
        
    ])

    const [tempData, setTempData]= useState({
      status: false,
      name:"Procedure",
      id:"",
      procedureName:"",
      type:"",
      amount:"",
      date: new Date().toISOString().split('T')[0]
    })

    const [displayDoctor, setDisplayDoctor]= useState(false)
    const [searchAssign, setSearchAssign]= useState("")
    const [assignList, setAssignList]= useState([])
    const [display,setDisplay]=useState(false);
    const [searchProcedure, setSearchProcedure]= useState('')
    const [procedureSuggest, setProcedureSuggest]=useState([])
    const [refresh,setRefresh]= useState(false)
    const [repeatShow, setRepeatShow]= useState(false)


    useEffect(() =>{
      const data = props.config
      if(data.length > 0){
        
        const checkReg = data.some(item => {
          return item.name === "Registration"
        })

        const checkConsult = data.some(item => {
          return item.name === "Consultation"
        })

        if(checkReg === false){
          data.unshift({
            status: false,
            name:"Registration",
            amount: "5",
            date: new Date().toISOString().split('T')[0]
          })
        }

        if(checkConsult === false){
          data.splice(1,0, {
            status: false,
            name:"Consultation",
            amount:"10",
            doctorName:"",
            doctorId:"",
            desc:"",
            spec:"",
            date: new Date().toISOString()
          })
        }

        setConfigData(data)
      }

    },[])

    useEffect(()=>{

        const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')  
                
          
          
        
          axios.get(`${APIURL}/api/v1/hospital/procedure-assignee/?q=${searchAssign}`, { headers: {
            
            "Authorization" : 'Token '+str  }})
          .then(res=>{
              
            if(res.data.status==="success"){
            
            setAssignList(res.data.data);
      
            }
      
      
          })
          .catch(err=>{
      
      })
      
      
      },[searchAssign]);


      useEffect(()=>{
        const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')  
                
          
          
        
          axios.get(`${APIURL}/api/v1/hospital/hospital-procedure-config/?q=${searchProcedure}`, { headers: {
            
            "Authorization" : 'Token '+str  }})
          .then(res=>{
              
            if(res.data.status==="success"){
            
            setProcedureSuggest(res.data.hospital_procedures);
      
            }
      
      
          })
          .catch(err=>{
      
      })
      
      
      },[searchProcedure]);

      const ErrorPopup =(props)=>{
          
      
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}>This Procedure has been selected already !!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }


    const handleChange = (e) => {
        const key = e.target.name

        const list = configData

        const data =tempData

        if(key === 'register'){
          list[0].status = !list[0].status
          setConfigData(list)
          setRefresh(!refresh)
        }

        if(key === 'consult'){
          list[1].status = !list[1].status
          setConfigData(list)
          setRefresh(!refresh)
        }

        // if(key === 'other'){
        //   list[2].status = !list[2].status
        //   setConfigData(list)
        //   setRefresh(!refresh)
        // }

        if(key === 'procedure'){
          data.status = !data.status
          setTempData(data)
          setRefresh(!refresh)
        }

       

        // setConfigData((current) => ({
        //     ...current, [key]: !(configData[key])
        // }))
    }

    const handleAdd =() =>{

        props.dataHandle(configData)
        props.modalClose()
    }

    const handleAddProcedure = () => {

      const list =configData
      const data =tempData

      const filtered = configData.filter(item => {
        return item.name==="Procedure"
      })

      const checkRedundancy = filtered.some(item =>{
        return item.procedureName === data.procedureName
      })

      if(checkRedundancy){
        setRepeatShow(true)
        setTempData({
          status: true,
        name:"Procedure",
        id:"",
        procedureName:"",
        type:"",
        amount:"",
        date: new Date().toISOString().split('T')[0]
        })
      }
      else{

        list.push(data)
        setConfigData(list)
        setRefresh(!refresh)
        setTempData({
          status: true,
        name:"Procedure",
        id:"",
        procedureName:"",
        type:"",
        amount:"",
        date: new Date().toISOString().split('T')[0]
        })

      }


    }

    const handleDelete = (index) => {
      index = index + 2
      //setCount(count - 1)
      let list =configData
      const filterDelete = list.filter((item,pos)=> {
        return pos !== index
      })

      list = filterDelete

      setConfigData(list)

    }

    const procedureFilter = configData.filter(item => {
      return item.name === 'Procedure'
    })

    const displayProcedures = procedureFilter.length > 0 ? procedureFilter.map((item,index) =>{
      if(item.procedureName !== ""){
        return (<div key={index} className="comorbidity-row comorb-item mr-0">
              <h6>{item.procedureName} </h6>
              <button onClick={()=>handleDelete(index)} type="button" className="btn btn-danger btn-smallest" > X </button> 
        </div>
         )
      }
      else {
        return null
      }

}) :<h6 className='text-muted font-weight-bold'>Please add procedures</h6>


    return (<>
    <h3 className='title-of-tasks'>Payment</h3>
    <div className='option-display'>
        <div className='payment-options'>

        <div style={{gridColumn:"1/4"}} className='flex-row align-items-center consult-grid'>
            <div>
                <input type='checkbox' value='procedure' id='procedure' name='procedure' onChange={handleChange} />
                <label className='mb-0' htmlFor='procedure'>Procedure</label>

                <div className='d-flex flex-column align-self-center'>
            <h6>Select Procedure from list</h6>
            <div className='d-flex'>

            <input
             disabled={tempData.status===false}
              value= {tempData.procedureName}
              className="form-control consult-doctors" 
              style={{width:"70%"}}       
              name="procedure"
              onClick={() => {
                setSearchProcedure("");
                setDisplay(true)
              }}
      
              onChange={(e)=>{
      
                 e.preventDefault();
                setDisplay(true);
      
        setSearchProcedure(e.target.value);
        const data = tempData
        data.procedureName = e.target.value
        setTempData(data)
        // const list = configData
        // list[count].procedureName = e.target.value
        // setConfigData(list)

         } }
           autoComplete="off"
                     
            />

            <button disabled={tempData.type===""} onClick={handleAddProcedure} className='btn btn-primary btn-col btn-square'><i className="fas fa-arrow-right"></i></button>

            </div>

      
             
      
               
              {display && (<div style={{width:"88%"}} className="suggestions-box">
                   {procedureSuggest.map((item,i)=>{
               
          return(
            <div className="scan-item" 
                
                  key={item.id} 
                  onClick={(e)=>{ e.preventDefault();
                                const data = tempData
                                data.id = item.id
                                data.procedureName= item.procedure_name
                                data.type = item.procedure_type
                                data.amount = item.amount
                                setTempData(data)
                                setDisplay(false);

                                //  const list = configData
                                //  list[count].id = item.id
                                //  list[count].procedureName = item.procedure_name
                                //  list[count].type = item.procedure_type
                                //  list[count].amount = item.amount
                                //  setConfigData(list)
                                //  setDisplay(false);
      
                                            }}
                  >
            <div className='search-procedure'>
            <p className="search-item">{item.procedure_name}</p>
            <p className="search-item">{item.procedure_type}</p>
            </div>
            </div>
            )
        }) 
                 }
      
              </div>)
            }
            
      
            </div>

            </div>

            <div>
              { displayProcedures }
            </div>

        </div>
  <div className='consult-register-contain'>
        <div className='flex-row align-items-center consult-payment-flex mb-4'>
                <div>
                    <input type='checkbox' checked={configData[1].status} value='consultation' id='consult' name='consult' onChange={handleChange} />
                    <label className='mb-0' htmlFor='consult'>Consultation</label>
                </div>
       

                <div className='d-flex flex-column align-self-center'>
            <h6>Select Doctor from list</h6>
            <input
            disabled={configData[1].status===false}
               onClick={() => {
                 setSearchAssign("");
                 setDisplayDoctor(true)
               }}
               value= {configData[1].doctorName}
               className="form-control consult-doctors"        
              name="assignee"
              
               onChange={(e)=>{
       
                 e.preventDefault();
       
                 const list = configData
                 list[1].doctorName = e.target.value
                 setConfigData(list)
                 
                // setDoctorName(e.target.value)
                setDisplayDoctor(true);
       
         setSearchAssign(e.target.value);
       
         
          } }
          
                      
             />
       
               { displayDoctor && (<div className="suggestions-box-holder">
                  <button className="btn-suggestion btn btn-secondary btn-smallest" title="close" onClick={()=>setDisplayDoctor(false)}> X </button>
       
                   <div className="suggestions-box suggestions-assignee-box">
                   
       
                    {assignList.map((item,i)=>{
                
           return(
             <div className="scan-item" 
                 
                   key={item.id} 
                   onClick={(e)=>{ e.preventDefault();

                      const list = configData
                      list[1].doctorName = item.name
                      list[1].doctorId = item.id
                      list[1].desc = item.department_speciality.department
                      list[1].spec = item.department_speciality.speciality

                      setConfigData(list)

                    //  setDoctorId(item.id)
                    //  setDoctorName(item.name)
                     setDisplayDoctor(false);
       
                                             }}
                   >
                   <div className="search-item each-option-suggestion">
             <h6 className="text-center">ID: {item.id} </h6>
             <h6 className="text-center"> {item.name}</h6>
              </div>
       
             </div>
             )
         }) 
                  }
       
               </div>
                </div>
               )
             }

            </div>
            
            </div>


            <div>
                <input type='checkbox' checked={configData[0].status} value='registration' id='register' name='register' onChange={handleChange} />
                <label className='mb-0' htmlFor='register'>Registration</label>
            </div>
          </div>
            {/* <div>
                <input type='checkbox' value='other' id='other' name='other' onChange={handleChange} />
                <label className='mb-0' htmlFor='other'>Other</label>
            </div> */}

        </div>

        <br/>
        <p className='text-center'>
          <button onClick={handleAdd} className='btn btn-primary btn-col'>Add</button>
        </p>
    </div>

        


        {repeatShow ?
    <ErrorPopup
        show={repeatShow}
        onHide={() =>  setRepeatShow(false)}
       
      />: ''
    }

        </>
    )
}

export default PaymentConfig
