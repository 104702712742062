import React, {useState, useEffect} from 'react'
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import {useHistory} from 'react-router-dom'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import './emergencycalllog.css'
import axios from "axios";
import {APIURL} from "../../Global";

function HospEmergencyCallLog() {

  const [logData, setLogData]= useState([])
  const [load, setLoad]= useState('')

    const history =useHistory();

const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'h': false});


useEffect(()=>{

window.onkeydown =(e) => {
 
 keys[e.key]=true

 if(e.altKey && keys['d']){
   history.push('/doctors-list')
 }
 if(e.altKey && keys['a']){
   history.push('/hospital/add')
 }
 if(e.altKey && keys['b']){
   history.push('/bdo-list')
 }
 if(e.altKey && keys['o']){
   history.push('/hospital/add-bdo')
 }
 if(e.altKey && keys['n']){
   history.push('/emergency-number')
 }
 if(e.altKey && keys['s']){
   history.push('/service-request-list')
 }
 if(e.altKey && keys['l']){
   history.push('/license-details')
 }
 if(e.altKey && keys['p']){
   history.push('/license-payment')
 }
 if(e.altKey && keys['h']){
   history.push('/payment-history')
 }

}
window.onkeyup=(ev)=> {
 
 keys[ev.key]= false
}

return()=>{
 window.onkeydown=null
 window.onkeyup=null
}
},[]);

    const handleBack=(e)=>{
        history.goBack();
      }

      useEffect(()=> {
        setLoad('Loading Data...')
        const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;

   axios.get(`${APIURL}/api/v1/patient/emergency_call_log/`, { headers: {
      
    "Authorization" : 'Token '+str  }})
  .then(res=>{
   

 
    if(res.data.status==="success"){

        setLoad('')
        let log = res.data.call_log
        const updatedLog = log.sort((a,b)=> {
          return new Date(b.date_time) - new Date(a.date_time)
        })
        setLogData(updatedLog)
           
    }

      else{
        setLoad('Error in Fetching Data')
    }
    


  })
  .catch(err=>{
    setLoad('Error in Fetching Data')
    })
},[])

      const renderTable= (cellInfo)=> {
          return(
              <div>{logData[cellInfo.index][cellInfo.column.id]}</div>
          )
      }
      const renderDate =(cellInfo)=> {
        let dateString = logData[cellInfo.index][cellInfo.column.id]
        let dateObject = new Date(dateString);

const updatedDate = dateObject.toString();
        return(
          <div >
            <h6> {updatedDate.slice(4,16)} </h6>
            <h6>{updatedDate.slice(16,24)}</h6>
           </div>
        )
      }

      const columns = [
        {
          Header: "Date",
          accessor: "date_time",
          Cell: renderDate,
           style:{
            backgroundColor:"rgba(0,0,0,0.1)"
          },
           sortable:true,
            filterable:true,
           width:170
        
        },
        
        {
          Header: "Patient Name",
          accessor: "patient_name",
          Cell: renderTable,
           sortable:true,
            filterable:true,
           width:200
        
        },
        {
          Header: "Mobile Number",
          accessor: "patient_mob",
          Cell: renderTable,
          style:{
            backgroundColor:"rgba(0,0,0,0.1)"
          },
           sortable:true,
            filterable:true,
           width:200
        
        }]
const display = <ReactTable
              columns={columns}
              data={logData}
              defaultPageSize = {10}
              className='calllog-table'
              resizable
              ></ReactTable>
    return (
        <>
        <HospitalNavbar /><br/>
        <div className=" text-left">
        
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 

    <div className="log-div">
     <h2 className="title-of-page"> Emergency Call Log</h2><br/>
      <div className="text-center">
        <div className="service-table-holder flex-col"> {load===''?display: load==='Error in Fetching Data'? <h6 style={{color: "red"}}>{load}</h6> : <h6 style={{color: "grey"}}>{load}</h6>}
        </div>
    
     
    </div>
    </div>
        </>
    )
}

export default HospEmergencyCallLog
