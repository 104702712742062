
import React from "react";
import { useHistory } from "react-router-dom";
import "./navbar.css";
import axios from 'axios';
import {APIURL} from "../../Global";

function CallLogout(){
	
	const history=useHistory();
	const handleLogout=()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
   
  axios.get(`${APIURL}/api/v1/account/logout/`,{ headers: {
                
                "Authorization" : 'Token '+str,
                'Content-Type': 'application/json'  }


              })



              .then(res=>{
         
                if(res.data.status==="success"){
                
               
               
            }
            else{
              
             
            }

              })
              .catch(err=>{
         
            
          })
              history.push('/login')

               

}
	return (<>
		
		<button className="btn btn-primary btn-col" onClick={handleLogout}>Logout</button>
		</>)
}



export default CallLogout;