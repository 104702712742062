import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import "./generateinvoice.css";

function Invoice(props) {
	 const [,setFreeCount]= useState('')
    const [currency, setCurrency]= useState('')
    const [price, setPrice]= useState('')
    const [tax, setTax]= useState('')
    const [,setPeriod]= useState(null)
    const [,setMsg]= useState('')
    const [amount,]=useState(props.amount)
    const [tenure,]=useState(props.tenure)
    const [usersCount,]=useState(props.userCount)
   //const [hospData1,setHospData1] =useContext(HospLoginContext);
   
   const [hospData,] =useState(JSON.parse(sessionStorage.getItem('hosp_data'))? JSON.parse(sessionStorage.getItem('hosp_data')) : null);
    const Hospital_name= hospData? hospData.name : null;
    //const Hospital_specializations = hospData? hospData.specializations : null;
   
   const location = hospData ? hospData.location : null;
	 const handlePrint=()=>{
		window.print();
	}
	 useEffect(()=>{

        const tokenString= localStorage.getItem('usertoken');
       
       let str= tokenString.replace(/["]+/g, '')  ;
    
    
      
        axios.get(`${APIURL}/api/v1/hospital/hospital-licenses-price/`, { headers: {
          
          "Authorization" : 'Token '+str  }})
        .then(res=>{
         
    
       
       
          if(res.data.status==="success"){ 

            const data =res.data.data
            
            if(res.data.message==='Hospital User Pricing Details Not Yet Added'){
                setMsg(res.data.message)
            }
            else{     
                if(data.cooling_period_till!==null){
                    let date = data.cooling_period_till.slice(0,10)
                    setPeriod(date)
                 }

                setFreeCount(data.free_user_count)
                setPrice(data.one_month_user_price)
                setCurrency(data.currency.toUpperCase())
                setTax(data.tax_percentage)
                
            }

          }
            else{
    
          }
          
    
    
        })
        .catch(err=>{
    
    })

},[]);
 return (<div>
    
 	<h2 style={{textAlign:'center'}} className="title-of-page"> Invoice  </h2><br/>
 	<div className="invoice-table flex-col to-print ">
 	 <div className="invoice-row1 flex-row">
    <div className="flex-col center-flex-content">
         <div className="flex-col ">
            <h3 className="invoice-heading caps">FelixaCare</h3>
           <h6 className="invoice-heading caps">felixacare@IntPurple.com</h6>
         <h6 className="invoice-heading caps"> +91 75610 57363</h6>
         </div>
      </div>
      
     </div>
     <div className="invoice-row2">
     
        <h5 className="grey-title caps">{Hospital_name}</h5>
         <h6 className="grey-title">{location}</h6>

     </div>
     <div className="invoice-row3">
        <table >
        <tr>
          <th> Particulars </th>
       
          <th> Users count </th>

          <th> Monthly Price </th>
          <th> Currency </th>
          <th> Tenure </th>
           <th> Tax </th>
          <th> Amount </th>


        </tr>

        <tr>
          <td> 
             <span className="caps">Purchase license </span><br/>
            

           </td>
           <td> {usersCount}</td>
           
          <td> {price}</td>
           <td> {currency}</td>
          <td> {tenure}</td>
          <td>{tax? tax : 0} % </td>
           <td>{amount} </td>
           
        </tr>

        </table>
     </div>
     <div className="invoice-row4">
        <h6 className="text-right">Total : <span className="caps-full">{currency} </span>{amount} </h6>
         <br/>
         <h6 className="text-muted text-right">Thanks,</h6>
         <h6 className="text-muted text-right">FelixaCare</h6>
          

     </div>
 	</div>
 	<br/>
 	<br/>
 	{/**<div className="to-print">
 	 <a href="https://www.felixacare.com" target="_blank"><h5 className="felixa-site" > www.felixacare.com</h5></a>

    </div>**/}
 	
 	<div className="do-not-print text-center" id="pr">
		         <button className="btn btn-primary btn-col btn-square" onClick={handlePrint}> Print </button>
		         <br/>
		         <br/>
		         <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                  <br/>
                  <br/>
           </div>

  </div>
 	)

	 }

export default Invoice;