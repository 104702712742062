import React,{useState,useRef,useEffect} from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
//import "./hosppatientrecord.css";
import { Link,useHistory} from "react-router-dom";
import DatePicker from 'react-date-picker';
import axios from "axios";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {Tooltip} from 'react-bootstrap';
import {OverlayTrigger} from 'react-bootstrap';
//import "./reacttable.css";
import moment from "moment";
import VitalsBP from "../VitalsBP/VitalsBP";
import VitalsPulse from "../VitalsPulse/VitalsPulse";
import DrNotes from "../DrNotes/DrNotes";

//import Observations from "../Observations/Observations";
import {APIURL} from "../../Global";

import AddRemark from '../ServiceRequests/AddRemark';  

import { Suspense, lazy } from 'react';
import EditRemark from '../HospDr_AddPrescription/EditRemark';
import ViewRemark from '../HospDr_AddPrescription/ViewRemark';
import AddObs from './Observations/AddObs';
import ImageUpdate from '../HospPatientRecord/ImageUpdate';
import HospDocNavbar from '../HospDocNavbar/HospDocNavbar';
import CurrentMed from './Medications/CurrentMed';
import AddPulse from './Vitals/AddPulse';
import AddBp from './Vitals/AddBp';
import ViewSymptoms from './Symptoms/ViewSymptoms';
import AddSymptoms from './Symptoms/AddSymptoms';
import ProcedureHistory from './ProcedureHistory/ProcedureHistory';
import PastMedications from '../HospDr_AddPrescription/PastMedications';
import AddDetails from './AddDetails';

const CurrentMedications = React.lazy(()=> import('../HospDr_AddPrescription/CurrentMedications'));
const ViewObs = React.lazy(()=> import('./Observations/ViewObs'))
const Symptoms =React.lazy(()=> import('../Symptoms/Symptoms'))
const Diagnosis = React.lazy(()=> import('./Diagnosis'))
const Allergies = React.lazy(()=> import('./Allergies'))


function PatientMedicalDetails(props){

 const [refresh,setRefresh]= useState(false)
  const  ref1=useRef();
  const  ref2=useRef();
  const  ref3=useRef();
  const  ref4=useRef();
  const  ref5=useRef();
  const  ref6=useRef();
  const  ref7=useRef();

  const [instructions,setInstructions] = useState();
   const [instructionsDate,setInstructionsDate] = useState();

    const [,setInstructionsArray] = useState([]);
  const [next_visit,setNext_visit] = useState(null);
  const [next,setNext] = useState();

  const [testsList,setTestsList]=useState([{ id:"",test : "", date: new Date().toISOString(), labtest_reason:{details:"",image: "",reason:""}}]);
  const [scanList,setScanList]=useState([{id:"",test: "", date:new Date().toISOString(), scan_details:{details:"",image: "",reason:""}}]);
  //const [procedureList,setProcedureList]=useState([{ id:"", procedure:{ name:"", price:"", type:"" }, date_time: new Date().toISOString().split('T')[0], area:"", session:"", quantity:"", details:"", reason:"", image:"",assign:"", assign_id:"", status:"" }]);
  const [procedureList,setProcedureList]=useState([{ 
    procedure_details: {
      procedure_name : "",
      procedure_id : "",
      procedure_type : "",
      amount : "",
    },
    date_of_procedure: new Date().toISOString(),
    area : "",
    session : "",
    quantity : "",
    details : "",
    reason : "",
    image : "",
    assign_name : "",
    status : "",
   }]);
  const [procedureSuggest, setProcedureSuggest]=useState()
  const [procedureToggle, setProcedureToggle]= useState(false)
  const [procedureReasonShow, setProcedureReasonShow]= useState(false)
  const [imageEditShow, setImageEditShow]= useState(false)
  const [imageViewShow, setImageViewShow]= useState(false)
  const [errorShow, setErrorShow]= useState(false)
  const [procedureRefresh, setProcedureRefresh]= useState(false)
  const [date,setDate]= useState()

  const [popupShow,setPopupShow]=useState(false)
  const [imgEditShow,setImgEditShow]= useState(false)
  const [reasonAddShow,setReasonAddShow]= useState(false)
  const [flag,setFlag]=useState(false)
  const [reasonEditShow,setReasonEditShow]= useState(false)
  const [index, setIndex]=useState()
  const [editShow, setEditShow]= useState(false);
  const [addShow, setAddShow]= useState(false);
  const [viewShow,setViewShow]= useState(false);
  const [remarkViewShow, setRemarkViewShow]= useState(false);
  const [detailData, setDetailData]= useState('')
  const [cell, setCell]= useState('')
  const [editSuccess,setEditSuccess]= useState(false)
  const [sizeShow ,setSizeShow] =useState(false)
  const [validShow ,setValidShow] =useState(false)
  const [contentShow ,setContentShow] =useState(false)
  const [uploadShow ,setUploadShow] =useState(false)
  const [tableRefresh,setTableRefresh]= useState(false)

   const [submitMsg,setSubmitMsg] = useState("");
   const [modalShow,setModalShow]=useState(false);
  const [deleteNextMsg,setDeleteNextMsg] = useState("");
   const [deleteNextModalShow,setDeleteNextModalShow]=useState(false);

 const [patientData,setPatientData] = useState({
   name:'',
   email:'',
   mobile_number:'',
   gender:'',
   dob:'',
   file_number:'',
   country:'',
   region:'',
   present_complaint:'',
   past_medical_history:'',
   family_history:'',
   cpr:"",
 });
 const [,setDisplaySuggestions] = useState(false);
 const [options,setOptions] = useState([]);
 const [display,setDisplay]=useState(false);
 const [search,setSearch]=useState("");
 const [searchScan,setSearchScan]=useState("");
 const [scanSuggest ,setScanSuggest]= useState();
 const [currentIndex,setCurrentIndex]=useState('');
 const [,setCurrentColumn] = useState('');
const [testListCounter,setTestListCounter]=useState(0);
const [scanListCounter,setScanListCounter]=useState(0);
const [procedureListCounter,setProcedureListCounter]=useState(0);
const [searchProcedure, setSearchProcedure]= useState('')
const [searchAssign, setSearchAssign]= useState("")
const [assignList, setAssignList]= useState([])

const [deleteProcedureConfirmMsg,setDeleteProcedureConfirmMsg]=useState("");
const [deleteProcedureConfirmModalShow,setDeleteProcedureConfirmModalShow]=useState(false);
const [procedureIndexToDelete,setProcedureIndexToDelete] = useState();

const wrapperRef=useRef(null);

const [render,setRender] =useState(false);
 const [reRender,setReRender] =useState(false);
const [,setDateVal]= useState("");
const [deleteScanConfirmMsg,setDeleteScanConfirmMsg]=useState("");
const [deleteScanConfirmModalShow,setDeleteScanConfirmModalShow]=useState(false);
const [deleteNextVisitConfirmMsg,setDeleteNextVisitConfirmMsg]=useState("");
const [deleteNextVisitConfirmModalShow,setDeleteNextVisitConfirmModalShow]=useState(false);

const [scanIndexToDelete,setScanIndexToDelete] = useState();
const [deleteTestConfirmMsg,setDeleteTestConfirmMsg]=useState("");
const [deleteTestConfirmModalShow,setDeleteTestConfirmModalShow]=useState(false);
const [testIndexToDelete,setTestIndexToDelete] = useState();
const [showTestTable,setShowTestTable] = useState(false);
const [showScanTable,setShowScanTable] = useState(false);
const [showProcedureTable,setShowProcedureTable] = useState(false);
const [testErr,setTestErr]=useState("nil");
const [scanErr,setScanErr]=useState("nil");
const [procedureErr,setProcedureErr]=useState("");

// const [updatedTestList,setUpdatedTestList]=useState([]);
// const [updatedScanList,setUpdatedScanList]=useState([]);
const [updatedProcedureList,setUpdatedProcedureList]=useState([]);

// const [submitTrigger,setSubmitTrigger]=useState(true);
const [errorModalShow,setErrorModalShow]=useState(false);
const [errorScanShow,setErrorScanShow]=useState(false);
const [errorSubmit,setErrorSubmit]=useState(false);
const [bpShow,setBpShow]=useState(false);
const [pulseShow,setPulseShow]=useState(false);

 const [addPrescShow,setAddPrescShow]=useState(false);
 const [drNotesShow,setDrNotesShow]=useState(false);
 const [observationsShow,setObservationsShow]=useState(false);


const [errorNextModalShow,setErrorNextModalShow]=useState(false);
const [nextErr,setNextErr]=useState("nil");
 const [submitInstructionsMsg,setSubmitInstructionsMsg] = useState("");
 const [modalInstructionsShow,setModalInstructionsShow]=useState(false);
 const [refreshInstr,setRefreshInstr]=useState(false);
 const [addPulse, setAddPulse]= useState(false)
 const [addBp, setAddBp]= useState(false)
 const [observationsArray,setObservationsArray]=useState([]);
 const [symptoms, setSymptoms]= useState([])
 const [symptomsShow, setSymptomsShow]= useState(false)
 const [name,setName] = useState();
 const [gender,setGender] = useState();
 const [historyShow, setHistoryShow]= useState(false)
 const [doctorId, setDoctorId]= useState('')
 const [doctorName, setDoctorName]= useState('')
 const [displayDoctor, setDisplayDoctor]= useState(false)
 const [displayScan, setDisplayScan]= useState(false)
 const [displayTest, setDisplayTest]= useState(false)
 const [displayAssign, setDisplayAssign]= useState(false)
 const [timeZone,]= useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
 const [pastMedicationShow, setPastMedicationShow]= useState(false)
 const [historyEditShow, setHistoryEditShow]= useState(false)
 const [medEditShow, setMedEditShow]= useState(false)
 const [complaintEditShow, setComplaintEditShow]= useState(false)

 const [historyAddShow, setHistoryAddShow]= useState(false)
 const [medAddShow, setMedAddShow]= useState(false)
 const [complaintAddShow, setComplaintAddShow]= useState(false)
 const [editDetailSuccess ,setEditDetailSuccess]= useState(false)


//const freq_link=`analysis/${id}`;
let history=useHistory();

let formData =new FormData();
const reader = new FileReader();

const [id,] = useState(props.match.params.id);
const hospId = props.location.hospId 

useEffect(()=> {
  if(hospId !== undefined){
    sessionStorage.setItem('hospId',hospId)
  }
},[])

const [keys,] = useState({'p': false,'a': false,'n': false,'m': false,'l': false,'s': false,});

    

  useEffect(()=>{
  
    window.onkeydown =(e) => {
      
      keys[e.key]=true
  
      if(e.altKey && keys['p']){
        history.push('/patients-directory')
      }
      if(e.altKey && keys['a']){
        history.push('/appointment')
      }
      if(e.altKey && keys['n']){
        history.push('/doctor/notifications')
      }
      if(e.altKey && keys['m']){
        history.push('/message')
      }
      if(e.altKey && keys['l']){
        history.push('/doctor/analytics')
      }
      if(e.altKey && keys['s']){
        history.push('/service-requestlist')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      keys[ev.key]= false
    }
  
    return()=>{
      window.onkeydown=null
      window.onkeyup=null
    }
  },[]);


  useEffect(()=> {

    const tokenString= localStorage.getItem('usertoken');
   
    let str= tokenString.replace(/["]+/g, '')  
           
     
     
   
     axios.get(`${APIURL}/api/v1/hospital/add-hospital-userdetails/?patient_id=${id}`, { headers: {
       
       "Authorization" : 'Token '+str  }})
     .then(res=>{
         
       if(res.data.status==="success"){
        
          setPatientData(res.data.patient_details)
          setObservationsArray(res.data.observations)
          setSymptoms(res.data.symptoms)
       }
 
 
     })
     .catch(err=>{
 
 })

  },[refresh])

  const procedureEdit = (data) => {

    const procedures = data

    const tokenString= localStorage.getItem('usertoken');
  
    let str= tokenString.replace(/["]+/g, '');

    const fd = new FormData();

        if(procedures.procedure_details.procedure_id){
          fd.append(`procedure_id`,procedures.procedure_details.procedure_id)
        }
        if(procedures.date_of_procedure){
          fd.append(`date_of_procedure`,procedures.date_of_procedure.split('T').join(" ").slice(0,16))
        }
        if(procedures.assign_id){
          fd.append(`assign_id`,procedures.assign_id)
        }
        if(procedures.details){
          fd.append(`details`,procedures.details)
        }  
          
          
          // fd.append(`area`,procedures.area)
          // fd.append(`session`,procedures.session)
          // fd.append(`quantity`,procedures.quantity)
          
          // fd.append(`reason`,procedures.reason)


  axios.put(`${APIURL}/api/v1/hospital/patient-hospital-procedures/`,fd, { headers: {
               
               "Authorization" : 'Token '+str,
               'Content-Type': 'application/json'  }

             })

             .then(res=>{
             
               if(res.data.status==="success"){
               setSubmitMsg("success");
               setModalShow(true);
 
           }
           else{
             setSubmitMsg("error");
             setErrorSubmit(true);
            
           }

             })
             .catch(err=>{
           
           setSubmitMsg("error");
           setErrorSubmit(true);
         })
  }

  const procedureSubmit = async(procedures) => {

      const tokenString= localStorage.getItem('usertoken');
  
    let str= tokenString.replace(/["]+/g, '');

    const fd = new FormData();

    if(procedures.length < 1){
      return false
    }
    
      for(let i=0 ; i<procedures.length; i++){

        if(procedures[i].image instanceof File === false && procedures[i].assign_id !== undefined && procedures[i].status !== ""){
          procedureEdit(procedures[i]);
          continue
        }

        if(procedures[i].image instanceof File === true || procedures[i].image === "" && procedures[i].assign_id !== undefined){
          fd.append(`procedure[${i}]procedure_id`,procedures[i].procedure_details.procedure_id)
          fd.append(`procedure[${i}]date_of_procedure`,procedures[i].date_of_procedure.split('T').join(" ").slice(0,16))
          fd.append(`procedure[${i}]assign_id`,procedures[i].assign_id)
          fd.append(`procedure[${i}]area`,procedures[i].area)
          fd.append(`procedure[${i}]session`,procedures[i].session)
          fd.append(`procedure[${i}]quantity`,procedures[i].quantity)
          fd.append(`procedure[${i}]image`,procedures[i].image)
          fd.append(`procedure[${i}]details`,procedures[i].details)
          fd.append(`procedure[${i}]reason`,procedures[i].reason)
        }
        else{
          if(i < procedures.length-1){
            continue
          }
          else{
            return false
          }
      }
        
  }

  if(fd.entries().next().done){
    return false
  }


  axios.post(`${APIURL}/api/v1/hospital/patient-hospital-procedures/?patient_id=${id}&timeZone=${timeZone}`,fd, { headers: {
               
               "Authorization" : 'Token '+str,
               'Content-Type': 'application/json'  }

             })

             .then(res=>{
             
               if(res.data.status==="success"){
               setSubmitMsg("success");
               setModalShow(true);
               //document.getElementsByClassName('scan-react-table').scrollTo(0,0)
 
           }
           else{
             setSubmitMsg("error");
             setErrorSubmit(true);
            
           }

             })
             .catch(err=>{
           
           setSubmitMsg("error");
           setErrorSubmit(true);
         })
  }

// useEffect(()=>{

//   const tokenString= localStorage.getItem('usertoken');
  
//   let str= tokenString.replace(/["]+/g, '');
  
// if(testErr=== "nil" && scanErr=== "nil"){
//  const scans =updatedScanList
//  const tests= updatedTestList
//  //const procedures= updatedProcedureList

//   for(let i=0 ; i<scans.length; i++){
//     if(scans[i].scan_details.image instanceof File === true || scans[i].scan_details.image===''){
//       formData.append(`scans[${i}]test`,scans[i].test)
//       formData.append(`scans[${i}]date`,scans[i].date)
//       formData.append(`scans[${i}]patient_scan_details`,scans[i].scan_details.details)
//       formData.append(`scans[${i}]patient_scan_reason`,scans[i].scan_details.reason)
//       formData.append(`scans[${i}]patient_scan_details_image`,scans[i].scan_details.image)
//     }    
//   }
//   for(let i=0 ; i<tests.length; i++){
//     if(tests[i].labtest_reason.image instanceof File === true || tests[i].labtest_reason.image===''){
//     formData.append(`tests[${i}]test`,tests[i].test)
//     formData.append(`tests[${i}]date`,tests[i].date)
//     formData.append(`tests[${i}]patient_lab_test_details`,tests[i].labtest_reason.details)
//     formData.append(`tests[${i}]patient_lab_test_details_image`,tests[i].labtest_reason.image)
//     formData.append(`tests[${i}]patient_lab_test_reason`,tests[i].labtest_reason.reason)
//     }
//   }

//   formData.append('patient_id',id)
//   if(next_visit !== null){
//     formData.append('next_visit',next_visit)
//   }
  
//  // formData.append('quick_summary',summary)

           
//              axios.post(`${APIURL}/api/v1/doctor/patient-record/`,formData, { headers: {
               
//                "Authorization" : 'Token '+str,
//                'Content-Type': 'application/json'  }


//              })



//              .then(res=>{
             
//                if(res.data.status==="success"){

//                     //procedureSubmit()
               
//                 setSubmitMsg("success");
//                 setModalShow(true);

//            }
//            else{
//              setSubmitMsg("error");
//              setErrorSubmit(true);
            
//            }

//              })
//              .catch(err=>{
//            setSubmitMsg("error");
//            setErrorSubmit(true);
//          })
             
//     }

// },[testErr!=="nil",scanErr!=="nil", submitTrigger]);


useEffect(()=>{
  const tokenString= localStorage.getItem('usertoken');
 
   let str= tokenString.replace(/["]+/g, '');           
    axios.get(`${APIURL}/api/v1/patient/instructions/?patient_id=${id}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     
      if(res.data.status=== "success"){

       setInstructionsArray(res.data.instructions);

       const arr= res.data.instructions.length>0 ? res.data.instructions[0].instruction : null;
      
      if(arr){
        const instrString= arr.length> 0 ? arr.reduce((accumulator, arr) => {
                return accumulator + arr + "\n" ;
             }, "")
       : null;

      
       setInstructions(instrString);
       setInstructionsDate(res.data.instructions[0].created_at);
      } 
      }
    })
    .catch(err=>{

})



},[id, refreshInstr]);


useEffect(()=>{

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  
          
    
    
  
    axios.get(`${APIURL}/api/v1/doctor/lab-tests/?per_page=20&q=${search}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
      
      setOptions(res.data.lab_tests);

      }


    })
    .catch(err=>{

})


},[search]);


useEffect(()=>{

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  
          
    
    
  
    axios.get(`${APIURL}/api/v1/doctor/scan-search/?q=${searchScan}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
      
      setScanSuggest(res.data.scans);

      }


    })
    .catch(err=>{

})


},[searchScan]);

useEffect(()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  
          
    
    
  
    axios.get(`${APIURL}/api/v1/hospital/hospital-procedure-config/?q=${searchProcedure}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){

        // const procedures = res.data.hospital_procedures
        // procedures.map(item => {
        //   return(

        //   )
        // })
      
      setProcedureSuggest(res.data.hospital_procedures);

      }


    })
    .catch(err=>{

})


},[searchProcedure]);


useEffect(()=>{

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  
          
    
    
  
    axios.get(`${APIURL}/api/v1/hospital/procedure-assignee/?q=${searchAssign}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
      
      setAssignList(res.data.data);

      }


    })
    .catch(err=>{

})


},[searchAssign]);

useEffect(()=> {

  setProcedureList([{ 
    procedure_details: {
      procedure_name : "",
      procedure_id : "",
      procedure_type : "",
      amount : "",
    },
    date_of_procedure: new Date().toISOString(),
    area : "",
    session : "",
    quantity : "",
    details : "",
    reason : "",
    image : "",
    assign_name : "",
    status : "",
   }])

  setProcedureListCounter(0);

  const tokenString= localStorage.getItem('usertoken');
   
  let str= tokenString.replace(/["]+/g, '')  
         
   
   
 
   axios.get(`${APIURL}/api/v1/hospital/patient-hospital-procedures/?patient_id=${id}`, { headers: {
     
     "Authorization" : 'Token '+str  }})
   .then(res=>{
       
     if(res.data.status==="success"){

      if(res.data.data.length>0){
        const data =res.data.data
        const filteredData = data.filter(item => {
          return item.status !== 'completed'
        })

        if(filteredData.length < 1){

          setProcedureList([{ 
            procedure_details: {
              procedure_name : "",
              procedure_id : "",
              procedure_type : "",
              amount : "",
            },
            date_of_procedure: new Date().toISOString(),
            area : "",
            session : "",
            quantity : "",
            details : "",
            reason : "",
            image : "",
            assign_name : "",
            status : "",
           }])

        }
        else {
          setProcedureList(filteredData)
        }
        
        setProcedureListCounter(filteredData.length);
        }

     }


   })
   .catch(err=>{

})

},[procedureRefresh])


const allDatas =async()=> {
  setScanList([{id:"",test: "", date:"", scan_details:{details:"",image: "",reason:""}}]);
  setScanListCounter(0);

  setTestsList([{id:"", test: "",date:"", labtest_reason:{details:"",image: "",reason:""}}]);
    setTestListCounter(0);

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');           
await axios.get(`${APIURL}/api/v1/doctor/patient-record/?patient_id=${id}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     
      if(res.data.status=== "success"){
       
      //setSummary(res.data.quick_summary);
      
if(res.data.scans.length>0){
         setScanList(res.data.scans);
setScanListCounter(res.data.scans.length);
}

if(res.data.tests.length>0){
      setTestsList(res.data.tests);
      setTestListCounter(res.data.tests.length);
   }   

      setNext_visit(res.data.next_visit);
      setGender(res.data.gender);
      setName(res.data.name);
   
     if(res.data.next_visit!== null){
             setNext(new Date(res.data.next_visit));
     }
     else{
      setNext(null);
     }
     
      }


    })
    .catch(err=>{

})
}



useEffect(()=>{
 
 async function execute() {
  await allDatas();
  //await procedureDatas();
  }
  
  execute();

},[reRender]);


// useEffect(()=>{
//   setTestsList(testsList);
// },[testsList]);

useEffect(()=>{
   
  
    setTestsList(testsList);
    setScanList(scanList);
    setProcedureList(procedureList)
    setRender(false);
   
},[render]);


useEffect(()=>{
  return ()=>{
    return (   <>       <ReactTable 
                     columns={columns}
                     data={testsList}
                     defaultPageSize = {4}
                     
                     resizable
                     
                     >
                     </ReactTable>  
</>)
  }
},[testsList]);



const handleClick = (ref) =>{
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
         });

      }

      const handleBpOverlay=(e)=>{
        e.preventDefault();
        setBpShow(true);
     
       }

    const saveNextVisit = () => {

      const fd1 = new FormData();

      const tokenString= localStorage.getItem('usertoken');
  
      let str= tokenString.replace(/["]+/g, '');

      // next_visit.split('T').join(" ").slice(0,16)

      if(next_visit !== null){

        fd1.append('patient_id',id)
        fd1.append('next_visit',next_visit.split('T').join(" ").slice(0,16))

        axios.post(`${APIURL}/api/v1/doctor/patient-record/?doctor_id=${doctorId}&timeZone=${timeZone}`,fd1, { headers: {
                 
          "Authorization" : 'Token '+str,
          'Content-Type': 'application/json'  }


        })



        .then(res=>{
        
          if(res.data.status==="success"){
          
           setSubmitMsg("success");
           setModalShow(true);

      }
      else{
        setSubmitMsg("error");
        setErrorSubmit(true);
       
      }

        })
        .catch(err=>{
      setSubmitMsg("error");
      setErrorSubmit(true);
    })

      }

    }

const submitScans = (scans) => {

  if( scanErr === 'nil'){
    
    const tokenString= localStorage.getItem('usertoken');
  
    let str= tokenString.replace(/["]+/g, '');
  
    for(let i=0 ; i<scans.length; i++){
      if(scans[i].scan_details.image instanceof File === true || scans[i].scan_details.image===''){
        formData.append(`scans[${i}]test`,scans[i].test)
        formData.append(`scans[${i}]date`,scans[i].date.split('T').join(" ").slice(0,16))
        formData.append(`scans[${i}]patient_scan_details`,scans[i].scan_details.details)
        formData.append(`scans[${i}]patient_scan_reason`,scans[i].scan_details.reason)
        formData.append(`scans[${i}]patient_scan_details_image`,scans[i].scan_details.image)
      }    
    }
  
    formData.append('patient_id',id)
    
   // formData.append('quick_summary',summary)
  
             
               axios.post(`${APIURL}/api/v1/doctor/patient-record/?timeZone=${timeZone}`,formData, { headers: {
                 
                 "Authorization" : 'Token '+str,
                 'Content-Type': 'application/json'  }
  
  
               })
  
  
  
               .then(res=>{
               
                 if(res.data.status==="success"){
                 
                  setSubmitMsg("success");
                  setModalShow(true);
  
             }
             else{
               setSubmitMsg("error");
               setErrorSubmit(true);
              
             }
  
               })
               .catch(err=>{
             setSubmitMsg("error");
             setErrorSubmit(true);
           })

  }

  

}

const submitTests = (tests) => {

  if( testErr === 'nil'){

    const tokenString= localStorage.getItem('usertoken');
  
  let str= tokenString.replace(/["]+/g, '');

  for(let i=0 ; i<tests.length; i++){
    if(tests[i].labtest_reason.image instanceof File === true || tests[i].labtest_reason.image===''){
    formData.append(`tests[${i}]test`,tests[i].test)
    formData.append(`tests[${i}]date`,tests[i].date.split('T').join(" ").slice(0,16))
    formData.append(`tests[${i}]patient_lab_test_details`,tests[i].labtest_reason.details)
    formData.append(`tests[${i}]patient_lab_test_details_image`,tests[i].labtest_reason.image)
    formData.append(`tests[${i}]patient_lab_test_reason`,tests[i].labtest_reason.reason)
    }
  }

  formData.append('patient_id',id)
  
 // formData.append('quick_summary',summary)

           
             axios.post(`${APIURL}/api/v1/doctor/patient-record/?timeZone=${timeZone}`,formData, { headers: {
               
               "Authorization" : 'Token '+str,
               'Content-Type': 'application/json'  }


             })



             .then(res=>{
             
               if(res.data.status==="success"){
               
                setSubmitMsg("success");
                setModalShow(true);

           }
           else{
             setSubmitMsg("error");
             setErrorSubmit(true);
            
           }

             })
             .catch(err=>{
           setSubmitMsg("error");
           setErrorSubmit(true);
         })

  }

  

}

const handleScanData = async() => {

  let isValid = true

  const scanData = scanList.filter((row,i)=> {
    return ( row.test!=="" || row.scan_details.details!=="" || row.scan_details.reason!=="" || row.scan_details.image!=="")
  })

  scanData.map((item)=> {
    if(item.test===""){
      setScanErr("*Scan name is mandatory!")
      setErrorScanShow(true)
    }
    if(item.date===""){
      setScanErr("*Date is mandatory!")
      setErrorScanShow(true)
    }
    if(item.scan_details.details===""){
      setScanErr("*Scan details is mandatory!")
      setErrorScanShow(true)
    }

    if(item.test === "" || item.date==="" || item.scan_details.details===""){

      isValid = false
  // setUpdatedScanList(updatedScans);
  // setSubmitTrigger(!submitTrigger)
    }  
  })

  return isValid
}

const handleSubmitScans= async()=>{
   
  // setSubmitTrigger(!submitTrigger);
   setScanErr("nil");

  const updatedScans = scanList.filter((row,i)=>{
   return (row.test!=="" && row.date!=="" && row.scan_details.details!=="")
   
  })


  if (await handleScanData()) {

    submitScans(updatedScans)
  }
 

 /* const newlist= testsList.map(row=>{
    if(row.test!=="" && row.date!==""){

         return true;
    }
    else if(row.test==="" && row.date!== ""){
        setTestErr("*Test name in Test Schedules Table is mandatory!");
        setErrorModalShow(true);
     
         return false;
    }
    else if(row.test!=="" && row.date=== ""){
        setTestErr("*Date in Test Schedules Table is mandatory!");
        setErrorModalShow(true);
      
         return false;
    }else{
      return false;
    }
  }) 

 const newScanList= scanList.map(row=>{
    if(row.test!=="" && row.date!==""){
      
         return true;
    }
    else if(row.test==="" && row.date!== ""){
        setScanErr("*Scan name in Scan Schedules table is mandatory!");
        setErrorScanShow(true);
        
         return false;
    }
    else if(row.test!=="" && row.date=== ""){
        setScanErr("*Date in Scan Schedules Table is mandatory!");
        setErrorScanShow(true);
       
         return false;
    }else{
      return false;
    }
  }) */

  // setUpdatedTestList(updatedTests);
  // setUpdatedScanList(updatedScans);
  // setSubmitTrigger(!submitTrigger)
  

}


const handleTestData = async() => {

  let isValid = true

  const testData = testsList.filter((row,i)=> {
    return ( row.test!==""  || row.labtest_reason.details!=="" || row.labtest_reason.reason!=="" || row.labtest_reason.image !== "" )
  })

testData.map((item)=> {
    if(item.test===""){
      setTestErr("*Test name is mandatory!")
      setErrorModalShow(true)
    }
    if(item.date===""){
      setTestErr("*Date is mandatory!")
      setErrorModalShow(true)
    }
    if(item.labtest_reason.details===""){
      setTestErr("*Test details is mandatory!")
      setErrorModalShow(true)
    }

    if(item.test ==="" || item.date === "" || item.labtest_reason.details === ""){

      isValid = false
  // setUpdatedTestList(updatedTests);
  // setSubmitTrigger(!submitTrigger)
    }
  })

  return isValid
}

const handleSubmitTests = async() => {

  // setSubmitTrigger(!submitTrigger);
   setTestErr("nil");

   const updatedTests = testsList.filter((row,i)=>{
   return (row.test!=="" && row.date!=="")
   
  })

  if(await handleTestData()){

    submitTests(updatedTests)
  }

  // setUpdatedTestList(updatedTests);
  // setSubmitTrigger(!submitTrigger)

}

const handleAssignee = () => {
  const data = procedureList
  const sessionData = JSON.parse(sessionStorage.getItem('logindatas'))

  data.forEach(item => {
    if(item.assign_id==="" || item.assign_name===""){
      item.assign_id = sessionData.user_id
      item.assign_name = sessionData.name
    }
  })

  setProcedureList(data)
}

const handleSubmitProcedure = () => {
  let isValid = true

  handleAssignee()

  const updatedProcedure = procedureList.filter((row,i)=>{
    return (row.procedure_details.procedure_name!=="" && row.date_of_procedure!=="" && row.assign_id!=="" && row.assign_id !== undefined)
    
   })

   const procedureData = procedureList.filter((row,i)=> {
     return ( row.procedure_details.procedure_name!=="" || row.assign_id!=="" || row.details!=="")
   })

   procedureData.map((item)=> {
    if(item.procedure_details.procedure_name===""){
      setProcedureErr("*Procedure name is mandatory!")
      setErrorShow(true)
      isValid = false
    }
    if(item.date_of_procedure === ""){
      setProcedureErr("*Date in Procedure Table is mandatory!")
      setErrorShow(true)
      isValid = false
    }
    // if(item.assign_name === ""){
    //   setProcedureErr("*Assignee in Procedure Table is mandatory!")
    //   setErrorShow(true)
    //   isValid = false
    // }
    /*if(item.test==="" && item.date===""){
      setTestErr("Please fill the Test and Date Field in row " + (index+1))
      setErrorModalShow(true)
    }*/
    if(item.procedure_details.procedure_name !=="" && item.date_of_procedure !=="" && item.assign_id !== ""){
        setUpdatedProcedureList(updatedProcedure);

    }
  })

  if(isValid){
    procedureSubmit(updatedProcedure)
  }

}

const handleNextVisit = (date)=>{
  
  setNext(date);
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));

let DateExtracted = dateUpdated.toISOString();

  setNext_visit(DateExtracted);
  }
}


const handleDeleteNextVisit1=()=>{
  setDeleteNextVisitConfirmMsg('success');
  setDeleteNextVisitConfirmModalShow('true');
}
const handleDeleteNext = ()=>{
  setNext_visit(null);
  setNext(null);
  callDeleteNext();
}

const handleDeleteTest=(index)=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
    
  
  const dataToUpdate ={
    ts_id: testsList[index].id,
    patient_id: id
  }


  
    axios.put(`${APIURL}/api/v1/doctor/patient-record/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }


    })



    .then(res=>{

      if(res.data.status==="success"){
   
     setReRender(!reRender);
  }
  else{
    setSubmitMsg("error");
    
   
  }

    })
    .catch(err=>{
  
  setSubmitMsg("error");

})


 }

 const handleDeleteProcedure=(index)=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
    
  
   const dataToUpdate ={
     procedure_id: procedureList[index].procedure_details.procedure_id,

   }


  
    axios.delete(`${APIURL}/api/v1/hospital/patient-hospital-payment_v1/?procedure_id=${procedureList[index].procedure_details.procedure_id}`, { headers: {
      
      "Authorization" : 'Token '+str,
  }


    })



    .then(res=>{

      if(res.data.status==="success"){
   
     setProcedureRefresh(!procedureRefresh);
  }
  else{
    setSubmitMsg("error");
    
   
  }

    })
    .catch(err=>{
  
  setSubmitMsg("error");

})


 }

const callDeleteNext=(index)=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
    
  
  const dataToUpdate ={
   
    patient_id: id,
    next_visit:"True"
  }


  
    axios.put(`${APIURL}/api/v1/doctor/patient-record/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }


    })



    .then(res=>{

      if(res.data.status==="success"){
    setDeleteNextMsg("Successfully deleted next visit data");
                setDeleteNextModalShow(true);
     
  }
  else if(res.data.status==="error"){
    setNextErr(res.data.message ? res.data.message : "Error in submission!");
    
   
  }
   else{
    setNextErr("Error in submission!");
    
   
  }

    })
    .catch(err=>{
  
    setNextErr("Error in submission!");

})


 }

const handleDisplayTestTable =()=>{
  setShowTestTable(!showTestTable);
   }

const handleDisplayScanTable =()=>{
  setShowScanTable(!showScanTable);
 }

const handleDisplayProcedureTable =()=>{
  setShowProcedureTable(!showProcedureTable);
 }
  

const DeleteNextVisitConfirmPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Please confirm to delete Next Visit!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{setDeleteNextVisitConfirmModalShow(false);
                                                               handleDeleteNext();
                                                                 }}> Confirm</Button>

       

      </Modal.Footer>
    </Modal>
  );
}

const DeleteTestConfirmPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Please confirm to delete Scheduled Test</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{setDeleteTestConfirmModalShow(false);
                                                               handleDeleteTest(props.index);;
                                                                 setTestIndexToDelete(null);}}> Confirm</Button>

       

      </Modal.Footer>
    </Modal>
  );
}


const DeleteProcedureConfirmPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Please confirm to delete Scheduled Procedure</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{setDeleteProcedureConfirmModalShow(false);
                                                               handleDeleteProcedure(props.index);;
                                                                 setProcedureIndexToDelete(null);}}> Confirm</Button>

       

      </Modal.Footer>
    </Modal>
  );
}


const PulsePopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VitalsPulse {...props} />
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}

const BpPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VitalsBP {...props} />
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}


const SubmitPopUp=(props)=> {
 
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}
const DeletePopUp=(props)=> {
 
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{deleteNextMsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}

const ErrorNextPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {nextErr}</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const ErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {testErr}</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}


const SubmitInstructionsPopUp=(props)=> {
   
   
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Instructions submitted sucessfully!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}

const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> Error in Data Submission. Please try again! </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const handleInstructions=(e)=>{

  setInstructions(e.target.value);

}


const handleSubmitInstructions=(e)=>{

  if(instructions!=='' && instructions!==null){
  const instrToSend= instructions.trim().split("\n");

  callInstructionSubmit(instrToSend);
}
}


const callInstructionSubmit=(instrString)=>{

  const tokenString= localStorage.getItem('usertoken');
   const dataToSend={
    instruction: instrString
   }
   let str= tokenString.replace(/["]+/g, '');           
    axios.post(`${APIURL}/api/v1/patient/instructions/?patient_id=${id}`,dataToSend, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }


    })

    .then(res=>{
     
      if(res.data.status=== "success"){
     
           setSubmitInstructionsMsg("success");
                setModalInstructionsShow(true);
                   
       

     
      }
      else{
        setSubmitMsg("error");
              setErrorSubmit(true);
      }
    })
    .catch(err=>{
      setSubmitMsg("error");
              setErrorSubmit(true);

})



}


const handleDrNotesOverlay=(e)=>{
 e.preventDefault();
 setDrNotesShow(true);

}
const handleObservationsOverlay=(e)=>{
 e.preventDefault();
 setObservationsShow(true);

}

const handleSymptomsOverlay=(e)=>{
  e.preventDefault();
  setSymptomsShow(true);
 
 }

const handlePulseOverlay=(e)=>{
 e.preventDefault();
 setPulseShow(true);

}

const handleAddPrescOverlay=(e)=>{
 e.preventDefault();
 setAddPrescShow(true);

}

// const handleAddPrescForm=(e)=>{
//   e.preventDefault();
//   history.push('/prescription-form')
//   //setPrescForm(true)

//  }

const submitPulseHandle = () => {
  setAddPulse(false)
}

const submitBpHandle = () => {
  setAddBp(false)
}

const AddPulsePopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddPulse id={id} submitpulse={submitPulseHandle} />
        

      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
           

      </Modal.Footer>
    </Modal>
  );
}

const AddBpPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddBp id={id} submitbp={submitBpHandle}/>
        

      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>


      </Modal.Footer>
    </Modal>
  );
}


const ErrorScanPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {scanErr}</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const ErrorProcedurePopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {procedureErr}</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const EditPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImageUpdate img={scanList[index].scan_details.image}  imgUpdate={handleFile}/>
        

      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>
           
         


      </Modal.Footer>
    </Modal>
  );
}

const ImgEditPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImageUpdate img={testsList[index].labtest_reason.image}  imgUpdate={handleFile}/>
        

      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>
           
         


      </Modal.Footer>
    </Modal>
  );
}

const ProcedureImgPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImageUpdate img={procedureList[index].image}  imgUpdate={handleFile}/>
        

      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>
           
         


      </Modal.Footer>
    </Modal>
  );
}

const PastMedicationPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
         <PastMedications id={id}/> 

      </Modal.Body>
      <Modal.Footer>
         
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
       

      </Modal.Footer>
    </Modal>
  );
}

const ImgViewPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImageUpdate img={procedureList[index].image}  imgUpdate={handleFile}/>
        

      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>
           
         


      </Modal.Footer>
    </Modal>
  );
}

const ViewPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          flag ? <ImageUpdate img={testsList[index].labtest_reason.image}  imgUpdate={handleFile}/>
          :
                <ImageUpdate img={scanList[index].scan_details.image}  imgUpdate={handleFile}/>

        }
        
        

      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>



      </Modal.Footer>
    </Modal>
  );
}
let imgFile = null

function handleFile(childData){
  imgFile = childData
}

const reasonAddHandle= ()=> {
  if(procedureToggle){
    setReasonAddShow(false)
    const procedure =procedureList
    procedure[cell].reason= sendData
    setProcedureList(procedure)
    setEditSuccess(true)
  }
  else{
    setReasonAddShow(false)
    const tests =testsList
    tests[cell].labtest_reason.reason= sendData
    setTestsList(tests)
    setEditSuccess(true)
  }

}

const ReasonAddPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <AddRemark update={handleSend} save={reasonAddHandle}/>

      </Modal.Body>
      <Modal.Footer>
         
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
       

      </Modal.Footer>
    </Modal>
  );
}


const ReasonEditPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditRemark data={detailData} update={handleSend}/>
  
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

      </Modal.Footer>
    </Modal>
  );
}

const ProcedureReasonPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditRemark data={detailData} update={handleSend}/>
  
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

      </Modal.Footer>
    </Modal>
  );
}

const handleDeleteRowTest = (index)=>{
  setDeleteTestConfirmMsg('success');
  setDeleteTestConfirmModalShow('true');
  setTestIndexToDelete(index);
   
}

const deleteRow=(index)=>{
  let newlist=[];
  if(index> testListCounter-1){
  newlist = testsList.filter((test,i)=>{
    return i!== index
  })

  setTestsList(newlist);
  if(newlist.length<=0){
   
    setTestsList([{id:"",test: "", date: new Date().toISOString(), labtest_reason:{details:"",image: "",reason:""}}]);
    
  }
  


}
else{

  handleDeleteRowTest(index);
    
}
  
}
const renderEditableTest=(cellInfo)=> {
  if(cellInfo.index > testListCounter-1 ||testListCounter===0){
    return (<div  ref={wrapperRef}>
      
      <input
        style={{ backgroundColor: "#fafafa" }}
        value= {testsList[cellInfo.index][cellInfo.column.id]}
        className="form-control"        
       name="test"

        onChange={(e)=>{

           e.preventDefault();

setCurrentIndex(cellInfo.index);
                             setCurrentColumn(cellInfo.column.id);
                            setDisplaySuggestions(true);
                            setDisplayTest(true);
 

 

  setSearch(e.target.value);


 
  const tests = testsList;
          tests[cellInfo.index].test = e.target.value;
          
          
          setTestsList(tests);
   } }
     autoComplete="off"
               
      />

       

         
        {currentIndex === cellInfo.index && cellInfo.column.id=== "test" && displayTest && (<div className="suggestions-box">
             {options.map((item,i)=>{
         
    return(
      <div className="scan-item" 
          
            key={i} 
            onClick={(e)=>{ e.preventDefault();
             
              setRender(!render);
              
                                      const tests=testsList;
                                      tests[cellInfo.index].test= item;
                                       
                                 
                                      setTestsList(tests);
                                      setDisplayTest(false);

                                      }}
            >
      <p className="search-item">{item}</p>
      </div>
      )
  }) 
           }

        </div>)
      }
      

      </div>
        

    )
  }
  else{
    return (<div>
      
      <div
        style={{ backgroundColor: "#fafafa" }}
        
        className="form-control test-name-col"        
       name="date"
        
        
       
        value={testsList[cellInfo.index].date}
       
        onBlur={(e)=>{const tests = testsList;
          tests[cellInfo.index].test = e.target.innerHTML;
          
          setTestsList(tests);

        

       }}
        
        
     autoComplete="off"
      dangerouslySetInnerHTML={{
          __html: testsList[cellInfo.index][cellInfo.column.id]
        }}
      />

       

         
       
        </div>)

  }
  }



const renderEditableTestDate=(cellInfo)=> {
  if(cellInfo.index > testListCounter-1){

    const today =new Date();
   
    const offset = today.getTimezoneOffset();

let dateUpdated = new Date(today.getTime() - (offset*60*1000));



let DateExtracted = dateUpdated.toISOString().split('T')[0];
    return (<div>
      <input type="date" data-date={testsList[cellInfo.index].date.split('T')[0]}  className="scan-date-col date-custom-format" min={DateExtracted} onChange={e=>

                              {
                                e.preventDefault();
                                let date= new Date(e.target.value)
                                const offset = date.getTimezoneOffset();

                                let updated = new Date(date.getTime() - (offset*60*1000));

                                let extracted = date.toISOString()

                                const tests = testsList;
          tests[cellInfo.index].date = extracted;
        
          setTestsList(tests);
          const m = moment(date,"YYYY-MM-DD").format("YYYY-MM-DD");
        
          setDateVal(m);
                               }
}
/> 

                     </div>

      )

      }




  else{

    return (<div>
      
      <div
        style={{ backgroundColor: "#fafafa" }}
        
        className="form-control test-date-col"        
       name="date"
        
        
       
        value={moment.utc(testsList[cellInfo.index].date).local().format('DD-MM-YYYY')}
       
        onBlur={(e)=>{const tests = testsList;
          tests[cellInfo.index].date = e.target.innerHTML;
          
          setTestsList(tests);

        

       }}
        
        
     autoComplete="off"
      dangerouslySetInnerHTML={{
          __html: moment.utc(testsList[cellInfo.index].date).local().format('DD-MM-YYYY')
        }}
      />

       

         
       
        </div>)

  }

      }
 
    

      const editTestImage = (pos)=> {
        setCell(pos)
        const fileInput = document.getElementById('testImage')
        fileInput.click();
      }
      
      const uploadTestImage= (e)=> {
        const file=  e.target.files[0];
                  const test =testsList
                  if(!file){
                    setUploadShow(!uploadShow)
                    //setInvalidPic('Please Select an Image')
                    return false
                  }
                  if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
                    setValidShow(!validShow)
                    //setInvalidPic('Please select valid image')
                    return false;
                  }
                  if(file.size>2e6){
                    setSizeShow(!sizeShow)
                    return false
                  }
                  reader.onload = (e) => {
                    const img1 = new Image();
                    img1.onload = () => {
                      //setToggle(true)
                      //setImg(file)
                      test[cell].labtest_reason.image= file
                      setTestsList(test)
                      setIndex(cell)
                      setTableRefresh(!tableRefresh)
                    };
                    img1.onerror = () => {
                      //setPreviewOn(false)
                      setContentShow(!contentShow)
                      
                      return false;
                    };
                    img1.src = e.target.result;
                  };
                  reader.readAsDataURL(file);       
      }
       
            const renderTestImage= (cellInfo)=> {
              if(cellInfo.index > testListCounter-1){

                if(testsList[cellInfo.index].labtest_reason.image===null || testsList[cellInfo.index].labtest_reason.image===''){
                  return(
                    <div>
                      <input id='testImage' hidden='hidden' type="file"  onChange={uploadTestImage} />
            
                      <i onClick={()=>{editTestImage(cellInfo.index)}} class="fas fa-image select-img"></i>
                    </div>
                  )
                }
                else {
                  return(
                    <button onClick={()=> {setImgEditShow(true); setIndex(cellInfo.index)}} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
                  )
                }
              }
              
              else{
                if(testsList[cellInfo.index].labtest_reason.image===null || testsList[cellInfo.index].labtest_reason.image===''){
          
                  return null
                  
                }
                else{
                  return(
                    <button onClick={()=> {setViewShow(true); setIndex(cellInfo.index); setFlag(true)}} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
                  )
                }
                
              }
              
            } 
            
          const renderTestDetails= (cellInfo)=> { 
      
          if(cellInfo.index > testListCounter-1){
            return (<div>
              <textarea className="text-area" placeholder="Add Test Details"  cols="100" rows="2"  onChange={e=>
        
                                      {
                                        e.preventDefault();
                                        let data=e.target.value;
                                       
                                        const tests = testsList;
                                        testsList[cellInfo.index].labtest_reason.details = data;
                  
                  setTestsList(tests);
      
                                       }
        }
        /> 
        
                             </div>
        
              )
        
              }
        
        
              else{
                 return (
      
      <textarea  className="scan-text" value={testsList[cellInfo.index].labtest_reason.details}  cols="100" rows="2"/>
              
              /*<div
                style={{ backgroundColor: "#fafafa" }}
                
                className="form-control scan-date-col"        
               name="date"
                 
               
                value={scanList[cellInfo.index].scan_details.details}
               
                onBlur={(e)=>{const scans = scanList;
                  scanList[cellInfo.index].scan_details.details = e.target.innerHTML;
                  
                  setScanList(scans);
        
        
               }}
                
                
             autoComplete="off"
              dangerouslySetInnerHTML={{
                  __html: scanList[cellInfo.index].scan_details.details
                }}
              />
        
               
        
                 
               
                </div>*/)
              }
      }
      
      const handleReasonAdd =(index,data)=> {
        if(data=== 'toggle'){
          setProcedureToggle(true)
        }
        else{
          setProcedureToggle(false)
        }
        setCell(index)
        setReasonAddShow(true)
      }

      const handleReasonEdit =(data,index) => {
        setDetailData(data)
        setCell(index)
        setReasonEditShow(true)
      }
      
      const renderTestReason= (cellInfo)=> {
        
        if(cellInfo.index > testListCounter-1){
          if(testsList[cellInfo.index][cellInfo.column.id].reason==="" || testsList[cellInfo.index][cellInfo.column.id].reason===null){
            return (
              <button onClick={()=> handleReasonAdd(cellInfo.index)}  className='btn btn-smallest add-remark-btn'><i className="fas fa-plus"></i></button>
            )
          }
          else{
            return(
              <button onClick={()=> handleReasonEdit(testsList[cellInfo.index][cellInfo.column.id].reason, cellInfo.index)} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
            )
          }
            
        }
            else{
              if(testsList[cellInfo.index][cellInfo.column.id].reason==="" || testsList[cellInfo.index][cellInfo.column.id].reason===null){
                return null
                
              }
              else{
                return <button onClick={()=> handleView(testsList[cellInfo.index][cellInfo.column.id].reason)} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
              }
            }
      }
      
          
              
      
      const AddRowTest=()=>{
        const list = testsList;
        list.push({id:"", test: "",date: new Date().toISOString(), labtest_reason:{details:"",image: "",reason:""}});
        
        setTestsList(list);
        setRender(true);
      
      }




      const handleDeleteRowProcedure = (index)=>{
        setDeleteProcedureConfirmMsg('success');
        setDeleteProcedureConfirmModalShow('true');
        setProcedureIndexToDelete(index);
         
      }
      
      const deleteProcedureRow=(index)=>{
        let newlist=[];

        if(index> procedureListCounter-1){
        newlist = procedureList.filter((test,i)=>{
          return i!== index
        })

        setProcedureList(newlist);
        if(newlist.length<=0){
         
          setProcedureList([{ 
    procedure_details: {
      procedure_name : "",
      procedure_id : "",
      procedure_type : "",
      amount : "",
    },
    date_of_procedure: new Date().toISOString(),
    area : "",
    session : "",
    quantity : "",
    details : "",
    reason : "",
    image : "",
    assign_name : "",
    status : "",
   }])
          
        }
        
      
      
      }
      else{

        handleDeleteRowProcedure(index);
          
      }
        
      }

      const renderEditableProcedure=(cellInfo)=> {

        if(cellInfo.index > procedureListCounter-1 || procedureListCounter===0){
          
          return (<div  ref={wrapperRef}>
            
            <input
              style={{ backgroundColor: "#fafafa" }}
              value= {procedureList[cellInfo.index][cellInfo.column.id].procedure_name}
              className="form-control"        
              name="procedure"
      
              onChange={(e)=>{
      
                 e.preventDefault();
      
      setCurrentIndex(cellInfo.index);
                                   setCurrentColumn(cellInfo.column.id);
                                  setDisplaySuggestions(true);
                                  setDisplay(true);
      
        setSearchProcedure(e.target.value);
      
      
       
        const procedures = procedureList;
                procedureList[cellInfo.index][cellInfo.column.id].procedure_name = e.target.value;
                
                
                setProcedureList(procedures)
         } }
           autoComplete="off"
                     
            />
      
             
      
               
              {currentIndex === cellInfo.index && cellInfo.column.id=== "procedure_details" && display && (<div className="suggestions-box">
                   {procedureSuggest.map((item,i)=>{
               
          return(
            <div className="scan-item" 
                
                  key={item.id} 
                  onClick={(e)=>{ e.preventDefault();
                   
                    setRender(!render);
                    
                                            const procedure=procedureList;
                                            procedure[cellInfo.index][cellInfo.column.id].procedure_id= item.id;
                                            procedure[cellInfo.index][cellInfo.column.id].procedure_name= item.procedure_name;
                                            procedure[cellInfo.index][cellInfo.column.id].procedure_type= item.procedure_type;
                                            procedure[cellInfo.index][cellInfo.column.id].amount= item.amount
                                       
                                            setProcedureList(procedure);
                                            setDisplay(false);
      
                                            }}
                  >
            <div className='search-procedure'>
            <p className="search-item">{item.procedure_name}</p>
            <p className="search-item">{item.procedure_type}</p>
            </div>
            </div>
            )
        }) 
                 }
      
              </div>)
            }
            
      
            </div>
              
      
          )
        }
        else{
          return (<div>
            
            <div
              style={{ backgroundColor: "#fafafa" }}
              
              className="form-control test-name-col"        
             name="date"
              
              
             
              value={procedureList[cellInfo.index][cellInfo.column.id].procedure_name}
             
              onBlur={(e)=>{const procedure = procedureList;
                procedureList[cellInfo.index][cellInfo.column.id].procedure_name = e.target.innerHTML;
                
                setProcedureList(procedure);
      
              
      
             }}
              
              
           autoComplete="off"
            dangerouslySetInnerHTML={{
                __html: procedureList[cellInfo.index][cellInfo.column.id].procedure_name
              }}
            />
      
             
      
               
             
              </div>)
      
        }
        }


  const renderEditableProcedureDate=(cellInfo)=> {
  if(cellInfo.index > procedureListCounter-1){

     const today =new Date();
   
     const offset = today.getTimezoneOffset();

 let dateUpdated = new Date(today.getTime() - (offset*60*1000));



 let DateExtracted = dateUpdated.toISOString().split('T')[0];
    return (<div>

{/* <DatePicker
                          minDate = {new Date()}
                          value={date}
                          onChange={date=>{

                            setDate(date)
                            let dateUpdated= date;
                              if(date!==null){
                            const offset = date.getTimezoneOffset();
                            
                            dateUpdated = date.toISOString()

                            //let DateExtracted = dateUpdated.toISOString().split('T')[0];
                            const procedure = procedureList;
                                procedure[cellInfo.index].date_of_procedure = dateUpdated;
        
          setProcedureList(procedure);

                          }}}
                     />  */}

       <input type="date" data-date={procedureList[cellInfo.index].date_of_procedure.split('T')[0]} className="scan-date-col date-custom-format" min={DateExtracted} onChange={e=>

                              {
                                e.preventDefault();
                                let date= new Date(e.target.value)
                                const offset = date.getTimezoneOffset();

                                let updated = new Date(date.getTime() - (offset*60*1000));

                                let extracted = date.toISOString()
                                const procedure = procedureList;
                                procedure[cellInfo.index].date_of_procedure = extracted;
        
          setProcedureList(procedure);
          setTableRefresh(!tableRefresh)
          const m = moment(date,"YYYY-MM-DD").format("YYYY-MM-DD");
        
          setDateVal(m);
                               }
}
/> 

                     </div>

      )

      }




  else{

    return (<div>
      
      <div
        style={{ backgroundColor: "#fafafa" }}
        
        className="form-control test-date-col"        
       name="date"
        
        
       
        // value={moment.utc(procedureList[cellInfo.index].date_of_procedure).local().format('DD-MM-YYYY')}

        value={moment(procedureList[cellInfo.index].date_of_procedure.split('T')[0]).format("DD-MM-YYYY")}
       
        onBlur={(e)=>{const procedure = procedureList;
          procedure[cellInfo.index].date_of_procedure.split('T')[0] = e.target.innerHTML;
          
          setProcedureList(procedure);

        

       }}
        
        
     autoComplete="off"
      dangerouslySetInnerHTML={{
          // __html: moment.utc(procedureList[cellInfo.index].date_of_procedure).local().format('DD-MM-YYYY')
          __html: moment(procedureList[cellInfo.index].date_of_procedure.split('T')[0]).format("DD-MM-YYYY")

        }}
      />

       

         
       
        </div>)

  }

      }
 
    

      const editProcedureImage = (pos)=> {
        setCell(pos)
        const fileInput = document.getElementById('procedureImage')
        fileInput.click();
      }
      
      const uploadProcedureImage= (e)=> {
        const file=  e.target.files[0];
                  const procedure =procedureList
                  if(!file){
                    setUploadShow(!uploadShow)
                    //setInvalidPic('Please Select an Image')
                    return false
                  }
                  if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
                    setValidShow(!validShow)
                    //setInvalidPic('Please select valid image')
                    return false;
                  }
                  if(file.size>2e6){
                    setSizeShow(!sizeShow)
                    return false
                  }
                  reader.onload = (e) => {
                    const img1 = new Image();
                    img1.onload = () => {
                      procedure[cell].image= file
                      setProcedureList(procedure)
                      setIndex(cell)
                      setTableRefresh(!tableRefresh)
                    };
                    img1.onerror = () => {
                      //setPreviewOn(false)
                      setContentShow(!contentShow)
                      
                      return false;
                    };
                    img1.src = e.target.result;
                  };
                  reader.readAsDataURL(file);       
      }
       
            const renderProcedureImage= (cellInfo)=> {
              if(cellInfo.index > procedureListCounter-1){

                if(procedureList[cellInfo.index].image===null || procedureList[cellInfo.index].image===''){
                  return(
                    <div>
                      <input id='procedureImage' hidden='hidden' type="file"  onChange={uploadProcedureImage} />
            
                      <i onClick={()=>{editProcedureImage(cellInfo.index)}} class="fas fa-image select-img"></i>
                    </div>
                  )
                }
                else {
                  return(
                    <button onClick={()=> {setImageEditShow(true); setIndex(cellInfo.index)}} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
                  )
                }
              }
              
              else{
                if(procedureList[cellInfo.index].image===null || procedureList[cellInfo.index].image===''){
          
                  return "NIL"
                  
                }
                else{
                  return(
                    <button onClick={()=> {setImageViewShow(true); setIndex(cellInfo.index);}} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
                  )
                }
                
              }
              
            } 


                const renderProcedureArea= (cellInfo)=> { 

          if(cellInfo.index > procedureListCounter-1){

              return (<div>
                <input type='text' className="form-control" value={procedureList[cellInfo.index].area} placeholder="Enter Area" onChange={e=>
          
                                        {
                                          e.preventDefault();
                                          let data=e.target.value;
                                         
                                          const procedure = procedureList;
                                          procedure[cellInfo.index].area = data;
                                          setTableRefresh(!tableRefresh)
                    setProcedureList(procedure)
        
                                         }
          }
          /> 
                               </div>
          
                )
            }

            else{
              return (<div>
                <div
                  style={{ backgroundColor: "#fafafa" }}
                  
                  className="form-control test-name-col"        
                 name="date"
                  
                  
                 
                  value={procedureList[cellInfo.index].area}
                 
                  onBlur={(e)=>{const procedure = procedureList;
                    procedureList[cellInfo.index].area = e.target.innerHTML;
                    
                    setProcedureList(procedure);
          
                  
          
                 }}
                  
                  
               autoComplete="off"
                dangerouslySetInnerHTML={{
                    __html: procedureList[cellInfo.index].area
                  }}
                />
          
                 
          
                   
                 
                  </div>)
          }
            
      }

      const renderProcedureSession= (cellInfo)=> { 

        if(cellInfo.index > procedureListCounter-1){

            return (<div>
              <input type='text' className="form-control" value={procedureList[cellInfo.index].session} placeholder="Enter Session" onChange={e=>
        
                                      {
                                        e.preventDefault();
                                        let data=e.target.value;
                                       
                                        const procedure = procedureList;
                                        procedure[cellInfo.index].session = data;
                                        setTableRefresh(!tableRefresh)
                  setProcedureList(procedure)
      
                                       }
        }
        /> 
                             </div>
        
              )
          }

          else{
            return (<div>
              <div
                style={{ backgroundColor: "#fafafa" }}
                
                className="form-control test-name-col"        
               name="date"
                
                
               
                value={procedureList[cellInfo.index].session}
               
                onBlur={(e)=>{const procedure = procedureList;
                  procedureList[cellInfo.index].session = e.target.innerHTML;
                  
                  setProcedureList(procedure);
        
                
        
               }}
                
                
             autoComplete="off"
              dangerouslySetInnerHTML={{
                  __html: procedureList[cellInfo.index].session
                }}
              />
        
               
        
                 
               
                </div>)
        }
          
    }

    const renderProcedureType= (cellInfo)=> { 

      if(procedureList[cellInfo.index][cellInfo.column.id].amount === "" && procedureList[cellInfo.index][cellInfo.column.id].procedure_name === ""){
        return (
          <div>
            Select a Procedure
          </div>
        )
      }

      else{
        return (<div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            
            className="form-control test-name-col"        
           name="date"
            
            
           
            value={procedureList[cellInfo.index].session}
           
            onBlur={(e)=>{const procedure = procedureList;
              procedureList[cellInfo.index][cellInfo.column.id].procedure_type = e.target.innerHTML;
              
              setProcedureList(procedure);
    
            
    
           }}
            
            
         autoComplete="off"
          dangerouslySetInnerHTML={{
              __html: procedureList[cellInfo.index][cellInfo.column.id].procedure_type
            }}
          />
    
           
    
             
           
            </div>)
    }

     /* if(cellInfo.index > procedureListCounter-1){

          return (<div>
            <input type='text' className="form-control" value={procedureList[cellInfo.index][cellInfo.column.id].procedure_type} placeholder="Enter Type" onChange={e=>
      
                                    {
                                      e.preventDefault();
                                      let data=e.target.value;
                                     
                                      const procedure = procedureList;
                                      procedure[cellInfo.index][cellInfo.column.id].procedure_type = data;
                                      setTableRefresh(!tableRefresh)
                setProcedureList(procedure)
    
                                     }
      }
      /> 
                           </div>
      
            )
        }

        else{
          return (<div>
            <div
              style={{ backgroundColor: "#fafafa" }}
              
              className="form-control test-name-col"        
             name="date"
              
              
             
              value={procedureList[cellInfo.index].session}
             
              onBlur={(e)=>{const procedure = procedureList;
                procedureList[cellInfo.index][cellInfo.column.id].procedure_type = e.target.innerHTML;
                
                setProcedureList(procedure);
      
              
      
             }}
              
              
           autoComplete="off"
            dangerouslySetInnerHTML={{
                __html: procedureList[cellInfo.index][cellInfo.column.id].procedure_type
              }}
            />
      
             
      
               
             
              </div>)
      }*/
        
  }

    const renderProcedureQuantity= (cellInfo)=> { 

      if(cellInfo.index > procedureListCounter-1){

          return (<div>
            <input type='number' min='0' className="form-control" value={procedureList[cellInfo.index].quantity} placeholder="Qty" onChange={e=>
      
                                    {
                                      e.preventDefault();
                                      let data=e.target.value;
                                      if(data.length < 4){
                                        const procedure = procedureList;
                                        procedure[cellInfo.index].quantity = data;
                                        setProcedureList(procedure)
                                        setTableRefresh(!tableRefresh)
                                      }
                                      
    
                                     }
      }
      /> 
                           </div>
      
            )
        }

        else{
          return (<div>
            <div
              style={{ backgroundColor: "#fafafa" }}
              
              className="form-control test-name-col"        
             name="date"
              
              
             
              value={procedureList[cellInfo.index].quantity}
             
              onBlur={(e)=>{const procedure = procedureList;
                procedureList[cellInfo.index].quantity = e.target.innerHTML;
                
                setProcedureList(procedure);
      
              
      
             }}
              
              
           autoComplete="off"
            dangerouslySetInnerHTML={{
                __html: procedureList[cellInfo.index].quantity
              }}
            />
      
             
      
               
             
              </div>)
      }
        
  }

  const renderProcedurePrice= (cellInfo)=> { 


    if(procedureList[cellInfo.index][cellInfo.column.id].amount === "" && procedureList[cellInfo.index][cellInfo.column.id].procedure_name === ""){
      return (
        <div>
          Select a Procedure
        </div>
      )
    }
    else{
      return (<div>
        <div
          style={{ backgroundColor: "#fafafa" }}
          
          className="form-control test-name-col"        
         name="date"
          
          
         
          value={procedureList[cellInfo.index][cellInfo.column.id].amount}
         
          onBlur={(e)=>{const procedure = procedureList;
            procedureList[cellInfo.index][cellInfo.column.id].amount = e.target.innerHTML;
            
            setProcedureList(procedure);
  
          
  
         }}
          
          
       autoComplete="off"
        dangerouslySetInnerHTML={{
            __html: procedureList[cellInfo.index][cellInfo.column.id].amount
          }}
        />
  
         
  
           
         
          </div>)
  }

   /* if(cellInfo.index > procedureListCounter-1){

        return (<div>
          <input type='number' className="form-control" value={procedureList[cellInfo.index][cellInfo.column.id].amount} placeholder="Price" onChange={e=>
    
                                  {
                                    e.preventDefault();
                                    let data=e.target.value;
                                   
                                    if(data.length < 8){
                                      const procedure = procedureList;
                                      procedure[cellInfo.index][cellInfo.column.id].amount = data;
                                      setProcedureList(procedure)
                                      setTableRefresh(!tableRefresh)
                                    }
  
                                   }
    }
    /> 
                         </div>
    
          )
      }

      else{
        return (<div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            
            className="form-control test-name-col"        
           name="date"
            
            
           
            value={procedureList[cellInfo.index][cellInfo.column.id].amount}
           
            onBlur={(e)=>{const procedure = procedureList;
              procedureList[cellInfo.index][cellInfo.column.id].amount = e.target.innerHTML;
              
              setProcedureList(procedure);
    
            
    
           }}
            
            
         autoComplete="off"
          dangerouslySetInnerHTML={{
              __html: procedureList[cellInfo.index][cellInfo.column.id].amount
            }}
          />
    
           
    
             
           
            </div>)
    }*/
      
}
            
          const renderProcedureDetails= (cellInfo)=> { 
      
          if(cellInfo.index > procedureListCounter-1){
            return (<div>
              <textarea className="text-area" placeholder="Add Procedure Details" cols="100" rows="2"  onChange={e=>
        
                                      {
                                        e.preventDefault();
                                        let data=e.target.value;
                                       
                                        const procedure = procedureList;
                                        procedure[cellInfo.index].details = data;

                  setProcedureList(procedure)
      
                                       }
        }
        /> 
        
                             </div>
        
              )
        
              }
        
        
              else{
                 return (
      
      <textarea  className="scan-text" value={procedureList[cellInfo.index].details}  cols="100" rows="2"/>
              )
            }
      }
      
      // const handleProcedureReasonAdd =(index)=> {
      //   setCell(index)
      //   setReasonAddShow(true)
      // }
      
      // const handleProcedureReasonEdit =(data,index) => {
      //   setDetailData(data)
      //   setCell(index)
      //   setReasonEditShow(true)
      // }
      
      const handleProcedureReasonEdit =(data,index) => {
        setDetailData(data)
        setCell(index)
        setProcedureReasonShow(true)
      }

      const renderProcedureReason= (cellInfo)=> {
        
        if(cellInfo.index > procedureListCounter-1){
          if(procedureList[cellInfo.index].reason==="" || procedureList[cellInfo.index].reason===null){
            return (
              <button onClick={()=> handleReasonAdd(cellInfo.index,'toggle')}  className='btn btn-smallest add-remark-btn'><i className="fas fa-plus"></i></button>
            )
          }
          else{
            return(
              <button onClick={()=> handleProcedureReasonEdit(procedureList[cellInfo.index].reason, cellInfo.index)} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
            )
          }
            
        }
            else{
              if(procedureList[cellInfo.index].reason==="" || procedureList[cellInfo.index].reason===null){
                return 'NIL'
                
              }
              else{
                return <button onClick={()=> handleView(procedureList[cellInfo.index].reason)} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
              }
            }
      }

      const renderStatus=(cellInfo)=> {

        if(procedureList[cellInfo.index].status !== "") {

          if(procedureList[cellInfo.index].status==="assigned"){
            return (
         
               <div ><div className="status accepted-status-2 caps"> {procedureList[cellInfo.index].status }</div></div>
               
             );
           }
           else if(procedureList[cellInfo.index].status==="ongoing"){
             return (
         
               <div ><div className="status ongoing-status-2 caps"> {procedureList[cellInfo.index].status}</div></div>
               
             );
           }
           if(procedureList[cellInfo.index].status==="completed"){
             return (
         
               <div ><div className="status completed-status-2 caps"> {procedureList[cellInfo.index].status}</div></div>
               
             );
           }
           if(procedureList[cellInfo.index].status==="rejected"){
             return (
         
               <div ><div className="status rejected-status-2 caps"> {procedureList[cellInfo.index].status}</div></div>
               
             );
           }
           if(procedureList[cellInfo.index].status==="pending"){
            return (
        
              <div ><div className="status pending-status-2 caps"> {procedureList[cellInfo.index].status}</div></div>
              
            );
          }

           else {
             return null
           }
        }

        
      }
      
          
      const renderAssigned=(cellInfo)=> {

        return (<div  >
        
           
       
           
       
             
             <input
               style={{ backgroundColor: "#fafafa" }}
               value= {procedureList[cellInfo.index].assign_name}
               className="form-control"        
              name="assignee"
              
               onChange={(e)=>{
       
                 e.preventDefault();
       
       
       
       
        
         const list = procedureList;
                 list[cellInfo.index].assign_name = e.target.value;
                 
                 
                 setProcedureList(list);
       
                       setCurrentIndex(cellInfo.index);
                                    setCurrentColumn(cellInfo.column.id);
                                      
                                      setDisplayAssign(true);
       
         setSearchAssign(e.target.value);
       
         
          } }
          
                      
             />
       
               {currentIndex === cellInfo.index && cellInfo.column.id=== "assign_name" && displayAssign && (<div className="suggestions-box-holder">
                  <button className="btn-suggestion btn btn-secondary btn-smallest" title="close" onClick={()=>setDisplayAssign(false)}> X </button>
       
                   <div className="suggestions-box suggestions-assignee-box">
                   
       
                    {assignList.map((item,i)=>{
                
           return(
             <div className="scan-item" 
                 
                   key={item.id} 
                   onClick={(e)=>{ e.preventDefault();
                    
                     setRender(!render);
                     
                                             const List = procedureList;
                                             //List[cellInfo.index].assign_id= item.id;
                                              List[cellInfo.index].assign_name= item.name;
                                              List[cellInfo.index].assign_id= item.id;
                                        
                                             setProcedureList(List);
                                             setDisplayAssign(false);
       
                                             }}
                   >
                   <div className="search-item each-option-suggestion">
             <h6 className="">ID: {item.id} </h6>
             <h6 className=""> {item.name}</h6>
              </div>
       
             </div>
             )
         }) 
                  }
       
               </div>
                </div>
               )
             }
             
       
             </div>
               
       
           )
       
             }          
      

      const renderSave = (cellInfo) => {
        if(procedureList[cellInfo.index].image instanceof File === false && procedureList[cellInfo.index].assign_id !== undefined ){
          return (
              <button className='btn btn-primary btn-col'>Save</button>
            )
        }


      }


      const AddRowProcedure=()=>{
        const list = procedureList;
        list.push({ 
          procedure_details: {
            procedure_name : "",
            procedure_id : "",
            procedure_type : "",
            amount : "",
          },
          date_of_procedure: new Date().toISOString(),
          area : "",
          session : "",
          quantity : "",
          details : "",
          reason : "",
          image : "",
          assign_name : "",
          status : "",
         });
        
        setProcedureList(list);
        setRender(true);
      
      }


const columnsProcedure = [


{
Header: <i className="fas fa-trash-alt"></i>,
Cell: props=>{
  return (
<i className="fas fa-trash-alt trash-btn" onClick={()=>{deleteProcedureRow(props.index)}} ></i>
  )
},
width:60,
sortable:false,
filterable:false
},

{
  Header: <><h6>Procedure Name</h6>
  <h6>(Select from the </h6>
  <h6>list only)</h6>
</>,
  accessor: "procedure_details",
  Cell: renderEditableProcedure,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:250,
  minWidth:100,
   sortable:false

},

{
  Header: "DATE (YYYY-MM-DD)",
  accessor: "date_of_procedure",
  Cell: renderEditableProcedureDate,
   sortable:false,
   width:150


},
{
  Header: "Area",
  accessor: "area",
  Cell: renderProcedureArea,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:140,
  minWidth:100,
   sortable:false

},
{
  Header: "Type",
  accessor: "procedure_details",
  Cell: renderProcedureType,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:140,
  minWidth:100,
   sortable:false

},
{
  Header: "Sessions",
  accessor: "session",
  Cell: renderProcedureSession,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:140,
  minWidth:100,
   sortable:false

},
{
  Header: "Qty",
  accessor: "quantity",
  Cell: renderProcedureQuantity,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:100,
  minWidth:100,
   sortable:false

},
{
  Header: "Price",
  accessor: "procedure_details",
  Cell: renderProcedurePrice,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width:140,
  minWidth:100,
   sortable:false

},
{
  Header: "Procedure Details",
  accessor: "details",
  Cell: renderProcedureDetails,
   sortable:false,
   filterable:false,
   width:310

},
{
  Header: "Reason",
  accessor: "reason",
  Cell: renderProcedureReason,
   sortable:false,
   filterable:false,
   width:110

},
{
  Header: <><h6>Status</h6>
            </>,
  accessor: "status",
  Cell: renderStatus,
   sortable:true,
    filterable:false,
                     
   width:150

},
{
  Header: <><h6>Assignee</h6>
           <h6>(Select from the </h6>
           <h6>list only)</h6>
         </>,
  accessor: "assign_name",
  Cell: renderAssigned,
   style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },

   sortable:false,
   filterable:false,
   
  //  filterMethod:(filter, row, column) => {
  //   const id = filter.pivotId || filter.id
  //   return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  // },     
   width:140

},
{
  Header: <i class="fas fa-upload"></i>,
  accessor: "image",
  Cell: renderProcedureImage,
  sortable:false,
  filterable:false,
  width:100
},

{
  Header: "ADD",
  
  Cell: props=>{
    return (
    props.index === procedureList.length- 1 ? 
    <button className="btn btn-primary btn-col btn-col btn-col btn-small" onClick={AddRowProcedure}>+Add</button> 
    : null 
    )
  },
   width:80,
  sortable:false,
  filterable:false
},

]

const columns = [


  {
  Header: <i className="fas fa-trash-alt"></i>,
  Cell: props=>{
    return (
  <i className="fas fa-trash-alt trash-btn" onClick={()=>{deleteRow(props.index)}} ></i>
    )
  },
  width:60,
  sortable:false,
  filterable:false
  },
  
  {
    Header: "TEST NAME",
    accessor: "test",
    Cell: renderEditableTest,
    style:{
      backgroundColor:"rgba(0,0,0,0.1)"
    },
    width:310,
    minWidth:100,
     sortable:false
  
  },
  
  {
    Header: "DATE (YYYY-MM-DD)",
    accessor: "date",
    Cell: renderEditableTestDate,
     sortable:false,
     width:190
  
  
  },
  {
    Header: "Test Details",
    accessor: "labtest_reason",
    Cell: renderTestDetails,
     sortable:false,
     filterable:false,
     width:310
  
  },
  {
    Header: "Reason",
    accessor: "labtest_reason",
    Cell: renderTestReason,
     sortable:false,
     filterable:false,
     width:110
  
  },
  {
    Header: <i class="fas fa-upload"></i>,
    accessor: "labtest_reason",
    Cell: renderTestImage,
    sortable:false,
    filterable:false,
    width:100
  },
  
  {
    Header: "ADD",
    
    Cell: props=>{
      return (
      props.index === testsList.length- 1 ? 
      <button className="btn btn-primary btn-col btn-col btn-col btn-small" onClick={AddRowTest}>+Add</button> 
      : null 
      )
    },
     width:80,
    sortable:false,
    filterable:false
  }
  
  ]


const AddRowScan=()=>{
  const list = scanList;
  list.push({id:"",test: "", date:new Date().toISOString(), scan_details:{details:"",image: "",reason:""}});
  
  setScanList(list);
  setRender(true);

}
const handleDeleteRowScan = (index)=>{
  setDeleteScanConfirmMsg('success');
  setDeleteScanConfirmModalShow('true');
  setScanIndexToDelete(index);
   
}

  const DeleteScanConfirmPopup =(props)=>{
   
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Please confirm to delete Scheduled Scan</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{setDeleteScanConfirmModalShow(false);
                                                               handleDeleteScan(props.index);
                                                               setScanIndexToDelete(null);}}> Confirm</Button>

       

      </Modal.Footer>
    </Modal>
  );
}

const DrNotesPopup =(props)=>{
   
  
  return (
       <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <DrNotes  {...props}/> 
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>

       

       

      </Modal.Footer>
    </Modal>
  );
}

const handleAddObs = () => {
  setObservationsShow(false)
  setRefresh(!refresh)
}

const handleAddSymptoms = () => {
  setSymptomsShow(false)
  setRefresh(!refresh)
}

const SymptomsPopup =(props)=>{
   
  
  return (
       <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        size='lg'
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <h3 className="title-of-tasks header">Symptom</h3>
       
        <AddSymptoms id={id} handleAdd={handleAddSymptoms}/>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button className='btn btn-primary btn-col' onClick={props.onHide}> X Close</Button>

       

       

      </Modal.Footer>
    </Modal>
  );
}

const ObservationsPopup =(props)=>{
   
  
  return (
       <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        size='lg'
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <h3 className="title-of-tasks header">Observation</h3>
       
        <AddObs id={id} handleAdd={handleAddObs}/>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button className='btn btn-primary btn-col' onClick={props.onHide}> X Close</Button>

       

       

      </Modal.Footer>
    </Modal>
  );
}

const SizePopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">You have Exceeded the size limit 2MB</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const LoadPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select an Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ValidPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select a valid Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ContentPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Invalid Image Content</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const handleDeleteScan=(index)=>{
 
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
    
    
  const dataToUpdate ={
    ts_id: scanList[index].id,
    patient_id: id
  }


  
    axios.put(`${APIURL}/api/v1/doctor/patient-record/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str,
      'Content-Type': 'application/json'  }


    })



    .then(res=>{
   
      if(res.data.status==="success"){
      setReRender(!reRender);
    
     
  }
  else{
   
   
  }

    })
    .catch(err=>{
  
  setSubmitMsg("error");
})


 
}
const deleteRowScan=(index)=>{
  let newlist=[];

  if(index>scanListCounter-1){
  newlist = scanList.filter((scan,i)=>{
    return i!== index

  })

  setScanList(newlist);
 
  if(newlist.length<=0){
  
    setScanList([{id:"",test: "", date:"", scan_details:{details:"",image: "",reason:""}}]);
    
  }
}
else{

  handleDeleteRowScan(index);
    }
}


const renderEditableScan=(cellInfo)=> {

   if(cellInfo.index > scanListCounter-1 ||scanListCounter===0){
    return (<div >
      
      <input
        style={{ backgroundColor: "#fafafa" }}
        value= {scanList[cellInfo.index][cellInfo.column.id]}
        className="form-control"        
       name="test"

        onChange={(e)=>{

           e.preventDefault();

setCurrentIndex(cellInfo.index);
                             setCurrentColumn(cellInfo.column.id);
                               setDisplaySuggestions(true);
                               setDisplayScan(true);
 

 
  
  setSearchScan(e.target.value);

  
 
         const scans = scanList;
          scans[cellInfo.index].test = e.target.value;
          
          
          setScanList(scans);
   } }
     autoComplete="off"
               
      />

       

         
        {currentIndex === cellInfo.index && cellInfo.column.id=== "test" && displayScan && (<div className="suggestions-box">
             {scanSuggest.map((item,i)=>{
         
    return(
      <div className="scan-item" 
          
            key={i} 
            onClick={(e)=>{ e.preventDefault();
              
              setRender(!render);
              
                                      const scans=scanList;
                                      scans[cellInfo.index].test= item;
                                      
                                      setScanList(scans);
                                      setDisplayScan(false);

                                      }}
            >
      <p className="search-item">{item}</p>
      </div>
      )
  }) 
           }

        </div>)
      }
      

      </div>
        

    )
  }
  else{
     return (<div>
      
      <div
        style={{ backgroundColor: "#fafafa" }}
        
        className="form-control scan-name-col"        
       name="scan"
         
       
        value={scanList[cellInfo.index].scan}
       
        onBlur={(e)=>{const scans = scanList;
          scans[cellInfo.index].scan = e.target.innerHTML;
          
          setScanList(scans);


       }}
        
        
     autoComplete="off"
      dangerouslySetInnerHTML={{
          __html: scanList[cellInfo.index][cellInfo.column.id]
        }}
      />

       

         
       
        </div>)
  }
  }

  const renderEditableScanDate=(cellInfo)=> {
  if(cellInfo.index > scanListCounter-1){

    const today =new Date();
    
    const offset = today.getTimezoneOffset();

let dateUpdated = new Date(today.getTime() - (offset*60*1000));



let DateExtracted = dateUpdated.toISOString().split('T')[0];
    return (<div>
      <input type="date" data-date={scanList[cellInfo.index].date.split('T')[0]} date className="scan-date-col date-custom-format" min={DateExtracted} onChange={e=>

                              {
                                e.preventDefault();
                                let date= new Date(e.target.value)
                                const offset = date.getTimezoneOffset();

                                let updated = new Date(date.getTime() - (offset*60*1000));

                                let extracted = date.toISOString()

                                const scans = scanList;
          scans[cellInfo.index].date = extracted;
        
          setScanList(scans);
          const m = moment(date,"YYYY-MM-DD").format("YYYY-MM-DD");
        
          setDateVal(m);
                               }
}
/> 

                     </div>

      )

      }


      else{
         return (<div>
      
      <div
        style={{ backgroundColor: "#fafafa" }}
        
        className="form-control scan-date-col"        
       name="date"
         
       
        value={moment.utc(scanList[cellInfo.index].date).local().format('DD-MM-YYYY')}
       
        onBlur={(e)=>{const scans = scanList;
          scans[cellInfo.index].date.split('T')[0] = e.target.innerHTML;
          
          setScanList(scans);


       }}
        
        
     autoComplete="off"
      dangerouslySetInnerHTML={{
          __html: moment.utc(scanList[cellInfo.index].date).local().format('DD-MM-YYYY')
        }}
      />

       

         
       
        </div>)
      }
      }

  
      let sendData = null

function handleSend(childData){
  sendData = childData
}

const addHandle= ()=> {
  setAddShow(false)
  const scans =scanList
  scans[cell].scan_details.reason= sendData
  setScanList(scans)
  setEditSuccess(true)
}

const AddPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <AddRemark update={handleSend} save={addHandle}/>

      </Modal.Body>
      <Modal.Footer>
         
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
       

      </Modal.Footer>
    </Modal>
  );
}

const RemarkViewPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <ViewRemark data={detailData}/>

      </Modal.Body>
      <Modal.Footer>
         
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
       

      </Modal.Footer>
    </Modal>
  );
}


const DetailPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditRemark data={detailData} update={handleSend}/>
  
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

      </Modal.Footer>
    </Modal>
  );
}

const DetailsUpdateSuccessPopUp=(props)=> {
 
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Updated sucessfully.Please Submit to Save Changes</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}

const PatientDetailsPopUp=(props)=> {
 
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Updated sucessfully.</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}


const HistoryPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditRemark data={detailData} update={handleSend}/>
  
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

      </Modal.Footer>
    </Modal>
  );
}

const MedHistoryPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditRemark data={detailData} update={handleSend}/>
  
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

      </Modal.Footer>
    </Modal>
  );
}

const ComplaintPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditRemark data={detailData} update={handleSend}/>
  
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

      </Modal.Footer>
    </Modal>
  );
}


const HistoryAddPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddDetails update={handleSend}/>
  
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

      </Modal.Footer>
    </Modal>
  );
}

const MedHistoryAddPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddDetails update={handleSend}/>
  
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

      </Modal.Footer>
    </Modal>
  );
}

const ComplaintAddPopup=(props)=>{

  return (
    <Modal
      {...props}
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddDetails update={handleSend}/>
  
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}>Close</Button>
         <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

      </Modal.Footer>
    </Modal>
  );
}


const handleHistory = (data) => {
  setHistoryEditShow(true)
  setDetailData(data)
}

const handleMedHistory = (data) => {
  setMedEditShow(true)
  setDetailData(data)
}

const handleComplaint = (data) => {
  setComplaintEditShow(true)
  setDetailData(data)
}

const handleHistoryAdd = () => {
  setHistoryAddShow(true)
}

const handleFamAdd = () => {
  setMedAddShow(true)
}

const handleComplaintAdd = () => {
  setComplaintAddShow(true)
}

const callSaveDetails = () => {

  const tokenString= localStorage.getItem('usertoken');
   
  let str= tokenString.replace(/["]+/g, '')  
         
   const data = {
    present_complaint: patientData.present_complaint,
    past_medical_history: patientData.past_medical_history,
    family_history: patientData.family_history
   }
   
 
   axios.put(`${APIURL}/api/v1/hospital/add-hospital-userdetails/?patient_id=${id}`, data, { headers: {
     
     "Authorization" : 'Token '+str  }})
   .then(res=>{
       
     if(res.data.status==="success"){
      
        setEditDetailSuccess(true)
     }


   })
   .catch(err=>{

})

}

const handleAdd =(index)=> {
  setCell(index)
  setAddShow(true)
}

const handleView =(data) => {
  setDetailData(data)
  setRemarkViewShow(true)
}

const handleEdit =(data,index) => {
  setDetailData(data)
  setCell(index)
  setEditShow(true)
}

  const renderDetails= (cellInfo)=> { 

    if(cellInfo.index > scanListCounter-1){
      return (<div>
        <textarea  className="text-area" placeholder="Add Scan Details"  cols="100" rows="2"  onChange={e=>
  
                                {
                                  e.preventDefault();
                                  let data=e.target.value;
                                 
                                  const scans = scanList;
                                  scanList[cellInfo.index].scan_details.details = data;
            
            setScanList(scans);

                                 }
  }
  /> 
  
                       </div>
  
        )
  
        }
  
  
        else{
           return (

<textarea  className="scan-text" value={scanList[cellInfo.index].scan_details.details}  cols="100" rows="2"/>
        
        /*<div
          style={{ backgroundColor: "#fafafa" }}
          
          className="form-control scan-date-col"        
         name="date"
           
         
          value={scanList[cellInfo.index].scan_details.details}
         
          onBlur={(e)=>{const scans = scanList;
            scanList[cellInfo.index].scan_details.details = e.target.innerHTML;
            
            setScanList(scans);
  
  
         }}
          
          
       autoComplete="off"
        dangerouslySetInnerHTML={{
            __html: scanList[cellInfo.index].scan_details.details
          }}
        />
  
         
  
           
         
          </div>*/)
        }
}

const renderReason= (cellInfo)=> {
  
  if(cellInfo.index > scanListCounter-1){
    if(scanList[cellInfo.index][cellInfo.column.id].reason==="" || scanList[cellInfo.index][cellInfo.column.id].reason===null){
      return (
        <button onClick={()=> handleAdd(cellInfo.index)}  className='btn btn-smallest add-remark-btn'><i className="fas fa-plus"></i></button>
      )
    }
    else{
      return(
        <button onClick={()=> handleEdit(scanList[cellInfo.index][cellInfo.column.id].reason, cellInfo.index)} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
      )
    }
      
  }
      else{
        if(scanList[cellInfo.index][cellInfo.column.id].reason==="" || scanList[cellInfo.index][cellInfo.column.id].reason===null){
          return null
          
        }
        else{
          return <button onClick={()=> handleView(scanList[cellInfo.index][cellInfo.column.id].reason)} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
        }
      }
}

const editImage = (pos)=> {
  setCell(pos)
  const fileInput = document.getElementById('imageInput')
  fileInput.click();
}

const uploadImage= (e)=> {
  const file=  e.target.files[0];
            const scans =scanList
            if(!file){
              setUploadShow(!uploadShow)
              return false
            }
            if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
              setValidShow(!validShow)
              return false;
            }
            if(file.size>2e6){
              setSizeShow(!sizeShow)
              return false
            }
            reader.onload = (e) => {
              const img1 = new Image();
              img1.onload = () => {
                scans[cell].scan_details.image= file
                setScanList(scans)
                setIndex(cell)
                setTableRefresh(!tableRefresh)
              };
              img1.onerror = () => {
                setContentShow(!contentShow)
                
                return false;
              };
              img1.src = e.target.result;
            };
            reader.readAsDataURL(file);     

}

  const renderImage= (cellInfo)=> {
    if(cellInfo.index > scanListCounter-1){
      /*if(scanList[cellInfo.index].scan_details.image!==null || scanList[cellInfo.index].scan_details.image!==''){
        return(
          <button onClick={()=> {setPopupShow(true); setIndex(cellInfo.index)}} className='btn btn-secondary btn-square btn-smallest'>
            {scanList[cellInfo.index].scan_details.image instanceof File ? 'View/Edit' : 'View'}</button>
          )
        }*/

      if(scanList[cellInfo.index].scan_details.image===null || scanList[cellInfo.index].scan_details.image===''){
        return(
          <div>
            <input id='imageInput' hidden='hidden' type="file"  onChange={uploadImage} />
  
            <i onClick={()=>{editImage(cellInfo.index)}} class="fas fa-image select-img"></i>
          </div>
        )
      }
      else {
        return(
          <button onClick={()=> {setPopupShow(true); setIndex(cellInfo.index)}} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
        )
      }
      /*else {
        return(
          <button onClick={()=> {setPopupShow(true); setIndex(cellInfo.index)}} className='btn btn-secondary btn-square btn-smallest'>
            {scanList[cellInfo.index].scan_details.image instanceof File ? 'View/Edit' : 'View'}</button>
        )
      }*/
    }
    
    else{
      if(scanList[cellInfo.index].scan_details.image===null || scanList[cellInfo.index].scan_details.image===''){

        return null
        
      }
      else{
        return(
          <button onClick={()=> {setViewShow(true); setIndex(cellInfo.index)}} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
        )
      }
      
    }
    
  }

  const columnsScans = [


  {
    Header: <i className="fas fa-trash-alt"></i>,
    Cell: props=>{
      return (
                <i className="fas fa-trash-alt trash-btn" onClick={()=>{deleteRowScan(props.index)}}></i>
      )
    },
    width:60,
    sortable:false,
    filterable:false
    },
    {
      Header: "SCAN NAME",
      accessor: "test",
      Cell: renderEditableScan,
      style:{
        backgroundColor:"rgba(0,0,0,0.1)"
      },
      width:310,
      minWidth:100,
       sortable:false
    
    },
    {
      Header: "DATE (YYYY-MM-DD)",
      accessor: "date",
      Cell: renderEditableScanDate,
       sortable:false,
       width:190
    
    },

    {
      Header: "Scan Details",
      accessor: "scan_details",
      Cell: renderDetails,
       sortable:false,
       filterable:false,
       width:310
    
    },
    {
      Header: "Reason",
      accessor: "scan_details",
      Cell: renderReason,
       sortable:false,
       filterable:false,
       width:110
    
    },
    {
      Header: <i class="fas fa-upload"></i>,
      accessor: "scan_details",
      Cell: renderImage,
      sortable:false,
      filterable:false,
      width:100
    },
    {
      Header: "ADD",
     
      Cell: props=>{
        return (
        props.index === scanList.length- 1 ? 
        <button className="btn btn-primary btn-col btn-col btn-small" onClick={AddRowScan}> +Add</button> 
        : null 
        )
      },
       width:80,
      sortable:false,
      filterable:false
    }
    
    ]

    const handlePulse =()=> {
      setAddPulse(true)
    }

    const handleBp =()=> {
      setAddBp(true)
    }

    const handleObs= () => {
      setRefresh(!refresh)
    }

const renderTooltip1 = (props) => (
  <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
   Includes Personal Datas and Vitals
  </Tooltip>
);

const renderTooltip2 = (props) => (
  <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
    Set Test Schedules,Scan schedules , Procedures and Next Visit for the patient
  </Tooltip>
);

const renderTooltip3 = (props) => (
  <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
    Current Medications taken by the patient displayed here. Can also set new medication.
  </Tooltip>
);
const renderTooltip4 = (props) => (
  <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
    Observations noted is displayed here. Can set new Observations.
  </Tooltip>
);
const renderTooltip5 = (props) => (
  <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
    Symptoms noted is displayed here. Can set new Symptoms.
  </Tooltip>
);

const renderTooltip6 = (props) => (
  <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
    Includes Allergies and Diagnosis
  </Tooltip>
);
const renderTooltip7 = (props) => (
  <Tooltip className='my-tooltip' id="button-tooltip" {...props}>
    Any Instructions for the patient to follow is displayed. Can set/update instructions.
  </Tooltip>
);


// let summaryArray = summary.split(/[\r\n]+/);
// let  displaySummary= summaryArray.length>1 ? summaryArray.map((line,i)=>{
//   if(line){
//   return <><h6 className="text-left "> {i+1}.{line} </h6></>
// }
//  }) : "-No data to show-" ;


// let displaySummaryTable= <div className="sub-section inferences-subsection">
//                       <div className="sub-section-header box-theme">
                               

//                       <h6 className="sub-section-title"> Inferences <span className="ref-text">(Ref: last 60 days) </span><OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip7}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
//                       </div>
//                       <div className="sub-section-body">  
//                       {displaySummary}
//                       </div>

//                       </div>


const ProcedureHistoryPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <ProcedureHistory id={id}/>

      </Modal.Body>
      <Modal.Footer>
         
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
       

      </Modal.Footer>
    </Modal>
  );
}

const getUpcomingProcedure = () => {

  const data = procedureList.filter(item => {

      return item.date_of_procedure >= new Date().toISOString().slice(0,10) && item.assign_name !== ""

  }).sort((a,b) => {
     return new Date(a.date_of_procedure) - new Date(b.date_of_procedure)
  })

  return data
}



return(<>
<HospDocNavbar /> 
<br/>

    <div className="patient-record-holder ">
             <div className="sticky-nav">
      <div className="left-nav">
          
         
         <ul className="stickynav-ul"> 
            
         <li onClick={()=>{handleClick(ref1)}}> Personal Data and Vitals <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip1}><i className="fas fa-info-circle"></i></OverlayTrigger> </li> 
            <li onClick={()=>{handleClick(ref2)}}> Treatment Planner <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip2}><i className="fas fa-info-circle"></i></OverlayTrigger> </li> 
            <li onClick={()=>{handleClick(ref3)}}> Current Medications <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip3}><i className="fas fa-info-circle"></i></OverlayTrigger> </li> 
            <li onClick={()=>{handleClick(ref4)}}> Observations <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip4}><i className="fas fa-info-circle"></i></OverlayTrigger></li> 
            <li onClick={()=>{handleClick(ref5)}}> Symptoms <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip5}><i className="fas fa-info-circle"></i></OverlayTrigger></li> 
            <li onClick={()=>{handleClick(ref6)}}> Diagnostics Info <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip6}><i className="fas fa-info-circle"></i></OverlayTrigger> </li>  
            <li onClick={()=>{handleClick(ref7)}}> Instructions <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip7}><i className="fas fa-info-circle"></i></OverlayTrigger> </li>
          

            <span className="history-link" onClick={handleDrNotesOverlay} >
            <div className="sub-section-header header-box1 box-4 box-dr-notes">
                             
            <h6  className="sub-section-title dr-notes-nav"> Doctor Notes </h6>


            </div>
              </span> 
               {/* <span  className="freq-link text-left"><Link to={{pathname:freq_link}} > <div className=" box-4 box-dr-notes header-box1 freq-box sub-section-header">
                           

            <h6 className="med-tests-link">Frequent Medicines/Tests Analysis</h6>
            </div>
            </Link></span> */}
           

            {/**********
             
           
            <button className="btn btn-primary btn-col btn-col btn-square btn-small" onClick={handleSubmit}> Save & Submit </button>
            **************/}
         </ul>


      </div>


      </div>
    

  <div className="right-section">

  <h4><span className="title-of-tasks" id={gender==='female' ? 'patient-female' : 'patient-male'}> {name} </span><span className="prime-header felixa-header"> (Felixa ID: {id})</span></h4>
   <br/>

  <div className="info section-1  zero-contentbox"  > 
        
        
        <div className="box-2 visit-box  panel-box" onClick={()=>{handleClick(ref1)}}><h6 className="prime-header "> Next Visit : {next_visit ? <span> {moment.utc(next_visit).local().format('DD-MM-YYYY')}</span> : <span>  ---</span> }</h6></div>
        {/* <div className="box-1 visit-box panel-box" onClick={()=>{handleClick(ref1)}}><h6 className="prime-header">  Upcoming Test : {testsList.length>0 ? <><span>{testsList[0].test ? testsList[0].test : "---"}</span><h6> {testsList[0].test ? moment.utc(testsList[0].date).local().format('DD-MM-YYYY') : ''}</h6></> : null}</h6></div> */}
         {/* <div className="box-4 visit-box panel-box" onClick={()=>{handleClick(ref1)}}><h6 className="prime-header"> Upcoming Scan : {scanList.length>0 ? <><span>{scanList[0].test ? scanList[0].test : "---"}</span><h6> {scanList[0].test ? moment.utc(scanList[0].date).local().format('DD-MM-YYYY') : ''}</h6></> : null }</h6></div> */}
         <div className="box-4 visit-box panel-box" onClick={()=>{handleClick(ref1)}}><h6 className="prime-header"> Upcoming Procedure : {procedureList.length>0 ? <><span>{getUpcomingProcedure()[0] ? getUpcomingProcedure()[0].procedure_details.procedure_name : "---"}</span><h6> {getUpcomingProcedure()[0] ? getUpcomingProcedure()[0].date_of_procedure.slice(0,10) : ''}</h6></> : null }</h6></div>
         {/* <div className="box-4 visit-box panel-box" onClick={()=>{handleClick(ref7)}}><h6 className="prime-header"> Procedure : {scanList.length>0 ? <><span>{scanList[0].test ? scanList[0].test : "---"}</span><h6> {scanList[0].date}</h6></> : null }</h6></div> */}
 
 </div>


  <div style={{justifyContent:'space-evenly'}} className="info section-1 each-section first-contentbox " ref={ref1}> 
          
         
             
          <div style={{width:'30%'}} className="second-tab sub-section ">        
                    
                 <div className="sub-section-header box-theme">
                               
                    
                      <h6 className="sub-section-title"> Personal Data </h6>
                    </div>
                      <div className="personal-details-grid">
                
                      <h6 className='text-left'> Name : </h6>
                          <span className="user-details text-left">{patientData.name}</span>

                          <h6 className='text-left'> Email : </h6>
                          <span className="user-details text-left">{patientData.email}</span>            
                 
                  <h6 className='text-left'> Phone : </h6>
                  <span className="user-details text-left">{patientData.mobile_number}</span>

                  <h6 className='text-left'> Location : </h6>
                  <span className="user-details text-left">{patientData.country} , {patientData.region}</span>

                  <h6 className='text-left'> Gender : </h6>
                  <span className="user-details text-left">{patientData.gender}</span>

                  <h6 className='text-left'> DOB: </h6>
                  <span className="user-details text-left"> {patientData.dob}</span>

                  <h6 className='text-left'> File Number: </h6>
                  <span className="user-details text-left"> {patientData.file_number}</span>

                  <h6 className='text-left'> CPR/Passport Number: </h6>
                  <span className="user-details text-left"> {patientData.cpr ? patientData.cpr : '-'}</span>

                  <h6 className='text-left'> Family History: </h6>
                  { patientData.family_history ? <button onClick={() => handleHistory(patientData.family_history)} style={{justifySelf:"start"}} className='btn btn-primary btn-square btn-small btn-col ml-2 mb-2'>View/Edit</button> :
                   <button onClick={() => handleHistoryAdd()} style={{justifySelf:"start"}} className='btn btn-primary btn-square btn-small btn-col ml-2 mb-2'>Add</button> } 

                  <h6 className='text-left'> Medical History: </h6>
                  { patientData.past_medical_history ? <button onClick={() => handleMedHistory(patientData.past_medical_history)} style={{justifySelf:"start"}} className='btn btn-primary btn-square btn-small btn-col ml-2 mb-2'>View/Edit</button> :
                  <button onClick={() => handleFamAdd()} style={{justifySelf:"start"}} className='btn btn-primary btn-square btn-small btn-col ml-2 mb-2'>Add</button> }

                  <h6 className='text-left'> Complaint: </h6>
                  { patientData.present_complaint ?  <button onClick={() => handleComplaint(patientData.present_complaint)} style={{justifySelf:"start"}} className='btn btn-primary btn-square btn-small btn-col ml-2'>View/Edit</button> :
                  <button onClick={() => handleComplaintAdd()} style={{justifySelf:"start"}} className='btn btn-primary btn-square btn-small btn-col ml-2'>Add</button> }

                 {/* <h6 className="user-details"> Place:  -  </h6>
                    <h6 className="user-details"> Phn:  -  </h6> */}
                 {/* <h6><i className="fa fa-phone" aria-hidden="true"></i> : +919876363647 </h6>
                  <h6>Addr: Palarivattom, <br/> Kochi, <br/>676123 </h6>  */}
                  </div>
          </div>
          

          <div style={{width:'30%'}} className="sub-section vitals-subsection diagnostics">
                      <div className="sub-section-header box-theme">
                               

                      <h6 className="sub-section-title"> Vitals <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip1}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
                      </div>
                      <div className="sub-section-body">
                      <div className="vitals-col">
                      <div className="vitals-row">

                               <div className="each-list-title each-tile box-4 colored-box colored-box-small" id="vitals-tile-small" onClick={handlePulseOverlay}>
                               <span className="history-link" >
                              <h6> Pulse </h6>
                              </span>
                              </div>
                              <button onClick={handlePulse} className='btn btn-add-vitals btn-smallest'>+</button>


                      </div> 
                       <div className="vitals-row">
                               <div className="each-list-title each-tile box-4 colored-box colored-box-small" id="vitals-tile-small"  onClick={handleBpOverlay}>
                              <span className="history-link" >
                              <h6>Blood Pressure </h6>
                              </span>
                              </div>
                              <button  onClick={handleBp} className='btn btn-add-vitals btn-smallest'>+</button>
                      </div> 





                     </div>
                      </div>

                      </div>


         {/* <div className="second-tab sub-section imp-info-subsection">
            <div className="sub-section-header header-box1">
                   <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-bookmark-fill" fill="var(--red)" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5V2z"/>
            </svg>
               
            <h6 className="sub-section-title"> Targets <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip2}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
            </div>
        
            <div className="target-tab-content">
            <h6 style={{color:"grey",fontWeight:"bold"}}>Main Targets: </h6> 
              <Suspense fallback={<div>Loading...</div>}>

                <TargetBasic {...props} />

              </Suspense>
                
                                 
                                 <br/>
                                  <span className="history-link" onClick={handleTargetOverlay} >

                                       <button className="btn btn-primary btn-col btn-col btn-smallest btn-square"> Goto Target Tables </button>
                                  </span>
                                 <br/>

            </div>
            
        </div> */}
    

       

</div>


             {/*****Schedule planners****/}
          <div className="each-section section-5 " ref={ref2}>
                <h5 className="sub-title head-strip">  Treatment Planner <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip2}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>
                <div className="schedule-planners">
              {/************/}

              <div style={{gap:'10px'}} className="second-contentbox">

                <div className='next-visit-box'>
                  <h5 className="font-weight-bold">Next Visit</h5>
                  <div className='d-flex flex-row justify-content-around'>
            <div className='d-flex flex-column align-self-center'>
            <h6>Select Doctor from list</h6>
            <input
               style={{ backgroundColor: "#fafafa" }}
               onClick={() => {
                 setSearchAssign("");
                 setDisplayDoctor(true)
               }}
               value= {doctorName}
               className="form-control"        
              name="assignee"
              
               onChange={(e)=>{
       
                 e.preventDefault();
       
       
       
                setDoctorName(e.target.value)
                setDisplayDoctor(true);
       
         setSearchAssign(e.target.value);
       
         
          } }
          
                      
             />
       
               { displayDoctor && (<div className="suggestions-box-holder">
                  <button className="btn-suggestion btn btn-secondary btn-smallest" title="close" onClick={()=>setDisplayDoctor(false)}> X </button>
       
                   <div className="suggestions-box suggestions-assignee-box">
                   
       
                    {assignList.map((item,i)=>{
                
           return(
             <div className="scan-item" 
                 
                   key={item.id} 
                   onClick={(e)=>{ e.preventDefault();
                    
                     setRender(!render);
                     setDoctorId(item.id)
                     setDoctorName(item.name)
                     setDisplayDoctor(false);
       
                                             }}
                   >
                   <div className="search-item each-option-suggestion">
             <h6 className="">ID: {item.id} </h6>
             <h6 className=""> {item.name}</h6>
              </div>
       
             </div>
             )
         }) 
                  }
       
               </div>
                </div>
               )
             }

            </div>

                
                 <div style={{alignSelf:"flex-start"}} className="each-list-title each-tile box-4 colored-box colored-box-small schedule-box" >

                        <div>
                          <DatePicker
                          format='dd-MM-yyyy'
                          value={next}
                          onChange={date=>{handleNextVisit(date)}}
                           minDate = {new Date()}
                           clearIcon={null}

                          
                     /> 
                      <button className="delete-btn btn-smallest" 
       onClick={()=>{handleDeleteNextVisit1()}}> X </button>
                     </div>

              
                  </div> 

                  </div>
                </div>


            {/* <div className="each-list-title each-tile box-4 colored-box colored-box-small"  onClick={handleDisplayTestTable}>
 <span className="history-link"><h6>Test Schedules </h6></span></div> */}


   {/* <div className="each-list-title each-tile box-4 colored-box colored-box-small"  onClick={handleDisplayScanTable}>
   <span className="history-link"><h6>Scan Schedules </h6></span></div> */}

   {/* <div class="vl"></div> */}


   {/* <div className="each-list-title each-tile colored-box colored-box-small" id='box-1'  onClick={handleDisplayScanTable}>
   <span className="history-link"><h6>Procedures </h6></span></div> */}

   

        </div>
<br/>
        <button style={{alignSelf:'center'}} disabled={ next_visit === "" || next_visit === null}  onClick={saveNextVisit} className='btn btn-primary btn-col btn-square btn-small'>Save Next Visit</button>
        
        <br/>


     {/***********schedule boxes-end*****/}
      

      {/***********SCAN SCHEDULE*****/}       

  {/* {showScanTable?
                    (<>
                    <p className='scan-close'>
           <i onClick={()=>setShowScanTable(false)} className="fas fa-times-circle scan-close-icon"></i></p>
                    <ReactTable 
         columns={columnsScans}
         data={scanList}
         defaultPageSize = {5}
         
         resizable
         className="scan-react-table"
                           
                 
         >

         </ReactTable> 
         <br/>
      

         </>)

         : null } */}

{/*******  Scan Schedules ************/}

{/* <h4 className='title-of-tasks head-strip'>Scan Table </h4>
<br/>

<ReactTable 
         columns={columnsScans}
         data={scanList}
         defaultPageSize = {5}
         
         resizable
         className="scan-react-table"
                           
                 
         >

         </ReactTable> 
         <br/>

         <div className='schedule-btn'>
      <button className="btn btn-primary btn-col btn-col btn-square btn-small" onClick={handleSubmitScans}> Save Scans</button>
      

    </div>
<br/>
<br/> */}

          {/***********SCAN SCHEDULE***ends**/}   


  {/***********TEST SCHEDULE*****/}   

            {/* {showTestTable?
                    (<> 
                    <p className='scan-close'>
           <i onClick={()=>setShowTestTable(false)} className="fas fa-times-circle scan-close-icon"></i></p>
                    <ReactTable 
                     columns={columns}
                     data={testsList}
                     defaultPageSize = {5}
                     
                     resizable
                     className="test-react-table"
                     >

                     </ReactTable><br/>  

                       {testsList.length<1 ?   <button className="btn btn-primary btn-col btn-col add-btn-react-table" onClick={AddRowTest}> + Click to Add Data </button>                  
                 : null}
                 </>)

                     : 

                     null } */}

      {/* ************** Test Table ********* */}

{/* <h4 className='title-of-tasks head-strip'>Test Table </h4>
<br/>

                        <ReactTable 
                     columns={columns}
                     data={testsList}
                     defaultPageSize = {5}
                     
                     resizable
                     className="test-react-table"
                     >

                     </ReactTable><br/>   */}

              

  {/***********Test SCHEDULE***ends**/}   







              

  
                 

         
                      
 

         

         {deleteScanConfirmMsg==="success" ? 

          <DeleteScanConfirmPopup
        show={deleteScanConfirmModalShow}
        onHide={() =>  setDeleteScanConfirmModalShow(false)}
        index= {scanIndexToDelete}
      />

           : 
             '' }
          
          {deleteNextVisitConfirmMsg==="success" ? 

          <DeleteNextVisitConfirmPopup
        show={deleteNextVisitConfirmModalShow}
        onHide={() =>  setDeleteNextVisitConfirmModalShow(false)}
      
      />

           : 
             '' }
          
                
              

               
  

                 

                   
                      {deleteTestConfirmMsg==="success" ? 

          <DeleteTestConfirmPopup
        show={deleteTestConfirmModalShow}
        onHide={() =>  setDeleteTestConfirmModalShow(false)}
        index= {testIndexToDelete}
      />

           : 
             '' }
                     

                    {deleteProcedureConfirmMsg==="success" ? 

          <DeleteProcedureConfirmPopup
        show={deleteProcedureConfirmModalShow}
        onHide={() =>  setDeleteProcedureConfirmModalShow(false)}
        index= {procedureIndexToDelete}
      />

           : 
             '' }       

                   
                
                                  
                 


              
                </div>

    {/* <div className='schedule-btn'>
      <button className="btn btn-primary btn-col btn-col btn-square btn-small" onClick={handleSubmitTests}> Save Tests </button>
      

    </div>
<br/>
<br/> */}

<div className='grid-procedure head-strip'>
  <h4 className='title-of-tasks text-left'>Procedure Table </h4>
  <button onClick={()=> setHistoryShow(true)} className='btn btn-primary btn-col btn-square btn-small'>Procedure History</button>
</div>
<br/>

    
<ReactTable 
         columns={columnsProcedure}
         data={procedureList}
         defaultPageSize = {5}
         
         resizable
         className="scan-react-table"
                           
                 
         >

         </ReactTable> 
         <br/>

          <button style={{alignSelf:'center'}} className="btn btn-primary btn-col btn-col btn-square btn-small" onClick={handleSubmitProcedure}>Save Procedures </button>

           </div>
               
             {/* <div style={{alignSelf:'center'}} className="each-list-title each-tile box-4 colored-box colored-box-small"  onClick={handleDisplayProcedureTable}>
          <span className="history-link"><h6>Procedures </h6></span></div> */}

             {/* {showProcedureTable?
                    (<>
                    <p className='scan-close'>
           <i onClick={()=>setShowProcedureTable(false)} className="fas fa-times-circle scan-close-icon"></i></p>
                    <ReactTable 
         columns={columnsProcedure}
         data={procedureList}
         defaultPageSize = {5}
         
         resizable
         className="scan-react-table"
                           
                 
         >

         </ReactTable> 
         <br/>
      

         </>)

         : null } */}


                 {/**********************************/}
          <div className="each-section summary  section-diagnostic" ref={ref3}>
                  <h5 className="sub-title head-strip"> Current Medications <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip3}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>
             <div className="second-contentbox">
          
                 {/******Prescription*******/}
                 <Suspense fallback={<div>Loading...</div>}>

                    <CurrentMedications id={id} />

                 </Suspense>     

                 <Link to={{pathname:'/prescription-form', id: id }}>
                    <span className="history-link" >

                                         <button style={{marginLeft:'10px'}} className="btn btn-primary btn-col btn-col btn-small btn-square"> Add Medication </button>

                                         
                                    </span>
                       </Link>  
                       <button style={{marginLeft:'10px'}} onClick={() => setPastMedicationShow(true)} className="btn btn-primary btn-col btn-col btn-small btn-square"> Past Medications </button>

            </div>
        </div>
        {/*************/}  


        {/*******************************OBSERVATIONS************/}     



          <div className="each-section summary  section-diagnostic" ref={ref4}>
                  <h5 className="sub-title head-strip">  Observations <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip4}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>
             
                       
                      
                                         {/****placeholder only****/}
                                          <Suspense fallback={<div>Loading...</div>}>
                                              <ViewObs patientId={id} handleObs={handleObs} obs={observationsArray} />
                                          </Suspense>
                                           

                                            <span className="history-link" onClick={handleObservationsOverlay}>
                               <button className="btn btn-primary btn-col btn-col btn-smallest btn-square mb-3"> Add Observations </button>
                              </span>                   
             
     {/******placeholder*** ENd*/}
                                           

                   

            
        </div>


        <div className="each-section summary  section-diagnostic" ref={ref5}>
                  <h5 className="sub-title head-strip"> Symptoms <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip5}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>        

                              <Suspense fallback={<div>Loading...</div>}>
                                                <ViewSymptoms patientId={id} handleObs={handleObs} obs={symptoms}  />
                                            </Suspense>

                                            <span className="history-link" onClick={handleSymptomsOverlay}>
                                 <button className="btn btn-primary btn-col btn-col btn-smallest btn-square"> Add Symptoms </button>
                                </span>
                     
             
     {/******placeholder*** ENd*/}
                                           

                   

            
        </div>

         {/*****************Diagnostic section ************/}
         <div className="each-section summary  section-diagnostic" ref={ref6}>
         <h5 className="sub-title head-strip">  Diagnostics Info <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip6}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>
             <div style={{justifyContent:'space-evenly'}} className="second-contentbox">
          
                    {/******Primary/Chronic Medical Condition*******/}
                       <div id='diagnostic-sec' className="sub-section primarymed-subsection diagnostics">
                      <div className="sub-section-header box-theme">
                               
                    
                      <h6 className="sub-section-title"> Allergies</h6>                     
                      </div>
                      <div className="sub-section-body comorb-body-content"> 

                      <Suspense fallback={<div>Loading...</div>}>
                          <Allergies id={id} />
                      </Suspense>
                      
                      
                      </div>

                      </div>
                       {/******Chronic/Other Medical Condition******END*/}
                      {/******Comorbidities*******/}
                       <div id='diagnostic-sec' className="sub-section comorbidities-subsection diagnostics">
                      <div className="sub-section-header box-theme">
                               
                    
                      <h6 className="sub-section-title"> Diagnosis <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip6}><i className="fas fa-info-circle"></i></OverlayTrigger></h6>
                      </div>
                      <div className="sub-section-body comorb-body-content"> 

                      <Suspense fallback={<div>Loading...</div>}>
                          <Diagnosis id={id} />
                      </Suspense>
                      
                      
                      </div>

                      </div>
                       {/******Comorbidities******END*/}
                         
                       
                            

        </div>
             



              </div>
              

                {/********* Set Reminders******/}
  <div className="each-section summary section-2" ref={ref7}>
                    <h5 className="sub-title head-strip">  Set Instructions <OverlayTrigger trigger='click' rootClose placement="top" overlay={renderTooltip7}><i className="fas fa-info-circle"></i></OverlayTrigger></h5>

                    
                    {/********instruction ****/}
                    <div className="second-contentbox">
                     <div className="second-tab sub-section sub-section-dr-notes ">
              <div className="sub-section-header box-theme">
                      
              <h6 className="sub-section-title"> Set Instruction</h6>
              </div>
              <div className="sub-section-body">
              <h6 date-instr> {instructionsDate? new Date(instructionsDate).toString().slice(0,21) : null}</h6> 

               <textarea className="text-area" placeholder=""  cols="150" rows="7"  value={instructions} onChange={handleInstructions}/>
                </div>


             

               
                <button className="btn btn-primary btn-col btn-col btn-square btn-small" onClick={handleSubmitInstructions}> Save Instructions </button>
               <br/><br/>
          </div>


           </div>
     

               </div>


                      
        {/*********Doctor Notes*********/}
         {/** <div className="second-tab sub-section sub-section-dr-notes ">
            <div className="sub-section-header box-theme">
                     <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-pencil-fill" fill="white" xmlns="http://www.w3.org/2000/svg">
        }
  <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
</svg>

            <h6 className="sub-section-title"> Doctor Notes</h6>
            </div>
            <div className="sub-section-body">
             <textarea className="text-area" placeholder=""  />
              </div>
        </div>
                
 **/}
               {/*****************/}
              



<br/>



{ errorModalShow ?
 <ErrorPopup
        show={errorModalShow}
        onHide={() => { setErrorModalShow(false); setTestErr("nil") }}
        
      />

: null }

{ errorScanShow ?
 <ErrorScanPopup
        show={errorScanShow}
        onHide={() => { setErrorScanShow(false); setScanErr("nil")}}
        
      />

: null }

{procedureErr!=="" ?
 <ErrorProcedurePopup
        show={errorShow}
        onHide={() =>  setErrorShow(false)}
        
      />

: null }

{drNotesShow ?
<DrNotesPopup 
        show={drNotesShow}
        onHide={() =>  setDrNotesShow(false)}
        id ={id}
        
      />

: null }

{nextErr!== "nil" ?
 <ErrorNextPopup
        show={errorNextModalShow}
        onHide={() =>  setErrorNextModalShow(false)}
        
      />

: null }

{observationsShow ?
<ObservationsPopup 
        show={observationsShow}
        onHide={() =>  setObservationsShow(false)}
        // id ={id}
        
      />

: null }

{symptomsShow ?
<SymptomsPopup 
        show={symptomsShow}
        onHide={() =>  setSymptomsShow(false)}
        // id ={id}
        
      />

: null }


{bpShow? 
  <BpPopup 
        show={bpShow}
        onHide={() =>  setBpShow(false)}
         id ={id}
        
      />

: null }

{pulseShow? 
  <PulsePopup 
        show={pulseShow}
        onHide={() =>  setPulseShow(false)}
         id ={id}
        
      />

: null }

{submitInstructionsMsg==="success" ? 
            <SubmitInstructionsPopUp
          show={modalInstructionsShow}
          onHide={() =>  {setModalInstructionsShow(false);
                        setReRender(!reRender);
                        setRefreshInstr(!refreshInstr);
                        setSubmitInstructionsMsg('')}}
         
        />
        :   submitMsg==="error"? 
       <SubmitErrorPopup
          show={errorSubmit}
          onHide={() =>  {setErrorSubmit(false);
                          setSubmitMsg('');}}
         
        />

           : ''
            }


{deleteNextMsg!== "" ? 
          <DeletePopUp
        show={deleteNextModalShow}
        onHide={() =>  {setDeleteNextModalShow(false);
                      setReRender(!reRender);
                      
                      setDeleteNextMsg('');}}
       
      />
         : ""}
{submitMsg==="success" ? 
          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false);
                      setReRender(!reRender);
                      setProcedureRefresh(!procedureRefresh)
                      setSubmitMsg('');}}
       
      />
         :  submitMsg==="error"? 
     <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setSubmitMsg('');}}
       
      />

         : ''
          }

{
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 

{
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 

{
        validShow ? 
        <ValidPopUp
        show={validShow}
        onHide={()=>{
          setValidShow(false)
        }} /> : null
      } 

{
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      } 

{
        popupShow ? 
        <EditPopup
        show={popupShow}
        onHide={()=>{
          setPopupShow(false)
        }}
        onSave= {() => {
          if(imgFile !== null){
            setPopupShow(false)
            const scans= scanList
            scans[index].scan_details.image= imgFile
            setScanList(scans)            
            setEditSuccess(true)
          }
          
      } }
         /> : null
      } 

{
        imgEditShow ? 
        <ImgEditPopup
        show={imgEditShow}
        onHide={()=>{
          setImgEditShow(false)
        }}
        onSave= {() => {
          if(imgFile !== null){
            setImgEditShow(false)
            const tests= testsList
            tests[index].labtest_reason.image= imgFile
            setTestsList(tests)            
            setEditSuccess(true)
          }
          
      } }
         /> : null
      }

{
        imageEditShow ? 
        <ProcedureImgPopup
        show={imageEditShow}
        onHide={()=>{
          setImageEditShow(false)
        }}
        onSave= {() => {
          if(imgFile !== null){
            setImageEditShow(false)
            const procedure= procedureList
            procedure[index].image= imgFile
            setProcedureList(procedure)        
            setEditSuccess(true)
          }
          
      } }
         /> : null
      }

{
      remarkViewShow ? 
      <RemarkViewPopup
        show={remarkViewShow}
        onHide= {() => { setRemarkViewShow(false)     
        }}
      /> : null
    }

{
        editSuccess ? 
        <DetailsUpdateSuccessPopUp
        show={editSuccess}
        onHide={()=>{
          setEditSuccess(false)          
        }}
        
         /> : null
      }

{
      addShow ? 
      <AddPopup
        show={addShow}
        onHide= {() => { setAddShow(false)     
        }}
      /> : null
    }

    {
      editShow ? 
      <DetailPopup
        show={editShow}
        onHide= {()=> {
          setEditShow(false)
        }}
        onSave= {() => {
          if(sendData !== scanList[cell].scan_details.reason){
            setEditShow(false);
            setEditSuccess(true)
            const scans= scanList
            scans[cell].scan_details.reason= sendData
            setScanList(scans) 
            
          }
      } } /> : null
    }


{
      historyEditShow ? 
      <HistoryPopup
        show={historyEditShow}
        onHide= {()=> {
          setHistoryEditShow(false)
        }}
        onSave= {() => {
          if(sendData !== patientData.family_history){
            setHistoryEditShow(false);
            const data= patientData
            data.family_history = sendData
            setPatientData(data)
            callSaveDetails()
          }
      } } /> : null
    }

{
      medEditShow ? 
      <MedHistoryPopup
        show={medEditShow}
        onHide= {()=> {
          setMedEditShow(false)
        }}
        onSave= {() => {
          if(sendData !== patientData.past_medical_history){
            setMedEditShow(false);
            const data= patientData
            data.past_medical_history = sendData
            setPatientData(data)
            callSaveDetails()
            
          }
      } } /> : null
    }

{
      complaintEditShow ? 
      <ComplaintPopup
        show={complaintEditShow}
        onHide= {()=> {
          setComplaintEditShow(false)
        }}
        onSave= {() => {
          if(sendData !== patientData.present_complaint){
            setComplaintEditShow(false);
            const data= patientData
            data.present_complaint = sendData
            setPatientData(data)
            callSaveDetails()
            
          }
      } } /> : null
    }


{
      historyAddShow ? 
      <HistoryAddPopup
        show={historyAddShow}
        onHide= {()=> {
          setHistoryAddShow(false)
        }}
        onSave= {() => {
          if(sendData !== patientData.family_history){
            setHistoryAddShow(false);
            const data= patientData
            data.family_history = sendData
            setPatientData(data)
            callSaveDetails()
          }
      } } /> : null
    }

{
      medAddShow ? 
      <MedHistoryAddPopup
        show={medAddShow}
        onHide= {()=> {
          setMedAddShow(false)
        }}
        onSave= {() => {
          if(sendData !== patientData.past_medical_history){
            setMedAddShow(false);
            const data= patientData
            data.past_medical_history = sendData
            setPatientData(data)
            callSaveDetails()
            
          }
      } } /> : null
    }

{
      complaintAddShow ? 
      <ComplaintAddPopup
        show={complaintAddShow}
        onHide= {()=> {
          setComplaintAddShow(false)
        }}
        onSave= {() => {
          if(sendData !== patientData.present_complaint){
            setComplaintAddShow(false);
            const data= patientData
            data.present_complaint = sendData
            setPatientData(data)
            callSaveDetails()
            
          }
      } } /> : null
    }

{
        editDetailSuccess ? 
        <PatientDetailsPopUp
        show={editDetailSuccess}
        onHide={()=>{
          setEditDetailSuccess(false);
          setRefresh(!refresh)          
        }}
        
         /> : null
      }

{
      viewShow ? 
      <ViewPopup
        show={viewShow}
        onHide= {() => { setViewShow(false); setFlag(false)     
        }}
      /> : null
    }

{
      imageViewShow ? 
      <ImgViewPopup
        show={imageViewShow}
        onHide= {() => { setImageViewShow(false); 
        }}
      /> : null
    }

{
      addPulse ? 
      <AddPulsePopup
        show={addPulse}
        onHide= {() => { setAddPulse(false)     
        }}
      /> : null
    }

{
      addBp ? 
      <AddBpPopup
        show={addBp}
        onHide= {() => { setAddBp(false)     
        }}
      /> : null
    }

{
      reasonAddShow ? 
      <ReasonAddPopup
        show={reasonAddShow}
        onHide= {() => { setReasonAddShow(false)     
        }}
      /> : null
    }

{
      historyShow ? 
      <ProcedureHistoryPopup
        show={historyShow}
        onHide= {() => { setHistoryShow(false)     
        }}
      /> : null
    }

    {
      reasonEditShow ? 
      <ReasonEditPopup
        show={reasonEditShow}
        onHide= {()=> {
          setReasonEditShow(false)
        }}
        onSave= {() => {
          if(sendData !== testsList[cell].labtest_reason.reason){
            setReasonEditShow(false);
            setEditSuccess(true)
            const tests= testsList
            tests[cell].labtest_reason.reason= sendData
            setTestsList(tests) 
            
          }
      } } /> : null
    }

{
      procedureReasonShow ? 
      <ProcedureReasonPopup
        show={procedureReasonShow}
        onHide= {()=> {
          setProcedureReasonShow(false)
        }}
        onSave= {() => {
          if(sendData !== procedureList[cell].reason){
            setProcedureReasonShow(false)
            setEditSuccess(true)
            const procedure= procedureList
            procedure[cell].reason= sendData
            setProcedureList(procedure)
            
          }
      } } /> : null
    }

{
      pastMedicationShow ? 
      <PastMedicationPopup
        show={pastMedicationShow}
        onHide= {() => { setPastMedicationShow(false)     
        }}
      /> : null
    }

    </div>

   
    </div>


   </>
)
}
export default PatientMedicalDetails;
