import React,{useContext,useState,useEffect, useRef} from "react";
import { Link,useHistory } from "react-router-dom";
import {LoginStatusContext} from "../../contexts/LoginStatusContext";
import "./navbar.css";
import axios from 'axios';
import {APIURL} from "../../Global";
import logo from "../../images/felixacare/logo_512.png";
import webLogo from "../../images/felixacare/logo_felixacare_big.png";
import {LoginContext} from "../../contexts/LoginContext";
import {NavDropdown} from 'react-bootstrap'
import {Modal ,Button} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import {ProfileContext} from '../../contexts/ProfileContext'

function Navbar() {
  const [img, setImg] =useState('')
  const [hospImg ,setHospImg] =useState();
  const [location, setLocation] =useState();
    const [isLoggedIn,setIsLoggedIn] = useContext(LoginStatusContext);
     const [loginDatas,] = useContext(LoginContext);
     const [show ,setShow] =useState(false);
     const [render,]= useContext(ProfileContext)
     const [logoutConfirm, setLogoutConfirm]= useState(false)
  const [login_datas,setLogin_datas]=useState(JSON.parse(sessionStorage.getItem('logindatas'))? JSON.parse(sessionStorage.getItem('logindatas')) : null);
     useEffect(()=>{
      setLogin_datas(JSON.parse(sessionStorage.getItem('logindatas')));
     },[loginDatas!==null])
   
  let history=useHistory();
  const ref= useRef(null)
  const { t } = useTranslation();

  const lang = [
    {
      code:'es',
      name:'Español',
      country_code:'es'
    },
    {
      code:'en',
      name:'English',
      country_code:'gb'
    },
    {
      code:'ar',
      name:'Arabic',
      country_code:'sa'
    }
  ]

const LogoutConfirmPopup =(props)=>{
    

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to logout</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
  
          <Button variant="danger" onClick={handleLogout}> Confirm</Button>
  
         
  
        </Modal.Footer>
      </Modal>
    );
  }
const callLogout=()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
   
  axios.get(`${APIURL}/api/v1/account/logout/`,{ headers: {
                
                "Authorization" : 'Token '+str,
                'Content-Type': 'application/json'  }


              })



              .then(res=>{
         
                if(res.data.status==="success"){
                
               
               
            }
            else{
              
             
            }

              })
              .catch(err=>{
         
            
          })

               history.push('/login');
}

const handleLogout1 = ()=>{
     setLogoutConfirm(true);
  
      
     
      
    }

    const handleLogout = ()=>{
      setLogoutConfirm(false)
     callLogout();
      setIsLoggedIn(false);
  
      
     
      localStorage.clear();
    }

     if(localStorage.getItem("usertoken")){
              setIsLoggedIn(true);
          
            }

    const handleOpen = () => {
      setShow(!show)
     }

     const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
      }
  };

     useEffect(()=> {

      document.addEventListener('click', handleClickOutside, true)
       
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };

     },[])

     useEffect(()=>{
      //if(localStorage.getItem("usertoken")) {
      if(isLoggedIn===true){
        if(login_datas!==null){
          if(login_datas.user_type==='hospital_doctor'){
  
            const tokenString= localStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '')                           
              
             
              axios.get(`${APIURL}/api/v1/account/profile`, { headers: {
                
                "Authorization" : 'Token '+str  }})
              .then(res=>{
                  
                if(res.data.status==="success"){
                
              
                setImg(res.data.data.photo)
            
                }
          
          
              })
              .catch(err=>{
          
          })  
        }
        else if(login_datas.user_type==='hospital' &&  login_datas.verification_status==='approved'){
      
          const tokenString= localStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '')                           
              
             
              axios.get(`${APIURL}/api/v1/hospital/hospital-profile/`, { headers: {
                
                "Authorization" : 'Token '+str  }})
              .then(res=>{
                  
                if(res.data.status==="success"){
                
              
                setHospImg(res.data.hospital_profile.photo)
                setLocation(res.data.hospital_profile.location)
            
                }
          
          
              })
              .catch(err=>{
          
          })
        }
        }
        
}
     },[render, isLoggedIn===true]) 

  return (<>
    <nav className="nav-bar">
     
      <div className="logo-div"> 
      <Link to="/login"><img src={logo} width="auto" height="80px" alt="felixacare logo" className="logo-small"/>
      <img src={webLogo} width="auto" height="80px" alt="felixacare logo" className="logo"/> </Link>

      </div>



      <div className="btns">
     

         {isLoggedIn ? login_datas!==null? login_datas.user_type==='hospital_doctor'? <>
          
          <NavDropdown ref={ref} style={{zIndex:'1001'}} title={<><h6 className="user-name">Dr.{login_datas.doctor_name}</h6>
           <i className="fas fa-user-circle user-icon"></i></>} id="collasible-nav-dropdown" renderMenuOnMount={true} 
         noCaret id="language-switcher-container" onClick = { handleOpen }
         show={show}>
          <NavDropdown.Item href='#' ><img style={{borderRadius: "50%",width :"50px" ,height :"50px"}} src={img} alt='Img'></img></NavDropdown.Item>
          <NavDropdown.Item href='#' >Dr.{login_datas.name}</NavDropdown.Item>
          <NavDropdown.Item href='#' >{login_datas.doctor_department} Department</NavDropdown.Item>
          <NavDropdown.Item href='#' >{login_datas.hospital_name} Hospital</NavDropdown.Item>
          <NavDropdown.Item ><Link to="/doctor/profile">Profile</Link></NavDropdown.Item>
        </NavDropdown> 
        <div>
         <button className="btn login-btn logout-btn" onClick={handleLogout1}>
         {t('Logout')}  
        </button>
             
        </div> </> 
        : login_datas.user_type==='hospital' &&  login_datas.verification_status==='approved' ? <>
        <NavDropdown ref={ref} style={{zIndex:'1001'}} title={<><h6 className="user-name">{login_datas.name}</h6>
           <i className="fas fa-user-circle user-icon"></i></>} id="collasible-nav-dropdown" renderMenuOnMount={true} 
         noCaret id="language-switcher-container"onClick = { handleOpen } 
         show={show}>
          <NavDropdown.Item href='#' ><img style={{borderRadius: "50%",width :"50px" ,height :"50px"}} src={hospImg} alt='Img'></img></NavDropdown.Item>
          <NavDropdown.Item href='#' >{login_datas.name}</NavDropdown.Item>
          <NavDropdown.Item href='#' >{location}</NavDropdown.Item>
          <NavDropdown.Item href='#' >{login_datas.hospital_username}</NavDropdown.Item>
          <NavDropdown.Item ><Link to="/hospital/profile">Profile</Link></NavDropdown.Item>
        </NavDropdown> 
        <div>
         <button className="btn login-btn logout-btn" onClick={handleLogout1}>
         {t('Logout')} 
        </button>
             
        </div> </> :  <>

         

        
        
         <h6 className="user-name">{login_datas && login_datas.name}</h6>
         <i className="fas fa-user-circle user-icon"></i>
         <button className="btn login-btn logout-btn" onClick={handleLogout1}>
         {t('Logout')} 
        </button>
        

        
        </>

        : <>
        <button className="btn login-btn">
          <Link to="/login">{t('Login')}</Link>
        </button>
        {/* <button className="btn reg-btn">
          <Link to="/register"> {t('Register')} </Link>

        </button> */}
        <i class="fas fa-globe"></i>
        </> : <>
        {/* <button className="btn login-btn">
          <Link to="/login">{t('Login')}</Link>
        </button> */}
        {/* <button className="btn reg-btn">
          <Link to="/register"> {t('Register')} </Link>

        </button> */}

      {/*}  <div className="dropdown">
  <button  onClick = { handleOpen } show={show} className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Dropdown button
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
  {
    lang.map(({code,name,country_code}) => (
      <>
      <li key={country_code}>
        <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
      <button className='dropdown-item' onClick={()=> { i18n.changeLanguage(code);}}>{name},{country_code}</button>
      </li>
       
      </>
    ))
  } 
  </ul>
</div>*/}

<NavDropdown ref={ref} style={{zIndex:'1001'}} title={<i className="fas fa-globe"></i>} id="collasible-nav-dropdown" renderMenuOnMount={true} 
         noCaret id="language-switcher-container" onClick = { handleOpen }
         show={show}>
          {
    lang.map(({code,name,country_code}) => (
      <>

      <li key={country_code}>
        <div className='flex-row'>
        <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
        <button style={{width:'60%', color:'black'}} className='dropdown-item' onClick={()=> { i18n.changeLanguage(code);}}>{name}</button>
        </div>
      </li>
  
      </>
    ))
  }
        </NavDropdown>
        </>
      }
      </div>
    </nav>
    {
        logoutConfirm===true? 
        <LogoutConfirmPopup 
            show={logoutConfirm}
            onHide= {() => {setLogoutConfirm(false);}}/> : null
    }
    </>
  );
}

export default Navbar;
