import React, {useState, useCallback} from 'react'
import {useHistory} from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-date-picker';
import axios from "axios";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import HospBdoNavbar from '../HospBdoNavbar/HospBdoNavbar';
import './hospregisterpatient.css'
import 'react-table-v6/react-table.css'
import GeneratePatientInvoice from './GeneratePatientInvoice';
import debounce from 'lodash.debounce';

function HospRegisterPatient() {

    const [userDatas,setUserDatas]= useState({
        firstname: "",
        lastname:'',
        email: "",
        username:"",
        address:"",
        gender: "",
        dob: "",
        country: "",
        region: "",
        phone: {
          dialcode: "",
          rawphone: "",
          mobile_number: "",
        },
        file:"",
        // complaint:"",
        // medHistory:"",
        // famHistory:"",
        // treatment:"",
        consent:"",
        cpr:"",
        age:""
    });

    const [patientId, setPatientId]= useState('')
    const [invalid ,setInvalid] =useState(false)
    const [isValidUsername,setIsValidUsername]=useState(true);
    const [isValidEmail,setIsValidEmail]=useState(true);
    const [isValidPhone,setIsValidPhone]=useState(true);
    const [isValidCpr,setIsValidCpr]=useState(true);
    const [isValidFile,setIsValidFile]=useState(true);
    const [errorMsg,setErrorMsg]=useState("");
    const [errorShow,setErrorShow]=useState(false);
    const [successShow,setSuccessShow]=useState(null);
    const [successMsg,setSuccessMsg]=useState("");
    const [dob, setDob] = useState();
    const [,setFormattedDob] = useState("");
    const [invoiceShow, setInvoiceShow]= useState(false)


    const history =useHistory();

    const [error,setError]=useState({
        username:"",
        email: '',
        phone:"",
         age:"",
         cpr:"",
         file:""
       });


    const handleChange=e=>{
        
        e.preventDefault();
        const val=e.target.value;
        const key=e.target.name;
        setError(currentstate=>({
              ...currentstate,
              [key]: ""
            }));
        if(key === "age"){

          if(val.length < 4){
            setUserDatas(currentstate=>({
              ...currentstate,
              [key]: val
            }));
          }
          else return false
        }
        else {
          setUserDatas(currentstate=>({
            ...currentstate,
            [key]: val
          }));
        }
           
        
  }

  const handleDOB=(date)=>{

    setDob(date);
  
  let dateUpdated= date;
    if(date!==null){
  const offset = date.getTimezoneOffset();
  
  dateUpdated = new Date(date.getTime() - (offset*60*1000))
  
  let DateExtracted = dateUpdated.toISOString().split('T')[0];
  
  /*let dateString= DateExtracted.toString(); */
  setFormattedDob(DateExtracted);
  
   setUserDatas(currentstate=>({
              ...currentstate,
              dob: DateExtracted
            }));
  }
  }

  const selectCountry =(val)=> {
    setUserDatas(currentstate=>({
       ...currentstate,
          country: val
        }));
    
   
 }

 const selectRegion= (val)=> {
   setUserDatas(currentstate=>({
       ...currentstate,
          region: val
        }));
 }

 const handleGenderRadioButton=(e)=>{
    let genderOpted = e.target.value;
    setUserDatas(currentstate=>({
      ...currentstate,
      gender: genderOpted

    }))
   }

   const handleConsentRadioButton=(e)=>{
    let consentOpted = e.target.value;

    setUserDatas(currentstate=>({
      ...currentstate,
      consent: consentOpted

    }))
   }

 const handlePhoneInput= (value, data, event, formattedValue)=>{
  
  
    const rawPhone = value.slice(data.dialCode.length) ;
    
    const dialcode = value.slice(0,data.dialCode.length) ;
     setUserDatas(currentstate=>({
          ...currentstate,
             phone: {
              dialcode: dialcode,
                rawphone: rawPhone,
                mobile_number: value
              }
  
                         }));
    
    
  
   }
    
    const handleCheckExistingPhone= (e)=>{
   setIsValidPhone(true);
  if(e.target.value !==""){
    
    const rawPhone =  userDatas.phone.rawphone ;
    
    const dialcode =  userDatas.phone.dialcode;
     setError(currentstate=>({
                ...currentstate,
              phone: ''}));
  
    
  
      const data = {
              code: dialcode,
              mobile_number: rawPhone
          }
         
            axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
       'Content-Type': 'application/json'}})
      .then(res =>{
           
              if(res.data.status === "success"){
                if(res.data.is_existing_user === true){
                 
                    setError(currentstate=>({
                ...currentstate,
              phone: "Phone Number already registered!"}));
                      setIsValidPhone(false);
                   }
                    else{
                      setIsValidPhone(true);
  
                    }
                     
                      
                  }
              else{
               
                setIsValidPhone(true);
                    
              }
                  
              })
      .catch(err =>{
  
      
    })
  
  }
    
  
   }


  const handleCheckUsername=e=>{
    setIsValidUsername(true);
    setInvalid(false)
    if(e.target.value!==''){
     setError(currentstate=>({
                ...currentstate,
              username: ''}));
    
    e.preventDefault();
  
      const data = {
              username: e.target.value
          }
         
            axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
       'Content-Type': 'application/json'}})
      .then(res =>{
          
              if(res.data.status === "success"){
  
  
                if(res.data.is_existing_user === true){
              
                      setError(currentstate=>({
                ...currentstate,
              username:"Username already exists!"}));
  
  
                   setIsValidUsername(false);
  
                   }
                   else{
                      setIsValidUsername(true);
                   }
                    
                  
                      
                      
                  }
                  else if(res.data.status === 'error' && res.data.message=== 'Unsupported language used for username!'){
                    setError(currentstate=>({
                      ...currentstate,
                    username:"Unsupported language used for username!"}));
                    setInvalid(true)
                  }
                  
              else{
                setInvalid(false)
                setIsValidUsername(true);
                    
              }
                  
              })
      .catch(err =>{
      
    })
  
  }
  }
  
  const handleCheckExisting=e=>{
    setIsValidEmail(true);
  if(e.target.value!== ""){
         setError(currentstate=>({
                ...currentstate,
              email:  ''}));
    e.preventDefault();
    let key=e.target.name;
  
  
      const data = {
              [e.target.name]: e.target.value
          }
       
            axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
       'Content-Type': 'application/json'}})
      .then(res =>{
            
              if(res.data.status === "success"){
                if(res.data.is_existing_user === true){
               
                      setError(currentstate=>({
                ...currentstate,
              [key]: `This ${key} is already registered!`}));
                   setIsValidEmail(false);
                   }
                    else{
                      setIsValidEmail(true);
                    }
                     
                      
                  }
              else{
          
                setIsValidEmail(true);
                    
              }
                  
              })
      .catch(err =>{
     
      
    })
  }
  
  }

  const handleChangeCpr=e=>{
        
    e.preventDefault();
    const val=e.target.value;
    const key=e.target.name;

    setError(currentstate=>({
          ...currentstate,
          [key]: ""
        }));

      setUserDatas(currentstate=>({
        ...currentstate,
        [key]: val
      }));   

      debouncedCheckCpr(key,val)
    
}


const debouncedCheckCpr = useCallback(
  debounce((key,val) => handleCheckExistingCpr(key,val),1000 ),
  [],
)


  const handleCheckExistingCpr=(key,val)=>{

    const tokenString= localStorage.getItem('usertoken');
   
    let str= tokenString.replace(/["]+/g, '');


    setIsValidCpr(true);
  if(val!== ""){
         setError(currentstate=>({
                ...currentstate,
              cpr:  ''}));
  
  
      const data = {
              cpr: val
          }
       
            axios.post(`${APIURL}/api/v1/hospital/check-cpr/`, data, { headers: {
       'Content-Type': 'application/json',
       "Authorization": "Token "+str}})
      .then(res =>{
            
              if(res.data.status === "success"){
                if(res.data.is_existing_cpr === true){
               
                      setError(currentstate=>({
                ...currentstate,
              [key]: `This ${key} is already used!`}));
                   setIsValidCpr(false);
                   }
                    else{
                      setIsValidCpr(true);
                    }
                     
                      
                  }
              else{
          
                setIsValidCpr(true);
                    
              }
                  
              })
      .catch(err =>{
     
      
    })
  }
  
  }

  const handleChangeFile=e=>{
        
    e.preventDefault();
    const val=e.target.value;
    const key=e.target.name;

    setError(currentstate=>({
          ...currentstate,
          [key]: ""
        }));

      setUserDatas(currentstate=>({
        ...currentstate,
        [key]: val
      }));   

      debouncedCheckFile(key,val)
    
}

const debouncedCheckFile = useCallback(
  debounce((key,val) => handleCheckExistingFile(key, val) , 1000),
  [],
)

  const handleCheckExistingFile=(key,val)=>{

    const tokenString= localStorage.getItem('usertoken');
   
    let str= tokenString.replace(/["]+/g, '');


    setIsValidFile(true);
  if(val!== ""){
         setError(currentstate=>({
                ...currentstate,
              file:  ''}));
  
  
      const data = {
              file_number: val
          }
       
            axios.post(`${APIURL}/api/v1/hospital/check-file-number/`, data, { headers: {
       'Content-Type': 'application/json',
       "Authorization": "Token "+str  }})
      .then(res =>{
            
              if(res.data.status === "success"){
                if(res.data.is_existing_file_number === true){
               
                      setError(currentstate=>({
                ...currentstate,
              [key]: `This ${key} is already used!`}));
              setIsValidFile(false);
                   }
                    else{
                      setIsValidFile(true);
                    }
                     
                      
                  }
              else{
          
                setIsValidFile(true);
                    
              }
                  
              })
      .catch(err =>{
     
      
    })
  }
  
  }

  const validate= async()=>{
    let input = userDatas;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["firstname"] || input["firstname"]==="" ) {
      isValid = false;
      errors["firstname"] = "Please enter first name";
    }

    // if (!input["lastname"] || input["lastname"]==="" ) {
    //   isValid = false;
    //   errors["lastname"] = "Please enter last name";
    // }
    
    // if (!input["username"] || input["username"]==="") {
    //   isValid = false;
    //   errors["username"] = "Please enter username";
    // }
    //   if (!input["address"] || input["address"]==="") {
    //   isValid = false;
    //   errors["address"] = "Please enter address";
    // }

    if (!input["age"] || input["age"]==="" ) {
      isValid = false;
      errors["age"] = "Please enter age";
    }

    if (!input["country"] || input["country"]==="" ) {
      isValid = false;
      errors["country"] = "Please select a country";
    }
  
    if (!input["region"] || input["region"]==="") {
      isValid = false;
      errors["region"] = "Please select a region";
    }

    if (!input["cpr"] || input["cpr"]==="") {
      isValid = false;
      errors["cpr"] = "Please enter CPR";
    }


    if (input["username"] && typeof input["username"] !== "undefined") {
      const re = /^\S*$/;
      if(input["username"].length < 3 || !re.test(input["username"].trim())){
          isValid = false;
          errors["username"] = "Please enter a valid username";
      }
    }
    if(isValidUsername===false){
      isValid = false;
      errors["username"] = "Username already exists!";
    }
    if(invalid=== true){
      isValid = false;
      errors["username"] = "Unsupported language used for username!";
    }
    if(isValidPhone===false){
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }
    if(isValidEmail===false){
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    if(isValidCpr===false){
      isValid = false;
      errors["cpr"] = "This CPR is already used!";
    }

    if(isValidFile===false){
      isValid = false;
      errors["file"] = "This File number is already used!";
    }

    // if (!input["email"]) {
    //   isValid = false;

    //   errors["email"] = "Please enter email Address.";
    // }


    if (input["email"] !== "") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
      }
    }

    if (typeof input["username"] !== "undefined") {
        
      var pattern1 = new RegExp(/[ ]/);
      if (pattern1.test(input["username"])) {
        isValid = false;
        errors["username"] = "Please enter a valid username";
      }
    }

    if (typeof input["firstname"] !== "undefined") {
        
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern2.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }

    if (typeof input["lastname"] !== "undefined") {
        
      var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern3.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }


    if (!userDatas.phone.dialcode || userDatas.phone.dialcode==="" || !userDatas.phone.rawphone || userDatas.phone.rawphone==="" ) {
      isValid = false;
      errors["phone"] = "Please enter phone number";
    }
    
    // if(typeof dob=="undefined" || dob==="" || dob=== null){
    //   isValid = false;
    //   errors["dob"] = "Please enter your date of birth";
    // }
     if(typeof userDatas.gender=="undefined" ||  userDatas.gender==="" ||  userDatas.gender=== null){
      isValid = false;
      errors["gender"] = "Please select gender";
    }
    // if (typeof input["password"] !== "undefined") {
        
    //   let pattern = new RegExp(/[A-Z]/g);
    //   if (!pattern.test(input["password"])) {
    //     isValid = false;
    //     errors["password"] = "Must contain atleast one Capital letter";
    //   }
      
    // }
    // if (!input["password"] || input["password"]==="" || input["password"]===null) {
    //   isValid = false;
    //   errors["password"] = "Please enter your password";
    // }

    // if (!input["confirmpassword"] || input["confirmpassword"]==="" || input["confirmpassword"]===null) {
    //   isValid = false;
    //   errors["confirm_password"] = "Please enter confirm password";
    // }

    // if (input["password"] && typeof input["password"] !== "undefined") {
    //   if(input["password"].length >0 && input["password"].length < 6){
    //       isValid = false;
    //       errors["password"] = "Must contain atleast 6 characters";
    //   }
    // }

    // if (typeof input["password"] !== "undefined" && input["password"]!=="" && input["password"]!==null && typeof input["confirmpassword"] !== "undefined" && input["confirmpassword"]!=="" && input["confirmpassword"]!==null) {
        
    //   if (input["password"] !== input["confirmpassword"]) {
    //     isValid = false;
    //     errors["confirm_password"] = "Passwords don't match";
    //   }
    // }




     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}


  const handleContinue= async (e)=>{

    e.preventDefault();

     if(await validate() && isValidUsername && isValidEmail && isValidPhone && isValidCpr && isValidFile){

       handleRegister();
     }
}

const  handleRegister=()=>{

  const tokenString= localStorage.getItem('usertoken');
   
  let str= tokenString.replace(/["]+/g, '');
     
                  const user = {
                      firstname : userDatas.firstname,
                       lastname : userDatas.lastname,
                      username: userDatas.username,
                      email:userDatas.email,
                      phone : userDatas.phone.rawphone,
                    gender : userDatas.gender,
                    countrycode:userDatas.phone.dialcode,
                    dob:userDatas.dob,
                    country:userDatas.country,
                    region: userDatas.region,
                     address:userDatas.address,
                    code : userDatas.phone.dialcode,
                    mobile_number: userDatas.phone.rawphone,
                    file: userDatas.file,
                    // present_complaint:userDatas.complaint,
                    // med_history:userDatas.medHistory,
                    // fam_history:userDatas.famHistory,
                    consent:userDatas.consent=== 'yes'? true : userDatas.consent=== 'no'? false : false,
                    cpr:userDatas.cpr,
                    age:userDatas.age

                  }

      
  
       axios.post(`${APIURL}/api/v1/hospital/hospital-add-user/`, {
          
          first_name : user.firstname,
          last_name: user.lastname,
          gender: user.gender,
          dob: user.dob,
          email: user.email,
          country: user.country,
          region: user.region,
          username: user.username,
          address: user.address,
          mobile_number: user.mobile_number,
          code: user.code,
          country_code: user.code,
          file_number: user.file,
          // present_complaint: user.present_complaint,
          // past_medical_history: user.med_history,
          // family_history: user.fam_history,
          consent:user.consent,
          cpr: user.cpr,
          age: parseInt(user.age)

         
    }, { headers: {
     'Content-Type': 'application/json',
      "Authorization": "Token "+str
      }})
    .then(res =>{
            
            if(res.data.status === "success"){
                
          setSuccessMsg(res.data.message);
          setPatientId(res.data.patient_id)
          setSuccessShow(true);

                }
            else {

             setErrorMsg(res.data.message);
             setErrorShow(true);

            }
                
            })
     .catch(err =>{
      setErrorMsg("Error in registration");
              setErrorShow(true);
    
  })
 
}

const handleSuccess = ()=> {
  setSuccessShow(false)
  history.push('/bdo')
}

const handleInvoice = () => {
  setInvoiceShow(true)
}


const SuccessPopup =(props)=> {
  return(
      <Modal
          {...props}
         
                aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
             dialogClassName='modal-80w'
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
        
           <div className="tenure-content">

     {invoiceShow?
     

<div className="invoice-holder">
            <GeneratePatientInvoice patientId={patientId} invoiceData={userDatas}/>
    <br/>
                      <div className="divider"></div>
                      <div className="text-right"><button  className="btn btn-primary btn-col btn-small text-right" onClick={()=> history.push('/bdo')}>X Close</button>
          </div>
          <br/>

          
         </div>
         : 
        <div>

        <h4 className="text-success">{successMsg!==''?successMsg : <h4 className="text-danger">Registered Patient Successfully!</h4>}</h4>

            {/* <button className="btn btn-danger btn-small btn-square" onClick={handleInvoice}>Generate Invoice</button> */}
    </div>
     }
           </div> 
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>
             <Button className='btn btn-primay btn-col' onClick={() => setInvoiceShow(true)}>Generate Invoice</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}


const ErrorPopup =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
         <h5 className="text-danger">{errorMsg!==''?errorMsg : <h5 className="text-danger">Error in Data Submission. Please try again!</h5>}</h5>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}

    return (
        <div>
            <HospBdoNavbar />
            <form className="">
                                     <h3 className="title-of-page">Patient Registration </h3>  <br/>
                                     {/* <div className='progress-bar'>
                                            <div className='step'>
                                                <p>Register</p>
                                                <div className='bullet'>
                                                    <span>1</span>
                                                </div> 
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className='step'>
                                                <p>Details</p>
                                                <div className='bullet'>
                                                    <span>2</span>
                                                </div> 
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>  */}
                                     <div className="hosp-patient-form">

                                             <h4 className='title-of-tasks'>Personal Details</h4>

                                     <br/>
                                        <div className='form-align'>
                                            
                                            <div className="item item-left-row"> 
                                        <label className="">* First Name: </label>
                                        <input className="form-control" type="text" name="firstname" placeholder="" onChange={handleChange} maxLength='30'
                                        />  

                                         { error.firstname ? <div className="error-validation-msg">{error.firstname}</div> : null}
                                         </div>
                                          <div className="item item-left-row"> 
                                        <label className=""> Last Name: </label> <input className="form-control" type="text" name="lastname" placeholder="" onChange={handleChange} maxLength='30'
                                        />  
                                          {/* { error.lastname ? <div className="error-validation-msg">{error.lastname}</div> : null} */}
                                            </div>

                                            <div className="item item-left-row"> 
                                        <label className=""> Username: </label><input className="form-control" type="text" name="username" placeholder="" onChange={handleChange} onBlur={handleCheckUsername} maxLength='30'
                                        /> 
                                          {error.username ? <div className="error-validation-msg"> {error.username}</div> : null}
                                          </div>

                                        <div className="item item-left-row"> 
                                        <label className=""> Email Address: </label><input className="form-control" type="email" name="email" placeholder="" onChange={handleChange} onBlur={handleCheckExisting} maxLength='50' />
                                        
                                        {error.email ? <div className="error-validation-msg"> {error.email}</div> : null}
                                        </div>
                                        
                                        {/*  <input className="form-control" type="text" name="dob" placeholder="Date of Birth* : YYYY-MM-DD" required />
                                          */}

                                     
                                         
                                        <div style={{alignSelf:"end"}} className="form-control dob item item-left-row">
                                       
                                       
                 <span>Date of Birth:</span> 
                    <DatePicker
                          onChange={date=>{handleDOB(date)}}

                          maxDate = {new Date()}
                          value={dob}
                          
                     /> 
                       {/* {error.dob?  <div className="error-validation-msg"> {error.dob}</div> : null} */}
         
         </div>  
         <div className="item item-left-row"> 
              <label className="">* Age: </label><input className="form-control" type="number" name="age" placeholder="" onChange={handleChange}
              /> 
            {error.age ? <div className="error-validation-msg"> {error.age}</div> : null}
        </div>
           

          <div className="form-control gender-div">
                                     
                                        <span>*Gender:</span> 
                                          <input 
                                             type="radio" 
                                             id="male" 
                                             name="gender" 
                                             value="male"
                                             checked={userDatas.gender === "male"}
                                             onClick={handleGenderRadioButton}
                                            
                                                 />
                                          <label htmlFor="male">Male</label>

                                          <input  
                                            type="radio"
                                            id="female"
                                             name="gender" 
                                             value="female"
                                             checked={userDatas.gender === "female"}
                                            onClick={handleGenderRadioButton} />

                                          <label htmlFor="female">Female</label>

                                          <input 
                                              type="radio"
                                              id="other" 
                                              name="gender"
                                              value="other"
                                              checked={userDatas.gender === "other"}
                                            onClick={handleGenderRadioButton} 
                                                 />
                                          <label htmlFor="other">Other</label>

                                         {error.gender ?  <div className="error-validation-msg"> {error.gender}</div> : null}
                                        </div>

                                        <div className="form-control gender-div consent-div">
                                     
                                     <span>Consent:</span> 
                                       <input 
                                          type="radio" 
                                          id="yes" 
                                          name="consent" 
                                          value="yes"
                                          checked={userDatas.consent === "yes"}
                                          onClick={handleConsentRadioButton}
                                         
                                              />
                                       <label htmlFor="yes">Yes</label>

                                       <input  
                                         type="radio"
                                         id="no"
                                          name="consent" 
                                          value="no"
                                          checked={userDatas.consent === "no"}
                                         onClick={handleConsentRadioButton} />

                                       <label htmlFor="no">No</label>

                                     </div>

                    
                                        <div className="country-selector item-left-row">
                                         <label className="">* Country: </label>
                                            <CountryDropdown
                                              value={userDatas.country}
                                              onChange={(val) => selectCountry(val)} />
                                              {error.country ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.country}</div> : null}
                                        </div>
                                        <div className="region-selector item-left-row">
                                         <label className="">* Region:  </label>
                                            <RegionDropdown
                                              country={userDatas.country}
                                              value={userDatas.region}
                                              onChange={(val) => selectRegion(val)} />
                                          {error.region ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.region}</div> : null}
                                            <small className="form-text text-muted">*Select Region - Enabled once you select Country</small>
                                           </div>

                                          {/* <div className="item item-left-row"><label className="">* Zipcode: </label><input className="form-control" type="text" name="zipcode" placeholder="" onChange={handleChange} maxLength='50'
                                        /> 
                                        {error.zipcode ? <div className="error-validation-msg"> {error.zipcode}</div> :null}
                                           </div> */}
                                         
                                         <div className="form-control phone-input item item-left-row">
                                           <label className="phone-hov">* Phone: </label>
                                        <PhoneInput
                                            inputProps={{
                                                  
                                                  
                                                  
                                                }}
                                            country={'bh'}
                                            value={userDatas.phone.mobile_number}
                                            onChange={handlePhoneInput}
                                            onBlur={handleCheckExistingPhone}

                                          />
                                          {error.phone ? <div className="error-validation-msg"> {error.phone}</div>  : null}

                                          </div>

                                         <div className="item item-left-row"> 
                                        <label className="">* CPR/Passport Number: </label>
                                        <input className="form-control" type="text" name="cpr" placeholder="" onChange={handleChangeCpr} maxLength='10'
                                        />  
                                        {error.cpr ? <div className="error-validation-msg"> {error.cpr}</div>  : null}
                                         </div>

                                         <div className="item item-left-row"> 
                                        <label className="">File Number: </label>
                                        <input className="form-control" type="text" name="file" placeholder="" onChange={handleChangeFile} maxLength='30'
                                        />  
                                        {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null}
                                         </div>

                                     <div className="item item-left-row"><label className=""> Address: </label>
                                     <textarea  className="text-area" name='address'  cols="100" rows="4" value={userDatas.address}  onChange={handleChange} maxLength='150'/>
                                        {/* {error.address ? <div className="error-validation-msg"> {error.address}</div>: null} */}
                                         </div>
                                    
                                     {/* <div className="item item-left-row"> 
                                        <label className=""> Family History </label>
                                        <textarea  className="text-area" name='famHistory'  cols="100" rows="4" value={userDatas.famHistory}  onChange={handleChange} maxLength='150'/>

                                         </div>   
                                         <div className="item item-left-row"> 
                                        <label className="">Past Medical History: </label>
                                        <textarea  className="text-area" name='medHistory'  cols="100" rows="4" value={userDatas.medHistory}  onChange={handleChange} maxLength='150'/>

                                         </div>
     
                                         <div className="item item-left-row"> 
                                        <label className="">Present Complaint: </label>
                                        <textarea  className="text-area" name='complaint'  cols="100" rows="4" value={userDatas.complaint}  onChange={handleChange} maxLength='150'/>

                                         </div> */}
                                 
                                     {/* <div className="divider"></div>
                                           <div className="item item-left-row"><label className="">* Password: </label><input className="form-control pswd" type="password" name="password" placeholder="" onChange={handleChange} maxLength='30' /> 
                                         {error.password ? <div className="error-validation-msg"> {error.password}</div> : null}
                                         </div>
                                          <div className="item item-left-row"><label className="">* Confirm Password: </label><input className="form-control pswd" type="password" name="confirmpassword" placeholder="" onChange={handleChange} maxLength='30' />
                                         {error.confirm_password ? <div className="error-validation-msg"> {error.confirm_password}</div>  :null}
                                           </div>     */}
                                      
                                        
                                        
                                        </div>
                                        <br/>

                                            <button onClick={handleContinue} className="btn btn-primary btn-col">Register</button>

                                    </div>
                                </form>


      {
        successShow ? 
        <SuccessPopup
        show={successShow}
        onHide={handleSuccess} /> : null
      } 

{
        errorShow ? 
        <ErrorPopup
        show={errorShow}
        onHide={()=> setErrorShow(false)} /> : null
      } 

        </div>
    )
}

export default HospRegisterPatient
