import React, { useEffect,useState} from "react";
import Chart from "chart.js";

import axios from "axios";
import DatePicker from 'react-date-picker';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {APIURL} from "../../Global";
import './pulse.css'

let myChart;
function VitalsPulse(props) {
  const [startDate, setStartDate]=useState(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]);
  const [endDate,setEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset()*60*1000)).toISOString().split('T')[0]);
  const [patientId,] =useState(props.id);
 
    const [inference, setInference]= useState([]);
    const [pulseArray,setPulseArray]=useState([]);
   const [pulseDatesArray,setPulseDatesArray]=useState([]);
   const [pulseList,setPulseList]=useState([]);
  const [updateRender,setUpdateRender]=useState(false);
 const [unit,setUnit]=useState();
 const [load ,setLoad]= useState('')
 const [inferenceLoad, setInferenceLoad]= useState('')

 

  useEffect(() => {
    const ctx = document.getElementById("chart-pulse").getContext('2d');
   

if (myChart) {
        myChart.destroy();
      }
  myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: pulseDatesArray,
        datasets: [
          {
            label: "Pulse",
            data: pulseArray,
            backgroundColor: 'purple',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'purple',

           
          }
        ],
        tooltips: {
          backgroundColor: 'purple',
             
              

        }
      },

      options:{
        scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: `Pulse Values  ${unit ? "(" +unit+ ")" : null} `
          },

        ticks: {
          beginAtZero: true,
          stepSize: 10,
          padding: 20,
        },
        }],
        xAxes:[{
          scaleLabel: {
          display: true,
            labelString: 'Date'
          }
        }
        ]
      },
          legend:{
          position:'right',
          labels:{
            fontColor: '#000'
          }
        }
      }
    });
  },[pulseArray,pulseDatesArray,unit]);


  
useEffect(()=>{
const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
   setLoad('Loading Data...')
  
    axios.get(`${APIURL}/api/v1/doctor/patient-pulse/?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&sort_by=asc`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
        setLoad('')
        let arr= res.data.results;
        let pulse=[];
       
        arr.map(result=>{
          pulse.push(result.pulse);
         
        })
     setPulseArray(pulse);
    
        let newArrayDates=[];
        arr.map(result=>{
          newArrayDates.push(result.date.slice(0,10))
        })
     setPulseDatesArray(newArrayDates);

     setPulseList(res.data.results);
   let unit1=null;
     if(res.data.results.length>0){
      unit1=res.data.results[0].unit
     }
     else{
      unit1=null;
     }
    
     setUnit(unit1);
      }
      else {
        setLoad('Error in Fetching Data')
      }

    })
    .catch(err=>{
      setLoad('Error in Fetching Data')
})


},[updateRender]);


useEffect(()=> {
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
   setInferenceLoad('Loading Data...')
  
    axios.get(`${APIURL}/api/v1/patient/pulse-inferences/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

        setInferenceLoad('')
        setInference(res.data.data)
        
      }
      else {
        setInferenceLoad('Error in Fetching Data')
      }

    })
    .catch(err=>{
      setInferenceLoad('Error in Fetching Data')
})
},[updateRender])

 const handleGo=(e)=>{
      e.preventDefault();
      setUpdateRender(!updateRender);
}


const handleFromDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));



let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setStartDate(DateExtracted);
  }
}

const handleToDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));

let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setEndDate(DateExtracted);
  }
}


const renderPulse=(cellInfo)=> {
   const data= pulseList
  const pulseData = [...data].reverse()

  return(<h6> {pulseData[cellInfo.index][cellInfo.column.id]} {pulseData[cellInfo.index].unit} </h6>)
}
const renderDate=(cellInfo)=> {
  
   const date4= pulseList
  const date5 = [...date4].reverse()

  return(<h6> {date5[cellInfo.index].date.slice(0,10)} </h6>)
}

const renderFitData= (cellInfo)=> {
  const data= pulseList
  const fitData = [...data].reverse()

  return(<h6> { fitData[cellInfo.index][cellInfo.column.id] ? 'Yes': 'No' } </h6>)
}

const columnsPulse= [
{
  Header: "Date",
  accessor: "date",
  Cell: renderDate,
    style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width: 170,
  minWidth:50
},
{
  Header: "Pulse",
  accessor: "pulse",
  Cell: renderPulse,
   width: 140,
  minWidth:50,
   
},
{
  Header: "Smart Device Data",
  accessor: "is_wearables",
  Cell: renderFitData,
   width: 150,
  minWidth:50,
   
}
]
const Pulse_Table = <ReactTable 
         columns={columnsPulse}
         data={pulseList}
         defaultPageSize = {10}
         
         resizable
                           
                 
         >

         </ReactTable> 

const inferenceData =

    inferenceLoad===''?
      inference!==undefined? <div className="inference-container analysis-subsection">
      <div className="inference-container-header box-theme">
       <h6 className="inference-container-title"> Analysis</h6>
      </div>
      <div className="inference-container-body">
        <h5 className='text-success font-weight-bold'>{inference.summary}</h5>
        <h6  className='text-muted font-weight-bold'>Maximum Pulse Level : </h6> <p >{inference.max_pulse}BPM {inference.max_pulse_date}</p><br/>
        <h6  className='text-muted font-weight-bold'>Minimum Pulse Level : </h6> <p >{inference.min_pulse}BPM {inference.min_pulse_date}</p><br/>
        <h6  className='text-muted font-weight-bold'>Pulse Deviation : </h6> <p >{inference.pulse_sd}</p>
        </div>
      </div>
      
    
: <h6 style={{color: "red"}}> No data to show! </h6> : inferenceLoad==='Error in Fetching Data'? <h6 style={{color: "red"}}>{inferenceLoad}</h6> : <h6 style={{color: "grey"}}>{inferenceLoad}</h6>
  return (
 

    <>
     <div className="food-habits-page">
     <div className="food-habits-header">
    <h2 className="title-of-tasks">Pulse Details </h2>
         {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}



<div className="calender-pickers-from-to">
<div className="span-date">           
<span> From </span>

<DatePicker
                          value={new Date(startDate)}
                          onChange={date=>{handleFromDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<div className="span-date">             
<span> To </span>

<DatePicker
                          value={new Date(endDate)}
                          onChange={date=>{handleToDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<button type="submit" className="btn btn-primary btn-col" onClick={handleGo}> Check </button>
</div>

</div> 
<div className="food-habits-sections">
   <div>
   <h5 className="title-of-lists"> Graph </h5>
    <div className="chart-div-glucose">
      
   
        <canvas id="chart-pulse" width="800" height="400"></canvas>

      
    </div>
    </div>
    
     <div className="food-table">
     <h5 className=" title-of-lists"> Table </h5>
    {load===''? pulseList.length>0 ?
     <>
    

 
     
   {Pulse_Table}
     </> 
     : <h6 style={{color: "red"}}> No data to show! </h6> : load==='Error in Fetching Data'? <h6 style={{color: "red"}}>{load}</h6> : <h6 style={{color: "grey"}}>{load}</h6>
   }
     </div>
   
     </div>
     </div>
     </>
  );
}

export default VitalsPulse;