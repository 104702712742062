import React,{useState,useEffect,useContext} from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./presc.css";
import "./presctable.css";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

import {APIURL} from "../../Global";
import {MedContext} from "../../contexts/MedContext";
import "./currentmedications.css";
import ImageScale from '../ImageScale/ImageScale';

function PastMedications(props) {
    const [img ,setImg] = useState([]);
const [medRender,setMedRender]=useContext(MedContext);
const [prescData ,setPrescData]= useState([]);
const [pastMed ,setPastMed]= useState(false)
const [icon ,setIcon]= useState(false)
const [view ,setView]= useState(false)
  const [medList,setMedList]=useState([{


                 drug: "",
                 
                 from: "",
                 to:"",
                
                 dosage: "",

                 intake_count: [],
                 intake_time: '',
                 remarks: ""
                  }]);
           
  const [render,setRender]=useState(true);
  const [errorMsg,setErrorMsg]=useState('');
  const [submitMsg,setSubmitMsg]=useState("");
  const [modalShow,setModalShow] =useState(false);
  const [imageShow, setImageShow]= useState(false)

const [errorSubmitModalShow,setErrorSubmitModalShow]=useState(false);

 const id = props.id;

 const [keys,] = useState({'z' :false });


           useEffect(()=>{
            const handleKeyDown =(e)=>
            {      
              keys[e.key]=true
          
              if(e.altKey && keys['z']){
                setErrorSubmitModalShow(false)
                setErrorMsg('')
                setModalShow(false)
                setMedRender(!medRender)
                setSubmitMsg('')
              }
          
            }
            window.addEventListener('keydown', handleKeyDown)
            
            
            const handleKeyUp =(ev)=>
          {
              
              keys[ev.key]= false
            }
          
          window.addEventListener('keyup', handleKeyUp)
          
          
          
            return()=>{
              window.removeEventListener('keydown', handleKeyDown)
              window.removeEventListener('keyup', handleKeyUp)
              
            }
          },[submitMsg, errorMsg]);


 
  useEffect(()=>{

    setMedList(medList)
   
  },[render,medRender, medList]);
  

  useEffect(()=>{

    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   
    
   
    axios.get(`${APIURL}/api/v1/doctor/update-current-medication/?medicines_list=past_medicines&patient_id=${id}`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){

       
        setPrescData(res.data.prescription)
       let data = []
        
       if(res.data.doctor.length ===0 && res.data.patient.length ===0 && res.data.prescription.length ===0){
        data=[]
      }

        if(res.data.doctor.length !==0){
            res.data.doctor.map((item)=> {
              data = data.concat(item.medicines)
            })
          }
        
          if(res.data.patient.length !==0){
            res.data.patient.map((item)=> {
              data = data.concat(item.medicine)
            })
          }

        if(res.data.prescription.length !==0){
          res.data.prescription.map((item)=> {
            data = data.concat(item.medicines)
          })
        }
        setMedList(data)


      }else{

        setErrorMsg(res.data.message? res.data.message : "Error in fetching data");
        setErrorSubmitModalShow(true);
      }


    })
    .catch(err=>{
        setErrorMsg("Error in fetching data");
        setErrorSubmitModalShow(true);
})
 

 
},[medRender,id])

/*const AddRow=()=>{
  const list = medList;
  list.push({drug:"", strength: "",form:"",duration: "",route:"", dosage:"",frequency:"",intake_count:[]});
  
  setMedList(list);
  setRender(!render);
  
}*/




/*const handleNewRow=(e)=>{
  e.preventDefault();
     const newMed = medList;
    newMed.push({drug:""});
    setMedList(newMed);

}*/

const handleShow=(index)=> {
  const newarr=medList;
  const newList = newarr.filter((elt,i)=>{
    return i=== index
  })
let data=[]
  for(let i=0; i<prescData.length; i++) {
    if(prescData[i].prescription_id===newList[0].prescription_id){
        prescData[i].image.map(item=>{
            data= data.concat(item)
        })
      setImg(data)
      break;
    }
    else {
      setImg([])
    }
  }

  
  setImageShow(true)
  
  
}
const renderEditable=(cellInfo)=>{

  return(
    <div style={{ backgroundColor: "#fafafa" }}
        className="form-control"
      > {medList[cellInfo.index][cellInfo.column.id]}</div> )
}

const renderDr=(cellInfo)=>{
const creator =medList[cellInfo.index]
const type =creator.creator_type
  return(

    type==='doctor'?

    <div style={{ backgroundColor: "#fafafa" }}
        className="form-control"
      ><h6> {medList[cellInfo.index].doctor}</h6>
       <h6> {medList[cellInfo.index].hospital} </h6> </div> :

       type==='self'?

       <div style={{ backgroundColor: "#fafafa" }}
        className="form-control"
      ><h6>Added by Patient</h6>
       </div> : 

       type=== undefined ?
       <div style={{ backgroundColor: "#fafafa" }}
        className="form-control"
      ><h6>Added as a Prescription</h6>
      <button className="presc-btn" 
       onClick={()=>{handleShow(cellInfo.index);}}>Click to View</button>
       </div> : null

        )
}
const renderIntakeTime=(cellInfo)=>{
  const food_time=medList[cellInfo.index][cellInfo.column.id];
  let food_label="";
  if(food_time=== "0"){
    food_label= "N/A";
  }
  else if(food_time=== "1"){
    food_label= "Before food";
  }
  else if(food_time=== "2"){
    food_label= "After food";
  } 
   return(

    <div style={{ backgroundColor: "#fafafa" }}
        className="form-control"
      > {food_label}</div> 
    

)
}

const columns = [
{
  Header: "DRUG",
  accessor: "drug",
  Cell: renderEditable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:100,
   sortable:false,
   filterable:false,
   width:200

},
{
  Header: "Strength",
  accessor: "strength",
  minWidth:80,
  width:110,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "INTAKE COUNT">COURSE</span>,
  accessor: "intake_count",
  Cell: renderEditable,
  sortable:false,
  width:110,
  minWidth:100,
  filterable:false

},
{
  Header: "Prescribed by",
  accessor: "doctor",
  Cell: renderDr,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:100,
   sortable:false,
   filterable:false,
   width:200

},
{
  Header: "REMARKS",
  accessor: "remarks",
  Cell: renderEditable,
  sortable:false,
  width:300,
  minWidth:100,
  filterable:false
  
},
{
  Header: "DOSAGE",
  accessor: "dosage",
  minWidth:80,
  width:100,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "FROM",
  accessor: "from",
  minWidth:80,
  width:120,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "TO",
  accessor: "to",
  minWidth:80,
  width:120,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},

{
  Header: "Frequency",
  accessor: "frequency",
  minWidth:80,
  width:100,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "Form",
  accessor: "form",
  minWidth:80,
  width:110,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "Route",
  accessor: "route",
  minWidth:80,
  width:130,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},


{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "INTAKE TIME">TIME</span>,
  accessor: "intake_time",
  Cell: renderIntakeTime,
  sortable:false,
  width:130,
  minWidth:100,
  filterable:false




},

]


const ErrorSubmitPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}




const SubmitPopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{submitMsg}!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> 
       
      
      </Modal.Footer>
    </Modal>
  );
}

const ImagePopUp=(props)=> {
  return (
    <Modal
      {...props}
      dialogClassName="modal-80w"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
              <ImageScale img={img} presc={true} />
          
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> 
       
      
      </Modal.Footer>
    </Modal>
  );
}

  return(
    <>    
      
    <div className="overlay-body">

    <div className="add-presc-page"><br/>
    <h2 className="title-of-tasks"> Past Medications </h2>
    <br/>
    {/*<h4> Patient : ID {id} </h4><br/> */}

<div className="current-table-holder">
    <ReactTable 
         columns={columns}
         data={medList}
         defaultPageSize = {5}
         filterable
         resizable
         noDataText={"No data"}
         className="current-med-table"
         >

         </ReactTable>
         <br/>
  </div>
       
          
        
        
      </div>
      <div className="divider"></div>
     







  {errorMsg !=="" ?
    <ErrorSubmitPopup
        show={errorSubmitModalShow}
        onHide={() =>  {setErrorSubmitModalShow(false);
                        setErrorMsg('')}}
        
      />   : null } 



 {submitMsg!=="" ? 

          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false);
                         setMedRender(!medRender);
                        setSubmitMsg('')}}
      /> 
      : ""}

{imageShow ? 

<ImagePopUp
show={imageShow}
image={img}
onHide={() =>  {setImageShow(false)}}
/> 
: null}
  
</div> 

</>
)
}

export default PastMedications
