import React, {useState} from 'react'

function AddDetails(props) {
    
    const [remarkData, setRemarkData]= useState('')

    let data = remarkData
    props.update(data)

    return (
        <div className='flex-col'>        

                <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {remarkData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setRemarkData(e.target.value)
          } }
            autoComplete="off"
                />
        </div>
    )
}

export default AddDetails
