import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import { useHistory } from 'react-router-dom'
import HospDocNavbar from '../HospDocNavbar/HospDocNavbar'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import './schedule.css'
import Reschedule from './Reschedule';
import AddSchedule from './AddSchedule';

function ScheduleList() {

    const [scheduleList, setScheduleList]= useState([])
    const [detail,setDetail]= useState({
      day_of_month:null,
      days : [],
      frequency: '',
      from_date: '',
      id:'',
      slots: [],
      to_date:'',
      week_of_month:null,
      weekday_of_month:null
    })
    const [expand, setExpand]= useState()
    const [id,setId]=useState('')
    const [rescheduleShow,setRescheduleShow]= useState(false)
    const [addScheduleShow,setAddScheduleShow]= useState(false)
    const [confirmShow,setConfirmShow]= useState(false)
    const [successShow,setSuccessShow]= useState(false)
    const [errorShow,setErrorShow]= useState(false)
    const [errorMsg, setErrorMsg]= useState('')
    const [refresh,setRefresh]= useState(false)
    const [confirmMsgShow, setConfirmMsgShow]= useState(false)
    const [confirmMsg, setConfirmMsg]= useState("")

    const history = useHistory();

    const handleBack= ()=> {
        history.goBack();
    }

    useEffect(()=>{

        const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')  
                
          
          
        
          axios.get(`${APIURL}/api/v1/doctor/schedule/`, { headers: {
            
            "Authorization" : 'Token '+str  }})
          .then(res=>{
              
            if(res.data.status==="success"){

                setScheduleList(res.data.schedules)
            }
            else{

            }
      
          })
          .catch(err=>{

      })

      
      
      
      },[refresh])

      const handleConfirm= (id)=> {
        setId(id)
        setConfirmShow(true)
      }

      const handleDelete= ()=> {
        const tokenString= localStorage.getItem('usertoken');
         
        let str= tokenString.replace(/["]+/g, '') 

        const data= {
          is_delete:true
        }

       axios.put(`${APIURL}/api/v1/doctor/schedule/${id}/`, data, { headers: {
           
         "Authorization" : 'Token '+str  }})
       .then(res=>{
           
         if(res.data.status==="success"){

          if(res.data.confirmation_message !== ""){
            setConfirmMsg(res.data.confirmation_message)
            setConfirmMsgShow(true)
        }
        else {
            setSuccessShow(true)
        }

         }
         else{
            setErrorMsg(res.data.message)
            setErrorShow(true)
         }
   
       })
       .catch(err=>{
          setErrorShow(true)
          setErrorMsg('Error in data submission.Please try again!')
   })
      }

      const handleDeleteConfirm= ()=> {
        const tokenString= localStorage.getItem('usertoken');
         
        let str= tokenString.replace(/["]+/g, '') 

        const data= {
          is_delete:true,
          is_confirm:true,
        }

       axios.put(`${APIURL}/api/v1/doctor/schedule/${id}/`, data, { headers: {
           
         "Authorization" : 'Token '+str  }})
       .then(res=>{
           
         if(res.data.status==="success"){

          if(res.data.confirmation_message !== ""){
            setConfirmMsg(res.data.confirmation_message)
            setConfirmMsgShow(true)
        }
        else {
            setSuccessShow(true)
        }

         }
         else{
            setErrorMsg(res.data.message)
            setErrorShow(true)
         }
   
       })
       .catch(err=>{
          setErrorShow(true)
          setErrorMsg('Error in data submission.Please try again!')
   })
      }

      const callDetail= (id)=> {

        const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '') 

        axios.get(`${APIURL}/api/v1/doctor/schedule/${id}/`, { headers: {
            
          "Authorization" : 'Token '+str  }})
        .then(res=>{
            
          if(res.data.status==="success"){

              setDetail(res.data.schedule)
          }
          else{

          }
    
        })
        .catch(err=>{

    })
  }

      const handleClick=(id,index)=> {
        localStorage.setItem('ID',id)
        if(expand !==index){
          setExpand(index)
          callDetail(id)
        }
        else{
          setExpand()
        }
            
      }

      const ReschedulePopup=(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">             
              
           <Reschedule detail={detail} refresh={handleRender} />
             
            </Modal.Body>
            <Modal.Footer>
               
               <Button variant="secondary" onClick={props.onHide}>Close</Button>
      
               
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      } 

      const AddSchedulePopup=(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">         
              
           <AddSchedule refresh={handleRefresh}/>
             
            </Modal.Body>
            <Modal.Footer>
               
               <Button variant="secondary" onClick={props.onHide}>Close</Button>
      
               
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      } 

      const handleRefresh=()=> {
        setAddScheduleShow(false)
        setRefresh(!refresh)
      }
      
      const handleRender=()=> {
        setRescheduleShow(false)
        setRefresh(!refresh)
        const data =localStorage.getItem('ID')
        callDetail(data);
      }

      const ConfirmPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Are you sure to delete this Schedule ?</h4>
               
              </Modal.Body>
              <Modal.Footer>
      
                 <Button variant="secondary" onClick={props.onHide}>No</Button>
                 <Button variant="danger" onClick={props.onCall}>Yes</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const SubmitPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Schedule Deleted Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>{errorMsg!==''? errorMsg : 'Error in data submission.Please try again!'}</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const ConfirmMsgPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 className='text-danger'>{confirmMsg}</h4>
               
              </Modal.Body>
              <Modal.Footer>
      
                 <Button variant="secondary" onClick={props.onHide}>No</Button>
                 <Button variant="danger" onClick={props.onCall}>Yes</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

      const display = scheduleList.map((item,index)=> {
          return(
            <div className='schedule-class'>
              <div key={index}>
                <div className='each-schedule'>
                  <span className='schedule-date'><i class="far fa-calendar-alt"></i> {item.date}</span>
                </div>
                <div onClick={()=>handleClick(item.id,index)} className='schedule-detail'>
                  <span className='each-schedule-detail'>{item.slots.map(slot=> { return <><span>{slot}</span><br/></>})}</span>
                  <span className='each-schedule-detail1'>{item.frequency==='daily'? 'weekly' : item.frequency==='monthly'? 'monthly':'Frquency not Available'}</span>
                  <span className='each-schedule-detail2 text-muted font-weight-bold'><i className={expand===index ? "fas fa-sort-up detail-open" : "fas fa-sort-down detail-open"}></i> Details</span>
                </div>
                {
                  expand===index ? 
                  <div className='detail-btns'>
                    <div className='left-detail'>
                      <button onClick={()=>setRescheduleShow(true)} className='btn btn-primary btn-col schedule-btn'>Reschedule</button>
                      <button onClick={()=> handleConfirm(item.id)} className='btn btn-danger schedule-btn'>Delete</button>
                    </div>
                    <div className='all-detail'>

                        {/*<div className='detail-flex'>
                          <span className='text-muted font-weight-bold'>Schedule ID </span>   
                          <span style={{marginTop:"10px"}}>{detail.id}</span>                      
                        </div>*/}
                        <div className='detail-flex'>                         
                          <span className='text-muted font-weight-bold'>Slot</span>
                          <div className='slot-flex'>
                            {/*<span className='slot-table'>ID</span>*/}
                            <span className='slot-table1'>From</span>
                            <span className='slot-table2'>To</span>
                            <span className='slot-table'>Number of Tokens</span>
                          </div>
                          
   
                          {
                            detail.slots.map(item=> {
                              return(
                                <div>
                                  {/*<span className='slot-data'>{item.id}</span>*/}
                                  <span className='slot-data'>{item.from_time.slice('0','5')}</span>
                                  <span style={{marginRight:'100px'}}>{item.to_time.slice('0','5')}</span>
                                  <span className='slot-data'>{item.token}</span>
                                </div>
                              )
                            })
                          }
                          
                        </div>

                      <div className='detail-flex'>
                        <span className='text-muted font-weight-bold'>Consultation Days </span>
                      {
                        detail.days.length>0 ? detail.days.map((data)=> {
                          return(
                            <div>
                              <span style={{marginTop:"10px"}}>{data==='0'?'Monday': data==='1'?'Tuesday': data==='2'?'Wednesday':data==='3'?'Thursday':data==='4'?'Friday':data==='5'?'Saturday':data==='6'?'Sunday':null}</span>
                            </div>
                          )
                        }) : detail.day_of_month !==null ?
                          <span className='consult-color'>Consultation on {detail.day_of_month}{detail.day_of_month===1?'st':detail.day_of_month===2?'nd':detail.day_of_month===3?'rd':'th'} day of month</span>
                          :
                          detail.week_of_month !==null ?
                          <span className='consult-color'>Consultation on {detail.weekday_of_month===0 ? 'Monday': detail.weekday_of_month===1 ? 'Tuesday': detail.weekday_of_month===2 ?
                           'Wednesday': detail.weekday_of_month===3 ? 'Thursday': detail.weekday_of_month=== 4 ? 'Friday': detail.weekday_of_month=== 5 ? 'Saturaday':
                            detail.weekday_of_month=== 6 ? 'Sunday': null} of {detail.week_of_month} week</span>
                             
                          :
                         <h6 className='text-muted'>No data available</h6>
                      } 
                      </div>
                      
                    </div>
                  </div>
                  : null
                }
                
              </div>
            </div> 
          )
          

      })

    return (
        <>
            <HospDocNavbar />
            <div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div>
    <h2 className="title-of-page"> Schedules </h2>
    
      {
      display.length>0 ? 
          display
        :
        <>
        <i class="fas fa-calendar-alt cal-size"></i>
        <h6 className='text-muted font-weight-bold no-schedule'>No Schedules Available at the Moment</h6>
        </>
        }
      
        <br/>

    <button onClick={()=>setAddScheduleShow(true)} className='btn btn-primary btn-col add-schedule-btn'>Add Schedule</button>



    {rescheduleShow ?
    <ReschedulePopup
        show={rescheduleShow}
        detail={detail}
        onHide={() => setRescheduleShow(false)}
       
      />: ''}

{addScheduleShow ?
    <AddSchedulePopup
        show={addScheduleShow}
        onHide={() => setAddScheduleShow(false)}
       
      />: ''}

    {
        confirmShow ? 
        <ConfirmPopUp 
            show={confirmShow}
            onCall={() => { setConfirmShow(false); handleDelete()   }}
            onHide={() => setConfirmShow(false) }/> : null
    }

{successShow ? 

<SubmitPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false); setRefresh(!refresh)}}
/> 
: null}

{errorMsg!=='' ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}

{
        confirmMsgShow ? 
        <ConfirmMsgPopUp 
            show={confirmMsgShow}
            onCall={() => { handleDeleteConfirm();  setConfirmMsgShow(false) }}
            onHide={() => setConfirmMsgShow(false) }/> : null
    }
       </>
    )
}

export default ScheduleList
