import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import printJS from 'print-js'
import moment from 'moment'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function GeneratePatientInvoice(props) {
	const [invoiceData ,]= useState(props.invoiceData);
    const [bdo_Data,]=useState(JSON.parse(sessionStorage.getItem('bdodata'))? JSON.parse(sessionStorage.getItem('bdodata')) : null);
    const [method, setMethod]= useState('')
    const [errorShow,setErrorShow]=useState(false);
    const [errorMsg,setErrorMsg]=useState("");
    const [apiData, setApiData]= useState({
       invoice_no: "",
       cpr:"",
       amount_paid: "",
       reg_amount:"",
       currency: "",
       payment_status:"",
       consultation_amount: "",
       consultation_payment_status:"",
       vat:"",
       net_amount:""
    })

useEffect(()=> {

   const tokenString= localStorage.getItem('usertoken');
 
   let str= tokenString.replace(/["]+/g, '');
  
    axios.get(`${APIURL}/api/v1/hospital/patient-hospital-payment_v1/?payment_for=registration&patient_id=${props.patientId}`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    
  
   
  
      if(res.data.status==="success"){

       setApiData(res.data)
  
      }else{
      
         setErrorMsg(res.data.message)
         setErrorShow(true)
      }
  
  
    })
    .catch(err=>{
  
             
      setErrorMsg('Error in Retreiving data.Please try again')
      setErrorShow(true)
  })

},[])

const handleMethod =(e) => {
   setMethod(e.target.value)
}

const handleSave = (e)=> {

   const tokenString= localStorage.getItem('usertoken');
 
   let str= tokenString.replace(/["]+/g, '');

   const dataToUpdate = {
       payment_method : method,
       payment_for:'registration',
       invoice_no: apiData.invoice_no
   }
  
    axios.post(`${APIURL}/api/v1/hospital/patient-hospital-payment_v1/?payment_for=registration&patient_id=${props.patientId}`, dataToUpdate ,
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    
  
   
  
      if(res.data.status==="success"){

       handlePrint()
  
      }else{
       setErrorMsg(res.data.message)
       setErrorShow(true)
      }
  
  
    })
    .catch(err=>{
       setErrorMsg('Error in Data Submission.Please try again')
       setErrorShow(true)
  })

}

const SubmitErrorPopup =(props)=>{
    

   return (
     <Modal
       {...props}
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered
        backdrop="static"
         keyboard={false}
     >
       <Modal.Header closeButton>
         <Modal.Title id="contained-modal-title-vcenter">
          
         </Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
        
       </Modal.Body>
       <Modal.Footer>
        
          <Button variant="secondary" onClick={props.onHide}>Ok</Button>
 
        
        
 
       </Modal.Footer>
     </Modal>
   );
 } 


   const handlePrint = () => {
    
      printJS({
         printable: 'print-invoice',
         type: 'html',
         targetStyles: ['*'],
         header: 'Registration Invoice'
      })
    
    }

    // const getSum = () => {
    //   const amt = parseFloat(apiData.reg_amount)
    //   const consultAmt = parseFloat(apiData.consultation_amount)
    //   const total = amt + consultAmt
    //   const totalAmt = total.toFixed(3)

    //   return totalAmt
    // }

 return (<div>
    
 	<h2 style={{textAlign:'center'}} className="title-of-page"> Registration Invoice  </h2><br/>

   
   <div className='flex-row invoice-radio'>
           <span className='text-muted font-weight-bold'>Select payment method :</span>
           <input type='radio' className='hideme' checked={method==='card'} id='card' value='card' onChange={handleMethod} />
        <label for='card' className='invoice-label'>
            Card
        </label>

        <input type='radio' className='hideme' checked={method==='cash'} id='cash' value='cash' onChange={handleMethod} />
        <label for='cash' className='invoice-label'>
            Cash 
        </label>

        <input type='radio' className='hideme' checked={method==='benefit_pay'} id='benefit_pay' value='benefit_pay' onChange={handleMethod} />
        <label for='benefit_pay' className='invoice-label'>
          Benefit Pay 
        </label>
       </div>

 	<div id='print-invoice' className="invoice-table flex-col to-print ">
 	 <div className="invoice-row1 invoice-header">
      <div>
         <h5 className='font-weight-bold text-left'>Invoice {apiData.invoice_no}</h5>
         <h6 className="text-left">Patient: {invoiceData.firstname} {invoiceData.lastname}</h6>
         <h6 className="text-left">Phn : {invoiceData.phone.mobile_number}</h6>
         <h6 className="text-left">Cpr : {apiData.cpr}</h6>
     </div>

         <div>
            <h3 className="invoice-heading caps text-center">{bdo_Data.hospital_name}</h3>
            <h6 className="invoice-heading caps text-center">{bdo_Data.hospital_state},{bdo_Data.hospital_location},{bdo_Data.hospital_street}</h6>
         </div>

      <div className='invoice-detail mr-2'>

          <h5>{moment().format('DD-MM-YYYY')}</h5>
          <h6 className="invoice-heading invoice-heading-normal">{bdo_Data.hospital_mobile}</h6>
          <h6 className="invoice-heading invoice-heading-normal">{bdo_Data.hospital_email}</h6>
          {/* <h6 className="invoice-heading invoice-heading-normal"> GSTIN : {bdo_hospital_gst} </h6>
          <h6 className="invoice-heading invoice-heading-normal"> Accreditation : {bdo_hospital_accreditation} </h6> */}
        </div>

     </div>

     <div className="invoice-row3">
        <table >
        <tr>
          <th> Description </th>

          <th>Amount</th>
          <th>VAT</th>

        </tr>

        <tr>
          <td> 
             <span className="caps">Patient</span><br/>
             <span className="caps">Registration </span>

             {/* <span> ID: {careId}</span><br/> */}
             

           </td>          
          <td>{apiData.reg_amount}</td>
          <td>{apiData.vat}</td>

        </tr>

        </table>
     </div>
     <div className="invoice-row4">
        <h6 className="text-right">Gross Total : <span className="caps-full">BD</span>{apiData.reg_amount}</h6>
        <h6 className="text-right">VAT Amount : <span className="caps-full">BD</span>{apiData.vat}</h6>
        <h6 className="text-right">Net Total : <span className="caps-full">BD</span>{apiData.net_amount}</h6>
        {/* <h6 className="text-right">Payment Status : {apiData.payment_status}</h6> */}
        {/* <h6 className="text-right">Consultation Payment Status : {apiData.consultation_payment_status}</h6> */}
         <br/>
         <h6 className="text-right">Thanks,</h6>
         <h6 className="text-right">{bdo_Data.name}</h6>
          

     </div>
 	</div>

 	<br/>
 	
 	<div className="do-not-print text-center" id="pr">
		         <button disabled={method === ''} className="btn btn-primary btn-col btn-square" onClick={handleSave}> Save and Print </button>
		         <br/>
		         <br/>
		         <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                  <br/>
                  <br/>
           </div>


           {errorMsg!=='' ? 
    <SubmitErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        setErrorMsg('');}}
       
      />: ''
    }

  </div>
 	)

	 }

export default GeneratePatientInvoice;
