import React, { useEffect,useState} from "react";
import Chart from "chart.js";

import axios from "axios";
import DatePicker from 'react-date-picker';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {APIURL} from "../../Global";
import './vitalschol.css';
let myChart;

function VitalsChol(props) {
  const [startDate, setStartDate]=useState(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]);
  const [endDate,setEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset()*60*1000)).toISOString().split('T')[0]);
  const [patientId,] =useState(props.id);
  const [hdlChol, setHdlChol]= useState([]);
  const [ldlChol, setLdlChol]= useState([]);
  const [serumChol, setSerumChol]= useState([]);
  const [totalChol, setTotalChol]= useState([]);
  const [inferenceLoad, setInferenceLoad]= useState('')
    const [hdlArray,setHdlArray]=useState([]);
    const [ldlArray,setLdlArray]=useState([]);
    const [cholArray,setCholArray]=useState([]);
    const [serumArray,setSerumArray]=useState([]);
   const [cholDatesArray,setCholDatesArray]=useState([]);
   const [cholList,setCholList]=useState([]);
  const [updateRender,setUpdateRender]=useState(false);
 const [unit,setUnit]=useState();
 const [load ,setLoad]= useState('')

  useEffect(() => {
    const ctx = document.getElementById("chart-chol").getContext('2d');
   

if (myChart) {
        myChart.destroy();
      }
  myChart =  new Chart(ctx, {
      type: "line",
      data: {
        labels: cholDatesArray,
        datasets: [
          {
            label: "Cholesterol",
            data: cholArray,
            backgroundColor: 'purple',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'purple',

           
          },
           {
            label: "HDL Cholesterol",
            data: hdlArray,
            backgroundColor: 'rgba(54, 162, 235, 0.9)',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'rgba(54, 162, 235, 0.9)',

              

               
          },
          {
            label: "LDL Cholesterol",
            data: ldlArray,
            backgroundColor: 'rgba(255, 99, 132, 0.9)',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'rgba(255, 99, 132, 0.9)',

              

           
          },
          {
            label: "Serum Cholesterol",
            data: serumArray,
            backgroundColor: 'rgba(255, 206, 86, 0.9)',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'rgba(255, 206, 86, 0.9)',

              

           
          }
        ],
        tooltips: {
          backgroundColor: 'purple',
             
              

        }
      },

      options:{
        scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: `Cholesterol Values  ${unit ? "(" +unit+ ")" : null} `
          },

        ticks: {
          beginAtZero: true,
          stepSize: 10,
          padding: 20,
        },
        }],
        xAxes:[{
          scaleLabel: {
          display: true,
            labelString: 'Date'
          }
        }
        ]
      },
          legend:{
          position:'right',
          labels:{
            fontColor: '#000'
          }
        }
      }
    });
  },[cholArray,ldlArray,hdlArray,serumArray,cholDatesArray,unit]);


  
useEffect(()=>{
const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
   setLoad('Loading Data...')
  
    axios.get(`${APIURL}/api/v1/doctor/patient-cholesterol/?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&sort_by=asc`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
        setLoad('')
        let arr= res.data.results;
        let chol=[];
        let ldl=[];
        let hdl=[];
        let serum=[];
        arr.map(result=>{
          chol.push(result.cholesterol);
          ldl.push(result.ldl_cholesterol);
           hdl.push(result.hdl_cholesterol);
            serum.push(result.serum_cholesterol);
        })
     setCholArray(chol);
     setLdlArray(ldl);
     setHdlArray(hdl);
     setSerumArray(serum);
     
        let newArrayDates=[];
        arr.map(result=>{
          newArrayDates.push(result.date.slice(0,10))
        })
     setCholDatesArray(newArrayDates);

     setCholList(res.data.results);
     let unit1=null;
     if(res.data.results.length>0){
      unit1=res.data.results[0].unit
     }
     else{
      unit1=null;
     }
    
     setUnit(unit1);

      }
      else {
        setLoad('Error in Fetching Data')
      }

    })
    .catch(err=>{
      setLoad('Error in Fetching Data')
})


},[updateRender]);


useEffect(()=> {
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
   setInferenceLoad('Loading Data...')
  
    axios.get(`${APIURL}/api/v1/patient/cholesterol-inferences/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

        setInferenceLoad('')
        setHdlChol(res.data.cholesterol_inference_data.hdl_cholesterol_data)
        setLdlChol(res.data.cholesterol_inference_data.ldl_cholesterol_data)
        setSerumChol(res.data.cholesterol_inference_data.serum_cholesterol_data)
        setTotalChol(res.data.cholesterol_inference_data.total_cholesterol_data)
      }
      else {
        setInferenceLoad('Error in Fetching Data')
      }

    })
    .catch(err=>{
      setInferenceLoad('Error in Fetching Data')
})
},[updateRender])


 const handleGo=(e)=>{
      e.preventDefault();
      setUpdateRender(!updateRender);
}


const handleFromDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));



let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setStartDate(DateExtracted);
  }
}

const handleToDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));

let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setEndDate(DateExtracted);
  }
}

const renderChol=(cellInfo)=> {
  const date4= cholList
  const date5 = [...date4].reverse()
  return(<h6> {date5[cellInfo.index][cellInfo.column.id]} {date5[cellInfo.index].unit}  </h6>)
}
const renderDate=(cellInfo)=> {
  const date4= cholList
  const date5 = [...date4].reverse()
  return(<h6> {date5[cellInfo.index].date.slice(0,10)} </h6>)
}
const renderFitData= (cellInfo)=> {
  const data= cholList
  const fitData = [...data].reverse()

  return(<h6> { fitData[cellInfo.index][cellInfo.column.id] ? 'Yes': 'No' } </h6>)
}


const columnsChol= [
{
  Header: "Date",
  accessor: "date",
  Cell: renderDate,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  }
   

},
{
  Header: "Cholesterol",
  accessor: "cholesterol",
  Cell: renderChol,
  
  width: 120,
  minWidth:50,
   
},
{
  Header: <><h6>HDL</h6>
            <h6>Cholesterol</h6>
         </>,
  accessor: "hdl_cholesterol",
  Cell: renderChol,
   width: 120,
  minWidth:50,
   
},
{
  Header: <><h6>LDL</h6>
            <h6>Cholesterol</h6>
         </>,
  accessor: "ldl_cholesterol",
  Cell: renderChol,
  width: 120,
  minWidth:50
   
},
{
  Header: <><h6>Serum</h6>
            <h6>Cholesterol</h6>
         </>,
  accessor: "serum_cholesterol",
  Cell: renderChol,
  width: 120,
  minWidth:50,
   
},
{
  Header: "Smart Device Data",
  accessor: "is_wearables",
  Cell: renderFitData,
   width: 150,
  minWidth:50,
   
}]
const Chol_Table = <ReactTable 
         columns={columnsChol}
         data={cholList}
         defaultPageSize = {10}
         className="chol-table"
         resizable
                           
                 
         >

         </ReactTable> 

const inferenceData =

inferenceLoad===''? 
   <>
  {
    hdlChol !==null? 
    <>
    <h5 className='text-success font-weight-bold'>{hdlChol.summary}</h5>
    <h6  className='text-muted font-weight-bold'>Maximum Cholesterol Level : </h6> <p >{hdlChol.max_cholesterol}mg/dL {hdlChol.max_cholesterol_date}</p><br/>
    <h6  className='text-muted font-weight-bold'>Minimum Cholesterol Level : </h6> <p >{hdlChol.min_cholesterol}mg/dL {hdlChol.min_cholesterol_date}</p><br/><br/>
    </>
    : null
  }
    
    {
    ldlChol !==null?
    <>
    <h5 className='text-success font-weight-bold'>{ldlChol.summary}</h5>
    <h6  className='text-muted font-weight-bold'>Maximum Cholesterol Level : </h6> <p >{ldlChol.max_cholesterol}mg/dL {ldlChol.max_cholesterol_date}</p><br/>
    <h6  className='text-muted font-weight-bold'>Minimum Cholesterol Level : </h6> <p >{ldlChol.min_cholesterol}mg/dL {ldlChol.min_cholesterol_date}</p><br/><br/>
    </>
    : null
  }

{
    serumChol !==null? 
    <>
    <h5 className='text-success font-weight-bold'>{serumChol.summary}</h5>
    <h6  className='text-muted font-weight-bold'>Maximum Cholesterol Level : </h6> <p >{serumChol.max_cholesterol}mg/dL {serumChol.max_cholesterol_date}</p><br/>
    <h6  className='text-muted font-weight-bold'>Minimum Cholesterol Level : </h6> <p >{serumChol.min_cholesterol}mg/dL {serumChol.min_cholesterol_date}</p><br/><br/>
    </>
    : null }

{
    totalChol !==null?
    <>
    <h5 className='text-success font-weight-bold'>{totalChol.summary}</h5>
    <h6  className='text-muted font-weight-bold'>Maximum Cholesterol Level : </h6> <p >{totalChol.max_cholesterol}mg/dL {totalChol.max_cholesterol_date}</p><br/>
    <h6  className='text-muted font-weight-bold'>Minimum Cholesterol Level : </h6> <p >{totalChol.min_cholesterol}mg/dL {totalChol.min_cholesterol_date}</p><br/><br/>
    </>
    : null }

</>
   
  

: inferenceLoad==='Error in Fetching Data'? <h6 style={{color: "red"}}>{inferenceLoad}</h6> : <h6 style={{color: "grey"}}>{inferenceLoad}</h6>



  return (
 

    <>
     <div className="food-habits-page">
     <div className="food-habits-header">
    <h2 className="title-of-tasks"> Cholesterol Details </h2>
         {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}



<div className="calender-pickers-from-to">
<div className="span-date">           
<span> From </span>

<DatePicker
                          value={new Date(startDate)}
                          onChange={date=>{handleFromDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<div className="span-date">             
<span> To </span>

<DatePicker
                          value={new Date(endDate)}
                          onChange={date=>{handleToDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<button type="submit" className="btn btn-primary btn-col" onClick={handleGo}> Check </button>
</div>

</div> 
<div className="food-habits-sections chol-section">
   <div>
   <h5 className="title-of-lists"> Graph </h5>
    <div className="chart-div-glucose">
   
        <canvas id="chart-chol" width="800" height="400"></canvas>

      
    </div>
    </div>
    
     <div className="food-table">
     <h5 className=" title-of-lists"> Table</h5>
    {load===''? cholList.length>0 ?
     <>
    

 
     
   {Chol_Table}
     </> 
     : <h6 style={{color: "red"}}> No data to show! </h6> : load==='Error in Fetching Data'? <h6 style={{color: "red"}}>{load}</h6> : <h6 style={{color: "grey"}}>{load}</h6>
   }
     </div>

     <div className="food-table">
     <h5 className=" title-of-lists"> Inference </h5>
     {
       hdlChol===null && ldlChol===null && serumChol===null && totalChol===null ? <h6 style={{color: "red"}}>No data to show!</h6> :
       <div style={{maxHeight:'565px'}} className="inference-container analysis-subsection">
  <div className="inference-container-header box-theme">
   <h6 className="inference-container-title"> Analysis</h6>
  </div>
  <div className="inference-container-body">

  {inferenceData}

  </div>

        
     
     </div>
     }
     

  </div>
   
   
   
     </div>
     </div>
     </>
  );
}

export default VitalsChol;