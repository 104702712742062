import React,{useState,useRef,useEffect} from 'react';
import 'react-table-v6/react-table.css'
import "./hosppatientrecord.css";
import {useHistory} from "react-router-dom";

import axios from "axios";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import "./reacttable.css";

import GlucoseVitals from  "../GlucoseVitals/GlucoseVitals";

import VitalsBP from "../VitalsBP/VitalsBP";
import VitalsPulse from "../VitalsPulse/VitalsPulse";
import VitalsChol from "../VitalsChol/VitalsChol";
import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";




import {APIURL} from "../../Global";

import CurrentMedications_Bdo from "../HospDr_AddPrescription/CurrentMedications_Bdo";


function PatientRecordBdo(props){

  const [summary,setSummary]=useState('');
  const [,setCurrentMedicines]=useState();
 
  const  ref1=useRef();
  const  ref2=useRef();
  const  ref3=useRef();
  const  ref4=useRef();
  //const  ref5=useRef();
  const [id] = useState(props.match.params.id);
  const [name,setName] = useState();
  const [gender,setGender] = useState();
  const [dob,setDob] = useState();
  const [chronic,setChronic]=useState();
  const [phn,setPhn]=useState();
  const [age, setAge]= useState(0)
  
   const [submitMsg,setSubmitMsg] = useState("");
   const [modalShow,setModalShow]=useState(false);
     //const [submitInstructionsMsg,setSubmitInstructionsMsg] = useState("");
   //const [modalInstructionsShow,setModalInstructionsShow]=useState(false);
   const [,setPatientData] = useState();
   /*const [displaySuggestions,setDisplaySuggestions] = useState(false);
   const [updatedSearchQuery,setUpdatedSearchQuery]= useState('');
   const [options,setOptions] = useState([]);
   const [display,setDisplay]=useState(false);
   const [search,setSearch]=useState("");
   const [currentIndex,setCurrentIndex]=useState('');
   const [currentColumn,setCurrentColumn] = useState('');
const [temp,setTemp]=useState('');


  const [render,setRender] =useState(false);*/
   const [reRender,setReRender] =useState(false);

 const [errorSubmit,setErrorSubmit]=useState(false);

 const [glucoseShow,setGlucoseShow]=useState(false);
 const [bpShow,setBpShow]=useState(false);
const [pulseShow,setPulseShow]=useState(false);
 const [cholesterolShow,setCholesterolShow]=useState(false);

let history=useHistory();


const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'s': false,'q': false,'l': false,});


const hospId = props.location.hospId 

useEffect(()=> {
  if(hospId !== undefined){
    sessionStorage.setItem('hospId',hospId)
  }
},[])

useEffect(()=>{

  window.onkeydown =(e) => {
    
    keys[e.key]=true

    if(e.altKey && keys['u']){
      history.push('/directory')
    }
    if(e.altKey && keys['c']){
      history.push('/careplans-db')
    }
    if(e.altKey && keys['n']){
      history.push('/notifications')
    }
    if(e.altKey && keys['a']){
      history.push('/analytics')
    }
    if(e.altKey && keys['s']){
      history.push('/service-requests')
    }
    if(e.altKey && keys['q']){
      history.push('/qrcode')
    }
    if(e.altKey && keys['l']){
      history.push('/call-log')
    }


  }
  window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
  }

  return()=>{
    window.onkeydown=null
    window.onkeyup=null
  }
},[]);






useEffect(()=>{
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');           
    axios.get(`${APIURL}/api/v1/patient/bdo-patient-record-view/?patient_id=${id}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     
      if(res.data.status=== "success"){
       

      setPatientData(res.data.data);
      setName(res.data.data.name);
      setDob(res.data.data.dob);
      setChronic(res.data.data.Primary_medical_condition);
      setCurrentMedicines(res.data.data.current_medicines_list);
      setPhn(res.data.data.mobile_number);
      function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
      }
      
      setAge(getAge(res.data.data.dob));
     setSummary(res.data.data.inference);
  
      

      setGender(res.data.data.gender);
     

      }


    })
    .catch(err=>{

})



},[id, reRender]);


















const handleClick = (ref) =>{
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
         });
      



      }
 
/*const handleSummary=(e)=>{
  e.preventDefault();
  setSummary(e.target.value);

}*/

















const PulsePopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VitalsPulse {...props} />
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}
const GlucosePopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GlucoseVitals {...props} />
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}

const BpPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VitalsBP {...props} />
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}
const CholesterolPopup=(props)=> {
 
 
  return (
    <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VitalsChol {...props} />
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}


const SubmitPopUp=(props)=> {
 
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}




const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> Error in Data Submission. Please try again! </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}







const handleGlucoseOverlay=(e)=>{
 e.preventDefault();
 setGlucoseShow(true);

}
const handlePulseOverlay=(e)=>{
 e.preventDefault();
 setPulseShow(true);

}
const handleCholOverlay=(e)=>{
 e.preventDefault();
 setCholesterolShow(true);

}
const handleBpOverlay=(e)=>{
 e.preventDefault();
 setBpShow(true);

}

const handleBack=(e)=>{
  history.goBack();
}




const summaryArray = summary;
const displaySummary= summaryArray.length>0 ? 
<><h6 className="text-left comorbidity-row comorb-item"> BP :  {summaryArray[0].bp_inference ? summaryArray[0].bp_inference : "Nil"} </h6>
  <h6 className="text-left comorbidity-row comorb-item"> Pulse :  {summaryArray[0].pulse_inference ? summaryArray[0].pulse_inference : "Nil"} </h6>
  <h6 className="text-left comorbidity-row comorb-item"> Sugar  :  {summaryArray[0].sugar_inference ? summaryArray[0].sugar_inference : "Nil"} </h6>
  <div className='infer-chol'>
    <h6> Cholesterol  :  </h6>
    <h6> {summaryArray[0].cholesterol_inference_data.hdl_cholesterol_data  ? summaryArray[0].cholesterol_inference_data.hdl_cholesterol_data.summary : null}</h6>
    <h6> {summaryArray[0].cholesterol_inference_data.ldl_cholesterol_data ? summaryArray[0].cholesterol_inference_data.ldl_cholesterol_data.summary : null}</h6>
    <h6> {summaryArray[0].cholesterol_inference_data.serum_cholesterol_data ?  summaryArray[0].cholesterol_inference_data.serum_cholesterol_data.summary : null}</h6>
    <h6> {summaryArray[0].cholesterol_inference_data.total_cholesterol_data? summaryArray[0].cholesterol_inference_data.total_cholesterol_data.summary : null}</h6>
  </div>
 
   </>
  : "-No data to show-" ;
return(<>
<HospBdoNavbar />
<div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div> 
	<div className="patient-record-holder ">
             <div className="sticky-nav">
      <div className="left-nav">
         <ul className="stickynav-ul"> 
          
            <li onClick={()=>{handleClick(ref1)}}> Basic Info </li>
               <li onClick={()=>{handleClick(ref2)}}> Primary Med. Condition </li> 
           <li onClick={()=>{handleClick(ref3)}}> Vitals </li> 
  
              {/* <li onClick={()=>{handleClick(ref4)}}> Inferences</li>  */}
            <li onClick={()=>{handleClick(ref4)}}> Current Medications </li> 
           
           {/* <li onClick={()=>{handleClick(ref7)}}> Schedule Planner </li> */}

          

           

         </ul>


      </div>

      </div>
    

    <div className="right-section">
    <h4 style={{marginLeft:'15px'}} className="title-of-tasks">Felixa ID: {id} {sessionStorage.getItem('hospId') ? ' , Hospital ID:'+ sessionStorage.getItem('hospId') : null }</h4>
    <h4 className="prime-header" id={gender==='female' ? 'patient-female' : 'patient-male'}>{name} </h4><br/>
    
     {/* <div className="info section-1 each-section zero-contentbox" ref={ref1} > 
        
        
       <h5 className="prime-header"> Patient ID: {id} </h5>
       <h5 className="prime-header"> {name} </h5>

         </div>
*/}
         
         {/******************************************************/}

         <div className="info section-1 each-section first-contentbox " ref={ref1}> 
        
       {/*
            <div className="sub-section-img ">
         <img className="profile-picture" src={imgUrl} width="120px" height="auto"/>                
         </div>

        */}
          <div className="second-tab sub-section ">
           
                    
                 <div className="sub-section-header box-theme">
                               
                    
                      <h6 className="sub-section-title"> Basic Info</h6>
                      </div>
                  <div className="personal-details-sub-section">
                  <h6 > Gender : <span className="user-details">{gender}</span></h6>
                  <h6 > DOB: <span className="user-details"> {dob}</span> </h6>
                  <h6 > Age: <span className="user-details"> {age}</span> </h6>
                   <h6 className="user-details"> Phn:  {phn}  </h6>
                  {/*<h6 > Weight: <span className="user-details"> {weight}</span> </h6>
                  <h6 > Height: <span className="user-details"> {height}</span> </h6>
                  <h6 > Marital Status: <span className="user-details"> {maritalStatus? "Married" : "Not Married"}</span> </h6>
                */}


                 {/* <h6 className="user-details"> Place:  -  </h6>
                    */}
                 {/* <h6><i className="fa fa-phone" aria-hidden="true"></i> : +919876363647 </h6>
                  <h6>Addr: Palarivattom, <br/> Kochi, <br/>676123 </h6>  */}
                 </div>
          </div>

           {/******Primary/Chronic Medical Condition*******/}
                       <div className="sub-section primarymed-subsection" ref={ref2}>
                      <div className="sub-section-header box-theme">
                               
                    
                      <h6 className="sub-section-title"> Primary/Chronic Medical Condition</h6>
                      </div>
                      <div className="sub-section-body comorb-body-content"> 
                  

                       {chronic!==null ? 
<div className="comorb-holder">

  <div className="comorbidity-row comorb-item">
  <h6>{chronic} </h6>      
   </div>          
    </div>

    : "No data" }



                      
                      </div>

                      </div>
                       {/******Chronic/Other Medical Condition******END*/}
          

        


       

</div>

        
         {/*****************Diagnostic section ************/}
         <div className="each-section summary  section-diagnostic" >
                  {/*<h5 className="sub-title">  Diagnostics Info  </h5>*/}
             <div className="second-contentbox" >
          
                
                         
                            <div className="sub-section vitals-subsection" ref={ref3}>
                      <div className="sub-section-header box-theme">
                               

                      <h6 className="sub-section-title"> Vitals</h6>
                      </div>
                      <div className="sub-section-body">
                      <div className="vitals-col">
                      <div className="vitals-row">
                               {/* <div className="each-list-title each-tile box-4 colored-box colored-box-small" id="vitals-tile" onClick={handleGlucoseOverlay}>
                              <span className="history-link"  >
                                   <h6>Blood Sugar/ Glucose </h6>
                              </span>
                              </div> */}

                               <div className="each-list-title each-tile box-4 colored-box colored-box-small" id="vitals-tile" onClick={handlePulseOverlay}>
                               <span className="history-link"  >
                              <h6> Pulse </h6>
                              </span>                      
                              </div>

                      </div> 
                       <div className="vitals-row">
                               <div className="each-list-title each-tile box-4 colored-box colored-box-small" id="vitals-tile" onClick={handleBpOverlay}>
                              <span className="history-link"  >
                              <h6>BP </h6>
                              </span>
                              </div>


                      </div> 





                     </div>
                      </div>

                      </div>


                      
                           
                    
                            
                   
                    
                         
                        {/**** Inferences/ Summary******/}
                             {/* <div className="sub-section inferences-subsection" ref={ref4} >
                      <div className="sub-section-header box-theme">
                               

                      <h6 className="sub-section-title"> Inferences </h6>
                      </div>
                      <div className="sub-section-body">  
                      {displaySummary}
                      </div>

                      </div> */}

        </div>
             



              </div>




      {/**********************************/}
          <div className="each-section summary  section-diagnostic" ref={ref4}>
                  <h5 className="sub-title">  Current Medications  </h5>
             <div className="second-contentbox">
          
                 {/******Prescription*******/}
                 <CurrentMedications_Bdo id={id}/>

                  

                      


            </div>
        </div>
        {/*************/}  


      



 



<br/>













{glucoseShow? 
  <GlucosePopup 
        show={glucoseShow}
        onHide={() =>  setGlucoseShow(false)}
        id ={id}
        
      />

: null }

{bpShow? 
  <BpPopup 
        show={bpShow}
        onHide={() =>  setBpShow(false)}
        id ={id}
        
      />

: null }

{pulseShow? 
  <PulsePopup 
        show={pulseShow}
        onHide={() =>  setPulseShow(false)}
        id ={id}
        
      />

: null }

{cholesterolShow? 
  <CholesterolPopup 
        show={cholesterolShow}
        onHide={() =>  setCholesterolShow(false)}
        id ={id}
        
      />

: null }





{submitMsg==="success" ? 
          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false);
                      setReRender(!reRender);
                      setSubmitMsg('');}}
       
      />
         :  submitMsg==="error"? 
     <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setSubmitMsg('');}}
       
      />

         : ''
          }


 

    </div>

   
    </div>

   </>
)
}
export default PatientRecordBdo;
