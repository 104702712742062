
/*export const APIURL= "https://pc.felixacare.com";

https://api.cianlogic.com/
*/

let backendHost;


const hostname = window && window.location && window.location.hostname;

if(hostname === 'bh.cianlogic.com') {
  backendHost = 'https://bha.cianlogic.com';
}
 else if(hostname === 'bh.felixacare.com') {
  backendHost = 'https://bha.felixacare.com';
}
else if(hostname === 'localhost') {
  backendHost = 'https://bha.cianlogic.com';
}



export const APIURL = `${backendHost}`;


