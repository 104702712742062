import React, {useState , useContext}  from 'react';
import {RegContext} from "../../contexts/RegistrationContext"
import {Link} from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-date-picker';
import '../../css/bootstrap.min.css';
import '../../css/iofrm-style.css';
import '../../css/iofrm-theme4.css';
import "./register.css"
import axios from "axios";
import "./popup.css";
import "./country_region_selector.css";
import "./phoneinput.css";
import "./dob.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

import graphic4 from "../../images/graphic4.svg";
import {APIURL} from "../../Global";
    let form_Data = new FormData();

function PatientRegister(){
 
    const [popup, setPopup] = useState(false);
   const [,setStatus]=useState(false);
   const [userDatas,setUserDatas]= useContext(RegContext);
   const [otpmsg,setotpmsg]= useState(false);
   const [dob, setDob] = useState();
   const [,setFormattedDob] = useState("");
   const [imgURL,setImgURL]=useState("");
   const [previewOn , setPreviewOn]= useState(false);
    const [errorOTPMsg,setErrorOTPMsg]=useState(null);
 const [errorOTPSubmit,setErrorOTPSubmit]=useState(false);

 const [errorSignupMsg,setErrorSignupMsg]=useState(null);
 const [errorSignupSubmit,setErrorSignupSubmit]=useState(false);
 const [successSubmitMsg,setSuccessSubmitMsg]=useState(null);
 const [successSubmit,setSuccessSubmit]=useState(false);
 const [formDataStatus,setFormDataStatus]=useState(false);
 const [invalidPic ,setInvalidPic]= useState(null)
 const [sizeShow ,setSizeShow] =useState(false)
const [validShow ,setValidShow] =useState(false)
const [contentShow ,setContentShow] =useState(false)
const [uploadShow ,setUploadShow] =useState(false)
  

   const [error,setError]=useState({
    username:"",
    email: '',
    phone:"",
     address:""
   });

const [isValidUsername,setIsValidUsername]=useState(true);
const [isValidEmail,setIsValidEmail]=useState(true);
const [isValidPhone,setIsValidPhone]=useState(true);
const [invalid ,setInvalid] =useState(false)
const [otpStatus, setOtpStatus]= useState(false)

const reader =new FileReader();
 const SubmitSuccessPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h4>You have successfully registered !! <br/></h4>
           <h6> Thank you for choosing our service. </h6>
        
       
        <img src={graphic4} width="30%" height="auto" alt="success"/>
       
      </Modal.Body>
      <Modal.Footer>
       <Link to="/info"> <Button className='btn btn-primary btn-col' >Continue</Button>

       </Link>
        

       
       

      </Modal.Footer>
    </Modal>
  );
}

const VerifyOTPErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorOTPMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}
const SignupErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorSignupMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}
const SizePopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h5 id="confirm-reject">You have exceeded image size limit 2MB</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const LoadPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select an Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ValidPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Please select a valid Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}

const ContentPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Invalid Image Content</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}


    const handleChange=e=>{
        
      e.preventDefault();
      const val=e.target.value;
      const key=e.target.name;
      setError(currentstate=>({
            ...currentstate,
            [key]: ""
          }));
         setUserDatas(currentstate=>({
            ...currentstate,
            [key]: val
          }));
      
}

const handleCheckUsername=e=>{
  setIsValidUsername(true);
  setInvalid(false)
  if(e.target.value!==''){
   setError(currentstate=>({
              ...currentstate,
            username: ''}));
  
  e.preventDefault();

    const data = {
            username: e.target.value
        }
       
          axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
     'Content-Type': 'application/json'}})
    .then(res =>{
        
            if(res.data.status === "success"){


              if(res.data.is_existing_user === true){
            
                    setError(currentstate=>({
              ...currentstate,
            username:"Username already exists!"}));


                 setIsValidUsername(false);

                 }
                 else{
                    setIsValidUsername(true);
                 }
                  
                
                    
                    
                }
                else if(res.data.status === 'error' && res.data.message=== 'Unsupported language used for username!'){
                  setError(currentstate=>({
                    ...currentstate,
                  username:"Unsupported language used for username!"}));
                  setInvalid(true)
                }
                
            else{
              setInvalid(false)
              setIsValidUsername(true);
                  
            }
                
            })
    .catch(err =>{
    
  })

}
}

const handleCheckExisting=e=>{
  setIsValidEmail(true);
if(e.target.value!== ""){
       setError(currentstate=>({
              ...currentstate,
            email:  ''}));
  e.preventDefault();
  let key=e.target.name;


    const data = {
            [e.target.name]: e.target.value
        }
     
          axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
     'Content-Type': 'application/json'}})
    .then(res =>{
          
            if(res.data.status === "success"){
              if(res.data.is_existing_user === true){
             
                    setError(currentstate=>({
              ...currentstate,
            [key]: `This ${key} is already registered!`}));
                 setIsValidEmail(false);
                 }
                  else{
                    setIsValidEmail(true);
                  }
                   
                    
                }
            else{
        
              setIsValidEmail(true);
                  
            }
                
            })
    .catch(err =>{
   
    
  })
}

}

   const handleContinue= async (e)=>{

    e.preventDefault();
   
    if(validate() && isValidUsername && isValidEmail && isValidPhone){
    togglepopup();
      handleSendOTP();
    }


   

}
   /*Handle SENDOTP */
   const handleSendOTP=()=> {
    
    if(otpStatus===false){
        //e.preventDefault();
        const data = {
            code : userDatas.phone.dialcode,
            mobile_number: userDatas.phone.rawphone,
            email:userDatas.email
          
        }

        axios.post(`${APIURL}/api/v1/account/send-otp/`, {
          

           mobile_number: data.mobile_number,
          code: data.code,
          email:data.email
      

    }, { headers: {
     'Content-Type': 'application/json'}})
    .then(res =>{
           
            if(res.data.status === "success"){
                  
                    
                     setStatus(true);
                    
                    
                }
            else{
             
                  setStatus(false);
         
            }
                
            })
    .catch(err =>{
   
    
      })
   }
    
}

   /*Handle RESENDOTP */
   const handleReSendOTP=(e)=> {
        e.preventDefault();
        const data = {
             code : userDatas.phone.dialcode,
            mobile_number: userDatas.phone.rawphone,
            
          
        }
       

        axios.post(`${APIURL}/api/v1/account/resend-otp/`, {
          

           mobile_number: data.mobile_number,
          code: data.code,
         
      

    },{ headers: {
     'Content-Type': 'application/json'}})
    .then(res =>{
            
            if(res.data.status === "success"){
                    
                    setotpmsg(true);
                     
                }
                
            })
     .catch(err =>{
  
  })
    }
   /*Handle VERIFYOTP */
  /* async function handleVerifyOTP() {
    if(userDatas.otp!=="" && userDatas.otp!==null){
    let status = null;
       // e.preventDefault();
        const data = {
             code : userDatas.phone.dialcode,
            mobile_number: userDatas.phone.rawphone,
            otp: userDatas.otp
          
        }
       


        axios.post(`${APIURL}/api/v1/account/verify-otp/`, {
          

           mobile_number: data.mobile_number,
          code: data.code,
          otp: data.otp
      

    }, { headers: {
     'Content-Type': 'application/json'
      }})
    .then(res =>{
            
            if(res.data.status === "success"){
                   status = true;
                       localStorage.setItem("usertoken", JSON.stringify(res.data.token));
                    
                    
       
              handleSignup();
                    
                }
                else{
                  
                  status= false;
                  setErrorOTPMsg(res.data.message);
              setErrorOTPSubmit(true);
                  //history.push('/error'); 
                }
                
            })
     .catch(err =>{
   setErrorOTPMsg("Error in OTP verification");
              setErrorOTPSubmit(true);
    
   })

    }
    else{
      setErrorOTPMsg("Please enter OTP");
              setErrorOTPSubmit(true);
    }
    }*/

const handleDOB=(date)=>{

  setDob(date);

let dateUpdated= date;
  if(date!==null){
const offset = date.getTimezoneOffset();

dateUpdated = new Date(date.getTime() - (offset*60*1000))

let DateExtracted = dateUpdated.toISOString().split('T')[0];

/*let dateString= DateExtracted.toString(); */
setFormattedDob(DateExtracted);

 setUserDatas(currentstate=>({
            ...currentstate,
            dob: DateExtracted
          }));
}
}


    /*Handle REGISTER */
 const handleRegister=(e)=>{
          e.preventDefault();
           togglepopup();
       
          handleSignup();
         
        
    }

    const  handleSignup=()=>{


  if(formDataStatus){
     

                callSignUpWithFormData();
  }
  else{

     callSignUpWithoutFormData();

  }
}
             /*Handle Signup */



  const callSignUpWithFormData=()=>{

    if(userDatas.otp!=="" && userDatas.otp!==null){
      let status = null;


        
const user = {
                      firstname : userDatas.firstname,
                       lastname : userDatas.lastname,
                      username: userDatas.username,
                      email:userDatas.email,
                      password :userDatas.password,
                      phone : userDatas.phone.rawphone,
                    usertype: userDatas.usertype,
                    gender : userDatas.gender,
                    countrycode:userDatas.phone.dialcode,
                    dob:userDatas.dob,
                
                    hospital :userDatas.hospital,
                    qualification: userDatas.qualification,
                    country:userDatas.country,
                    region: userDatas.region,
                     address:userDatas.address,
                    zipcode: userDatas.zipcode,
                    code : userDatas.phone.dialcode,
                    mobile_number: userDatas.phone.rawphone,
                    otp: userDatas.otp,
                  
                  }


        form_Data.append("first_name", user.firstname);
         form_Data.append("last_name", user.lastname);

         form_Data.append("is_doctor", false);
          form_Data.append("gender", user.gender);
           form_Data.append("dob", user.dob);
             form_Data.append("email", user.email);
              form_Data.append("qualification", user.qualification);
               form_Data.append("hospital", user.hospital);
                form_Data.append("country", user.country);
                 form_Data.append("region",user.region);
                 form_Data.append("password", user.password);
               form_Data.append("username", user.username);
                form_Data.append("zipcode",user.zipcode);
                form_Data.append("address", user.address);
                form_Data.append("mobile_number", user.mobile_number);
                form_Data.append("code", user.code);
                form_Data.append("otp", user.otp);
                
                
  axios.post(`${APIURL}/api/v1/account/verify-otp/v2/`, form_Data, { headers: {
      "Content-Type": "multipart/form-data"
      }}).then(res =>{
            
            if(res.data.status === "success"){
                
              status = true;
              localStorage.setItem("usertoken", JSON.stringify(res.data.token));
                          setSuccessSubmitMsg("success");
          setSuccessSubmit(true);
          setOtpStatus(false)

                }
            else {
              status= false;
             setErrorSignupMsg(res.data.message);
              setErrorSignupSubmit(true);
              if(res.data.message==='Invalid OTP'){
                setOtpStatus(true)
              }
              else {
                setOtpStatus(false)
              }

              form_Data.delete('first_name')
              form_Data.delete('last_name')
              form_Data.delete('is_doctor')
              form_Data.delete('gender')
              form_Data.delete('dob')
              form_Data.delete('email')
              form_Data.delete('qualification')
              form_Data.delete('hospital')
              form_Data.delete('country')
              form_Data.delete('region')
              form_Data.delete('password')
              form_Data.delete('username')
              form_Data.delete('zipcode')
              form_Data.delete('address')
              form_Data.delete('mobile_number')
              form_Data.delete('code')
              form_Data.delete('otp')

            }
                
            })
     .catch(err =>{
      setErrorSignupMsg("Error in registration");
              setErrorSignupSubmit(true);
    
  })
            
}    
else{
  setErrorOTPMsg("Please enter OTP");
          setErrorOTPSubmit(true);
}

}

  const  callSignUpWithoutFormData=()=>{
       
    if(userDatas.otp!=="" && userDatas.otp!==null){
      let status = null;

     
                  const user = {
                      firstname : userDatas.firstname,
                       lastname : userDatas.lastname,
                      username: userDatas.username,
                      email:userDatas.email,
                      password :userDatas.password,
                      phone : userDatas.phone.rawphone,
                    usertype: userDatas.usertype,
                    gender : userDatas.gender,
                    countrycode:userDatas.phone.dialcode,
                    dob:userDatas.dob,
                    hospital :userDatas.hospital,
                    qualification: userDatas.qualification,
                    country:userDatas.country,
                    region: userDatas.region,
                     address:userDatas.address,
                    zipcode: userDatas.zipcode,
                    code : userDatas.phone.dialcode,
                    mobile_number: userDatas.phone.rawphone,
                    otp: userDatas.otp
                  
                  }

      
  
       axios.post(`${APIURL}/api/v1/account/verify-otp/v2/`, {
          
          first_name : user.firstname,
          last_name: user.lastname,
          is_doctor: false,
          gender: user.gender,
          dob: user.dob,
          email: user.email,
          qualification: user.qualification,
          hospital: user.hospital,
          country: user.country,
          region: user.region,
          password: user.password,
          username: user.username,
          zipcode:user.zipcode,
          address: user.address,
          mobile_number: user.mobile_number,
          code: user.code,
          otp: user.otp

         
    }, { headers: {
     'Content-Type': 'application/json'
      }})
    .then(res =>{
            
            if(res.data.status === "success"){
                
              status = true;
              localStorage.setItem("usertoken", JSON.stringify(res.data.token)); 
                          setSuccessSubmitMsg("success");
          setSuccessSubmit(true);
          setOtpStatus(false)

                }
            else {
              status= false;
             setErrorSignupMsg(res.data.message);
              setErrorSignupSubmit(true);
              if(res.data.message==='Invalid OTP'){
                setOtpStatus(true)
              }
              else {
                setOtpStatus(false)
              }
            }
                
            })
     .catch(err =>{
      setErrorSignupMsg("Error in registration");
              setErrorSignupSubmit(true);
    
  })

    }  

    else{
      setErrorOTPMsg("Please enter OTP");
              setErrorOTPSubmit(true);
    }
 
}
   

   const  handleGenderRadioButton=(e)=>{
    let genderOpted = e.target.value;
    setUserDatas(currentstate=>({
      ...currentstate,
      gender: genderOpted

    }))
   }

   const removePicPreview=(e)=>{
    e.preventDefault();
    setPreviewOn(false);

   }
/*
  const handleRadioChangeUserType = (e)=>{
      let currentusertype = e.target.value;
    setUserDatas(currentstate => ({
        ...currentstate,
          usertype: currentusertype
         }));
     
   
    if(userDatas.usertype === "doctor"){
      setUserDatas(currentstate=>({
        ...currentstate,
           isDoctor: true
         }));
     
     
    }
    else{
      setUserDatas(currentstate=>({
        ...currentstate,
           isDoctor: true
         }));
     
    
     
    }
  }
    */
  const togglepopup = () =>{
     setPopup(!popup)
  }
  const selectCountry =(val)=> {
     setUserDatas(currentstate=>({
        ...currentstate,
           country: val
         }));
     
    
  }

  const selectRegion= (val)=> {
    setUserDatas(currentstate=>({
        ...currentstate,
           region: val
         }));
  }

const uploadImage=async e=>{
  const file=  e.target.files[0];
 if(!file){
    setUploadShow(!uploadShow)
    //setInvalidPic('Please Select an Image')
    return false
  }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
    setValidShow(!validShow)
    //setInvalidPic('Please select valid image')
    return false;
  }
  if(file.size>2e6){
    setSizeShow(!sizeShow)
    return false
  }

  reader.onload = (e) => {
    const img = new Image();
    img.onload = () => {
      //const formData = new FormData();
      //formData.append('photo',file);
      //setProfilePic(formData);
      form_Data.append('photo',file);
      setInvalidPic(null)
    };

    img.onerror = () => {
      setPreviewOn(false)
      setContentShow(!contentShow)
      //setInvalidPic('Invalid Image Content')    
      return false;
    };
    img.src = e.target.result;
  };
  reader.readAsDataURL(file);

  const objectURL = URL.createObjectURL(file);
  
  setImgURL(objectURL);
  setPreviewOn(true);
 


  setFormDataStatus(true);


  
}




 const handlePhoneInput= (value, data, event, formattedValue)=>{
  
  
  const rawPhone = value.slice(data.dialCode.length) ;
  
  const dialcode = value.slice(0,data.dialCode.length) ;
   setUserDatas(currentstate=>({
        ...currentstate,
           phone: {
            dialcode: dialcode,
              rawphone: rawPhone,
              mobile_number: value
            }

                       }));
  
  

 }
  
  const handleCheckExistingPhone= (e)=>{
 setIsValidPhone(true);
if(e.target.value !==""){
  
  const rawPhone =  userDatas.phone.rawphone ;
  
  const dialcode =  userDatas.phone.dialcode;
   setError(currentstate=>({
              ...currentstate,
            phone: ''}));

  

    const data = {
            code: dialcode,
            mobile_number: rawPhone
        }
       
          axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
     'Content-Type': 'application/json'}})
    .then(res =>{
         
            if(res.data.status === "success"){
              if(res.data.is_existing_user === true){
               
                  setError(currentstate=>({
              ...currentstate,
            phone: "Phone Number already registered!"}));
                    setIsValidPhone(false);
                 }
                  else{
                    setIsValidPhone(true);

                  }
                   
                    
                }
            else{
             
              setIsValidPhone(true);
                  
            }
                
            })
    .catch(err =>{

    
  })

}
  

 }

 const handleError= ()=> {
  setErrorSignupSubmit(false)
  if(errorSignupMsg==='Invalid OTP'){
    setPopup(true)
  }
 }

/************validate******/

  
  const validate=()=>{
      let input = userDatas;
      let errors = {};
      let isValid = true;
    setError({});


      if (!input["firstname"] || input["firstname"]==="" ) {
        isValid = false;
        errors["firstname"] = "Please enter first name";
      }

      if (!input["lastname"] || input["lastname"]==="") {
        isValid = false;
        errors["lastname"] = "Please enter last name";
      }
      
      if (!input["username"] || input["username"]==="") {
        isValid = false;
        errors["username"] = "Please enter username";
      }
        if (!input["address"] || input["address"]==="") {
        isValid = false;
        errors["addresss"] = "Please enter address";
      }
      if (!input["address"]) {
        isValid = false;

        errors["address"] = "Please enter your Address.";
      }

      if (!input["country"] || input["country"]==="" ) {
        isValid = false;
        errors["country"] = "Please select a country";
      }
    
      if (!input["region"] || input["region"]==="") {
        isValid = false;
        errors["region"] = "Please select a region";
      }

       if (!input["zipcode"] || input["zipcode"]==="") {
        isValid = false;
        errors["zipcode"] = "Please enter Zipcode";
      }
  
      if (input["username"] && typeof input["username"] !== "undefined") {
        const re = /^\S*$/;
        if(input["username"].length < 3 || !re.test(input["username"].trim())){
            isValid = false;
            errors["username"] = "Please enter a valid username";
        }
      }
      if(isValidUsername===false){
        isValid = false;
        errors["username"] = "Username already exists!";
      }
      if(invalid=== true){
        isValid = false;
        errors["username"] = "Unsupported language used for username!";
      }
      if(isValidPhone===false){
        isValid = false;
        errors["phone"] = "Phone number already registered!";
      }
      if(isValidEmail===false){
        isValid = false;
        errors["email"] = "Email already registered!";
      }
  
      if (!input["email"]) {
        isValid = false;

        errors["email"] = "Please enter email Address.";
      }
  

      if (typeof input["email"] !== "undefined") {
          
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Please enter a valid email address.";
        }
      }

      if (typeof input["username"] !== "undefined") {
          
        var pattern1 = new RegExp(/[ ]/);
        if (pattern1.test(input["username"])) {
          isValid = false;
          errors["username"] = "Please enter a valid username";
        }
      }

      if (typeof input["firstname"] !== "undefined") {
          
        var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
        if (pattern2.test(input["firstname"])) {
          isValid = false;
          errors["firstname"] = "Special Characters are not allowed";
        }
      }

      if (typeof input["lastname"] !== "undefined") {
          
        var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
        if (pattern3.test(input["lastname"])) {
          isValid = false;
          errors["lastname"] = "Special Characters are not allowed";
        }
      }


      if (!userDatas.phone.dialcode || userDatas.phone.dialcode==="" || !userDatas.phone.rawphone || userDatas.phone.rawphone==="" ) {
        isValid = false;
        errors["phone"] = "Please enter phone number";
      }
      
      if(typeof dob=="undefined" || dob==="" || dob=== null){
        isValid = false;
        errors["dob"] = "Please enter your date of birth";
      }
       if(typeof userDatas.gender=="undefined" ||  userDatas.gender==="" ||  userDatas.gender=== null){
        isValid = false;
        errors["gender"] = "Please select gender";
      }
      if (typeof input["password"] !== "undefined") {
          
        let pattern = new RegExp(/[A-Z]/g);
        if (!pattern.test(input["password"])) {
          isValid = false;
          errors["password"] = "Must contain atleast one Capital letter";
        }
        
      }
      if (!input["password"] || input["password"]==="" || input["password"]===null) {
        isValid = false;
        errors["password"] = "Please enter your password";
      }
  
      if (!input["confirmpassword"] || input["confirmpassword"]==="" || input["confirmpassword"]===null) {
        isValid = false;
        errors["confirm_password"] = "Please enter confirm password";
      }
  
      if (input["password"] && typeof input["password"] !== "undefined") {
        if(input["password"].length >0 && input["password"].length < 6){
            isValid = false;
            errors["password"] = "Must contain atleast 6 characters";
        }
      }
  
      if (typeof input["password"] !== "undefined" && input["password"]!=="" && input["password"]!==null && typeof input["confirmpassword"] !== "undefined" && input["confirmpassword"]!=="" && input["confirmpassword"]!==null) {
          
        if (input["password"] !== input["confirmpassword"]) {
          isValid = false;
          errors["confirm_password"] = "Passwords don't match";
        }
      }
  



       setError(currentstate=>({
            ...currentstate,
            ...errors
          }));
      

      return isValid;
  }
    
   
    
              return (
                <div className="form-body">
                   
                    <div className="row">
                        <div className="img-holder">
                           <div className="bg bg-2"> 
            <h4 className="grey-title tagline">The Intelligent Platform for Patient Engagement</h4></div>
                            <div className="info-holder holder-sticky"> 
                                 <div className="captions-holder">
                                 <div className="s-wrap">

                                  <div className="s-move">
                                   <div className="slide">
                                      <h2 className="captions">
                                         Digitize Health Documents. Get Continuum Care. </h2>
                                         
                                       
                                                                           
                                       
                                        <h2 className="captions">Register as Patient and install <span className="felixacare-logo-color">FelixaCare app</span>.
                                     </h2> 
                                     
                                     <a href='https://play.google.com/store/apps/details?id=intp.purpc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="google-play-text"/></a>
                                  </div>

                                  <div className="slide">
                                      <h2 className="captions">
                                         Take better care of your parents, spouse and relatives. </h2>
                                      <h2 className="captions">
                                         Volunteer to care and assist someone . </h2>
                                         
                                       
                                                                           
                                       
                                        <h2 className="captions">Register as Caregiver and install <span className="felixacare-logo-color">FelixaCare app</span>.
                                     </h2> 
                                     
                                     <a href='https://play.google.com/store/apps/details?id=intp.purpc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="google-play-text"/></a>
                                  </div>
                                </div>

                              </div>
                              </div>
                            </div>
                        </div>
                        <div className="form-holder form-container">
                            <div className="form-content">
                                <div className="form-items">
                                   {/*  <h3>Get access to the innovative AI platform for people's wellbeing.</h3> */}
                                    <p></p>
                                    <div className="page-links">
                                        <Link to="/login" >Login</Link><Link to="register" className="active">Register</Link>
                                       
                                    </div>

                                    

                                         <form className="reg-form" onSubmit={handleContinue}>
                                     <h3 className="form-heading">Patient/Caregiver Registration </h3>  <br/>
                                     <div className="reg-form-div">
                                   <div className="first-row">   

                                    
                                    <div className="custom-file">
                                       {previewOn=== false ?  
                                                   <div className="pic-container">

                                                    <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={uploadImage}/>
                                                    {invalidPic && <p className="error">{invalidPic}</p>}
                                                    <label className="" htmlFor="validatedCustomFile"><p>Upload Profile Picture</p><div className="camera-icon">
                                                    <i className="fas fa-2x fa-camera cam-icon"></i>
                                                    </div>
                                                    </label>
                                                    {/*
                                                    <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Photo</label> */}
                                                    </div>
                                     : 
                                     <div>
                                       <div onClick={removePicPreview}><i className="fas fa-times-circle close-img-btn"></i></div>
                                       <img src={imgURL} className="avatar" alt='Profile Photo'/>
                                       </div> }
                                       
                                     </div>
                                     

                                  {/*
                                     <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={preview_image}/>
                                                    <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Photo</label>
                                     
                                       <img id="output_image" className="avatar"/>
                                     </div>  */}
                                   </div>

                                       
                                      
                                        
                                        <div className="item item-left-row"> 
                                        <label className="">* First Name: </label>
                                        <input className="form-control" type="text" name="firstname" placeholder="" onChange={handleChange} maxLength='30'
                                        />  

                                         { error.firstname ? <div className="error-validation-msg">{error.firstname}</div> : null}
                                         </div>
                                          <div className="item item-left-row"> 
                                        <label className="">* Last Name: </label> <input className="form-control" type="text" name="lastname" placeholder="" onChange={handleChange} maxLength='30'
                                        />  

                                          {error.lastname ? <div className="error-validation-msg">{error.lastname}</div> : null}
                                            </div>

                                            <div className="item item-left-row"> 
                                        <label className="">* Username: </label><input className="form-control" type="text" name="username" placeholder="" onChange={handleChange} onBlur={handleCheckUsername} maxLength='30'
                                        /> 
                                          {error.username ? <div className="error-validation-msg"> {error.username}</div> : null}
                                          </div>

                                        <div className="item item-left-row"> 
                                        <label className="">* Email Address: </label><input className="form-control" type="email" name="email" placeholder="" onChange={handleChange} onChange={handleChange} onBlur={handleCheckExisting} maxLength='50' />
                                        
                                        {error.email ? <div className="error-validation-msg"> {error.email}</div> : null}
                                        </div>
                                        
                                        {/*  <input className="form-control" type="text" name="dob" placeholder="Date of Birth* : YYYY-MM-DD" required />
                                          */}

                                     
                                         
                                        <div className="form-control dob item item-left-row">
                                       
                                       
                 <span>* Date of Birth:</span> 
                    <DatePicker
                          onChange={date=>{handleDOB(date)}}

                          maxDate = {new Date()}
                          value={dob}
                          
                     /> 
                       {error.dob?  <div className="error-validation-msg"> {error.dob}</div> : null}
         
         </div>    

          <div className="form-control gender-div">
                                     
                                        <span>*Gender:</span> 
                                          <input 
                                             type="radio" 
                                             id="male" 
                                             name="gender" 
                                             value="male"
                                             checked={userDatas.gender === "male"}
                                             onClick={handleGenderRadioButton}
                                            
                                                 />
                                          <label htmlFor="male">Male</label>

                                          <input  
                                            type="radio"
                                            id="female"
                                             name="gender" 
                                             value="female"
                                             checked={userDatas.gender === "female"}
                                            onClick={handleGenderRadioButton} />

                                          <label htmlFor="female">Female</label>

                                          <input 
                                              type="radio"
                                              id="other" 
                                              name="gender"
                                              value="other"
                                              checked={userDatas.gender === "other"}
                                            onClick={handleGenderRadioButton} 
                                                 />
                                          <label htmlFor="other">Other</label>

                                         {error.gender ?  <div className="error-validation-msg"> {error.gender}</div> : null}
                                        </div>


                    
                                        <div className="country-selector item-left-row">
                                         <label className="">* Country: </label>
                                            <CountryDropdown
                                              value={userDatas.country}
                                              onChange={(val) => selectCountry(val)} />
                                              {error.country ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.country}</div> : null}
                                        </div>
                                        <div className="region-selector item-left-row">
                                         <label className="">* Region:  </label>
                                            <RegionDropdown
                                              country={userDatas.country}
                                              value={userDatas.region}
                                              onChange={(val) => selectRegion(val)} />
                                          {error.region ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.region}</div> : null}
                                            <small className="form-text text-muted">*Select Region - Enabled once you select Country</small>
                                           </div>

                                           <div className="item item-left-row"><label className="">* Address: </label><input className="form-control" type="text" name="address" placeholder="" onChange={handleChange} maxLength='150' 
                                      />  
                                        {error.address ? <div className="error-validation-msg"> {error.address}</div>: null}
                                         </div>

                                          <div className="item item-left-row"><label className="">* Zipcode: </label><input className="form-control" type="text" name="zipcode" placeholder="" onChange={handleChange} maxLength='50'
                                        /> 
                                        {error.zipcode ? <div className="error-validation-msg"> {error.zipcode}</div> :null}
                                           </div>
                                         
                                         <div className="form-control phone-input item item-left-row">
                                           <label className="">* Phone: </label>
                                        <PhoneInput
                                            inputProps={{
                                                  
                                                  
                                                  
                                                }}
                                            country={'in'}
                                            value={userDatas.phone.mobile_number}
                                            onChange={handlePhoneInput}
                                            onBlur={handleCheckExistingPhone}

                                          />
                                          {error.phone ? <div className="error-validation-msg"> {error.phone}</div>  : null}

                                          </div>
                                       
                                    {/*<div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="validatedCustomFile"/>
                                                    <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Photo</label>
                                     </div> */}
                                     
                                          

                                     {/*  <UserType updateUserType={updateUserType} updateQualification={updateQualification} updateHospital={updateHospital}/>  */}
  
                                     

                                 
                                     <div className="divider"></div>
                                           <div className="item item-left-row"><label className="">* Password: </label><input className="form-control pswd" type="password" name="password" placeholder="" onChange={handleChange} maxLength='30' /> 
                                         {error.password ? <div className="error-validation-msg"> {error.password}</div> : null}
                                         </div>
                                          <div className="item item-left-row"><label className="">* Confirm Password: </label><input className="form-control pswd" type="password" name="confirmpassword" placeholder="" onChange={handleChange} maxLength='30' />
                                         {error.confirm_password ? <div className="error-validation-msg"> {error.confirm_password}</div>  :null}
                                           </div>    
                                      
                                        
                                        <div className="form-button">
                                            <button id="submit" type="submit" className="btn btn-primary btn-col" >Register</button>
                                        </div>
                      {popup ?  
                  <div className='popup'>  
                  <div className='popup-inner'>  
                  <div className="container">
                  <div className="popup-form">  
                                         <div className="form-button close-btn-div">                         
                  <button className="ibtn close-btn" onClick={togglepopup}>X</button>
                  </div>
                       <h3 className="msg-heading"> Thank you! Your Registration is almost done ! </h3>
                        <p className="msg">We have send you an OTP to the mobile number and email address you have entered.<br/>
                           </p>
                          

                            <div className="click-resendotp-div">
                            <span className="click-resendotp" onClick={handleReSendOTP}>Click here</span> to Resend OTP
                               <br/>
                            {otpmsg=== true ? <span style={{color:"green"}}> Successfully resend OTP </span> : ""}
                            </div>
                            <input className="form-control otp-input" type="text" maxLength='30' name="otp" placeholder="Enter OTP here" value={userDatas.otp} onChange={handleChange}
                                        required/>
                           
                            <div className="form-button">
                                            <button id="submit" type="submit" className="ibtn" onClick={handleRegister}>Submit</button>
                            </div>
                                  

                            </div>
                            </div>
                            </div>  
                            </div>   

                            : null  
                            }  
                                      </div>
                                      <h6 className="text-muted">You would recieve an OTP in mobile number and email filled above</h6>
                                      </form>

                              {/*}
                                    <div className="other-links">
                                        <span>Or register with</span><a href="#">Facebook</a><a href="#">Google</a><a href="#">Linkedin</a>
                                    </div>

                                  */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {errorOTPMsg!==null ?
    <VerifyOTPErrorPopup
        show={errorOTPSubmit}
        onHide={() =>  setErrorOTPSubmit(false)}
       
      />: null}

    {errorSignupMsg!==null ?
    <SignupErrorPopup
        show={errorSignupSubmit}
        onHide={handleError}
       
      />: null}

      {successSubmitMsg === "success" ?
    <SubmitSuccessPopup
        show={successSubmit}
        onHide={() =>  setSuccessSubmit(false)}
       
      />: null}


{
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 

{
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 

{
        validShow ? 
        <ValidPopUp
        show={validShow}
        onHide={()=>{
          setValidShow(false)
        }} /> : null
      } 

{
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      }

                </div>
              )
       
    
}



export default PatientRegister;