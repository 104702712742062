import React from "react";
import { NavLink } from "react-router-dom";


import "./hospitalnavbar.css";




function HospitalNavbar() {
  return (
    <nav className="hosp-navbar">
      {/*<img
        src="http://intpurple.com/wp-content/uploads/2020/04/logo_small.png"
        className="logo"
        alt="logo"
      /> */}

       
         

      
    
      
      <div className="btns">
    
        

          <button className="btn nav-btn">
          <NavLink exact className="inactive" activeClassName="active" to="/hospital">  <i className="fas fa-home"></i> HOME</NavLink>
        </button>
       <button className="btn nav-btn">
          <NavLink className="inactive" activeClassName="active" to="/doctors-list">DOCTORS LIST </NavLink>
        </button>
        <button className="btn nav-btn">
          <NavLink className="inactive" activeClassName="active" to="/bdo-list"> BDO LIST </NavLink>
        </button>
        <button className="btn nav-btn">
          <NavLink className="inactive" activeClassName="active" to="/sda-list"> SDA LIST </NavLink>
        </button>


       {/* <button className="btn login-btn">
          <Link to="/login">Login </Link>
        </button>
        <button className="btn">
          <Link to="/register"> Register </Link>
        </button> */}
      </div>
    </nav>
  );
}

export default HospitalNavbar;
