import React,{useState,useEffect,useContext} from 'react';
import axios from "axios";
import "./observations.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {ObservationsContext} from "../../contexts/ObservationsContext";

import {APIURL} from "../../Global";

function AddObservations(props){
  const [patientId,] =useState(props.patientId);


 const [reRender,setReRender]=useContext(ObservationsContext);
  const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);

           const [submitMsg,setSubmitMsg] = useState("");

           const [obsValue,setObsValue]=useState(null);
           const [symValue,setSymValue]=useState(null);
          const [refreshObs,setRefreshObs]=useState(false);
           const [refreshSym,setRefreshSym]=useState(false);
           const [obsArray,setObsArray]=useState([]);
           const [symArray,setSymArray]=useState([]);
              const [modalShow,setModalShow]=useState(false);
           let obsDisplay=null;
           let symDisplay=null;
useEffect(()=>{

  obsDisplay=obsArray.length>0 ? obsArray.map((obs,index)=>{
  return (
     <div key={index} className="">

         <h6> {obs}</h6>
     </div>
  )
}) : null
 
},[refreshObs])

useEffect(()=>{

  symDisplay= symArray.length>0 ? symArray.map((sym,index)=>{
  return (
     <div key={index} className="each-obs">

         <h6> {sym}</h6>
     </div>
  )
}) : null
 
},[refreshSym])
  

const SubmitPopUp=({show,onHide})=> {
 

  return (
    <Modal
      show
      onHide
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Data submitted sucessfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button onClick={onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}


const handleChange=(e)=>{
  
	e.preventDefault();
  if(e.target.name==="obs"){
  setObsValue(e.target.value);
  }
  if(e.target.name==="sym"){
  setSymValue(e.target.value);
  }

}
const handleAddObservation=(e)=>{
	e.preventDefault();
 
  if(obsValue!=="" && obsValue!== null){
    const newArray = obsArray;
    newArray.push(obsValue);
    setObsArray(newArray);
 
    setObsValue("");
    setRefreshObs(!refreshObs);
  }

  
}


const handleSubmit=(e)=>{

  if(obsArray.length!==0 || symArray.length!==0 || (obsValue!==null && obsValue!=="")|| (symValue!==null && symValue!=="")){
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
   let newObsValue=[];
   let newSymValue=[];
  if(obsArray.length!==0 && obsValue!==null && obsValue!==""){
    newObsValue=obsArray;
    newObsValue.push(obsValue);


  } 
  if(obsArray.length===0 && obsValue!==null && obsValue!==""){
    newObsValue.push(obsValue);
  


  } 

   if(obsArray.length!==0 && (obsValue===null || obsValue==="")){
    newObsValue=obsArray;
   


  }   
  
  if(symArray.length!==0 && symValue!==null && symValue!==""){
    newSymValue= symArray;
    newSymValue.push(symValue);
  }   
   if(symArray.length!==0 && (symValue ===null || symValue ==="")){
    newSymValue= symArray;
   
  }   
   if(symArray.length===0 && symValue !==null && symValue !==""){
    newSymValue.push(symValue);
   
  }   
  
  
  const dataToSend= {
    observed_at :new Date(),
    observations: newObsValue,
    symptoms:newSymValue

  }
 

    axios.post(`${APIURL}/api/v1/patient/observations-symptoms/?patient_id=${patientId}`, dataToSend, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
        
        setSubmitMsg("success");
     setModalShow(true);
     
      setObsArray([]);
      setSymArray([]);
      setSymValue("");
      setObsValue("");
       setReRender(!reRender);
      
      }
else{
 
   setErrorMsg(res.data.message);
              setErrorSubmit(true);
                setReRender(!reRender);
}

    })
    .catch(err=>{

  setErrorMsg("error");
  
              setErrorSubmit(true);
                setReRender(!reRender);
})
 }

}

const handleAddSymptom=(e)=>{
  e.preventDefault();

  if(symValue!=="" && symValue!== null){
    const newArray = symArray;
    newArray.push(symValue);
    setSymArray(newArray);

    setSymValue('');
    setRefreshSym(!refreshSym);
  }
}
const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg!=='' ? "Error:"+ ''+ errorMsg : "Error in Data Submission. Please try again!" } </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}







obsDisplay=obsArray.length>0 ? obsArray.map((obs,index)=>{
  return (
     <div key={index} className="each-obs">

         <h6 > {obs}</h6>
     </div>
  )
}) : null;

 symDisplay= symArray.length>0 ? symArray.map((sym,index)=>{
  return (
     <div key={index} className="each-obs">

         <h6> {sym}</h6>
     </div>
  )
}) : null






        
return (<>

<>
<div className="divider"></div>
 <h5 className="title-of-tasks header">  Add Observations & Symptoms  </h5>
 


<div className="add-obs-sym-section">
   
     <div className="add-observations-and-symptoms">
        
         <div className="observations-add-section">
           <div className="box-theme sub-section-header"><h6 className="obs-header"> Observations </h6></div><br/>
           <div className="obs-display">
           {obsDisplay}
           </div>
            <div className="comorbidity-add-row">
                              <input type="text" maxLength="150" name="obs" placeholder="Add Observation" value={obsValue} onChange={handleChange} className="form-control"/>  
                              <button type="submit" className="btn btn-smallest btn-square btn-primary btn-col btn-add-comorbidity" onClick={handleAddObservation}> + Add </button> 
                     </div>
                     <br/>

        </div>


        <div className="symptoms-add-section">
               <div className="sub-section-header box-theme "><h6 className="obs-header"> Symptoms </h6></div><br/>
           <div className="obs-display">
           {symDisplay}
           </div>

            
            <div className="comorbidity-add-row">
                              <input type="text" maxLength="150" name="sym" placeholder="Add Symptom" value={symValue} onChange={handleChange} className="form-control"/>  
                              <button type="submit" className="btn btn-smallest btn-square btn-primary btn-col btn-add-comorbidity" onClick={handleAddSymptom}> + Add </button> 
                     </div>
                     <br/>

        </div>





    </div>
      <br/>
      <button type="submit" className="btn btn-primary btn-col btn-submit-obs-sym" onClick={handleSubmit}> Submit </button> 
      <br/>
</div>







             <br/>

 {submitMsg==="success" ? 
          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false);
                        setSubmitMsg('');
                      }}

       
      /> : null }
         
      {errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }

 </>

             </>

)
}
export default AddObservations;