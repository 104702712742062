import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import printJS from 'print-js'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import moment from 'moment'

function PaymentInvoice(props) {

    const [invoiceData ,]= useState(props.invoiceData);
    const [method, setMethod]= useState('')
    //const [amount, setAmount]= useState('')
    const [errorShow,setErrorShow]=useState(false);
    const [errorMsg,setErrorMsg]=useState("");
    // const [type, setType]= useState("full")
    // const [discount, setDiscount]= useState(0)
    // const [show, setShow]= useState(false)
    const [bdo_Data,]=useState(JSON.parse(sessionStorage.getItem('bdodata'))? JSON.parse(sessionStorage.getItem('bdodata')) : null);

   /* useEffect(()=> {

        setAmount(getPayable())


    },[type,apiData,discount])*/


     const handleMethod =(e) => {
        setMethod(e.target.value)
    }

    // const handleAmount = (e)=> {
    //     const val = e.target.value
    //     if(val.length < 6 ){
    //         setAmount(e.target.value)
    //     }
    //     else return false
    // }

    // useEffect(()=> {
    //   setProcedure(procedure)
    // },[refresh])

    const handleSave = async()=> {

        const tokenString= localStorage.getItem('usertoken');
      
        let str= tokenString.replace(/["]+/g, '');

        const data = invoiceData.invoice_details.procedure_invoice_details !== null ?
                           invoiceData.invoice_details.procedure_invoice_details.all_procedure_details : null

        const idArray = data ? data.map(item => {
            return item.patient_procedure_id
        })
        : []

        const config = props.allData

        let checkProcedure = config.length > 0 ? config.some(item => {
          return item.name === "Procedure"
        })
        : false
   
        let checkRegistration = config.length > 0 ? config.some(item => {
         return item.name === "Registration"
       })
       : false
       
       let checkConsultation = config.length > 0 ? config.some(item => {
         return item.name === "Consultation"
       })
       : false

        const consult = props.consultData
        const dataToUpdate = {
            payment_method : method,
            amount_paid: invoiceData.total_amount_to_pay,
            invoice_no: invoiceData.invoice_no,
            procedure_id_list: idArray,
            doctor_id: consult ? consult.doctorId : null ,
            consultation_date: consult ? consult.date.split('T').join(" ").slice(0,16) : null
            // discount: discount
        }
       
         axios.post(`${APIURL}/api/v1/hospital/hospital-all-payment/?patient_id=${props.id}${checkProcedure?"&procedure_payment=true":""}${checkConsultation?"&consultation=true":""}${checkRegistration?"&registration=true":""}`, dataToUpdate ,
         { headers: {
           'Content-Type': 'application/json',
           "Authorization" : 'Token '+str  }})
            .then(res=>{
       
           if(res.data.status==="success"){

          /*  async function callGet(callBack){

              if(type !== "full"){
                await callBack()
              }
              setShow(true)
              if(type === "full"){

                const procedureData = procedure
                const data = procedureData.map(item => {
                  return {
                    procedure_name: item.procedure_name,
                    procedure_type: item.procedure_type,
                    currency: item.currency,
                    amount_without_vat: item.amount_without_vat,
                    vat: item.vat,
                    status: item.status
                  }
                    
                  
                })
                setProcedure(data)
                setRefresh(!refresh)

              }

              handlePrint()
            }

            callGet(handleProcedureInvoice) */

              
            handlePrint()

            
       
           }else{
            setErrorMsg(res.data.message)
            setErrorShow(true)
           }
       
       
         })
         .catch(err=>{
            setErrorMsg('Error in Data Submission.Please try again')
            setErrorShow(true)
       })

    }


     const handlePrint = () => {
    
        printJS({
           printable: 'print-invoice',
           type: 'html',
           targetStyles: ['*'],
        })
      
      }


      const SubmitErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      } 

      const getRegistration = () => {
          const data = invoiceData.invoice_details.registration_invoice_details
        if(invoiceData.invoice_details.procedure_invoice_details === null){
          return(
            
            <tr>
                  <td>Registration</td>
                  <td>{data.registration_gross_amount}</td>
                  <td>{data.registration_vat_amount}</td>
            </tr>
            )
        }
        else{
          return(
            
            <tr>
                  <td>Registration</td>
                  <td>-</td>
                  <td>-</td>
                  <td>{data.registration_gross_amount}</td>
                  <td>{data.registration_vat_amount}</td>
            </tr>
            )
        }
          
      }

      const getConsultation = () => {
        const data = invoiceData.invoice_details.consultation_invoice_details
        if(invoiceData.invoice_details.procedure_invoice_details === null){
          return(
            <tr>
                  <td>Consultation</td>
                  <td>{data.consultation_gross_amount}</td>
                  <td>{data.consultation_vat_amount}</td>
            </tr>
            )
        }
        else {
          return(
            <tr>
                  <td>Consultation</td>
                  <td>-</td>
                  <td>-</td>
                  <td>{data.consultation_gross_amount}</td>
                  <td>{data.consultation_vat_amount}</td>
            </tr>
            )
        }

    }

      const procedureData = invoiceData.invoice_details.procedure_invoice_details !== null ?

                                                         invoiceData.invoice_details.procedure_invoice_details.all_procedure_details.map(item => {

          return (

                  <tr>
                      <td>Procedure</td>
                      <td>{item.procedure_name}</td>
                      <td>{item.procedure_type}</td>
                      <td>{item.amount_without_vat}</td>
                      <td>{item.vat}</td>
                  </tr>

          )
      }) : null

      // const handlePayment = (e)=> {
      //   setType(e.target.value)
      // }

      // const handleDiscount = (e)=> {
      //   setDiscount(e.target.value)
      // }

      // const getPayable = () => {

      //   const data = apiData.balance_payment - discount

      //   return data
      // }

   return (<div>
      
      <h2 style={{textAlign:'center'}} className="title-of-tasks"> Invoice  </h2><br/>

      <div className='flex-row invoice-radio'>
           <span className='text-muted font-weight-bold'>Select payment method :</span>
           <input type='radio' className='hideme' checked={method==='card'} id='card' value='card' onChange={handleMethod} />
        <label htmlFor='card' className='invoice-label'>
            Card
        </label>

        <input type='radio' className='hideme' checked={method==='cash'} id='cash' value='cash' onChange={handleMethod} />
        <label htmlFor='cash' className='invoice-label'>
            Cash 
        </label>

        <input type='radio' className='hideme' checked={method==='benefit_pay'} id='benefit_pay' value='benefit_pay' onChange={handleMethod} />
        <label htmlFor='benefit_pay' className='invoice-label'>
            Benefit Pay 
        </label>
       </div>
       <br/>
       <div id='print-invoice' className="invoice-table flex-col to-print invoice-print-size ">
        <div className="invoice-row1 invoice-header">

              <div>
                <h5 className='font-weight-bold text-left'>Invoice {invoiceData.invoice_no}</h5>
                <h6 className='text-left'>Patient: {props.name}</h6>
                <h6 className='text-left'>Mobile: {invoiceData.mobile_number}</h6>
                <h6 className="text-left">CPR : {invoiceData.cpr}</h6>
              </div>

             <div>
              <h3 className="invoice-heading caps text-center">{bdo_Data.hospital_name}</h3>
              <h6 className="invoice-heading caps text-center">{bdo_Data.hospital_state},{bdo_Data.hospital_location},{bdo_Data.hospital_street}</h6>
             </div>
           {/* <h6 className="invoice-heading caps">Al Janabiyah North</h6> */}

        <div className='invoice-detail mr-2'>

            <h5>{moment().format('DD-MM-YYYY')}</h5>
            <h6 className="invoice-heading invoice-heading-normal">{bdo_Data.hospital_mobile}</h6>
            <h6 className="invoice-heading invoice-heading-normal">{bdo_Data.hospital_email}</h6>

        </div>
       </div>
       {/* <div className="invoice-row2">
  
       <div>
          <h5 className="grey-title caps">{invoiceData.patient_name}</h5>
    </div>
       </div> */}
       <div className="invoice-row3">
          <table >
          
            {
              invoiceData.invoice_details.procedure_invoice_details !== null ?
            <tr>
              <th> Description </th>
  
              <th>Procedure Name</th>
              <th>Type</th>
          
              <th> Amount </th>
              <th>VAT</th>
            </tr>
            :

            <tr>
            <th> Description </th>
            <th> Amount </th>
            <th>VAT</th>
          </tr>
            }

  
          

          { invoiceData.invoice_details.registration_invoice_details !== null ? getRegistration() : false }
          { invoiceData.invoice_details.consultation_invoice_details !== null ? getConsultation() : false }
          { procedureData }
  
          {/* <tr>
            <td>
                
               <span className="caps">Patient</span><br/>
               <span className="caps">Registration </span>
               
  
             </td>
             <td>{apiData.amount_paid}</td>
             
            <td>{apiData.currency}</td>
            <td>{apiData.vat}</td>
  
          </tr> */}
  
          </table>
       </div>

       <div className="invoice-row4">
       <h6 className="text-right">Gross Total : <span className="caps-full">BD</span>{invoiceData.total_gross_amount}</h6>
        <h6 className="text-right">VAT Amount : <span className="caps-full">BD</span>{(invoiceData.total_amount_to_pay - invoiceData.total_gross_amount).toFixed(3)}</h6>
        <h6 className="text-right">Net Total : <span className="caps-full">BD</span>{invoiceData.total_amount_to_pay}</h6>
        {/* <h6 className="text-right">Consultation Payment Status : {apiData.consultation_payment_status}</h6> */}
         <br/>
         <h6 className="text-right">Thanks,</h6>
         <h6 className="text-right">{bdo_Data.name}</h6>
          

     </div>

       </div>
       <br/>

       {/**<div className="to-print">
        <a href="https://www.felixacare.com" target="_blank"><h5 className="felixa-site" > www.felixacare.com</h5></a>
  
      </div>**/}
       
       <div className="do-not-print text-center" id="pr">
                   <button className="btn btn-primary btn-col btn-square" disabled={method === ''} onClick={handleSave}> Save and Print </button>
                   <br/>
                   <br/>
                   <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                    <br/>
                    <br/>
             </div> 
  

             {errorMsg!=='' ? 
    <SubmitErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        setErrorMsg('');}}
       
      />: ''
    }

    </div>
       )
}

export default PaymentInvoice
