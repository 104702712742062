import React,{useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import BdoCard from "../BdoCard/BdoCard";

import "./bdolist.css";
import axios from 'axios';
import HospitalNavbar  from "../HospitalNavbar/HospitalNavbar";
import {APIURL} from "../../Global";

function BdoList(){
  const [bdoList,setBdoList] = useState([]); 
  const [freeCount, setFreeCount]= useState();
  const [activeCount, setActiveCount]= useState()
  const [errorMsg, setErrorMsg]= useState("");
  const [refresh, setRefresh]= useState(false)
	
  
 useEffect(() => {
    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g,""); 

  
    axios
      .get(`${APIURL}/api/v1/hospital/bdo/`, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " +v2},
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.bdos;
        
          setFreeCount(res.data.free_user_count)
          setActiveCount(res.data.active_free_users)
          setBdoList(datas);
        } else {
          setErrorMsg("error");
        }
      })
      .catch((err) => {
        setErrorMsg("error");
      });
  }, [refresh]); 

  const refreshHandle= ()=> {
    setRefresh(!refresh)
  }

	return(<div className="drlist-page">
    <HospitalNavbar />
		

		<h2 className="title-of-page"> BDO List </h2>
    <div className="quick-links">
      <Link to="/hospital/add-bdo"><button type="button" className="btn btn-primary btn-col dr-btn"> + Add BDO to List </button></Link>
      <Link to={{pathname:'license-details', flag:"bdo-page" }}><button type="button" style={{marginLeft:"10px"}} className="btn btn-info dr-btn"> View Licence Details</button></Link>

</div>

		{bdoList.length!== 0  ? (<div>
		<div className="drs-container">
		{bdoList.length>0 ? bdoList.map((data,index)=>{
			return <BdoCard key={index} data={data} freeCount={freeCount} activeCount={activeCount} handle={refreshHandle}/>
		}): null}
         </div>
       
        <div className="quick-links">
		  <Link to="/hospital/add-bdo"><button type="button" className="btn btn-primary btn-col dr-btn"> + Add BDO to List </button></Link>

       </div>

       </div>)
       :
       <h5 style={{color: "red" ,margin: "20px"}}>BDO List is empty </h5>
   }

    {errorMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data fetching.Please try again!
          </h5>
      :
      null}
		</div>
		);
}

export default BdoList;