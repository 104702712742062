import React, {useState, useEffect} from 'react'
import { Link,useHistory} from "react-router-dom";
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import Select from 'react-select';
import HospDocNavbar from '../HospDocNavbar/HospDocNavbar'

function AddPrescriptionForm(props) {

    const[name,setName]=useState('')
    const[time,setTime]= useState('')
    const[strength,setStrength]=useState('')
    const[dosage,setDosage]=useState('')
    const [type, setType]= useState()
    const [route, setRoute]= useState()
    const [frequency, setFrequency]= useState()
    const [duration, setDuration]= useState()
    const [count, setCount]= useState()
    const [intakeTime, setIntakeTime]= useState()
    const[remark,setRemark]=useState('')
    /*const[checked1, setChecked1]= useState(false)
    const[checked2, setChecked2]= useState(false)
    const[checked3, setChecked3]= useState(false)
    const[checked4, setChecked4]= useState(false)
    const[intake,setIntake]= useState([0,0,0,0])*/
    const [errorMsg,setErrorMsg]=useState('');
	const [submitMsg,setSubmitMsg]=useState("");
	const [modalShow,setModalShow] =useState(false);
    const [options,setOptions] = useState([]);
    const [display, setDisplay]= useState(false)
    const [period, setPeriod]= useState('')

const [errorSubmitModalShow,setErrorSubmitModalShow]=useState(false);

const [error,setError]=useState({
    name:"",
    frequency:"",
    duration:"",
    count:"",
    period:""
    
   });


const id = props.location.id;

useEffect(()=> {
    if(id !== undefined){
        sessionStorage.setItem('id',id)
    }
},[])

useEffect(() => { window.scrollTo(0, 0) }, [])

/*useEffect(()=> {
    document.addEventListener('mousedown', ()=> {
        if(display){
            setDisplay(!display)
        } 
    })

})*/

  const callSuggestions = (val)=> {

    const tokenString= localStorage.getItem('usertoken');
     
    let str= tokenString.replace(/["]+/g, '')  
           
     
     
     axios.get(`${APIURL}/api/v1/doctor/medicine-search/?per_page=50&q=${val}`, { headers: {
       
       "Authorization" : 'Token '+str  }})
     .then(res=>{
      const medicines=[]
       if(res.data.status==="success"){
       
         const medicine=res.data.patients
         medicine.map((person, index) => (medicines.push(person["medicine_name"])))
       
       setOptions(medicines);
       
       if(medicines.length>0 && val !== ''){
           
         setDisplay(true)
      }
      else{
          setDisplay(false)
      }
 
       }
 
 
     })
     .catch(err=>{
 
    })
  }

    const formOptions = [

        {value: 'Tablets' , label: 'Tablets'},
         { value: 'Capsules' , label: 'Capsules'},
          {value: 'Chewable tablets' , label: 'Chewable tablets'},
          {value: 'Powders',label:'Powders'},
           {value: 'Solutions',label:'Solutions'},
            {value: 'Emulsions',label:'Emulsions'},
             {value: 'Suspensions',label:'Suspensions'},
              {value: 'Lotions',label:'Lotions'},
           {value: 'Creams',label:'Creams'},
            {value: 'Ointments',label:'Ointments'},
             {value: 'Effervescent granules',label:'Effervescent granules'},
             {value:'Aerosols', label: 'Aerosols'},
              {value:'Gases', label: 'Gases'},
               {value:'Suppositories', label: 'Suppositories'},
                 {value:'Injections', label: 'Injections'},
  
      ];

      const durationOptions=[

        {value: 1 , label: 'Days'},
        {value: 7 , label: 'Weeks'},
        {value: 30 , label: 'Months'},
        
];
const routeOptions= [
      {value: 'Oral',label:'Oral'},
      {value: 'Topical-Skin',label:'Topical-skin'},
      {value: 'Topical-Eyes',label:'Topical-Eyes'},
      {value: 'Topical-Ears',label:'Topical-Ears'},
      {value: 'Inhalation',label:'Inhalation'},
      {value: 'Injections',label:'Injections'},
      {value: 'Vaginal',label:'Vaginal'},
      {value: 'Anal',label:'Anal'},
      {value: 'Sub-lingual', label: 'Sub-lingual'}

]

const intakeTimeOptions = [
     {value: 0 , label: 'N/A'},
     {value: 1 , label: 'Before food'},
     {value: 2 , label: 'After food'}
    
]
const intakeCountOptions = [
     {value: [0,0,0,1] , label: '0-0-0-1'},
     {value: [0,0,1,0] , label: '0-0-1-0'},
      {value: [0,0,1,1] , label: '0-0-1-1'},
       {value: [0,1,0,0] , label: '0-1-0-0'},
        {value: [0,1,0,1] , label: '0-1-0-1'},
         {value: [0,1,1,0] , label: '0-1-1-0'},
          {value: [0,1,1,1] , label: '0-1-1-1'},
           {value: [1,0,0,0] , label: '1-0-0-0'},
          {value: [1,0,0,1] , label: '1-0-0-1'},
     {value: [1,0,1,0] , label: '1-0-1-0'},
      {value: [1,0,1,1] , label: '1-0-1-1'},
       {value: [1,1,0,0] , label: '1-1-0-0'},
        {value: [1,1,0,1] , label: '1-1-0-1'},
         {value: [1,1,1,0] , label: '1-1-1-0'},
          {value: [1,1,1,1] , label: '1-1-1-1'}
          
   ];

   const frequencyOptions = [
    {value: 1 , label: 'Daily'},
     { value: 2 , label: 'Weekly'},
      {value: 3 , label: 'Monthly'},
       {value: 4 , label: 'Alternate days'},
        {value: 5 , label: 'Weekly twice'},
         {value: 6 , label: 'Weekly Thrice'}
  ];

    let history=useHistory();

    const handleBack = ()=> {
        history.goBack();
    }

    const handleName= (e)=> {
        setName(e.target.value)
        setError(currentstate=>({
            ...currentstate,
            name:''
          }));
        callSuggestions(e.target.value)
    }

    const handleStrength= (e)=> {
        setStrength(e.target.value)
    }

    const handleDosage= (e)=> {
        setDosage(e.target.value)
    }

    const handleType= (data)=> {
        setType(data)
    }

    const handleRoute= (data)=> {
        setRoute(data)
    }

    const handleIntakeTime= (data)=> {
        setIntakeTime(data)
    }

    const handleFrequency= (data)=> {
        setFrequency(data)
        setError(currentstate=>({
            ...currentstate,
            frequency:''
          }));
    }

    const handleDuration= (data)=> {
        setDuration(data)
        setError(currentstate=>({
            ...currentstate,
            duration:''
          }));
    }

    const handleCount= (data)=> {
        setCount(data)
        setError(currentstate=>({
            ...currentstate,
            count:''
          }));
    }

    const handleRemark= (e)=> {
        setRemark(e.target.value)
    }

    const handleTime= (e)=> {
        const val = e.target.value
        setTime(val)
    }

    const handleNumber = e => {
        e.preventDefault();

        const val = e.target.value

        if(val.length <= 4){
            setPeriod(e.target.value)    
        }
        else return false

    }

   /* const handleIntake1= ()=> {
        let arr = intake
        setChecked1(!checked1)
        if(checked1 === false){
            arr[0]= 1
        }
        else{
            arr[0]= 0
        }
        setIntake(arr)
    }

    const handleIntake2= ()=> {
        let arr = intake
        setChecked2(!checked2)
        console.log(checked2)
        if(checked2 === false){
            arr[1]= 1
        }
        else{
            arr[1]= 0
        }
        setIntake(arr)
    }

    const handleIntake3= ()=> {
        let arr = intake
        setChecked3(!checked3)
        if(checked3 === false){
            arr[2]= 1
        }
        else{
            arr[2]= 0
        }
        setIntake(arr)
    }

    const handleIntake4= ()=> {
        let arr = intake
        setChecked4(!checked4)
        if(checked4 === false){
            arr[3]= 1
        }
        else{
            arr[3]= 0
        }
        setIntake(arr)
    }*/


    const SubmitPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Data submitted sucessfully!!</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> 
             
             
            </Modal.Footer>
          </Modal>
        );
      }

      const ErrorSubmitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> Error in Data Submission</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }


    const callSubmit=()=>{
        console.log(duration.value * period)
        const tokenString= localStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '');
           
           const medListToSend=  [{
                       drug: name,
                       strength: strength,
                       form: type!==undefined? type.value: '',
                       route: route!==undefined? route.value: '',
                       frequency: frequency!==undefined? frequency.value: '',
                       duration: duration!==undefined? duration.value * period: '',               
                       intake_count: count!==undefined? count.value: '',
                       intake_time: intakeTime!==undefined?intakeTime.value: '',
                       remarks: remark,
                       dosage: dosage,
              }]
            
      
        const dataToSend = {
          patient_id: sessionStorage.getItem('id'),
          medicines: medListToSend
        }
         
          axios.post(`${APIURL}/api/v1/doctor/patient-prescriptions/`, dataToSend,
          { headers: {
            'Content-Type': 'application/json',
            "Authorization" : 'Token '+str  }})
             .then(res=>{
          
      
         
      
            if(res.data.status==="success"){
            
            setSubmitMsg("success");
            setModalShow(true);
          
      
            }else{
              setErrorMsg(res.data.message);
              setErrorSubmitModalShow(true);
            }
      
      
          })
          .catch(err=>{
        
        setErrorMsg("error");
        setErrorSubmitModalShow(true);
      })
       
      
      }

      const callValidate= async()=> {

        let isValid=true;
        let errors = {};
        //let arr= intake
        setError({});

        if(name===''){
            errors['name']='*Medicine Name is Mandatory'
            isValid=false;
        }

        if(frequency === undefined ||frequency.value===''){
            errors['frequency']='*Frequency is Mandatory'
            isValid=false;
        }

        if(duration === undefined || duration.value===''){
            errors['duration']='*Duration is Mandatory'
            isValid=false;
        }

        if(count === undefined || count.value===''){
            errors['count']='*Count is Mandatory'
            isValid=false;
        }
        if(period === ""){
            errors['period']='*Duration number is Mandatory'
            isValid=false;
        }

        if(period < 1){
            errors['period']='*Please enter valid duration'
            isValid=false;
        }
            
        // if(arr.includes(1)===false){
        //     errors['count']='*Count is Mandatory'
        //     isValid=false;
        // }

        setError(currentstate=>({
            ...currentstate,
            ...errors
          }));

    return isValid
      }

      const handleSubmit= async (e)=>{
        e.preventDefault();
        
       if(await callValidate()){
      
        callSubmit();
      
      
       }
      }

    return (
        <div>
            <HospDocNavbar />
            <br/>
            <div className="text-left">
                <span className="btn btn-info" onClick={handleBack} >  <i className="fas fa-angle-double-left"></i> Back</span>
            </div>
            <h2 className="title-of-tasks"> Add Prescription </h2>
            <div className='add-presc-container'>

                <div className='add-med-padding'>
                <form>
                    <div className='flex-med'>
                    <label>Medicine Name </label>
                    <input value={name} className='presc-input' onChange={handleName} type='text' placeholder='Enter Medicine Name'/>
                    {
                        display ?
                        <div className='suggest-display'>
                            {
                                options.map((item,index)=> {
                                    return (
                                        <p onClick={()=> { setName(item); setDisplay(false)}} className='suggest-item text-secondary'>{item}</p>
                                    )
                                })
                            }
                        </div>

                        : null
                    }
                    { error.name ? <div className="presc-validation-msg">{error.name}</div> : null}
                    </div>
                    
                    <div className='flex-med'>
                    <label>Strength </label>
                    <input className='presc-input' onChange={handleStrength} type='text' placeholder='Eg: 200mg'/>
                    </div>

                    <div className='flex-med'>
                    <label>Dosage </label>
                    <input className='presc-input' onChange={handleDosage} type='text' placeholder='Enter Dosage'/>
                    </div>
                    
                    <div className='flex-add-med'>
                        <div className='flex-med'>
                        <label>Medicine Type </label>
                    <Select 
                value={type}
                onChange={(data)=>{handleType(data)
                    }}
                options={formOptions}
                className="select-add-presc"
                    />
                        </div>
                    
                        <div className='flex-med'>
                        
                        <label>Route </label>
                        <Select 
                    value={route}
                    onChange={(data)=>{handleRoute(data)
                        }}
                    options={routeOptions}
                    className="select-add-presc"
                        />
                        </div>

                    </div>

                    

                    <div className='flex-add-med'>
                    <div className='flex-med'>

                    <label>Frequency </label>
                    <Select 
                value={frequency}
                onChange={(data)=>{handleFrequency(data)
                    }}
                options={frequencyOptions}
                className="select-add-presc"
                    />
                    { error.frequency ? <div className="presc-validation-msg">{error.frequency}</div> : null}
                    </div>

                    <div style={{flexBasis:'400px'}} className='flex-med'>

                    <label>Duration </label>
                    <div className='d-flex'>
                        <input type='number' min='0' style={{width:'150px',marginRight:'10px'}} className='form-control'  placeholder='Number' value={period} onChange={handleNumber}  />
                            <Select 
                        value={duration}
                        onChange={(data)=>{handleDuration(data)
                         }}
                        options={durationOptions}
                        className="dur-select"
                            /> 
                    </div>
                    { error.period ? <div className="presc-validation-msg">{error.period}</div> :  error.duration ? <div className="presc-validation-msg">{error.duration}</div> : null}
                    </div>

                    </div>

                    <div className='flex-add-med'>
                    <div className='flex-med'>

                    <label>Time </label>
                    <Select 
                value={intakeTime}
                onChange={(data)=>{handleIntakeTime(data)
                    }}
                options={intakeTimeOptions}
                className="select-add-presc"
                    />
                    </div>

                    <div className='flex-med'>

                    <label>Count </label>
                    <Select 
                value={count}
                onChange={(data)=>{handleCount(data)
                    }}
                options={intakeCountOptions}
                className="select-add-presc"
                    />
                    { error.count ? <div className="presc-validation-msg">{error.count}</div> : null}
                    </div>

                    </div>

                   {/* <div className='flex-add-med'>
                    <div className='flex-med'>
                        <span>Time</span>
                        <div className='flex-add-med'>
                        <input className='food-time' id='radio1' type='radio' name='before' value='1' onChange={handleTime}/>
                        <label className='before-food-time' for='radio1'>Before Food</label>

                        <input className='food-time' id='radio2' type='radio' name='before' value='2' onChange={handleTime}/>
                        <label className='before-food-time' for='radio2'>After Food</label>
                        </div>
                        
                    </div>

                    </div>         

                    <div className='flex-med'>
                    <label>Count </label>
                    <div className='flex-add-med'>
                        <input className='food-time' id='box1' checked={checked1} type='checkbox'  onChange={handleIntake1}/>
                        <label className='before-food-time' for='box1'>Morning</label>

                        <input className='food-time' id='box2' checked={checked2} type='checkbox' onChange={handleIntake2}/>
                        <label className='before-food-time' for='box2'>Afternoon</label>

                        <input className='food-time' id='box3'  checked={checked3} type='checkbox' onChange={handleIntake3}/>
                        <label className='before-food-time' for='box3'>Evening</label>

                        <input className='food-time' id='box4'  checked={checked4} type='checkbox' onChange={handleIntake4}/>
                        <label className='before-food-time' for='box4'>Night</label>

                    </div>
                        
                </div>*/}
                        
                            

                    <div className='flex-med'>
                        <span>Remark</span>
                        <textarea onChange={handleRemark} value={remark} style={{width:'100%'}} cols="100" rows="4" className="text-area " placeholder="Add Remarks" />
                    </div>

                </form>
                </div>
                <button style={{marginBottom:'20px'}} onClick={handleSubmit} className='btn btn-primary btn-col'>Submit</button>

            </div>


            {submitMsg==="success" ? 

          <SubmitPopUp
        show={modalShow}
        onHide={() => {
            setSubmitMsg('');
            setModalShow(false);
            history.goBack();
        }
            
        }
        /> : null
    }

{errorMsg !== '' ?
    <ErrorSubmitPopup
        show={errorSubmitModalShow}
        onHide={() =>  setErrorSubmitModalShow(false)}
        
      />   : null } 
            

        </div>
    )
}

export default AddPrescriptionForm
