import React, { useEffect,useState,useRef} from "react";
import Chart from "chart.js";
import "./vitalsbp.css";
import axios from "axios";
import DatePicker from 'react-date-picker';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

import {APIURL} from "../../Global";
let myChart;
function VitalsBP(props) {
  const [startDate, setStartDate]=useState(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]);
  const [endDate,setEndDate] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset()*60*1000)).toISOString().split('T')[0]);
  const [patientId,] =useState(props.id);
 
  const [inference, setInference]= useState();
  const [inferenceLoad, setInferenceLoad]= useState('')
    const [systolicArray,setSystolicArray]=useState([]);
    const [diastolicArray,setDiastolicArray]=useState([]);
   const [bpDatesArray,setBpDatesArray]=useState([]);
   const [bpList,setBpList]=useState([]);
  const [updateRender,setUpdateRender]=useState(false);
  const [unit,setUnit]=useState();
  const [load ,setLoad]= useState('')

 

  useEffect(() => {
    const ctx = document.getElementById("chart-bp").getContext('2d');
   

if (myChart) {
        myChart.destroy();
      }
  myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: bpDatesArray,
        datasets: [
          {
            label: "Systolic BP",
            data: systolicArray,
            backgroundColor: 'rgba(54, 162, 235, 0.9)',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'rgba(54, 162, 235, 0.9)',

           
          },
           {
            label: "Diastolic BP",
            data: diastolicArray,
            backgroundColor: 'rgba(255, 99, 132, 0.9)',
              
              
              pointBorderWidth:2,
              pointHoverRadius: 5,
            fill: false,
            borderColor: 'rgba(255, 99, 132, 0.9)',

              

           
          }
        ],
        tooltips: {
          backgroundColor: 'purple',
             
              

        }
      },

      options:{
        scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString:  `Blood Pressure Values  ${unit ? "(" +unit+ ")" : null} `
          },

        ticks: {
          beginAtZero: true,
          stepSize: 10,
          padding: 20,
        },
        }],
        xAxes:[{
          scaleLabel: {
          display: true,
            labelString: 'Date'
          }
        }
        ]
      },
          legend:{
          position:'right',
          labels:{
            fontColor: '#000'
          }
        }
      }
    });
  },[systolicArray,diastolicArray,bpDatesArray,unit]);


  
useEffect(()=>{
const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
   setLoad('Loading Data...')
  
    axios.get(`${APIURL}/api/v1/doctor/patient-blood-pressure/?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&sort_by=asc`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){
        setLoad('')
        let arr= res.data.results;
        let systolic=[];
        let diastolic=[];
        arr.map(result=>{
          systolic.push(result.systolic);
          diastolic.push(result.diastolic);
        })
     setSystolicArray(systolic);
     setDiastolicArray(diastolic);
     
        let newArrayDates=[];
        arr.map(result=>{
          newArrayDates.push(result.date.slice(0,10))
        })
     setBpDatesArray(newArrayDates);

     setBpList(res.data.results);
      let unit1=null;
     if(res.data.results.length>0){
      unit1=res.data.results[0].unit
     }
     else{
      unit1=null;
     }
   
     setUnit(unit1);


      }
      else {
        setLoad('Error in Fetching Data')
      }

    })
    .catch(err=>{
      setLoad('Error in Fetching Data')
})


},[updateRender]);


useEffect(()=> {
  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
          
   setInferenceLoad('Loading Data...')
  
    axios.get(`${APIURL}/api/v1/patient/inferences/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

        setInferenceLoad('')
        setInference(res.data.data)
        
      }
      else {
        setInferenceLoad('Error in Fetching Data')
      }

    })
    .catch(err=>{
      setInferenceLoad('Error in Fetching Data')
})
},[updateRender])

 const handleGo=(e)=>{
      e.preventDefault();
      setUpdateRender(!updateRender);
}


const handleFromDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));



let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setStartDate(DateExtracted);
  }
}

const handleToDate = (date)=>{
  
  if(date!==null){
const offset = date.getTimezoneOffset();

let dateUpdated = new Date(date.getTime() - (offset*60*1000));

let DateExtracted = dateUpdated.toISOString().split('T')[0];



  setEndDate(DateExtracted);
  }
}


const handleStartDate=(e)=>{
  setStartDate(e.target.value);
}
const handleEndDate=(e)=>{
  setEndDate(e.target.value);
}
const renderBP=(cellInfo)=> {
  const date4= bpList
  const date5 = [...date4].reverse()
  return(<h6> {date5[cellInfo.index][cellInfo.column.id]} {date5[cellInfo.index].unit} </h6>)
}
const renderDate=(cellInfo)=> {
  const date4= bpList
  const date5 = [...date4].reverse()
  return(<h6> {date5[cellInfo.index].date.slice(0,10)} </h6>)
}
const renderFitData= (cellInfo)=> {
  const data= bpList
  const fitData = [...data].reverse()

  return(<h6> { fitData[cellInfo.index][cellInfo.column.id] ? 'Yes': 'No' } </h6>)
}


const columnsBp= [
{
  Header: "Date",
  accessor: "date",
  Cell: renderDate,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  width: 130,

},
{
  Header: "Systolic",
  accessor: "systolic",
  Cell: renderBP,
  width: 120,
  minWidth:50,
   
},
{
  Header: "Diastolic",
  accessor: "diastolic",
  Cell: renderBP,
  width: 120,
  minWidth:50,
   
},
{
  Header: "Smart Device Data",
  accessor: "is_wearables",
  Cell: renderFitData,
   width: 140,
  minWidth:50,
   
}]
const BP_Table = <ReactTable 
         columns={columnsBp}
         data={bpList}
         defaultPageSize = {10}
         
         resizable
                           
                 
         >

         </ReactTable> 

//let summary =inference.summary;
let data =[]

if(inference!==undefined){
  let summary =inference.summary;
  data= summary.split('\n')
}
 

const inferenceData =

    inferenceLoad===''?
      inference!==undefined? <div style={{maxHeight:'410px'}} className="inference-container analysis-subsection">
      <div className="inference-container-header box-theme">
       <h6 className="inference-container-title"> Analysis</h6>
      </div>
      <div className="inference-container-body">
        {
          data.length>1?
          <>
          <h5 className='text-success font-weight-bold'>{data[0]}</h5>
        <h6  className='text-muted font-weight-bold'>Maximum BP Level : </h6> <p >{inference.max_sys_bp}mm/Hg {inference.max_sys_date}</p><br/>
        <h6  className='text-muted font-weight-bold'>Minimum BP Level : </h6> <p >{inference.min_sys_bp}mm/Hg {inference.min_sys_date}</p><br/>
        <h6  className='text-muted font-weight-bold'>BP Deviation : </h6> <p >{inference.sys_bp_sd}</p><br/><br/>
        <h5 className='text-success font-weight-bold'>{data[1]}</h5>
        <h6  className='text-muted font-weight-bold'>Maximum BP Level : </h6> <p >{inference.max_dia_bp}mm/Hg {inference.max_dia_date}</p><br/>
        <h6  className='text-muted font-weight-bold'>Minimum BP Level : </h6> <p >{inference.min_dia_bp}mm/Hg {inference.min_dia_date}</p><br/>
        <h6  className='text-muted font-weight-bold'>BP Deviation : </h6> <p >{inference.dia_bp_sd}</p><br/> </>
        : null

        }
        
        </div>
      </div>
      
    
: <h6 style={{color: "red"}}> No data to show! </h6> : inferenceLoad==='Error in Fetching Data'? <h6 style={{color: "red"}}>{inferenceLoad}</h6> : <h6 style={{color: "grey"}}>{inferenceLoad}</h6>


  return (
 

    <>
     <div className="food-habits-page">
     <div className="food-habits-header">
    <h2 className="title-of-tasks"> Blood Pressure Details </h2>
         {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}



<div className="calender-pickers-from-to">
<div className="span-date">           
<span> From </span>

<DatePicker
                          value={new Date(startDate)}
                          onChange={date=>{handleFromDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<div className="span-date">             
<span> To </span>

<DatePicker
                          value={new Date(endDate)}
                          onChange={date=>{handleToDate(date)}}
                         
                          maxDate = {new Date()}
                        
                          
                     /> 
</div>
<button type="submit" className="btn btn-primary btn-col" onClick={handleGo}> Check </button>
</div>

</div> 
<div className="food-habits-sections">
   <div>
   <h5 className="title-of-lists"> Graph </h5>
    <div className="chart-div-glucose">
      
     
        <canvas id="chart-bp" width="800" height="400"></canvas>

      
    </div>
    </div>
    
     <div className="food-table">
     <h5 className=" title-of-lists"> Table </h5>
    {load===''? bpList.length>0 ?
     <>
    

 
     
   {BP_Table}
     </> 
     : <h6 style={{color: "red"}}> No data to show! </h6> : load==='Error in Fetching Data'? <h6 style={{color: "red"}}>{load}</h6> : <h6 style={{color: "grey"}}>{load}</h6>
   }
  
     </div>
   
   
   
     </div>
     </div>
     </>
  );
}

export default VitalsBP;