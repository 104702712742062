import React ,{useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import axios from 'axios'
import {APIURL} from '../../Global'

import "./hospdocnavbar.css";




function HospDocNavbar() {

  const [notification ,setNotification] =useState();

 
  useEffect(()=>{
    const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

    axios.get(`${APIURL}/api/v1/account/web-notification/?key=new`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){

       const data=res.data.new_notifications

        setNotification(data)
        

      }else{
      
      }


    })
    .catch(err=>{
      
  })


},[])


  return (
    <nav className="hosp-navbar">
      
       
      {
          window.location.href.includes('register-patient-details') ?

            <h4 className='nav-header'>Patient Record</h4>

            : null
        }

      
    
      
      <div className="btns">
    


          <button className="btn nav-btn">
          <NavLink exact className="inactive" activeClassName="active" to="/doctor">  <i className="fas fa-home"></i> HOME</NavLink>
        </button>
       <button className="btn nav-btn">
          <NavLink className="inactive" activeClassName="active" to="/patients-directory">PATIENT RECORDS </NavLink>
        </button>
         <button className="btn nav-btn">

          <NavLink className="inactive" activeClassName="active" to="/doctor/notifications"> { notification >=1 ?
            <div  className="notification">
            <svg width="1.5em" height="1.5em" viewBox="0 0 36 36"><path className="clr-i-solid--badged clr-i-solid-path-1--badged" d="M18 34.28A2.67 2.67 0 0 0 20.58 32h-5.26A2.67 2.67 0 0 0 18 34.28z" fill="red"></path><path className="clr-i-solid--badged clr-i-solid-path-2--badged" d="M32.85 28.13l-.34-.3A14.37 14.37 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94a10.92 10.92 0 0 0-.16-1.79A7.5 7.5 0 0 1 22.5 6v-.63a10.57 10.57 0 0 0-3.32-1V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.3 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93l-.34.3v2.82h29.8z" fill="currentColor"></path></svg>
            <span className="badge">{notification}</span>
            </div>
          : <svg width="1.5em" height="1.5em" viewBox="0 0 36 36"><path className="clr-i-solid clr-i-solid-path-1" d="M32.85 28.13l-.34-.3A14.37 14.37 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94A10.81 10.81 0 0 0 19.21 4.4V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.33 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81a14.4 14.4 0 0 1-2.47 2.93l-.34.3v2.82h29.8z" fill="currentColor"></path><path className="clr-i-solid clr-i-solid-path-2" d="M15.32 32a2.65 2.65 0 0 0 5.25 0z" fill="currentColor"></path></svg> } NOTIFICATIONS</NavLink>

        </button>
     
     
   



       
      </div>
    </nav>
  );
}

export default HospDocNavbar;
