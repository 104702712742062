import React, { useState } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Modal } from "react-bootstrap";
import { Button , Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import { APIURL } from "../../Global";
import ViewRemark from "../HospDr_AddPrescription/ViewRemark";
// import AddRemark from "../Remarks/AddRemark";
// import EditRemark from "../Remarks/EditRemark";
import CommentHistoryPopUp from "../Remarks/CommentHistory";
function ProcedureList(props) {
  const [procedures] = useState(props.data);
 const [patientdata]=  useState(props.data);

  const [modalShow, setModalShow] = useState(false);
  const [detailData, setDetailData] = useState("");
  const [statusData, setStatusData] = useState(props.status);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitMsg, setSubmitMsg] = useState(""); 
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [commentShow, setCommentShow] = useState(false);
  const [commenthistory, setCommenthistory] = useState(false);
  const [commenthistoryData, setCommenthistoryData] = useState(false);
  // const [remarkData, setRemarkData] = useState("");
  // const [editShow, setEditShow] = useState(false);
  // const [updateSuccess, setUpdateSuccess] = useState(false);
  // const [cell, setCell] = useState("");
  // const [remarkList, setRemarkList] = useState([]);
  // const [tableData, setTableData] = useState([]);
  // const [render, setRender] = useState(true);
  // const [backup, setBackup] = useState("");

  // const [errorShow, setErrorShow] = useState(false);

  const [errorSubmit, setErrorSubmit] = useState(false);

  const renderEditable = (cellInfo) => {
    if (procedures[cellInfo.index][cellInfo.column.id]) {
      return (
        <div className="fixed-co">
          {" "}
          {procedures[cellInfo.index][cellInfo.column.id]}
        </div>
      );
    } else {
      return <span className="text-danger">NIL</span>;
    }
  };
console.log(props.date)
  const renderProcedureName = (cellInfo) => {
    if (procedures[cellInfo.index][cellInfo.column.id].procedure_name) {
      return (
        <div className="fixed-co">
          {" "}
          {procedures[cellInfo.index][cellInfo.column.id].procedure_name}
        </div>
      );
    } else {
      return <span className="text-danger">NIL</span>;
    }
  };

  const renderProcedureType = (cellInfo) => {
    if (procedures[cellInfo.index][cellInfo.column.id].procedure_type) {
      return (
        <div className="fixed-co">
          {" "}
          {procedures[cellInfo.index][cellInfo.column.id].procedure_type}
        </div>
      );
    } else {
      return <span className="text-danger">NIL</span>;
    }
  };

  const renderProcedurePrice = (cellInfo) => {
    if (procedures[cellInfo.index][cellInfo.column.id].amount) {
      return (
        <div className="fixed-co">
          {" "}
          {procedures[cellInfo.index][cellInfo.column.id].amount}
        </div>
      );
    } else {
      return <span className="text-danger">NIL</span>;
    }
  };

  const renderDetails = (cellInfo) => {
    if (procedures[cellInfo.index][cellInfo.column.id]) {
      return (
        <button
          onClick={() =>
            handleView(procedures[cellInfo.index][cellInfo.column.id])
          }
          className="btn btn-primary btn-col btn-square btn-small"
        >
          View
        </button>
      );
    } else {
      return <span className="text-danger">NIL</span>;
    }
  };

  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ViewRemark data={detailData} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleView = (data) => {
    setDetailData(data);
    setModalShow(true);
  };

  const showMe = (data) => {
    setCommentShow(true);
  };

  const callSave = () => {
    const tokenString = localStorage.getItem("usertoken");

    const id = procedures.map((item) => {
      return item.procedure_details.procedure_id;
    });

    let str = tokenString.replace(/["]+/g, "");

    const procedure_id = id;
    const status = statusData;

    const dataToUpdate = {
      procedure_id,
      status,
    };

    axios
      .put(
        `${APIURL}/api/v1/hospital/patient-procedure-fulfilment/`,
        dataToUpdate,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setSubmitMsg(
            res.data.message ? res.data.message : "Updated successfully!"
          );
          setSuccessSubmit(true);
        } else {
          setErrorMsg(
            res.data.message ? res.data.message : "Error in submission"
          );
          setErrorSubmit(true);
        }
      })
      .catch((err) => {
        setErrorMsg("Error in submission");
        setErrorSubmit(true);
      });
  };

  const SuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>{props.msg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMsg !== ""
              ? errorMsg
              : "Error in Data Submission. Please try again!"}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const columnsProcedure = [
    {
      Header: "Procedure NAME",
      accessor: "procedure_details",
      Cell: renderProcedureName,
      //   style:{
      //     backgroundColor:"rgba(0,0,0,0.1)"
      //   },
      width: 240,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Type",
      accessor: "procedure_details",
      Cell: renderProcedureType,

      width: 190,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Area",
      accessor: "area",
      Cell: renderEditable,

      width: 140,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Sessions",
      accessor: "session",
      Cell: renderEditable,

      width: 150,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Qty",
      accessor: "quantity",
      Cell: renderEditable,

      width: 100,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Price",
      accessor: "procedure_details",
      Cell: renderProcedurePrice,

      width: 120,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Details",
      accessor: "details",
      Cell: renderDetails,

      width: 110,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Reason",
      accessor: "reason",
      Cell: renderDetails,

      width: 110,
      minWidth: 100,
      sortable: false,
    },
  ];

  const displayTable = (
    <ReactTable
      columns={columnsProcedure}
      data={procedures}
      defaultPageSize={5}
      className="procedure-table"
      resizable
    ></ReactTable>
  );

  const handleStatus = (e) => {
    setStatusData(e.target.value);
  };

   

  // const handleSave = (props) => {
  //   debugger
  //   setRemarkData(props);
  //   const tokenString = localStorage.getItem("usertoken");

  //   let str = tokenString.replace(/["]+/g, "");

  //   const dataToUpdate = {
  //     comment: props
  //   };

  //   axios
  //     .post(
  //       `${APIURL}/api/v1/hospital/procedure-comment/?patient_id=` +
  //         procedures[0].patient_id,
  //       dataToUpdate,
  //       {
  //         headers: {
  //           Authorization: "Token " + str,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )

  //     .then((res) => {
        
  //       if (res.data.status === "success") {
       
  //         setCommentShow(false);
  //         setSubmitMsg(
            
  //           res.data.message ? res.data.message : "Saved successfully!"
            
  //         );
          
  //         setSuccessSubmit(true);
          
  //       } 
        
        
  //       else {
  //         setErrorMsg(
  //           res.data.message ? res.data.message : "Error in submission"
  //         );
  //         setErrorSubmit(true);
  //       }
  //     })
  //     .catch((err) => {
  //       setErrorMsg("Error in submission");
  //       setErrorSubmit(true);
  //     });
  //     setCommentShow(false);
      
  // };

  const SubmitClose=(value)=>{

  setCommenthistory(value);
  }

   

  const ShowCommenthistory = () => {
 
    
    const tokenString = localStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    axios
      .get(
        `${APIURL}/api/v1/hospital/procedure-comment/?procedure_date=${props.date}&patient_id=` +
          procedures[0].patient_id,
        {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        
        if (res.data.status === "success") {
          setCommenthistoryData(res.data.comments.reverse())
          // setCommentShow(false)
           setCommenthistory(true);
        
        } else {
          setErrorMsg(
            res.data.message ? res.data.message : "Error in submission"
          );
          setErrorSubmit(true);
        }
      })
      .catch((err) => {
        setCommenthistory(false);
        // setErrorMsg("Error in submission");
        // setErrorSubmit(true);
      });
  };

  

  const CommentPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        // keyboard={false}
        dialogClassName="comment-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <br/>
        <Modal.Body className="text-center">
          <br/>
        <span style={{ float: "right" }} className="comment-history">
            Comment History
            <i
              // onClick={ShowCommenthistory}
              class="fas fa-arrow-circle-right arrow-ic ml-2"
            ></i>
          </span>
       
          {/* update={handleSend} save={addHandle} */}
          {/* <AddRemark save={handleSave} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  // {
  //   modalShow ?
  //   <SuccessPopup1
  //     show={modalShow}
  //     onHide= {() => { setModalShow(false); props.handleAdd();
  //     }}
  //   /> : null
  // }

  return (
    <>
      <div id="procedure-table-list">

      {props.toggle === "doctor" ? (
        <div className='flex-row'>

        <h2 style={{width:"95%"}} className="title-of-tasks">Procedures</h2>
        <div>
         
       
          
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Comments</Tooltip>}>
  <span style={{ float: "right" }} className="d-inline-block">
    {/* <Button disabled style={{ pointerEvents: 'none' }} className="btn btn-primary btn-col">
      Comments
    </Button> */}
       <button
            onClick={ShowCommenthistory}
            style={{ float: "right" }}
            className='btn btn-primary btn-col'
          >
            + 
          </button>
  </span>
</OverlayTrigger>
        </div>

        </div>
      ) : <h2 style={{width:"95%"}} className="title-of-tasks">Procedures</h2> }
      
        <br />
        {displayTable}
        <br />

        {props.toggle === "doctor" ? (
          <div className="status-procedure">
            Status
            <input
              type="radio"
              className="hideme"
              checked={statusData === "assigned"}
              id="assigned"
              value="assigned"
              onChange={handleStatus}
            />
            <label for="assigned" className="status-label">
              Assigned
            </label>
            <input
              type="radio"
              className="hideme"
              checked={statusData === "ongoing"}
              id="ongoing"
              value="ongoing"
              onChange={handleStatus}
            />
            <label for="ongoing" className="status-label">
              Ongoing
            </label>
            <input
              type="radio"
              className="hideme"
              checked={statusData === "completed"}
              id="completed"
              value="completed"
              onChange={handleStatus}
            />
            <label for="completed" className="status-label">
              Completed
            </label>
            <input
              type="radio"
              className="hideme"
              checked={statusData === "rejected"}
              id="rejected"
              value="rejected"
              onChange={handleStatus}
            />
            <label for="rejected" className="status-label">
              Rejected
            </label>
            <button
              onClick={callSave}
              style={{ alignSelf: "center" }}
              disabled={statusData === ""}
              className="btn btn-primary btn-col"
            >
              Save
            </button>
          </div>
        ) : null}

        {submitMsg !== "" ? (
          <SuccessPopup
            show={successSubmit}
            msg={submitMsg}
            onHide={() => {
              setSuccessSubmit(false);
              setSubmitMsg("");
              props.modalHandle();
            }}
          />
        ) : (
          ""
        )}

        {errorMsg !== "" ? (
          <SubmitErrorPopup
            show={errorSubmit}
            onHide={() => {
              setErrorSubmit(false);
              setErrorMsg("");
            }}
          />
        ) : (
          ""
        )}

        {modalShow ? (
          <ViewPopup
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          />
        ) : null}

        {commentShow ? (
          <CommentPopup
            show={commentShow}
            onHide={() => {
              setCommentShow(false);
              
            }}
          />
        ) : null}

        {commenthistory ? (
           
           <CommentHistoryPopUp
             show={commenthistory}
             loadCommentData={commenthistoryData}
             patientdata = {patientdata}
             retunSubmitClose = {SubmitClose}
             date = {props.date}

            //  onHide={() => {
            //    setCommenthistory(false)

            //  }}

            
             
          /> 
        ) : null
        
        
        }
      </div>
    </>
  );
}

export default ProcedureList;
